import { ClientKnowledgeDocument } from '@shapeci/types'
import { getSharableRoute } from '@shapeci/utils'
import { useEffect, useState } from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { cache } from '../caches'
import LoaderPage from '../components/Layout/LoaderPage'
import { useApi } from '../hooks/useApi'
import { getIdFromSharableRoute } from '../utils/documents'
import Editor from './Editor'

const Public = () => {
    const api = useApi()
    const { data: user } = cache.useUser()
    const { documentSlug } = useParams()

    const documentId = documentSlug && getIdFromSharableRoute(documentSlug)

    const [document, setDocument] = useState<ClientKnowledgeDocument>()

    useEffect(() => {
        if (!documentId) {
            setDocument(undefined)
            return
        }

        api.getDocument(documentId)
            .then((res) => {
                setDocument(res as ClientKnowledgeDocument)
            })
            .catch((e) => {
                setDocument(() => {
                    throw e
                })
            })
    }, [documentId])

    const isLoading = !documentId || !document
    const documentTeam = user?.teams?.find((team) => team.id === document?.team)
    const isTeamMember = documentTeam !== undefined

    if (isLoading) {
        return <LoaderPage />
    }

    if (!isTeamMember) {
        return <Editor />
    }

    return (
        <Navigate
            to={`/${documentTeam.domain}/documents/${getSharableRoute(
                document.id,
                document.title
            )}`}
        />
    )
}

export default Public
