import { FC, ReactElement } from 'react'
import { Navigate, NavigateProps, useLocation } from 'react-router-dom'

interface NavigateOnceProps extends NavigateProps {
    matchBeginning?: boolean
    children: ReactElement
}

export const NavigateOnce: FC<NavigateOnceProps> = ({
    to,
    matchBeginning,
    children,
    ...navigateProps
}) => {
    const location = useLocation()

    if (matchBeginning && location.pathname.startsWith(to.toString())) return children

    if (location.pathname === to) return children

    return <Navigate to={to} {...navigateProps} />
}
