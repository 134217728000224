import { fromEnum } from '../../utils/enum.util'

export const UNSETTABLE_ATTRIBUTES = ['meta'] as const
export const IMMUTABLE_ATTRIBUTES = [...UNSETTABLE_ATTRIBUTES, 'id'] as const

export enum PayloadTypes {
    LINK_ACCOUNTS = 'LinkAccounts',
    CREATE_ALERT = 'UpdateAlert',
    UPDATE_ALERT = 'UpdateAlert',
    CREATE_USER = 'CreateUser',
    INITIALIZE_USER = 'InitializeUser',
    UPDATE_USER = 'UpdateUser',
    UPLOAD_LOG = 'UploadLog',
    CREATE_VIEW = 'CreateView',
    UPDATE_VIEW = 'UpdateView',
    CREATE_TEAM = 'CreateTeam',
    UPDATE_TEAM = 'UpdateTeam',
    CREATE_FOLDER = 'CreateFolder',
    UPDATE_FOLDER = 'UpdateFolder',
    CREATE_GITEA_REPO = 'CreateGiteaRepo',
    UPDATE_GITEA_REPO = 'UpdateGiteaRepo',
    CREATE_MODEL = 'CreateModel',
    UPDATE_MODEL = 'UpdateModel',
    CREATE_DOCUMENT = 'CreateDocument',
    UPDATE_DOCUMENT = 'UpdateDocument',
    CREATE_BRANCH = 'CreateBranch',
    MERGE_BRANCH = 'MergeBranch',
    UPDATE_REVIEW = 'UpdateReview',
    CREATE_REVIEW = 'CreateReview',
    UPDATE_ACCOUNT = 'UpdateAccount',
    VERIFY_EMAIL = 'VerifyEmail',
    MERGE = 'Merge',
    SEARCH = 'Search',
}

export const payloadTypes = fromEnum('PayloadTypes', PayloadTypes)
