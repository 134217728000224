import moment from 'moment'

// eslint-disable-next-line import/prefer-default-export
export const getTimeAgo = (date: string | Date) => {
    if (!date) return 'never'

    let parsedDate
    if (date instanceof Date) parsedDate = date
    else parsedDate = new Date(Date.parse(date))

    return moment(parsedDate).fromNow()
}
