import {
    Branch,
    BranchCommit,
    ClientFolder,
    CollectionType,
    Model,
    ModelCommit,
    ModelContentType,
    ModelId,
    ModelPathData,
    RepositoryId,
} from '@shapeci/types'
import { Checkbox, Select, Space } from '@shapeci/ui'
import {
    formatBranchName,
    formatCommitMessage,
    getBranchRootFolderId,
    GITEA_DEFAULT_BRANCH_NAME,
} from '@shapeci/utils'
import { Data, GitBranch, GitCommit } from '@styled-icons/boxicons-regular'
import { FC, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { cache } from '../../caches'
import { isFolder } from '../../types/folder.types'
import { breadcrumbsFromPath } from '../../utils/crumb'
import Breadcrumbs, { BreadcrumbLink } from '../MenuBar/Breadcrumbs'
import RepoDirectory from '../RepoDirectory'

const DirectoryContainer = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`
const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 0;
`

const StyledBreadcrumps = styled(Breadcrumbs)`
    background: #e1e1e5;
`
const ProjectSelector = styled(Select)`
    width: 100%;
    z-index: 4;
    margin: ${({ theme }) => theme.getSpacing(1)} 0;
`
const BranchSelector = styled(ProjectSelector)`
    z-index: 3;
`
const CommitSelector = styled(ProjectSelector)`
    z-index: 2;
`

const CheckboxWrapper = styled(Space)`
    align-items: flex-start;
    justify-content: start;
    font-weight: 400;
    margin-top: ${({ theme }) => theme.getSpacing(1)};
    margin-bottom: ${({ theme }) => theme.getSpacing(2)};
`

type RepositoryPreview = {
    id: RepositoryId
    name: string
}

interface ModelSelectorProps {
    onSelectModelId: (
        modelId: ModelId,
        repoId: RepositoryId,
        modelPathData: ModelPathData,
        selectedCommit: ModelCommit | undefined
    ) => void
    onCloseModal: () => void
    isReviewDocument: boolean
    forcedBranch?: string
    forcedRepositoryId?: RepositoryId
}

const ModelSelector: FC<ModelSelectorProps> = ({
    onSelectModelId,
    onCloseModal,
    isReviewDocument,
    forcedRepositoryId,
    forcedBranch,
}) => {
    const [repoId, setRepoId] = useState<string>('')
    const [repoName, setRepoName] = useState<string>('')
    const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null)
    const [selectedCommit, setSelectedCommit] = useState<BranchCommit | undefined>(undefined)
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbLink[]>([])
    const [isAutoSync, setIsAutoSync] = useState<boolean>(true)
    const [filepath, setFilepath] = useState<string>('')

    // Team data
    const { data: team } = cache.useTeam()

    // Repo data
    const {
        data: repository,
        isLoading: isRepoLoading,
        isError: isRepoError,
    } = cache.useRepository(repoId)

    const branchName = selectedBranch?.name || GITEA_DEFAULT_BRANCH_NAME

    // Repo Selected Branch Commits data
    const { data: commits, isLoading: isCommitsLoading } = cache.useBranchCommits(
        repoId,
        branchName
    )

    // Path data
    const {
        data: folder,
        isLoading: isFolderLoading,
        isError: isFolderError,
    } = cache.usePath(repoId, branchName, filepath, CollectionType.FOLDER, selectedCommit?.commit)

    // Selector options
    const repoOptions = useMemo(
        () =>
            team?.repositories?.map((r: { name: string; id: string }) => ({
                label: r.name,
                value: r.id,
            })),
        [team?.id]
    )

    useEffect(() => {
        if (!repository) return

        const name = forcedBranch || GITEA_DEFAULT_BRANCH_NAME
        switchBranch(name)
    }, [repository])

    useEffect(() => {
        if (!team?.repositories?.length) return

        const repoIdToSet =
            isReviewDocument && forcedRepositoryId ? forcedRepositoryId : team.repositories[0].id
        let repoNameToSet = team.repositories[0].name
        if (isReviewDocument && forcedRepositoryId) {
            const repo = team.repositories.find(
                (r: RepositoryPreview) => r.id === forcedRepositoryId
            )
            if (repo) repoNameToSet = repo.name
        }

        switchRepo(repoIdToSet, repoNameToSet)
    }, [team?.id])

    useEffect(() => {
        setBreadcrumbs(breadcrumbsFromPath(filepath, getBreadcrumbValue))
    }, [filepath])

    /**
     * It's easiest to just set the value to be the full path to the folder. Then we
     * can copy that value to the current path with selected
     */
    const getBreadcrumbValue = (folderName?: string, path?: string) => {
        // This must be the root path
        if (!folderName && !path) return ''

        // It's one deep
        if (folderName && !path) return folderName

        // Every other case
        return `${path}/${folderName}`
    }

    const switchRepo = (repositoryId: RepositoryId, name: string) => {
        setRepoId(repositoryId)
        setRepoName(name)
        setSelectedBranch(null)
        resetPath()
    }

    const switchBranch = (name: string) => {
        if (!repository) return

        const branch = repository.branches.find(
            (b) => formatBranchName(b.name) === formatBranchName(name)
        )
        setSelectedCommit(undefined)
        setSelectedBranch(branch || null)
        resetPath()
    }

    const switchCommit = (hash: string | undefined) => {
        if (!selectedBranch) return

        let commit
        if (hash) commit = selectedBranch.commits.find((c) => c.commit === hash)

        setSelectedCommit(commit)
        resetPath()
    }

    const resetPath = () => {
        setBreadcrumbs([])
        setFilepath('')
    }

    const onRowClicked = (row: ClientFolder | Model) => {
        // TODO: fix but that you can set a wrong file path when quickly clicking between breadcrumb and table row
        if (row.type === ModelContentType.WEB_ACCESSIBLE_MODEL) {
            // add warning to select a commit
            if (!isAutoSync && !selectedCommit) return

            const pathWithoutRoot = breadcrumbs.filter((el) => el.label !== '~')
            const commitData = selectedCommit
                ? {
                      sha: selectedCommit.commit,
                      message: formatCommitMessage(selectedCommit.message),
                  }
                : undefined

            const pathData = {
                repoName,
                branchName: selectedBranch?.name ?? '',
                modelName: row.name,
                foldersPath: pathWithoutRoot,
            }
            onSelectModelId(row.id, repository!.id, pathData, commitData)
            onCloseModal()
        } else if (isFolder(row)) {
            setFilepath((p) => {
                if (!p.length) return row.title
                return `${p}/${row.title}`
            })
        }
    }

    const onCrumbClicked = (value: string) => {
        setFilepath(value)
    }

    const onBranchChange = (option: any) => {
        switchBranch(option.label)
    }

    const onCommitChange = (option: any) => {
        switchCommit(option.value)
    }

    const toggleAutoSyncCheck = () => {
        setIsAutoSync((prev) => !prev)
    }

    useEffect(() => {
        if (isAutoSync) switchCommit(undefined)
    }, [isAutoSync])

    const renderCommitValue = () => {
        // An assigned undefined value would still keep the old state visible
        const commitValue = selectedCommit
            ? {
                  label: formatCommitMessage(selectedCommit.message),
                  value: selectedCommit.commit,
              }
            : {
                  label: '',
                  value: '',
              }

        return commitValue
    }

    if (!team) return <p>Loading</p>

    if (!team.repositories.length) return <p>Could not find any repositories on this team</p>

    return (
        <ModalContentWrapper>
            <>
                <Space align="center">
                    <Data style={{ width: '1.375em', opacity: 0.6 }} />
                    <ProjectSelector
                        isSearchable
                        disabled={isReviewDocument}
                        options={repoOptions || []}
                        onChange={(repoOption: any) =>
                            switchRepo(repoOption.value, repoOption.label)
                        }
                        value={
                            repoId
                                ? {
                                      label: repoName,
                                      value: repoId,
                                  }
                                : undefined
                        }
                    />
                </Space>
                <>
                    <Space align="center">
                        <GitBranch style={{ width: '1.375em', opacity: 0.6 }} />
                        <BranchSelector
                            isSearchable
                            disabled={isReviewDocument}
                            isLoading={isRepoLoading || !repository}
                            options={
                                repository?.branches.map((b) => ({
                                    label: formatBranchName(b.name),
                                    value: getBranchRootFolderId(b),
                                })) || []
                            }
                            isClearable={false}
                            onChange={(option: any) => {
                                onBranchChange(option)
                            }}
                            value={
                                selectedBranch
                                    ? {
                                          label: formatBranchName(selectedBranch.name),
                                          value: getBranchRootFolderId(selectedBranch),
                                      }
                                    : undefined
                            }
                        />
                    </Space>
                    {!isReviewDocument && !isAutoSync && selectedBranch && (
                        <Space align="center">
                            <GitCommit style={{ width: '1.375em', opacity: 0.6 }} />
                            <CommitSelector
                                size="auto"
                                isSearchable
                                isLoading={isCommitsLoading}
                                disabled={isReviewDocument || isCommitsLoading}
                                options={commits?.map((c) => ({
                                    label: formatCommitMessage(c.message),
                                    value: c.commit,
                                }))}
                                isClearable={false}
                                onChange={(option: any) => {
                                    onCommitChange(option)
                                }}
                                value={renderCommitValue()}
                            />
                        </Space>
                    )}

                    <>
                        {!isReviewDocument && (
                            <CheckboxWrapper size={0}>
                                <Checkbox
                                    checked={isAutoSync}
                                    onChange={() => toggleAutoSyncCheck()}
                                    label="Auto Sync"
                                />
                            </CheckboxWrapper>
                        )}

                        <StyledBreadcrumps
                            path={breadcrumbs}
                            onClick={onCrumbClicked}
                            maxDepth={4}
                        />
                        <DirectoryContainer>
                            <RepoDirectory
                                folder={folder as ClientFolder}
                                isError={isRepoError || isFolderError}
                                onFolderCreate={() => {}}
                                onEmptyFolderDelete={() => {}}
                                onModelCreate={() => {}}
                                onModelUpdate={() => {}}
                                onRowClick={onRowClicked}
                                isRepoEditable={false}
                                isLoading={isFolderLoading || isRepoLoading}
                                isFolderDeleteLoading={false}
                                setIsCreateFolderModalOpen={() => {}}
                                setIsUploadModelModalOpen={() => {}}
                                fromModelModalSelector
                            />
                        </DirectoryContainer>
                    </>
                </>
            </>
        </ModalContentWrapper>
    )
}
ModelSelector.defaultProps = {
    forcedRepositoryId: '',
    forcedBranch: '',
}

export default ModelSelector
