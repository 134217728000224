import * as t from 'io-ts'

import { user, userMetadata } from '../models/user.model'
import { partialOnType, pickOnType } from '../utils/io-ts.util'

export interface DocumentCollaborator extends t.TypeOf<typeof documentCollaborator> {}
export const documentCollaborator = t.intersection([
    partialOnType(pickOnType(userMetadata, ['avatarName'])),
    pickOnType(user, ['id', 'firstName', 'lastName']),
    t.partial({
        sessionId: t.string,
    }),
])
