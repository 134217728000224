import { Card, CardProps, zIndex } from '@shapeci/ui'
import React, { CSSProperties, FC, useEffect } from 'react'
import styled from 'styled-components'

const ModalWrapper = styled.div`
    ${Card} {
        z-index: ${zIndex.MODAL};
        ${({ theme }) => theme.getDepth(2)}
        position: fixed;
        left: 50%;
        top: 40%;
        max-height: 80vh;
        overflow: hidden;
        transform: translateX(-50%) translateY(-50%);
        animation: fadeIn 0.15s;
    }

    .mask {
        position: fixed;
        background: ${({ theme }) => theme.colors.grey900};
        opacity: 0.4;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: ${zIndex.MODAL_OVERLAY};
        cursor: pointer;
        transform: translateY(0);
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateX(-50%) translateY(-47.5%) scale(0.975);
        }
        100% {
            opacity: 1;
            transform: translateX(-50%) translateY(-50%) scale(1);
        }
    }
`

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
    className?: string
    cardProps?: CardProps & { style?: CSSProperties }
    forceOpen?: boolean
    style?: CSSProperties
}

const Modal: FC<ModalProps> = ({
    isOpen,
    onClose,
    children,
    className,
    cardProps,
    forceOpen,
    style,
}) => {
    if (!isOpen) {
        return null
    }

    useEffect(() => {
        if (forceOpen) return
        document.addEventListener(
            'keydown',
            (e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                    onClose()
                }
            },
            { capture: true }
        )
    }, [])

    return (
        <ModalWrapper className={className} style={style}>
            <Card {...cardProps}>{children}</Card>
            <div
                className="mask"
                onClick={!forceOpen ? onClose : () => {}}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
            />
        </ModalWrapper>
    )
}

Modal.defaultProps = {
    className: undefined,
    cardProps: undefined,
    forceOpen: false,
}

export default Modal
