export interface ProcessToOptions {
    isPublic?: boolean
    isNew?: boolean
    updateTeamDomain?: string
}

export function processTo(to: string, teamDomain?: string, options?: ProcessToOptions) {
    let processedTo = to

    if (options?.updateTeamDomain) {
        // if the provided with an updated team domain, replace the param
        // eslint-disable-next-line no-param-reassign
        teamDomain = options.updateTeamDomain
    }

    if (options?.isNew) {
        // if the user has no teams, then we use the placeholder domain 'new'
        // eslint-disable-next-line no-param-reassign
        teamDomain = 'new'
    }

    if (teamDomain) {
        if (to.includes(':teamDomain')) {
            // if the provided path contains a :teamDomain param, replace it with the teamDomain
            processedTo = to.replace(':teamDomain', teamDomain)
        } else if (
            options?.isPublic !== true &&
            !to.includes(':teamDomain') &&
            !to.startsWith(`/${teamDomain}`)
        ) {
            // if the provided path is not a public path, prepend the teamDomain to the path
            processedTo = `/${teamDomain}${to}`
        }
    }

    return processedTo
}
