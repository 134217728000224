import { DocumentCollaborator, EditorSession } from '@shapeci/types'
import uniqBy from 'lodash.uniqby'
import { ReactNode } from 'react'
import create from 'zustand'

import { MenuBarProps } from '../components/MenuBar'
import { BreadcrumbLink } from '../components/MenuBar/Breadcrumbs'

interface MenuBarState {
    value: MenuBarProps | null
    setUsers: (users: DocumentCollaborator[]) => void
    addUser: (users: DocumentCollaborator) => void
    removeUser: (session: EditorSession) => void
    setPrePath: (prePath: ReactNode[]) => void
    setPath: (path: BreadcrumbLink[]) => void
    setPreActions: (actions: ReactNode[]) => void
    setPostActions: (actions: ReactNode[]) => void
    setBottomless: (bottomless: boolean) => void
    setOnCrumbClick: (onClick: (val: string) => void) => void
    setValue: (value: MenuBarProps | null) => void
    setFullWidth: (fullWidth: boolean) => void
}

const useMenuBarStore = create<MenuBarState>((set) => ({
    value: {
        prePath: [],
        path: [],
        preStackActions: [],
        postStackActions: [],
        onClick: () => {},
        bottomless: true,
        fullWidth: false,
        users: [],
    },
    setPrePath: (prePath: ReactNode[]) => set((state) => ({ value: { ...state.value, prePath } })),
    setPath: (path: BreadcrumbLink[]) => set((state) => ({ value: { ...state.value, path } })),
    setPreActions: (preStackActions: ReactNode[]) =>
        set((state) => ({ value: { ...state.value, preStackActions } })),
    setPostActions: (postStackActions: ReactNode[]) =>
        set((state) => ({ value: { ...state.value, postStackActions } })),
    setBottomless: (bottomless: boolean) =>
        set((state) => ({ value: { ...state.value, bottomless } })),
    setFullWidth: (fullWidth: boolean) =>
        set((state) => ({ value: { ...state.value, fullWidth } })),
    setOnCrumbClick: (onClick: (val: string) => void) =>
        set((state) => ({ value: { ...state.value, onClick } })),
    setValue: (value: MenuBarProps | null) => set((state) => ({ ...state, value }), true),
    setUsers: (users: DocumentCollaborator[]) =>
        set((state) => ({ value: { ...state.value, users } })),
    removeUser: (session: EditorSession) =>
        set((state) => ({
            value: {
                ...state.value,
                users: (state.value?.users || []).filter(
                    (u) => u.id !== session.userId || u.sessionId !== session.sessionId
                ),
            },
        })),
    addUser: (user: DocumentCollaborator) =>
        set((state) => {
            const newUsers = (state.value?.users || []).concat(user)
            const userSet = uniqBy(newUsers, (u) => `${u.id}-${u.sessionId}`)

            return {
                value: { ...state.value, users: userSet },
            }
        }),
}))

export default useMenuBarStore
