import { UserAvatar } from '@shapeci/components'
import { Badge, Card, Popover, zIndex } from '@shapeci/ui'
import { getAvatarBucketUri } from '@shapeci/utils'
import {
    Cog,
    Data,
    ExpandVertical,
    Folder,
    Search,
    Star,
    Trash,
} from '@styled-icons/boxicons-regular'
import { FC, useState } from 'react'
import styled, { css } from 'styled-components'

import { cache } from '../../../caches'
import Lockup, { LockupLoader, TeamLockup } from '../../Lockup'
import { useCommandMenu } from '../../Search/store'
import SidebarLink, { SidebarButton } from './SidebarLink'
import SidebarLinkGroup from './SidebarLinkWrapper'
import { TeamSwitcher, UserMenu } from './SidebarMenus'

interface SidebarProps {}

const NoTeamsLockup = styled(Lockup)`
    .shape__avatar {
        display: none;
    }
`

const hoverBackgroundStyles = css`
    &:hover:before {
        content: '';
        position: absolute;
        left: -${({ theme }) => theme.getSpacing(1)};
        top: -${({ theme }) => theme.getSpacing(1)};
        width: 100%;
        height: 100%;
        padding: ${({ theme }) => theme.getSpacing(1)};
        background: #e9e9e9;
        border-radius: ${({ theme }) => theme.borderRadius};
    }
`

const SidebarWrapper = styled.aside`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    background: ${({ theme }) => theme.colors.grey300};

    border-right: ${({ theme }) => theme.border};

    header {
        display: flex;
        justify-content: space-between;
        padding: ${({ theme }) => theme.getSpacing(3)} ${({ theme }) => theme.getSpacing(3)}
            ${({ theme }) => theme.getSpacing(1.5)};
        gap: ${({ theme }) => theme.getSpacing(3)};
    }

    section,
    footer {
        width: 100%;
        box-sizing: border-box;
        padding: ${({ theme }) => theme.getSpacing(3)};

        :not(:last-of-type) {
            border-bottom: ${({ theme }) => theme.border};
        }
    }

    footer {
        position: absolute;
        bottom: 0;
        font-size: 0.9125rem;

        ${Card} {
            line-height: 1.375;
            opacity: 0.875;

            background: ${({ theme }) => theme.colors.info100};
            color: ${({ theme }) => theme.colors.info700};
            border-color: ${({ theme }) => theme.colors.info200};

            h3 {
                margin: 0 0 ${({ theme }) => theme.getSpacing(0.5)};
                font-size: 1em;
                font-weight: 500;
            }

            p {
                margin: 0 0 ${({ theme }) => theme.getSpacing(0.25)};
                font-weight: 400;
            }
        }
    }
`

const LockupInner = styled.div<{ $isDisabled: boolean }>`
    display: flex;
    color: ${({ theme }) => theme.colors.grey600};
    justify-content: space-between;
    flex: 1;
    user-select: none;
    position: relative;
    cursor: pointer;
    width: 12em;

    svg {
        width: 1.125rem;
        margin-left: ${({ theme }) => theme.getSpacing(1)};
        z-index: 0;
    }

    .shape__lockup {
        z-index: 0;
    }

    ${({ $isDisabled }) =>
        $isDisabled
            ? css`
                  cursor: not-allowed;
                  opacity: 0.5;
              `
            : hoverBackgroundStyles}
`

export const AvatarButtonWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${hoverBackgroundStyles}

    .shape__avatar {
        z-index: 1;
    }

    .shape__badge-standalone {
        position: absolute;
        right: -0.625em;
        bottom: -0.5em;
        z-index: 2;
        width: 1.375rem;
        height: 1.375rem;
        min-height: unset;
        min-width: unset;
        font-size: 0.75rem;
        border: 2px solid ${({ theme }) => theme.colors.grey300};
    }
`

const Sidebar: FC<SidebarProps> = () => {
    const [isTeamSwitcherOpen, setIsTeamSwitcherOpen] = useState<boolean>(false)
    const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false)

    const { data: team, isInitialLoading } = cache.useTeam()
    const { data: user } = cache.useUser()
    const { data } = cache.useNotifications()

    const { setOpen: setSearchOpen } = useCommandMenu()

    const isNotifications = data?.alerts?.length || 0

    if (!user) {
        return null
    }

    let teamLockup = <LockupLoader size={0.125} />

    if (team) {
        teamLockup = <TeamLockup team={team} size={0.125} />
    } else if (!isInitialLoading) {
        teamLockup = <NoTeamsLockup title={'No Teams'} size={0.125} />
    }

    return (
        <SidebarWrapper>
            <header>
                <Popover
                    isOpen={isTeamSwitcherOpen}
                    onClickOutside={() => setIsTeamSwitcherOpen(false)}
                    hover={false}
                    content={<TeamSwitcher onClose={() => setIsTeamSwitcherOpen(false)} />}
                    align="start"
                    zIndex={zIndex.MODAL.toString()}
                >
                    <LockupInner
                        // disable menu if there are no teams available
                        onClick={() => team && setIsTeamSwitcherOpen(!isTeamSwitcherOpen)}
                        $isDisabled={!team}
                    >
                        {teamLockup}
                        <ExpandVertical />
                    </LockupInner>
                </Popover>
                <Popover
                    isOpen={isUserMenuOpen}
                    onClickOutside={() => setIsUserMenuOpen(false)}
                    hover={false}
                    content={<UserMenu onClose={() => setIsUserMenuOpen(false)} user={user} />}
                    align="start"
                    zIndex={zIndex.MODAL.toString()}
                >
                    <AvatarButtonWrapper onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}>
                        <UserAvatar
                            user={user}
                            showTooltip={false}
                            imageServerURL={getAvatarBucketUri(localStorage)}
                        />
                        {isNotifications ? (
                            <Badge standalone threshold={9} value={isNotifications} />
                        ) : null}
                    </AvatarButtonWrapper>
                </Popover>
            </header>
            <section>
                <SidebarButton
                    onClick={() => {
                        setSearchOpen(true)
                    }}
                >
                    <Search /> Search
                </SidebarButton>
            </section>
            <section>
                <SidebarLinkGroup>
                    {team && (
                        <>
                            <SidebarLink to={`/projects`}>
                                <Data /> Projects
                            </SidebarLink>
                            <SidebarLink to={`/folders`}>
                                <Folder /> Documents
                            </SidebarLink>
                            <SidebarLink to={`/starred`}>
                                <Star /> Starred
                            </SidebarLink>
                            <SidebarLink to="/trash">
                                <Trash /> Trash
                            </SidebarLink>
                        </>
                    )}
                </SidebarLinkGroup>
            </section>

            {team && (
                <section>
                    <SidebarLinkGroup>
                        <SidebarLink to={`/settings/team/general`}>
                            <Cog /> Team Settings
                        </SidebarLink>
                    </SidebarLinkGroup>
                </section>
            )}
            <footer>
                <Card padding={2}>
                    <h3>Need help?</h3>
                    <p>
                        Reach out to us for support over Slack if you&apos;re stuck or have
                        feedback.
                    </p>
                </Card>
            </footer>
        </SidebarWrapper>
    )
}

export default Sidebar
