/* eslint-disable react/destructuring-assignment */
import { ImageSkeleton } from '@shapeci/components'
import { TeamId } from '@shapeci/types'
import { getErrorMessage } from '@shapeci/utils'
import { ImageElement, ImageElementProps, TImageElement } from '@udecode/plate'
import { FC, useEffect, useMemo, useState } from 'react'
import { Node, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'

import { useApi } from '../../hooks/useApi'
import { useSlateReactStatic } from '../../hooks/useSlateReactStatic'
import ImageError from '../ImageError'

interface TImage extends Omit<TImageElement, 'url'> {
    uploadId?: string
    publicURL?: string
    width?: number
}

interface ImageProps extends Omit<ImageElementProps, 'element'> {
    teamid: TeamId
    element: TImage
}

export const Image: FC<ImageProps> = (props: ImageProps) => {
    const api = useApi()
    const editor = useSlateReactStatic()
    const path = ReactEditor.findPath(editor, props.element as any)
    const [errorMessage, setErrorMessage] = useState('')
    const [isDownloading, setIsDownloading] = useState(true)
    const [imageURL, setImageURL] = useState('')
    const imageElementProps = useMemo<ImageElementProps>(
        () =>
            ({
                ...props,
                element: {
                    ...props.element,
                    url: imageURL,
                },
            } as any as ImageElementProps),
        [imageURL]
    )

    useEffect(() => {
        if (!props.element.publicURL) return

        setImageURL(props.element.publicURL)
        setIsDownloading(false)
    }, [props.element.publicURL])

    useEffect(() => {
        if (!props.element.uploadId) return () => {}

        tryDownloadImage()

        return () => {
            if (imageURL) URL.revokeObjectURL(imageURL)
        }
    }, [props.element.uploadId])

    useEffect(() => {
        if (!imageURL) return

        // This is a little jank, but since I don't want to rewrite the image component, I'm just wrapping
        // Plate's which requires the url to be set on the node
        Transforms.setNodes(
            editor,
            {
                url: imageURL,
            } as Partial<Node>,
            { at: path }
        )
    }, [imageURL])

    const tryDownloadImage = async () => {
        setIsDownloading(true)

        try {
            await downloadImage()
        } catch (error) {
            setErrorMessage(getErrorMessage(error, 'Could not download image'))
        } finally {
            setIsDownloading(false)
        }
    }

    const downloadImage = async () => {
        // Download blob and make a local URL
        const blob = await api.getUpload(props.teamid, props.element.uploadId!)
        const url = URL.createObjectURL(blob)

        setImageURL((oldUrl) => {
            if (oldUrl) URL.revokeObjectURL(oldUrl)

            return url
        })
    }

    if (errorMessage) return <ImageError message={errorMessage} imageWidth={props.element.width} />

    if (isDownloading || !imageElementProps || !imageElementProps.element.url)
        return (
            <div {...props.attributes}>
                <ImageSkeleton imageWidth={props.element.width} />
                {props.children}
            </div>
        )

    return <ImageElement {...imageElementProps} />
}
