import 'react-loading-skeleton/dist/skeleton.css'

import { Helmet } from 'react-helmet'

import devFavicon from './dev-favicon.png'
import Router from './router'

function App() {
    const isDev = process.env.NODE_ENV === 'development'

    return (
        <>
            {isDev && (
                <Helmet>
                    <link id="favicon" rel="icon" href={devFavicon} type="image/png" />
                </Helmet>
            )}

            <Router />
        </>
    )
}

export default App
