/* eslint-disable react/require-default-props */
import {
    AutoformatRule,
    createPlateEditor,
    CreatePlateEditorOptions,
    createPluginFactory,
    createPlugins,
    createTEditor,
    Decorate,
    DecorateEntry,
    DOMHandler,
    getTEditor,
    InjectComponent,
    InjectProps,
    KeyboardHandler,
    NoInfer,
    OnChange,
    OverrideByKey,
    PlateId,
    PlatePlugin,
    PlatePluginComponent,
    PlatePluginInsertData,
    PlatePluginProps,
    PlateProps,
    PluginOptions,
    SerializeHtml,
    useEditorRef,
    useEditorState,
    usePlateActions,
    usePlateEditorRef,
    usePlateEditorState,
    usePlateSelectors,
    usePlateStates,
    WithOverride,
} from '@udecode/plate'

import { MyRootBlock } from './blockElement'
import { ShapeEditor } from './editor'

export type { ShapeEditor as ShapeEditorType }

export type ShapeValue = MyRootBlock[]

/**
 * Plate types
 */

export type MyDecorate<P = PluginOptions> = Decorate<P, ShapeValue, ShapeEditor>
export type MyDecorateEntry = DecorateEntry<ShapeValue>
export type MyDOMHandler<P = PluginOptions> = DOMHandler<P, ShapeValue, ShapeEditor>
export type MyInjectComponent = InjectComponent<ShapeValue>
export type MyInjectProps = InjectProps<ShapeValue>
export type MyKeyboardHandler<P = PluginOptions> = KeyboardHandler<P, ShapeValue, ShapeEditor>
export type MyOnChange<P = PluginOptions> = OnChange<P, ShapeValue, ShapeEditor>
export type MyOverrideByKey = OverrideByKey<ShapeValue, ShapeEditor>
export type MyPlatePlugin<P = PluginOptions> = PlatePlugin<P, ShapeValue, ShapeEditor>
export type MyPlatePluginInsertData = PlatePluginInsertData<ShapeValue>
export type MyPlatePluginProps = PlatePluginProps<ShapeValue>
export type MyPlateProps = PlateProps<ShapeValue, ShapeEditor>
export type MySerializeHtml = SerializeHtml<ShapeValue>
export type MyWithOverride<P = PluginOptions> = WithOverride<P, ShapeValue, ShapeEditor>

/**
 * Plate store, Slate context
 */

export const getShapeEditor = (editor: ShapeEditor) => getTEditor<ShapeValue, ShapeEditor>(editor)
export const useShapeEditorRef = () => useEditorRef<ShapeValue, ShapeEditor>()
export const useShapeEditorState = () => useEditorState<ShapeValue, ShapeEditor>()
export const useMyPlateEditorRef = (id?: PlateId) => usePlateEditorRef<ShapeValue, ShapeEditor>(id)
export const useMyPlateEditorState = (id?: PlateId) =>
    usePlateEditorState<ShapeValue, ShapeEditor>(id)
export const useMyPlateSelectors = (id?: PlateId) => usePlateSelectors<ShapeValue, ShapeEditor>(id)
export const useMyPlateActions = (id?: PlateId) => usePlateActions<ShapeValue, ShapeEditor>(id)
export const useMyPlateStates = (id?: PlateId) => usePlateStates<ShapeValue, ShapeEditor>(id)

/**
 * Utils
 */
export const createShapeEditor = () => createTEditor() as ShapeEditor
export const createMyPlateEditor = (
    options: CreatePlateEditorOptions<ShapeValue, ShapeEditor> = {}
) => createPlateEditor<ShapeValue, ShapeEditor>(options)
export const createMyPluginFactory = <P = PluginOptions>(
    defaultPlugin: PlatePlugin<NoInfer<P>, ShapeValue, ShapeEditor>
) => createPluginFactory(defaultPlugin)
export const createMyPlugins = (
    plugins: MyPlatePlugin[],
    options?: {
        components?: Record<string, PlatePluginComponent>
        overrideByKey?: MyOverrideByKey
    }
) => createPlugins<ShapeValue, ShapeEditor>(plugins, options)

export type MyAutoformatRule = AutoformatRule<ShapeValue, ShapeEditor>
