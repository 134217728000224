import * as t from 'io-ts'

import { PayloadTypes } from './http.payloads'

export type SearchPayload = t.TypeOf<typeof searchPayload>
export const searchPayload = t.strict({
    type: t.literal(PayloadTypes.SEARCH),

    teamId: t.string,
    query: t.string,

    /**
     * The total amount of results to return. The search will be performed on all
     * searchable collections and the results will be sorted by relevance and limited
     * to this number.
     *
     * Note that high limits will be significantly slower since we need to search
     * limit * num_collection times (e.g. 10 * 3 = 30 searches for 3 collections,
     * even though 10 results will be returned)
     *
     * @default 10
     */
    limit: t.union([t.number, t.undefined]),

    /**
     * if true, the search will be performed on the full text of the object
     *
     * e.g. all blocks of a document vs just the metadata
     * e.g. all fields of a user vs just the name
     */
    fullText: t.union([t.boolean, t.undefined]),
})
