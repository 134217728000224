import {
    ClientKnowledgeDocument,
    ClientReviewDocument,
    DocumentType,
    Nullish,
} from '@shapeci/types'

export const isReviewDocument = (
    document: Nullish<ClientReviewDocument | ClientKnowledgeDocument>
): document is ClientReviewDocument => document?.type === DocumentType.REVIEW_DOCUMENT
