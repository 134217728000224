import { useAuth0 } from '@auth0/auth0-react'
import { RestorePoint } from '@shapeci/types'
import { Button, Heading, LIGHT_THEME, Result, Space, zIndex } from '@shapeci/ui'
import { EDITOR_WIDTH, formatDateWithTime } from '@shapeci/utils'
import { History as HistoryIcon, LeftArrowAlt } from '@styled-icons/boxicons-regular'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { useApi } from '../../../hooks/useApi'
import { modelStore } from '../../../store/model.store'
import { getIdFromSharableRoute } from '../../../utils/documents'
import { NOOP } from '../../Comments/constants'
import { MENU_BAR_HEIGHT } from '../../Layout/constants'
import LoaderPage from '../../Layout/LoaderPage'
import { MenuBarWrapper } from '../../MenuBar'
import { ShapeEditor, ShapeEditorValue } from '../core'
import { ShapeEditorType } from '../core/types'
import { TitleContainer, TitleInput } from '../ui'
import { RestorePointWithCreator } from './types'
import { getRestorePoints } from './utils'
import VersionList from './VersionList'

const BackButton = styled(Button)`
    font-size: 1.125rem;
    margin-left: 1.5rem;
    position: relative;

    :hover {
        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.grey800};
    }
`

const HistoryWrapper = styled.div`
    position: fixed;
    background: ${({ theme }) => theme.colors.grey100};

    left: 0;
    top: 0;
    height: 100%;
    width: 100vw;

    display: grid;
    grid-template-areas:
        'menubar versions'
        'editor versions';
    grid-template-columns: 1fr 20rem;
    grid-template-rows: ${MENU_BAR_HEIGHT} 1fr;

    z-index: ${zIndex.MENU_BAR + 3};
`

const HistoryMenuOverlay = styled(MenuBarWrapper)`
    z-index: ${zIndex.MENU_BAR + 2};

    grid-area: menubar;

    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.grey100};
    box-sizing: border-box;

    > div {
        justify-content: flex-start;
        gap: ${({ theme }) => theme.getSpacing(2)};
    }
`

const EditorInner = styled.div`
    box-sizing: border-box;
    position: relative;
    font-weight: 400;
    width: ${EDITOR_WIDTH};
    line-height: 1.5;
    margin: 0 auto;
    flex-grow: 1;
`

const HistoryContent = styled.section<{ $isLoading: boolean }>`
    width: 100%;
    padding: ${({ theme }) => theme.getSpacing(3)} 0 ${({ theme }) => theme.getSpacing(9)};
    overflow-y: scroll;
    grid-area: editor;

    ${({ $isLoading }) =>
        $isLoading &&
        css`
            // prevent interaction on new version loading
            pointer-events: none;
            user-select: none;

            & > div {
                // mute editor to show loading state
                opacity: 0.3;
            }
        `}
`

interface HistoryProps {
    title: string
    teamId: string
    editorValue: ShapeEditorValue
    onClose: () => void
    restoreDocument: (restorePointId: string) => void
    canRestore: boolean
}

export default function History({
    title,
    teamId,
    editorValue: currentEditorValue,
    onClose,
    restoreDocument,
    canRestore,
}: HistoryProps) {
    const historyEditorRef = useRef<ShapeEditorType | null>(null)
    const api = useApi()
    const authContext = useAuth0()

    const { documentSlug } = useParams()

    if (!documentSlug) {
        throw Error('Attempted to load document history outside of a document.')
    }

    const documentId = getIdFromSharableRoute(documentSlug)

    const [allRestorePoints, setAllRestorePoints] = useState<RestorePointWithCreator[]>([])
    const [selectedRestorePoint, setSelectedRestorePoint] = useState<RestorePoint | null>(null)
    const [editorValue, setEditorValue] = useState<ShapeEditorValue>()

    const [isLoadingVersion, setIsLoadingVersion] = useState<boolean>(false)
    const [isErrorGettingVersion, setIsErrorGettingVersion] = useState<boolean>(false)
    const [isLoadingMore, setIsLoadingMore] = useState<boolean>(true)

    const [pageCounter, setPageCounter] = useState<number>(0)

    // if we have more pages of history to fetch
    const hasFetchedAllVersions = allRestorePoints[allRestorePoints.length - 1]?.hasMore === false

    // if there is no editor value then the user is initially loading this overlay
    const isLoadingInitial = !editorValue

    // load more versions as the page increments
    useEffect(() => {
        async function loadRestorePoints() {
            if (hasFetchedAllVersions) {
                // if we already have all the pages, abort the fetch
                return
            }

            setIsLoadingMore(true)
            const restorePoints = await getRestorePoints(
                api,
                documentId,
                pageCounter,
                new Date().toISOString()
            )

            setAllRestorePoints((prev) => [...prev, ...restorePoints])
            setIsLoadingMore(false)
        }

        loadRestorePoints()
    }, [pageCounter, api])

    // update editor value when a new restore point is selected
    useEffect(() => {
        async function loadEditorValue() {
            setIsLoadingVersion(true)

            if (!selectedRestorePoint) {
                // if we there is no selected restore point then we are previewing the live document
                setTimeout(() => {
                    setEditorValue(currentEditorValue)
                    setIsErrorGettingVersion(false)
                    setIsLoadingVersion(false)
                    // wait a few ms to the change apparent to the user when switching to current version
                    // (even though we should have it ready from the parent editor page instance)
                }, 350)

                return
            }

            // otherwise load a document from the restore point
            try {
                const nodes = await api.getEditorNodes(documentId, selectedRestorePoint.id)
                setEditorValue({
                    nodes: nodes as any,
                    undoHistory: [],
                    cachedVersion: 0,
                })
                setIsErrorGettingVersion(false)
            } catch (e) {
                setIsErrorGettingVersion(true)
            } finally {
                setIsLoadingVersion(false)
            }
        }
        loadEditorValue()
    }, [selectedRestorePoint])

    const handleRestore = canRestore
        ? (restorePointId: string) => {
              // eslint-disable-next-line no-alert
              if (!window.confirm('Are you sure you want to restore this version?')) {
                  return
              }

              restoreDocument(restorePointId)
          }
        : undefined

    let inner = (
        <Result
            intent="danger"
            message="Error fetching document version."
            description="Try again or contact us if this problem persists."
        />
    )

    if (!isErrorGettingVersion) {
        inner = isLoadingInitial ? (
            <LoaderPage />
        ) : (
            <EditorInner>
                <TitleContainer>
                    <TitleInput value={title} disabled onChange={() => {}} />
                </TitleContainer>
                <ShapeEditor
                    authContext={authContext}
                    onTransactionFinished={NOOP}
                    onHover={NOOP}
                    onOpenWizard={NOOP}
                    isPreviewMode={true}
                    modelStore={modelStore}
                    theme={LIGHT_THEME}
                    value={editorValue}
                    teamId={teamId}
                    ref={historyEditorRef}
                />
            </EditorInner>
        )
    }

    return (
        <>
            <HistoryWrapper>
                <HistoryMenuOverlay>
                    <div>
                        <Space justify="center" align="center">
                            <BackButton
                                kind="icon"
                                intent="muted"
                                onClick={onClose}
                                title="Back to editor"
                            >
                                <LeftArrowAlt />
                            </BackButton>
                        </Space>
                        <Space justify="start" align="center" size={4}>
                            <Heading kind="h1" size={2.25}>
                                {selectedRestorePoint?.meta.dateCreated
                                    ? formatDateWithTime(selectedRestorePoint.meta.dateCreated)
                                    : 'Current Document'}
                            </Heading>
                            {selectedRestorePoint && handleRestore && (
                                <Space justify="end" align="center" size={2}>
                                    <Button
                                        prepend={<HistoryIcon />}
                                        onClick={() => handleRestore(selectedRestorePoint.id)}
                                    >
                                        Restore this version
                                    </Button>
                                    {/*
                                    // TODO - implement copy from restore point
                                    <Button kind="secondary" intent="muted" prepend={<Copy />}>
                                        Make a copy
                                    </Button> */}
                                </Space>
                            )}
                        </Space>
                    </div>
                </HistoryMenuOverlay>
                <VersionList
                    isLoading={isLoadingInitial}
                    isLoadingNextPage={isLoadingMore}
                    setSelectedRestorePoint={setSelectedRestorePoint}
                    selectedRestorePoint={selectedRestorePoint}
                    hasMorePages={!hasFetchedAllVersions}
                    getNextPage={() => setPageCounter((prev) => prev + 1)}
                    versions={allRestorePoints}
                    onRestore={handleRestore}
                />
                <HistoryContent $isLoading={isLoadingVersion && !isLoadingInitial}>
                    {inner}
                </HistoryContent>
            </HistoryWrapper>
        </>
    )
}
