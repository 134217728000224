import * as Dialog from '@radix-ui/react-dialog'
import { Input, zIndex } from '@shapeci/ui'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const SearchInput = styled(Input)`
    box-sizing: border-box;
    margin-bottom: ${({ theme }) => theme.getSpacing(1)};
`

export const SearchDialogContent = styled(Dialog.Content)`
    width: 100%;
    max-width: min(650px, 95vw);

    position: fixed;
    top: 15vh;
    left: 50%;
    transform: translateX(-50%);

    z-index: ${zIndex.MODAL};

    border: 1px solid ${({ theme }) => theme.colors.grey200};
    border-radius: 0.625rem;
    background: ${({ theme }) => theme.colors.white};

    box-shadow: ${({ theme }) => theme.getDepth(2)};

    overflow: hidden;

    padding: ${({ theme }) => theme.getSpacing(1)};

    display: flex;
    flex-direction: column;

    .search-hotkey {
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        gap: 0.375ch;

        position: absolute;
        top: ${({ theme }) => theme.getSpacing(1.875)};
        right: ${({ theme }) => theme.getSpacing(1.875)};
        border-radius: 0.175rem;
        z-index: 1;

        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.grey600};
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 700;

        font-family: ${({ theme }) => theme.fonts.monospace};

        svg {
            margin-top: -0.125rem;
            width: 0.9125em;
            height: 0.9125em;
        }

        padding: ${({ theme }) => theme.getSpacing(0.5)} ${({ theme }) => theme.getSpacing(1)};
    }

    animation: search-fade-in 0.15s;

    @keyframes search-fade-in {
        0% {
            opacity: 0;
            transform: translateX(-50%) translateY(2.5%) scale(0.975);
        }
        100% {
            opacity: 1;
            transform: translateX(-50%) translateY(0) scale(1);
        }
    }
`

export const SearchDialogOverlay = styled(Dialog.Overlay)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    z-index: ${zIndex.MODAL_OVERLAY};
`

export const SearchResultItem = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => theme.getSpacing(1.5)};
    border-radius: 0.375rem;

    user-select: none;

    gap: ${({ theme }) => theme.getSpacing(4)};

    svg {
        width: 1.125rem;
        height: 1.125rem;
        color: ${({ theme }) => theme.colors.grey600};
        margin-left: ${({ theme }) => theme.getSpacing(0.5)};
    }

    cursor: pointer;

    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.grey800};
    font-weight: 500;

    text-decoration: none;

    .left {
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.getSpacing(1.5)};

        flex: 1;

        min-width: 0;
    }

    .search-result-meta {
        display: block;
        flex: 1;
        min-width: 0;

        h4 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        p,
        h4 {
            width: 100%;
            line-height: 1.25;
            margin: 0;
        }

        p {
            margin-top: 0.25rem;

            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            @supports (-webkit-line-clamp: 2) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: initial;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .search-highlight {
        color: ${({ theme }) => theme.colors.grey600};
        font-size: 0.875rem;

        [data-search-highlight='hit'] {
            font-weight: 550;
            color: ${({ theme }) => theme.colors.grey700};
        }
    }

    .right {
        position: relative;
        color: ${({ theme }) => theme.colors.grey600};
    }

    .hovered-indicator {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        color: ${({ theme }) => theme.colors.grey800};

        svg {
            width: 1.125rem;
            height: 1.125rem;
        }
        opacity: 0;
    }

    &:focus {
        outline: none;
        background: ${({ theme }) => theme.colors.grey300};

        .right {
            time {
                opacity: 0;
            }

            .hovered-indicator {
                opacity: 1;
            }
        }
    }
`
