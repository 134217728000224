import * as t from 'io-ts'

import { folder } from '../../models/folder.model'
import {
    omitOnIntersection,
    partialOnIntersection,
    pickOnIntersection,
} from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const FOLDER_UNSETTABLE_ATTRIBUTES = [...UNSETTABLE_ATTRIBUTES] as const
const FOLDER_MUTABLE_ATTRIBUTES = ['title'] as const

export interface CreateFolderPayload extends t.TypeOf<typeof createFolderPayload> {}
export const createFolderPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_FOLDER),
    folder: omitOnIntersection(folder, FOLDER_UNSETTABLE_ATTRIBUTES),
})

export interface UpdateFolderPayload extends t.TypeOf<typeof updateFolderPayload> {}
export const updateFolderPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_FOLDER),
    folder: partialOnIntersection(pickOnIntersection(folder, FOLDER_MUTABLE_ATTRIBUTES)),
})
