import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { cache } from '../caches'

const useTeamId = () => {
    const { data: user } = cache.useUser()
    const { teamDomain } = useParams()

    return useMemo(() => {
        if (!user) {
            return null
        }

        const team = user?.teams.find((t) => t.domain === teamDomain)
        return team?.id ?? null
    }, [user, teamDomain])
}

export default useTeamId
