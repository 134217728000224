import { ModelCommit, ModelId, ModelPathData, RepositoryId } from '@shapeci/types'
import { Button, Card, Portal } from '@shapeci/ui'
import { X } from '@styled-icons/boxicons-regular'
import React from 'react'
import styled from 'styled-components'

import Modal from '../Modal'
import ModelSelector from './model.selector'

const SelectModelModalWrapper = styled(Modal)`
    ${Card} {
        width: 600px;
        height: 80vh;
        box-sizing: border-box;
        padding: ${({ theme }) => theme.getSpacing(4)};
        top: 50%;
        display: flex;
        flex-direction: column;

        h2 {
            margin: 0;
            display: flex;
            justify-content: space-between;

            button {
                border: none;
                padding: 0;
            }
            svg {
                width: 1.75rem;
                height: 1.75rem;
                color: ${({ theme }) => theme.colors.grey600};
            }
        }
    }

    .content-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 0;
        margin-top: 1em;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                margin-right: ${({ theme }) => theme.getSpacing(1)};
            }
        }
    }
`

interface SelectModelModalProps {
    isModalOpen: boolean
    onCloseModal: () => void
    onSelectModelId: (
        modelId: ModelId,
        repoId: RepositoryId,
        modelPathData: ModelPathData,
        selectedCommit: ModelCommit | undefined
    ) => void
    isReviewDocument: boolean
    forcedRepositoryId?: RepositoryId
    forcedBranch?: string
}

const SelectModelModal: React.FC<SelectModelModalProps> = ({
    isModalOpen,
    onCloseModal,
    onSelectModelId,
    isReviewDocument,
    forcedRepositoryId,
    forcedBranch,
}) => (
    <Portal>
        <SelectModelModalWrapper isOpen={isModalOpen} onClose={onCloseModal}>
            <h2>
                Select a model to insert
                <Button
                    kind="icon"
                    onClick={(e) => {
                        e.stopPropagation()
                        onCloseModal()
                    }}
                >
                    <X />
                </Button>
            </h2>
            <div className="content-wrapper">
                <ModelSelector
                    forcedRepositoryId={forcedRepositoryId}
                    forcedBranch={forcedBranch}
                    isReviewDocument={isReviewDocument}
                    onSelectModelId={onSelectModelId}
                    onCloseModal={onCloseModal}
                />
            </div>
        </SelectModelModalWrapper>
    </Portal>
)

SelectModelModal.defaultProps = {
    forcedRepositoryId: '',
    forcedBranch: '',
}
export default SelectModelModal
