import { GITEA_DEFAULT_BRANCH_NAME } from '@shapeci/utils'
import { Cog, GitMerge, Star } from '@styled-icons/boxicons-regular'
import { Folder } from '@styled-icons/boxicons-solid'
import { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { cache } from '../../caches'
import { useAppNavigate } from '../../hooks/useAppNavigate'
import useMenuBarStore from '../../hooks/useMenuBarStore'
import { AppNavigate } from '../../router/AppNavigate'
import { getIdFromSharableRoute } from '../../utils/documents'
import ActionButton from '../MenuBar/ActionButton'
import ShareButton from '../MenuBar/ShareButton'
import LoaderPage from './LoaderPage'
import PageWithTabs from './PageWithTabs'

const ProjectLayout = () => {
    const { repoSlug } = useParams()

    const repoId = getIdFromSharableRoute(repoSlug ?? '')

    const { data: repository, isLoading, isError } = cache.useRepository(repoId)
    const { branchName, commitHash } = useParams<{ branchName?: string; commitHash?: string }>()

    const navigate = useAppNavigate()
    const setMenuBar = useMenuBarStore((state) => state.setValue)

    useEffect(() => {
        if (!repository) {
            return () => {}
        }

        setMenuBar({
            path: [
                {
                    label: 'Projects',
                    value: '/projects',
                },
                {
                    label: repository?.name ?? '',
                    value: '',
                },
            ],
            postStackActions: [
                <ActionButton>
                    <Star />
                </ActionButton>,
                <ShareButton />,
            ],
            users: [],
            onClick: (value: string) => navigate(value),
            bottomless: true,
        })

        return () => {
            setMenuBar(null)
        }
    }, [setMenuBar, repository])

    if (isError) {
        return <AppNavigate to="/projects" />
    }

    if (isLoading || !repository) {
        return <LoaderPage />
    }
    return (
        <PageWithTabs
            title={repository.name}
            description={repository.description}
            tabs={[
                {
                    label: 'Files',
                    path: `/projects/${repoSlug}/files/${branchName ?? GITEA_DEFAULT_BRANCH_NAME}${
                        commitHash ? `/${commitHash}` : ''
                    }`,
                    icon: <Folder />,
                    matchPath: [/\/projects\/[^\\]+\/files\/.*/],
                },
                {
                    label: 'Merge Requests',
                    path: `/projects/${repoSlug}/reviews`,
                    icon: <GitMerge />,

                    // Also highlight this tab if the diff page is being visted
                    matchPath: [/\/projects\/[^\\]+\/diff\/./, /\/projects\/[^\\]+\/reviews\/./],
                },
                {
                    label: 'Settings',
                    path: `/projects/${repoSlug}/settings`,
                    icon: <Cog />,
                },
            ]}
        >
            <Outlet context={{ repository }} />
        </PageWithTabs>
    )
}

export default ProjectLayout
