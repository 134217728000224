import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { documentReviewStatus, reviewStatus } from '../models/document.model'
import { documentId } from '../models/id.model'
import { objectMetadata } from '../models/metadata.model'
import { documentCollaborator } from './documentCollaborator.types'

export interface DocumentReviewStub extends t.TypeOf<typeof documentReviewStub> {}
export const documentReviewStub = t.type({
    status: reviewStatus,
    reviewer: documentCollaborator,
})

export interface MergeRequestStub extends t.TypeOf<typeof mergeRequestStub> {}
export const mergeRequestStub = t.type({
    meta: objectMetadata,
    id: documentId,
    title: t.string,
    status: documentReviewStatus,
    reviews: t.array(documentReviewStub),
})

export interface MergeRequestStubWithCreator extends t.TypeOf<typeof mergeRequestStubWithCreator> {}
export const mergeRequestStubWithCreator = t.intersection([
    mergeRequestStub,
    t.type({
        commitHash: t.string,
        creator: documentCollaborator,
    }),
])

export interface CommitWithDocument extends t.TypeOf<typeof commitWithDocument> {}
export const commitWithDocument = t.intersection([
    t.type({
        commit: t.string,
        message: t.string,
        number: t.number,
        headCommit: t.string,
        branch: t.string,
        meta: objectMetadata,
    }),
    t.partial({
        document: mergeRequestStub,
    }),
])

export interface BranchWithDocument extends t.TypeOf<typeof branchWithDocument> {}
export const branchWithDocument = t.type({
    document: mergeRequestStubWithCreator,
    headCommit: t.string,
    branchName: t.string,
    status: documentReviewStatus,
    meta: t.type({
        dateCreated: DateFromISOString,
        lastUpdated: DateFromISOString,
        lastUpdatedBy: documentCollaborator,
        creator: documentCollaborator,
    }),
})
