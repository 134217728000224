import { FC, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import useMenuBarStore from '../../hooks/useMenuBarStore'
import MenuBar from '../MenuBar'
import { LayoutGridAreas, MENU_BAR_HEIGHT, SIDE_BAR_WIDTH } from './constants'
import LoaderPage from './LoaderPage'
import Sidebar from './Sidebar'

/**
 * Top level layout with grid areas for sidebar, menu bar, and main content.
 */
export const LayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: ${SIDE_BAR_WIDTH} 1fr;
    grid-template-rows: ${MENU_BAR_HEIGHT} 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        '${LayoutGridAreas.SIDEBAR} ${LayoutGridAreas.MENU_BAR}'
        '${LayoutGridAreas.SIDEBAR} ${LayoutGridAreas.MAIN}';

    margin: 0 auto;
    height: 100vh;
`

export const MenuBarDummy = styled.div`
    grid-area: ${LayoutGridAreas.MENU_BAR};
`

export interface LayoutProps {
    title: string
}

const MainLayout: FC<LayoutProps> = ({ title }: LayoutProps) => {
    const menuBarState = useMenuBarStore((state) => state.value)

    return (
        <LayoutWrapper>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <Sidebar />
            {menuBarState ? <MenuBar {...menuBarState} /> : <MenuBarDummy />}
            <Suspense fallback={<LoaderPage />}>
                <Outlet />
            </Suspense>
        </LayoutWrapper>
    )
}

export default MainLayout
