/* eslint-disable no-param-reassign */
import { BlockType } from '@shapeci/types'
import { PlateEditor, TDescendant, TOperation, Value } from '@udecode/plate'
import { Text } from 'slate'

import { hasChildren, hasNode } from '../../helpers/operations'

/**
 * Leaf nodes, (I.e inline texts) don't usually have the "type" attribute set. However, our backend expects that
 * all blocks have a type set. This plugin infers that text nodes without a type are inline texts. If we ever encounter
 * a node without a type that isn't a text node, we throw an error.
 */
export const withBlockType = <V extends Value = Value, E extends PlateEditor<V> = PlateEditor<V>>(
    editor: E
) => {
    const { apply } = editor

    editor.apply = (op) => {
        apply(addTypeToOp(op))
    }

    return editor
}

const addTypeToOp = <T extends TOperation>(op: T) => {
    if (!hasNode(op)) return op

    const node = addTypeToNodeAndChildren(op.node)
    return {
        ...op,
        node,
    }
}

const addTypeToNodeAndChildren = <T extends TDescendant>(node: T): T => {
    const newNode = addTypeToNode(node)
    if (!hasChildren(node)) return newNode

    const children = node.children.map((child) => addTypeToNodeAndChildren(child))

    return {
        ...newNode,
        children,
    }
}

const addTypeToNode = <T extends TDescendant>(node: T) => {
    if (node.type) return node

    if (!Text.isText(node)) throw new Error(`Cannot infer type of node ${node}`)

    return {
        ...node,
        type: BlockType.INLINE_TEXT,
    }
}
