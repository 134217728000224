import { Button, Result } from '@shapeci/ui'
import { getErrorMessage } from '@shapeci/utils'
import { LogOut } from '@styled-icons/boxicons-regular'
import { useRouteError } from 'react-router-dom'
import styled from 'styled-components'

import { cache } from '../../caches'
import { useAuth } from '../../hooks/useAuth'

const DEFAULT_MESSAGE = 'Something went wrong on our end.'

const ErrorPageWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    padding: ${({ theme }) => theme.getSpacing(1)};
    justify-content: center;
    align-items: center;
`

const LogoutButton = styled(Button)`
    position: absolute;
    left: 0;
    top: 0;
`

export default function ErrorPage() {
    const error = useRouteError()
    const invalidateUser = cache.useInvalidateUser()
    const { logout } = useAuth()

    console.error(error)

    const handleLogout = async () => {
        logout()
        invalidateUser()
        localStorage.clear()
        window.location.reload()
    }

    const message = getErrorMessage(error) || DEFAULT_MESSAGE

    return (
        <ErrorPageWrapper>
            <LogoutButton kind="text" intent="muted" onClick={handleLogout} prepend={<LogOut />}>
                Logout
            </LogoutButton>
            <Result
                message={message}
                description="Try again and contact us on Slack if this keeps happening."
                intent="danger"
            />
        </ErrorPageWrapper>
    )
}
