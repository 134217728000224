import * as t from 'io-ts'

import { baseOperation, OperationType } from './base.operations'

export const documentPoint = t.type({
    path: t.array(t.number),
    offset: t.number,
})

export interface SelectionProperties extends t.TypeOf<typeof selectionProperties> {}
export const selectionProperties = t.partial({
    anchor: documentPoint,
    focus: documentPoint,
})

export interface SetSelectionOperation extends t.TypeOf<typeof setSelectionOperation> {}
export const setSelectionOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.SET_SELECTION),
        properties: t.union([t.null, selectionProperties]),
        newProperties: t.union([t.null, selectionProperties]),
    }),
])
