import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'
import { diffId, modelId, repositoryId } from './id.model'
import { objectMetadata } from './metadata.model'
import { sceneGraph } from './scenegraph.model'
import { MongoModelType } from './types.model'

/**
 * There are four states a diff can be in:
 *     - Submitting: We are in the process of sending the request to the SWAPI server
 *     - Processing: The SWAPI server received our request and is processing it
 *     - Succeeded: We've recieved a response from the SWAPI server with the result
 *     - Failed: Something went wrong, we don't have a diff
 */
export enum DiffState {
    SUBMITTING_REQUEST = 'Submitting',
    PROCESSING_REQUEST = 'Processing',

    // These two need to match the resource state to play nicely with withPolling.ts
    // In newer versions of typescript we can just set these values to be the same as the resource enum
    SUCCEEDED = 'Succeeded',
    FAILED = 'Failed',
}

export const diffState = fromEnum('DiffState', DiffState)

export interface Diff extends t.TypeOf<typeof diff> {}
export const diff = t.intersection([
    t.strict({
        // Base object details
        id: diffId,
        type: t.literal(MongoModelType.DIFF),
        meta: objectMetadata,

        // Defines what's being diffed
        repoId: repositoryId,
        baseCommit: t.string,
        baseId: modelId,
        ourCommit: t.string,
        ourId: modelId,
        theirCommit: t.string,
        theirId: modelId,

        // Metadata regarding the status of the diff and its processing
        status: diffState,
    }),
    t.partial({
        // This is the request ID in the swapi server. This is only used for polling from there
        swapiReqId: t.string,

        // General message on state of resource. Error message will be put here on failure
        message: t.string,

        // Position in the SWAPI processing queue
        queuePosition: t.number,

        // The URL of the actual diff SLDPRT file
        fileLocation: t.string,

        // The scenegraph for the part. Identifies features and conflicts
        sceneGraph,
    }),
])
