import { EditorSession } from '@shapeci/types'
import create from 'zustand'

type StateUpdate<T> = T | ((old: T) => T)

interface DocumentStoreValue {
    localSessionId: string
    editorSession: EditorSession | null
    onOpenEditRequestModal: () => void
}

interface DocumentState {
    value: DocumentStoreValue
    setEditorSession: (update: StateUpdate<EditorSession | null>) => void
    setLocalSessionId: (localSessionId: string) => void
}

const useDocumentStore = create<DocumentState>((set) => ({
    value: {
        localSessionId: '',
        editorSession: null,
        onOpenEditRequestModal: () => {},
    },
    setLocalSessionId: (localSessionId: string) =>
        set((state) => ({ value: { ...state.value, localSessionId } })),
    setEditorSession: (newVal: StateUpdate<EditorSession | null>) =>
        set((state) => {
            if (typeof newVal === 'function')
                return {
                    value: { ...state.value, editorSession: newVal(state.value.editorSession) },
                }
            return { value: { ...state.value, editorSession: newVal } }
        }),
}))

export default useDocumentStore
