import * as t from 'io-ts'

import { modelId, repositoryId } from './id.model'
import { modelCommit, modelPathData } from './model.model'

export type Vec3 = t.TypeOf<typeof vec3>
export const vec3 = t.type({
    x: t.number,
    y: t.number,
    z: t.number,
})

export interface CrossSection extends t.TypeOf<typeof crossSection> {}
export const crossSection = t.type({
    id: t.string,
    baseNormal: vec3,
    pitch: t.number,
    roll: t.number,
    pos: vec3,
})

export interface Camera extends t.TypeOf<typeof camera> {}
export const camera = t.type({
    eye: vec3,
    look: vec3,
    up: vec3,
})

export interface ModelViewerState extends t.TypeOf<typeof modelViewerState> {}
export const modelViewerState = t.partial({
    crossSections: t.array(crossSection),
    hiddenNodeIds: t.array(t.string),
    camera,
    modelId,
    repoId: repositoryId,
    modelPathData,
    modelCommit,
})
