import { DragIndicator } from '@styled-icons/material'
import {
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_IMAGE,
    ELEMENT_OL,
    ELEMENT_PARAGRAPH,
    ELEMENT_UL,
    PlatePluginComponent,
    withDraggables,
} from '@udecode/plate'
import styled from 'styled-components'

import { ELEMENT_CODE } from '../plugins/codePlugin/types'
import { ELEMENT_LATEX } from '../plugins/latexPlugin/types'
import { ELEMENT_MODEL_VIEWER } from '../plugins/modelViewer/types'

const styles = {
    grabber: { fontSize: 12 },
    grabberText: { color: 'rgba(255, 255, 255, 0.45)' },
    dragButton: {
        width: 18,
        height: 18,
        color: 'rgba(55, 53, 47, 0.3)',
    },
}

const DragButton = styled.button`
    overflow: hidden;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    border-style: none;
    cursor: pointer;
    outline: 0;

    min-width: 18px;
    min-height: 18px;
`

// TODO: Add draggables back
export const withStyledDraggable = (component: PlatePluginComponent, componentKey: string) => {
    const draggableComponents = withStyledDraggables({
        [componentKey]: component,
    })

    return draggableComponents[componentKey]
}

export const withStyledDraggables = (
    components: Record<string, PlatePluginComponent>
): Record<string, PlatePluginComponent> =>
    withDraggables(components, [
        {
            keys: [ELEMENT_PARAGRAPH, ELEMENT_UL, ELEMENT_OL],
            level: 0,
        },
        {
            keys: [
                ELEMENT_PARAGRAPH,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_UL,
                ELEMENT_OL,
                ELEMENT_MODEL_VIEWER,
                ELEMENT_LATEX,
                ELEMENT_CODE,
                ELEMENT_IMAGE,
            ],
            onRenderDragHandle: () => (
                <DragButton type="button">
                    <DragIndicator style={styles.dragButton} />
                </DragButton>
            ),
        },
        {
            keys: [
                ELEMENT_PARAGRAPH,
                ELEMENT_MODEL_VIEWER,
                ELEMENT_UL,
                ELEMENT_OL,
                ELEMENT_H1,
                ELEMENT_H2,
                ELEMENT_H3,
                ELEMENT_LATEX,
                ELEMENT_CODE,
                ELEMENT_IMAGE,
            ],
            styles: {
                blockToolbarWrapper: {
                    height: '100%',
                },
                blockToolbar: {
                    height: '100%',
                },
            },
        },
    ])
