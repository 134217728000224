import { BlockType } from '@shapeci/types'
import { Button, Portal, RPopover, Space, Tooltip } from '@shapeci/ui'
import { ChevronDown } from '@styled-icons/boxicons-solid'
import { setNodes, useEditorRef } from '@udecode/plate'
import { useState } from 'react'
import styled from 'styled-components'

import { TOOLBAR_TEXT_OPTIONS } from './constants'
import MarkButtons from './MarkButtons'
import ToolbarSeparator from './Separator'

const OptionButton = styled.button`
    all: unset;

    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts};

    > * {
        margin: 0;
    }

    p {
        font-size: 0.875rem;
        font-weight: 400;
    }

    padding: ${({ theme }) => theme.getSpacing(1.5)} ${({ theme }) => theme.getSpacing(2)};
    padding-right: ${({ theme }) => theme.getSpacing(2)};

    box-sizing: border-box;

    :hover,
    :focus {
        background: ${({ theme }) => theme.colors.grey400};
    }
`

const Content = styled(RPopover.Content)`
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.grey100};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: ${({ theme }) => theme.border};

    padding: ${({ theme }) => theme.borderRadius} 0;

    ${({ theme }) => theme.getDepth(2)};
`

interface TextButtonsProps {
    selectedType: BlockType | null
}

export default function TextButtons({ selectedType }: TextButtonsProps) {
    const [open, setOpen] = useState(false)

    const editor = useEditorRef()

    const selectedOption = TOOLBAR_TEXT_OPTIONS.find((option) => option.value === selectedType)

    const onChange = ({ value }: (typeof TOOLBAR_TEXT_OPTIONS)[number]) => {
        if (value === selectedType) {
            // don't do anything if the selected type is the same as the current type
            return
        }

        setNodes(editor as any, { type: value as BlockType })
    }

    return (
        <>
            {selectedOption ? (
                <>
                    <Tooltip content="Text Styles" placement="bottom">
                        <Space align="center">
                            <RPopover.Root open={open} onOpenChange={setOpen}>
                                <RPopover.Trigger asChild>
                                    <Button
                                        kind="text"
                                        onMouseDown={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                        }}
                                        size="small"
                                        append={<ChevronDown />}
                                        intent="muted"
                                    >
                                        {selectedOption.label}
                                    </Button>
                                </RPopover.Trigger>
                                <Portal>
                                    <Content
                                        align="start"
                                        onOpenAutoFocus={(e) => e.preventDefault()}
                                        onCloseAutoFocus={(e) => e.preventDefault()}
                                        sideOffset={10}
                                    >
                                        {TOOLBAR_TEXT_OPTIONS.map((option) => (
                                            <OptionButton
                                                key={option.value}
                                                onClick={() => {
                                                    onChange(option)
                                                    setOpen(false)
                                                }}
                                                onMouseDown={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                            >
                                                <option.component>{option.label}</option.component>
                                            </OptionButton>
                                        ))}
                                    </Content>
                                </Portal>
                            </RPopover.Root>
                        </Space>
                    </Tooltip>
                    <ToolbarSeparator />
                </>
            ) : null}
            <MarkButtons />
            <ToolbarSeparator />
        </>
    )
}
