/* eslint-disable no-restricted-syntax */
import { ModelStore } from '@shapeci/components'
import { TeamId } from '@shapeci/types'
import {
    AnyObject,
    createPlateUI,
    ELEMENT_IMAGE,
    PlatePluginComponent,
    RenderFunction,
    withProps,
} from '@udecode/plate'

import ModelViewer from '../blocks/ModelViewer'
import { Image } from '../components/Image'
import { ELEMENT_MODEL_VIEWER } from '../plugins/modelViewer/types'
import { Listeners, withOnHover } from './withOnHover'
import { withStyledDraggables } from './withStyledDraggables'

export const createShapeUI = (teamId: TeamId, listeners: Listeners, store: ModelStore) => {
    const plateUI = createComponents(teamId, store)

    for (const [element, component] of Object.entries(plateUI)) {
        plateUI[element] = withDragDisabled(component) as any
    }

    for (const [element, component] of Object.entries(plateUI)) {
        plateUI[element] = withIdOnDOM(component) as any
    }

    for (const [element, component] of Object.entries(plateUI)) {
        plateUI[element] = withOnHover(component, listeners) as any
    }

    // for (const [element, component] of Object.entries(plateUI)) {
    //     plateUI[element] = withContextMenu(component) as any
    // }

    return withStyledDraggables(plateUI)
}

const createComponents = (
    teamId: TeamId,
    store: ModelStore
): Record<string, PlatePluginComponent<any>> =>
    createPlateUI({
        [ELEMENT_IMAGE]: withProps(Image, { teamid: teamId }),
        [ELEMENT_MODEL_VIEWER]: withProps(ModelViewer, { store }),
    })

/**
 * Adds the block's id to its DOM node.
 * This is how the comments will be able to know where the referenced block is in the DOM
 */
const withIdOnDOM =
    (renderer: RenderFunction<AnyObject>) =>
    (props: AnyObject, defaultRender?: (p?: AnyObject) => JSX.Element | null) =>
        renderer({ ...props, id: props?.element?.id }, defaultRender)

/**
 * Prevents individual elements from being draggable. Dragging and dropping random items that
 * are rendered causes react-dnd errors.
 */
const withDragDisabled =
    (renderer: RenderFunction<AnyObject>) =>
    (props: AnyObject, defaultRender?: (p?: AnyObject) => JSX.Element | null) =>
        renderer(
            {
                ...(props || {}),
                attributes: {
                    ...(props?.attributes || {}),
                    onDragStart: (e: Event) => e.preventDefault(),
                },
            },
            defaultRender
        )

/**
 * Adds a context menu to the block
 */
// TODO re-implment when we have document.execCommand availability from a Chrome extension
// const withContextMenu =
//     (renderer: RenderFunction<AnyObject>) =>
//     (props: AnyObject, defaultRender?: (p?: AnyObject) => JSX.Element | null) =>
//         (
//             <BlockContextMenu>
//                 {renderer({ ...props, id: props?.element?.id }, defaultRender)}
//             </BlockContextMenu>
//         )
