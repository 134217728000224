import { ClientThread as ThreadType } from '@shapeci/types'
import { getShapeClassName, zIndex } from '@shapeci/ui'
import { COMMENT_GUTTER, EDITOR_WIDTH } from '@shapeci/utils'
import { FC, MouseEvent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { THREAD_GUTTER_GAP, THREAD_TRANSITION_DELAY_MS, THREAD_WIDTH } from './constants'
import { ThreadsContextType, ThreadsProvider } from './Context'
import Thread from './Thread'
import { AnyThreadWithPosition, NewThread } from './types'
import { isNewThread, ThreadPositionsManager } from './utils'

const ThreadManageContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;

    position: absolute;
    width: ${THREAD_WIDTH}px;
    right: -${COMMENT_GUTTER}px;

    z-index: ${zIndex.COMMENTS};
`

// highlights the referenced block of the open thread
const ElementHighlight = styled.div<{ height: number; y: number | null }>`
    ${({ height }) => (height === 0 ? 'display: none;' : '')}

    --height: ${({ height }) => height}px;
    --y: ${({ y }) => y}px;

    position: absolute;
    top: 0;
    left: calc(-${EDITOR_WIDTH} - ${THREAD_GUTTER_GAP * 1.25}px);
    width: calc(${EDITOR_WIDTH} + ${THREAD_GUTTER_GAP / 2.5}px);
    border-radius: ${({ theme }) => theme.borderRadius};
    height: var(--height);
    background-color: ${({ theme }) => theme.colors.primary100};
    border-right: 3px solid ${({ theme }) => theme.colors.primary500};
    transform: translateY(var(--y));
`

export interface ThreadManagerProps {
    threads: (ThreadType | NewThread)[]
    openThreadId: string | null
    setOpenThreadId: (threadId: string | null) => void

    editingCommentId: string | null
    setEditingCommentId: (commentId: string | null) => void

    submitComment: (content: string, thread: ThreadType | NewThread) => void
    editComment: (threadId: string, commentId: string, content: string) => void
    deleteComment: (threadId: string, commentId: string) => void
    deleteThread: (threadId: string) => void
    abandonComment: () => void
    isDocumentInPreviewMode: boolean

    nodeIdOrder: string[]
}

const ThreadManager: FC<ThreadManagerProps> = ({
    threads,
    openThreadId,
    editingCommentId,
    setEditingCommentId,
    setOpenThreadId,
    submitComment,
    editComment,
    deleteComment,
    deleteThread,
    abandonComment,
    nodeIdOrder,
    isDocumentInPreviewMode,
}) => {
    const [threadsWithPositions, setThreadsWithPositions] = useState<AnyThreadWithPosition[]>([])
    const openThread = useMemo(
        () => threads.find((thread) => thread.id === openThreadId),
        [threads, openThreadId]
    )
    const [highlightedBlockHeight, setHighlightedBlockHeight] = useState<number>(0)
    const [highlightedBlockY, setHighlightedBlockY] = useState<number>(0)

    const threadPositionsManager = useMemo(
        () => new ThreadPositionsManager({ isDocumentInPreviewMode }),
        [isDocumentInPreviewMode]
    )

    /**
     * Handle closing the active thread when the user clicks outside of it
     * and will clean up any abandoned comment editors
     *
     * @param {MouseEvent} - e
     * @param {boolean} - withOverride - whether the user chose to explicitly close the thread
     * @returns {void}
     */
    const clearOpenThread = (e: MouseEvent, withOverride?: boolean) => {
        if (!withOverride) {
            const target = e.target as HTMLElement

            if (
                target.closest('.shape__thread') ||
                target.closest('.shape__comment-button') ||
                target.closest('.shape__comment') ||
                target.closest('.shape__comment-editor') ||
                target.closest('.shape__comment-dropdown-menu') ||
                // radix-ui dropdown menus force <html /> over the page when open
                // if the user clicks directly on the html element we don't want to close the thread
                target.tagName === 'HTML'
            ) {
                return
            }
        }

        if (openThread && isNewThread(openThread)) {
            abandonComment()
        }

        setOpenThreadId(null)
        setEditingCommentId(null)
    }

    useEffect(
        () => () => {
            // cleans up React root and DOM nodes when the component unmounts
            threadPositionsManager.cleanup()
        },
        []
    )

    useEffect(() => {
        async function main() {
            // if the user is adding a new thread then we always have that one open/focused
            const newThreadIdx = threads.findIndex((thread) => isNewThread(thread))
            if (newThreadIdx > -1) {
                setOpenThreadId(threads[newThreadIdx].id)
            }

            // sorts threads by position of block they reference and then by when they were last updated
            const sortedThreads = nodeIdOrder
                .map((nodeId) => {
                    const nodes = threads.filter((thread) => thread.referencedBlock === nodeId)
                    return nodes.sort((a, b) => {
                        const bCreated = new Date(b.meta?.dateCreated ?? '').getTime()
                        const aCreated = new Date(a.meta?.dateCreated ?? '').getTime()

                        return bCreated - aCreated
                    })
                })
                .flat()

            setThreadsWithPositions(
                await threadPositionsManager.getPositions(
                    sortedThreads,
                    openThreadId,
                    editingCommentId
                )
            )
        }

        main()

        // TODO - resolve this `as any`
        // listen for clicks outside of the thread manager to close the active thread
        if (openThreadId) {
            window.addEventListener('click', clearOpenThread as any)
        }

        return () => {
            window.removeEventListener('click', clearOpenThread as any)
        }

        // TODO: re-position threads on window re-size
    }, [openThreadId, threads, nodeIdOrder])

    useEffect(() => {
        // calculate position for the highlighted block ahead of time
        // so the user doesn't see it jump
        const openReferencedBlockTop =
            threadsWithPositions.find((thread) => thread.id === openThreadId)?.position ?? null

        if (openReferencedBlockTop !== null) {
            setHighlightedBlockY(openReferencedBlockTop)
        }
    }, [threadsWithPositions])

    useEffect(() => {
        if (!openThreadId || !openThread) {
            // if there is no open thread then disable the highlight element
            setHighlightedBlockHeight(0)
            return
        }

        // if a thread is open then we get the height of the block it references
        // to paint the highlight over it and scroll to the referenced block
        const referenced = document.getElementById(openThread.referencedBlock)
        const editorContainer = document.getElementById(getShapeClassName('editor'))
        const editorScrollContainer = document.getElementById('edit-zone-container')

        if (referenced) {
            const { top, height } = referenced.getBoundingClientRect()

            // set highlight height after threads have moved to their new positions
            // after we wait twice a long as it might take
            setTimeout(() => setHighlightedBlockHeight(height), THREAD_TRANSITION_DELAY_MS * 2)

            const { height: threadHeight } = document
                .getElementById(openThreadId)
                ?.getBoundingClientRect() ?? { height: 0 }

            const scrollTop = editorScrollContainer?.getBoundingClientRect().top || 0
            const editorTop = editorContainer?.getBoundingClientRect().top || 0
            const threadTop = top - editorTop - scrollTop + height / 2 - threadHeight / 2

            editorScrollContainer?.scrollTo({
                top: threadTop,
                behavior: 'smooth',
            })
        }
    }, [openThread])

    const threadsContextValue: ThreadsContextType = useMemo(
        () => ({
            threadHandlers: {
                open: setOpenThreadId,
                delete: deleteThread,
            },
            commentHandlers: {
                submit: submitComment,
                delete: deleteComment,
                cancel: clearOpenThread,
                edit: (threadId: string, commentId: string) => {
                    setOpenThreadId(threadId)
                    setEditingCommentId(commentId)
                },
                saveEdit: editComment,
            },
        }),
        [clearOpenThread, setOpenThreadId, submitComment, deleteComment, deleteThread]
    )

    return (
        <ThreadsProvider value={threadsContextValue}>
            <ThreadManageContainer>
                {threadsWithPositions.map((thread) => {
                    // keys suffixes are conditional since new threads do not have a 'last updated' date
                    const keySuffix = isNewThread(thread) ? 'new' : thread.meta.lastUpdated
                    const key = `${thread.id} ${keySuffix}`

                    return (
                        <Thread
                            key={key}
                            thread={thread}
                            isOpen={openThreadId === thread.id}
                            position={thread.position}
                            editingCommentId={editingCommentId}
                        />
                    )
                })}
                <ElementHighlight height={highlightedBlockHeight} y={highlightedBlockY} />
            </ThreadManageContainer>
        </ThreadsProvider>
    )
}

export default ThreadManager
