import { zIndex } from '@shapeci/ui'
import styled from 'styled-components'

import { MENU_BAR_HEIGHT, SIDE_BAR_WIDTH } from '../../Layout/constants'

export const BannerStackWrapper = styled.div`
    position: absolute;
    z-index: ${zIndex.BLOCK_DRAWER};
    top: calc(${MENU_BAR_HEIGHT} + 40px); // toolbar height
    left: 0;

    max-width: 1280px;
    width: calc(100% - ${SIDE_BAR_WIDTH});

    @media (min-width: 1600px) {
        margin-left: calc(((100% - ${SIDE_BAR_WIDTH}) - 1280px) / 2);
    }

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    box-sizing: border-box;
    user-select: none;

    padding: ${({ theme }) => theme.getSpacing(1)};
    padding-right: calc(${({ theme }) => theme.getSpacing(1)} + 15px);
`

export const MutedBannerWrapper = styled.div`
    width: 100%;
    height: 48px;
    background: ${({ theme }) => theme.colors.grey300};
    color: ${({ theme }) => theme.colors.grey700};
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 ${({ theme }) => theme.getSpacing(2)};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: 1px solid ${({ theme }) => theme.colors.grey400};

    box-sizing: border-box;

    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
`

export const InfoBannerWrapper = styled(MutedBannerWrapper)`
    background: ${({ theme }) => theme.colors.info100};
    color: ${({ theme }) => theme.colors.info800};
    border-color: ${({ theme }) => theme.colors.info200};
`

export const WarningBannerWrapper = styled(MutedBannerWrapper)`
    background: ${({ theme }) => theme.colors.warning100};
    color: ${({ theme }) => theme.colors.warning800};
    border-color: ${({ theme }) => theme.colors.warning200};
`

export const BannerSegment = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`
