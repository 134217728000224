import { BlockType, Code } from '@shapeci/types'
import { Text } from 'slate'
import { v4 as uuidv4 } from 'uuid'

import { fragmentToText } from './fragmentToText'
import { Fragment } from './types'

export const fragmentToCode = (fragment: Fragment) => {
    const maybeCode = fragment as Code

    let children = maybeCode.children?.filter(Text.isText) as any as Fragment[]
    children = children.map(fragmentToText).filter((t) => t !== null)
    if (!children) return null

    const code = {
        type: BlockType.CODE,
        id: uuidv4(),
        children,
        language: 'javascript',
        value: '',
    } as Code

    if (maybeCode.language) code.language = maybeCode.language

    return maybeCode
}
