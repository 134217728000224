import { transparentize } from 'polished'
import { ButtonHTMLAttributes, FC } from 'react'
import styled from 'styled-components'

import { AppNavLink, AppNavLinkProps } from '../../../router/AppLink'

const SidebarLinkWrapper = styled(AppNavLink)`
    color: ${({ theme }) => theme.colors.grey600};
    font-weight: 500;
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    background: none;
    width: 100%;
    cursor: pointer;
    padding: 0;
    font-family: ${({ theme }) => theme.fonts};

    :not(:last-child) {
        margin-bottom: ${({ theme }) => theme.getSpacing(2.75)};
    }

    .inner {
        display: flex;
        align-items: center;
        z-index: 0;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.grey700};
    }

    &:before {
        content: '';
        position: absolute;
        left: -${({ theme }) => theme.getSpacing(1)};
        width: 100%;
        height: 1rem;
        padding: ${({ theme }) => theme.getSpacing(1.25)} ${({ theme }) => theme.getSpacing(1)};
    }

    &:hover:before,
    &.active:before {
        background: ${({ theme }) => transparentize(0.125, theme.colors.grey400)};
        border-radius: ${({ theme }) => theme.borderRadius};
    }

    &.active {
        color: ${({ theme }) => theme.colors.primary500};
    }

    &.active:before {
        background: ${({ theme }) => theme.colors.primary200};
    }

    svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: ${({ theme }) => theme.getSpacing(1.5)};
    }
`

export const SidebarButton = ({ children, ...props }: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <SidebarLinkWrapper as="button" {...props}>
        <span className="inner">{children}</span>
    </SidebarLinkWrapper>
)

const SidebarLink: FC<AppNavLinkProps> = ({ children, ...props }) => (
    <SidebarLinkWrapper {...props}>
        <span className="inner">
            {typeof children === 'function' ? children(props as any) : children}
        </span>
    </SidebarLinkWrapper>
)

SidebarLink.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    className: ({ isActive }) => (isActive ? 'active' : ''),
}

export default SidebarLink
export { SidebarLinkWrapper as SidebarItem }
