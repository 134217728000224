import {
    ClientReviewDocument,
    DocumentCollaborator,
    ReviewStatus,
    ReviewWithReviewer,
} from '@shapeci/types'
import { Button, Dialog, Heading, RadioGroup, Space, Tooltip } from '@shapeci/ui'
import { Sync } from '@styled-icons/boxicons-regular'
import { ErrorCircle, UserPlus } from '@styled-icons/boxicons-solid'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { cache } from '../../../../caches'
import { useApi } from '../../../../hooks/useApi'
import { UserLockup } from '../../../Lockup'
import { useBannerStore } from '../../Banners'
import { PaddedContainer, ReviewCard, Separator } from '../ui'
import ActionButtonsWrapper from './Actions'
import ReviewIndicator from './Indicator'
import RequestMenu from './RequestMenu'

const MinApprovalNotice = styled(PaddedContainer)`
    margin-top: ${({ theme }) => theme.getSpacing(4.5)};

    div {
        margin: ${({ theme }) => theme.getSpacing(1)} 0 ${({ theme }) => theme.getSpacing(2)};
        display: flex;
        color: ${({ theme }) => theme.colors.info600};
        font-weight: 500;
        font-size: 0.875rem;

        align-items: flex-start;

        gap: ${({ theme }) => theme.getSpacing(1)};

        svg {
            width: 2.375rem;
            margin-top: 0.125rem;
            margin-right: 0.125rem;
            color: ${({ theme }) => theme.colors.info500};
        }

        border: 1px solid ${({ theme }) => theme.colors.info200};
        background: ${({ theme }) => theme.colors.info50};

        border-radius: ${({ theme }) => theme.borderRadius};
        padding: ${({ theme }) => theme.getSpacing(1)} ${({ theme }) => theme.getSpacing(1.25)};

        p {
            margin: 0;
        }
    }
`

const NoReviewers = styled(PaddedContainer)`
    margin: ${({ theme }) => theme.getSpacing(3)} 0 ${({ theme }) => theme.getSpacing(4)};

    p {
        color: ${({ theme }) => theme.colors.grey600};
    }
`

const ReviewOptionCard = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${({ theme }) => theme.getSpacing(1)};

    cursor: default;

    p {
        margin: 0;
        color: ${({ theme }) => theme.colors.grey600};
        line-height: 1.25;
        font-weight: 400;
    }

    user-select: none;
`

const CURRENT_USER_PENDING_REVIEW_BANNER_ID = 'current-user-pending-review'

interface ReviewsProps {
    documentMetadata?: ClientReviewDocument
    documentId?: string
    reviews?: ReviewWithReviewer[]
    updateReviewData?: () => void
    restoreDocument: (value: string) => void
    reloadDocument: () => void
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    isPreviewMode: boolean
}

export default function Reviews({
    documentMetadata,
    documentId,
    reviews,
    updateReviewData,
    reloadDocument,
}: ReviewsProps) {
    const api = useApi()
    const [isReviewApproved, setIsReviewApproved] = useState<boolean>(false)
    const { data: team } = cache.useTeam()
    const { data: user } = cache.useUser()

    const [isReviewing, setIsReviewing] = useState<boolean>(false)
    const [updatedReview, setUpdatedReview] = useState<ReviewStatus>(ReviewStatus.PENDING)
    const [isOwnReviewUpdating, setOwnReviewUpdating] = useState<boolean>(false)

    const { addBanner, removeBanner } = useBannerStore()

    const currentUserReviewIdx =
        reviews?.findIndex((review) => review.reviewer.id === user?.id) ?? -1

    let currentUserReview: null | ReviewWithReviewer = null

    if (reviews && currentUserReviewIdx > -1) {
        currentUserReview = reviews[currentUserReviewIdx]
    }

    const otherTeamMembers =
        team?.users?.filter((member: DocumentCollaborator) => member.id !== user?.id) ?? []

    const checkApprovedReviews = async () => {
        if (team?.minimumMergeReviews === 0) {
            setIsReviewApproved(true)
            return
        }
        if (!reviews?.length) {
            setIsReviewApproved(false)
            return
        }
        const approvedReviews = reviews.filter((r) => r.status === ReviewStatus.APPROVED)
        const isApproved = approvedReviews?.length >= (team?.minimumMergeReviews ?? 0)

        setIsReviewApproved(isApproved)
    }

    const updateReview = async (reviewId: string, status: ReviewStatus) => {
        if (!documentId || !reviews || !updateReviewData) {
            return
        }

        try {
            setOwnReviewUpdating(true)
            await api.updateReview(documentId, reviewId, status)
            await updateReviewData()

            // close modal on success
            setIsReviewing(false)
        } finally {
            setOwnReviewUpdating(false)
        }
    }

    useEffect(() => {
        if (reviews) {
            checkApprovedReviews()
        }
    }, [reviews])

    useEffect(() => {
        if (currentUserReview?.status === ReviewStatus.PENDING) {
            addBanner({
                id: CURRENT_USER_PENDING_REVIEW_BANNER_ID,
                intent: 'warning',
                message: 'Your review was requested',
                right: (
                    <Button intent="default" onClick={() => setIsReviewing(true)}>
                        Leave a review
                    </Button>
                ),
            })
        }

        return () => removeBanner(CURRENT_USER_PENDING_REVIEW_BANNER_ID)
    }, [currentUserReview])

    const requestReview = async (requestedUserId: string) => {
        if (!documentId || !updateReviewData || !reviews) {
            return
        }

        if (reviews.some((review: ReviewWithReviewer) => review.reviewer.id === requestedUserId)) {
            throw new Error('User already requested for review.')
        }

        try {
            await api.createReview(documentId, requestedUserId)

            // invalidate review data
            updateReviewData()
        } catch {
            throw new Error('Something went wrong on our end. Try again.')
        }
    }

    return (
        <>
            <Dialog
                open={isReviewing}
                onOpenChange={setIsReviewing}
                title="Leave a Review"
                description={`Review the commits described in this document requesting to merge ${documentMetadata?.branch} into main. Leave comments for the author on the document itself.`}
                content={
                    <>
                        <RadioGroup.Root
                            style={{ marginTop: '1rem' }}
                            value={updatedReview}
                            onValueChange={(value: ReviewStatus) => {
                                setUpdatedReview(value)
                            }}
                        >
                            <RadioGroup.Item
                                value={ReviewStatus.APPROVED}
                                label={
                                    <ReviewOptionCard>
                                        <Heading kind="h4" m={0}>
                                            Approve
                                        </Heading>
                                        <p>Mark this document as ready to merge into main.</p>
                                    </ReviewOptionCard>
                                }
                            />
                            <RadioGroup.Item
                                value={ReviewStatus.DENIED}
                                label={
                                    <ReviewOptionCard>
                                        <Heading kind="h4" m={0}>
                                            Request Changes
                                        </Heading>
                                        <p>
                                            Mark this document as needing changes before it can be
                                            merged into main.
                                        </p>
                                    </ReviewOptionCard>
                                }
                            />
                        </RadioGroup.Root>
                    </>
                }
                actions={[
                    {
                        label: 'Submit Review',
                        disabled: !updatedReview,
                        isLoading: isOwnReviewUpdating,
                        onClick: () => {
                            if (!updatedReview) {
                                throw new Error('Unreachable: No review status selected.')
                            }
                            if (!currentUserReview) {
                                throw new Error('Unreachable: No current user review.')
                            }

                            updateReview(currentUserReview.id, updatedReview)
                        },
                    },
                ]}
            />
            <PaddedContainer>
                <Space justify="space-between" align="center">
                    <Heading kind="h2" m={0}>
                        Reviews
                    </Heading>
                    <RequestMenu
                        reviews={reviews ?? []}
                        teamMembers={otherTeamMembers as any}
                        requestReview={requestReview}
                    >
                        <Button kind="text" prepend={<UserPlus />}>
                            Add Reviewer
                        </Button>
                    </RequestMenu>
                </Space>
                <Separator />
            </PaddedContainer>
            {reviews?.length ? (
                reviews?.map((review: ReviewWithReviewer) => (
                    <PaddedContainer>
                        <ReviewCard key={review.id}>
                            <div>
                                <UserLockup user={review.reviewer} size={0} />
                            </div>
                            <Space align="center" justify="end" size={2}>
                                {review.status !== ReviewStatus.PENDING && (
                                    <Tooltip content="Re-request Review" delayDuration={375}>
                                        <Button
                                            kind="icon"
                                            size="small"
                                            intent="muted"
                                            onClick={() =>
                                                updateReview(review.id, ReviewStatus.PENDING)
                                            }
                                        >
                                            <Sync />
                                        </Button>
                                    </Tooltip>
                                )}
                                <ReviewIndicator status={review.status} />
                            </Space>
                        </ReviewCard>
                    </PaddedContainer>
                ))
            ) : (
                <NoReviewers>
                    <p>No reviews yet. Click above to add a reviewer and get started.</p>
                </NoReviewers>
            )}
            {team?.minimumMergeReviews ? (
                <MinApprovalNotice>
                    <div>
                        <ErrorCircle />
                        <p>
                            At least <strong>{team?.minimumMergeReviews}</strong> approving review
                            {team?.minimumMergeReviews > 1 ? 's are ' : ' is '} required to merge
                            this pull request.
                        </p>
                    </div>
                </MinApprovalNotice>
            ) : null}

            <PaddedContainer>
                <Separator />
                <ActionButtonsWrapper
                    documentMetadata={documentMetadata}
                    isReviewApproved={isReviewApproved}
                    onAction={updateReviewData}
                    reloadDocument={reloadDocument}
                />
            </PaddedContainer>
        </>
    )
}
