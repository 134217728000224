import { ApiInstance } from '@shapeci/components'
import { PaginatedRestorePoint } from '@shapeci/types'

import { RestorePointWithCreator } from './types'

const placeholderUserInfo = {
    id: '',
    firstName: 'Unknown',
    lastName: 'User',
    email: '',
    avatarName: '',
}

export const getRestorePoints = async (
    api: ApiInstance,
    documentId: string,
    page: number,
    sessionStartTime: string
): Promise<RestorePointWithCreator[]> => {
    const limit = 14
    const additionalPoints: (PaginatedRestorePoint & {
        creator?: RestorePointWithCreator['creator']
    })[] = await api.getDocumentRestorePoints(documentId, page, limit, sessionStartTime)

    return additionalPoints.map((restorePoint) => ({
        ...restorePoint,
        creator: restorePoint.creator ?? placeholderUserInfo,
    }))
}
