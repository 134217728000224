import { Transaction } from '@shapeci/types'
import { v4 } from 'uuid'

interface transactionReceivedPayload {
    ops: Transaction
}

const getEventName = (id: string) => `transaction-received-${id}`

/**
 * This class is used to listen for transactions from the server and emit them to the editor.
 *
 * @example
 *
 * const s = new Socket()
 * const observer = new TransactionObserver()
 *
 * observer.onTransaction(op => console.log(op))
 *
 * s.on('transaction', (op) => observer.emitTransactions(op))
 *
 * // output after 3 events are received
 *
 * // 1 | { type: 'insert_text', path: [0], offset: 0, text: 'a', mark: null }
 * // 2 | { type: 'insert_text', path: [0], offset: 1, text: 'b', mark: null }
 * // 3 | { type: 'insert_text', path: [0], offset: 2, text: 'c', mark: null }
 */
export class TransactionObserver {
    listener: ((e: Event) => void) | null

    // id so multiple instances don't intercept each other's events
    id: string

    constructor() {
        this.id = v4()
        this.listener = null
    }

    get eventName() {
        return getEventName(this.id)
    }

    private dispatch(ops: Transaction) {
        document.dispatchEvent(
            new CustomEvent<transactionReceivedPayload>(this.eventName, {
                detail: { ops },
            })
        )
    }

    emitTransaction(transaction: Transaction) {
        if (!transaction.length) {
            return
        }

        this.dispatch(transaction)
    }

    onTransaction(callback: (transaction: Transaction) => void) {
        if (this.listener) {
            document.removeEventListener(this.eventName, this.listener)
        }

        this.listener = (e: Event) => {
            const { detail } = <CustomEvent<transactionReceivedPayload>>e
            callback(detail.ops)
        }

        document.addEventListener(this.eventName, this.listener)
    }
}
