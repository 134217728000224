import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import { useMemo } from 'react'

/**
 * Returns the normal Auth0 hook but sets default values for the logout call
 * @returns
 */
export const useAuth = () => {
    const auth = useAuth0()

    return useMemo(
        () => ({
            ...auth,
            logout: (options?: LogoutOptions) => {
                auth.logout({
                    logoutParams: {
                        returnTo: new URL('/login', window.origin).toString(),
                    },
                    ...(options || {}),
                })
            },
        }),
        [auth]
    )
}
