import * as t from 'io-ts'
import lodashOmit from 'lodash.omit'
import lodashPick from 'lodash.pick'

type HasProps<P extends t.Props> = {
    props: P
}

type HasStrictProps<P extends t.Props> = {
    type: HasProps<P>
}

type IsArrayWithStrictProps<P extends t.Props[]> = {
    [K in keyof P]: P[K] extends t.Props ? HasStrictProps<P[K]> : never
}

type IntersectionOfProps = [t.Props, t.Props, ...t.Props[]]

type HasStrictTypes<T extends IntersectionOfProps> = {
    types: IsArrayWithStrictProps<T>
}

type StrictOmission<P extends t.Props, K extends PropertyKey> = t.ExactC<t.TypeC<Omit<P, K>>>
type OmitMany<P extends IntersectionOfProps, K extends PropertyKey> = {
    [key in keyof P]: P[key] extends t.Props ? StrictOmission<P[key], K> : never
}

// Omit for codecs that are a single strict t.TypeC
export function omitOnType<P extends t.Props, K extends PropertyKey>(
    o: HasStrictProps<P>,
    k: readonly K[]
): StrictOmission<P, K> {
    const omittedProps = lodashOmit(o.type.props, ...k)
    return t.strict(omittedProps)
}

// Omit for intersection of strict codecs
export function omitOnIntersection<P extends IntersectionOfProps, K extends PropertyKey>(
    o: HasStrictTypes<P>,
    k: readonly K[]
): t.IntersectionC<OmitMany<P, K>> {
    const intersectionItems = o.types.map((ty) => omitOnType(ty, k)) as [
        StrictOmission<t.Props, K>,
        StrictOmission<t.Props, K>
    ]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return t.intersection(intersectionItems) as any as t.IntersectionC<OmitMany<P, K>>
}

type StrictPartial<P extends t.Props> = t.ExactC<t.PartialC<P>>
type PartialMany<P extends IntersectionOfProps> = {
    [key in keyof P]: P[key] extends t.Props ? StrictPartial<P[key]> : never
}

// Partial for codecs that are a single strict t.TypeC
export function partialOnType<P extends t.Props>(o: HasStrictProps<P>): StrictPartial<P> {
    return t.exact(t.partial(o.type.props))
}

// Partial for intersection of strict codecs
export function partialOnIntersection<P extends IntersectionOfProps>(
    o: HasStrictTypes<P>
): t.IntersectionC<PartialMany<P>> {
    const intersectionItems = o.types.map((ty) => partialOnType(ty)) as [
        StrictPartial<t.Props>,
        StrictPartial<t.Props>
    ]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return t.intersection(intersectionItems) as any as t.IntersectionC<PartialMany<P>>
}

type StrictPick<P extends t.Props, K extends keyof P> = t.ExactC<t.TypeC<Pick<P, K>>>
type PickMany<P extends IntersectionOfProps, K extends PropertyKey> = {
    [key in keyof P]: P[key] extends t.Props
        ? K extends keyof P[key]
            ? StrictPick<P[key], K>
            : t.ExactC<t.TypeC<Record<string, t.Mixed>>>
        : never
}

// Pick for codecs that are a single strict t.TypeC
export function pickOnType<P extends t.Props, K extends keyof P>(
    o: HasStrictProps<P>,
    k: readonly K[]
): StrictPick<P, K> {
    const pickedProps = lodashPick(o.type.props, ...k)
    return t.strict(pickedProps)
}

// Pick for intersection of strict codecs
export function pickOnIntersection<P extends IntersectionOfProps, K extends string | number>(
    o: HasStrictTypes<P>,
    k: readonly K[]
): t.IntersectionC<PickMany<P, K>> {
    const intersectionItems = o.types.map((ty) => pickOnType(ty, k)) as [
        StrictPick<t.Props, K>,
        StrictPick<t.Props, K>
    ]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return t.intersection(intersectionItems) as any as t.IntersectionC<PickMany<P, K>>
}
