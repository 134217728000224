import { FC, ReactNode, Suspense } from 'react'
import styled from 'styled-components'

import LoadingScreen from '../LoadingScreen'

const FormPageOuter = styled.div`
    background: ${({ theme }) => theme.colors.grey300};
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const FormPageInner = styled.main`
    margin: 0 auto;
    width: 95%;
    max-width: 1300px;
    overflow: auto;

    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 5vh;
    box-sizing: border-box;

    button {
        margin-bottom: ${({ theme }) => theme.getSpacing(1)};
    }

    h1 {
        width: 95%;
        max-width: 400px;
        text-align: center;

        + p {
            color: ${({ theme }) => theme.colors.grey600};
            margin: 0 0 ${({ theme }) => theme.getSpacing(6)};
        }
    }

    a {
        color: ${({ theme }) => theme.colors.primary500};
        text-decoration: none;
    }

    h2 {
        font-size: 1rem;
        font-weight: 400;
        margin: 0 0 ${({ theme }) => theme.getSpacing(2)};
    }
`

interface FormPageProps {
    children: ReactNode
}

const FormPage: FC<FormPageProps> = ({ children }) => (
    <Suspense fallback={<LoadingScreen />}>
        <FormPageOuter>
            <FormPageInner>{children}</FormPageInner>
        </FormPageOuter>
    </Suspense>
)

export default FormPage
