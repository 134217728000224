import * as t from 'io-ts'

import { omitOnIntersection } from '../utils/io-ts.util'
import { baseBlock, blockProperties, BlockType } from './block.model'
import { modelViewerState } from './geometry.model'
import { BlockId, blockId } from './id.model'

const baseBlockWithStringValue = t.intersection([
    omitOnIntersection(baseBlock, ['value']),
    t.partial({
        value: t.string,
    }),
])

export interface LeafText extends t.TypeOf<typeof leafText> {}
export const leafText = t.type({
    text: t.string,
})

export interface InlineText extends t.TypeOf<typeof inlineText> {}
export const inlineText = t.intersection([
    leafText,
    t.type({
        id: blockId,
        type: t.literal(BlockType.INLINE_TEXT),
    }),
    t.partial({
        value: t.string,
    }),
    blockProperties,
])

export interface InlineLink extends t.TypeOf<typeof inlineLink> {}
export const inlineLink = t.intersection([
    baseBlock,
    t.type({
        type: t.literal(BlockType.LINK),
    }),
    t.partial({
        children: t.array(inlineText),
        value: t.string,
    }),
    blockProperties,
])

export type InlineNode = t.TypeOf<typeof inlineNode>
export const inlineNode = t.union([inlineText, inlineLink])

export interface ListItemChild {
    id: BlockId
    type: BlockType.LIST_ITEM_CHILD
    children?: (ListItem | InlineNode | H1 | H2 | H3 | Paragraph | Code)[]
}
export const listItemChild: t.Type<ListItemChild> = t.recursion('listItemChild', () =>
    t.intersection([
        baseBlock,
        t.type({
            type: t.literal(BlockType.LIST_ITEM_CHILD),
        }),
        t.partial({
            children: t.array(t.union([listItem, inlineNode, h1, h2, h3, paragraph, code])),
        }),
    ])
)

export interface ListItem {
    id: BlockId
    type: BlockType.LIST_ITEM
    children?: (ListItemChild | BulletedList | NumberedList)[]
}

export const listItem: t.Type<ListItem> = t.recursion('listItem', () =>
    t.intersection([
        baseBlock,
        t.type({
            type: t.literal(BlockType.LIST_ITEM),
        }),
        t.partial({
            children: t.array(t.union([listItemChild, bulletedList, numberedList])),
        }),
    ])
)

export interface BulletedList extends t.TypeOf<typeof bulletedList> {}
export const bulletedList = t.intersection([
    baseBlock,
    t.type({
        type: t.literal(BlockType.BULLET_LIST),
    }),
    t.partial({
        children: t.array(listItem),
    }),
])

export interface NumberedList extends t.TypeOf<typeof numberedList> {}
export const numberedList = t.intersection([
    baseBlock,
    t.type({
        type: t.literal(BlockType.NUMBER_LIST),
    }),
    t.partial({
        children: t.array(listItem),
    }),
])

export interface Image extends t.TypeOf<typeof image> {}
export const image = t.intersection([
    baseBlock,
    t.type({
        type: t.literal(BlockType.IMAGE),
    }),
    t.partial({
        children: t.array(inlineNode),
        uploadId: t.string,
        width: t.number,
        publicURL: t.string,
    }),
])

export interface ModelViewer extends t.TypeOf<typeof modelViewer> {}
export const modelViewer = t.intersection([
    baseBlock,
    t.type({
        type: t.literal(BlockType.MODEL_VIEWER),
    }),
    t.partial({
        children: t.array(inlineNode),
        value: t.union([modelViewerState, t.string]),
    }),
])

export interface Latex extends t.TypeOf<typeof latex> {}
export const latex = t.intersection([
    baseBlockWithStringValue,
    t.type({
        type: t.literal(BlockType.LATEX),
    }),
    t.partial({
        children: t.array(inlineNode),
    }),
])

export interface Paragraph extends t.TypeOf<typeof paragraph> {}
export const paragraph = t.intersection([
    baseBlockWithStringValue,
    t.type({
        type: t.literal(BlockType.PARAGRAPH),
    }),
    t.partial({
        children: t.array(inlineNode),
    }),
])

export interface Code extends t.TypeOf<typeof code> {}
export const code = t.intersection([
    baseBlockWithStringValue,
    t.type({
        type: t.literal(BlockType.CODE),
    }),
    t.partial({
        children: t.array(inlineNode),
        language: t.string,
    }),
])

export type H1 = t.TypeOf<typeof h1>
export const h1 = t.intersection([
    baseBlockWithStringValue,
    t.type({
        type: t.literal(BlockType.H1),
    }),
    t.partial({
        children: t.array(inlineNode),
    }),
])

export type H2 = t.TypeOf<typeof h2>
export const h2 = t.intersection([
    baseBlockWithStringValue,
    t.type({
        type: t.literal(BlockType.H2),
    }),
    t.partial({
        children: t.array(inlineNode),
    }),
])

export type H3 = t.TypeOf<typeof h3>
export const h3 = t.intersection([
    baseBlockWithStringValue,
    t.type({
        type: t.literal(BlockType.H3),
    }),
    t.partial({
        children: t.array(inlineNode),
    }),
])

export type EditorInternalNode = t.TypeOf<typeof editorInternalNode>
export const editorInternalNode = t.union([
    inlineLink,
    modelViewer,
    code,
    paragraph,
    h1,
    h2,
    h3,
    bulletedList,
    numberedList,
    listItem,
    listItemChild,
    image,
    latex,
])

export interface EditorLeafNode extends t.TypeOf<typeof editorLeafNode> {}
export const editorLeafNode = inlineText

export type EditorNode = t.TypeOf<typeof editorNode>
export const editorNode: t.Type<EditorInternalNode | EditorLeafNode> = t.recursion(
    'editorNode',
    () => t.union([editorInternalNode, editorLeafNode])
)

export interface EditorRoot extends t.TypeOf<typeof editorRoot> {}
export const editorRoot = t.type({
    children: t.array(editorNode),
})
