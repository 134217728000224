import { RestorePoint } from '@shapeci/types'
import { Button, Heading, Space, zIndex } from '@shapeci/ui'
import styled from 'styled-components'

import { RestorePointWithCreator } from './types'
import VersionListItem from './VersionListItem'

const HistoryListWrapper = styled.aside`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-left: ${({ theme }) => theme.border};
    box-shadow: 0px 0px 2.2px rgba(0, 0, 0, 0.008), 0px 0px 5.3px rgba(0, 0, 0, 0.012),
        0px 0px 10px rgba(0, 0, 0, 0.015), 0px 0px 17.9px rgba(0, 0, 0, 0.018),
        0px 0px 33.4px rgba(0, 0, 0, 0.022), 0px 0px 80px rgba(0, 0, 0, 0.03);

    grid-area: versions;
    z-index: ${zIndex.MENU_BAR + 2};

    > h3 {
        padding: ${({ theme }) => theme.getSpacing(6)} ${({ theme }) => theme.getSpacing(2)};
        border-bottom: ${({ theme }) => theme.border};
        position: sticky;
        top: 0;
        background: ${({ theme }) => theme.colors.grey100};
        z-index: 999;

        box-shadow: 0px 1px 0.7px rgba(0, 0, 0, 0.003), 0px 2.3px 1.6px rgba(0, 0, 0, 0.004),
            0px 4.4px 3px rgba(0, 0, 0, 0.005), 0px 7.8px 5.4px rgba(0, 0, 0, 0.006),
            0px 14.6px 10px rgba(0, 0, 0, 0.007), 0px 35px 24px rgba(0, 0, 0, 0.01);
    }

    > .shape__space {
        margin: ${({ theme }) => theme.getSpacing(2.5)} 0 ${({ theme }) => theme.getSpacing(5)};

        p {
            color: ${({ theme }) => theme.colors.grey600};
        }
    }
`

interface HistoryListProps {
    selectedRestorePoint: RestorePoint | null
    setSelectedRestorePoint: (v: RestorePoint | null) => void
    hasMorePages: boolean
    getNextPage: () => void
    isLoading: boolean
    isLoadingNextPage: boolean
    versions: RestorePointWithCreator[]
    onRestore?: (restorePointId: string) => void
}

export default function HistoryList({
    selectedRestorePoint,
    setSelectedRestorePoint,
    hasMorePages,
    getNextPage,
    isLoading,
    isLoadingNextPage,
    versions,
    onRestore,
}: HistoryListProps) {
    return (
        <HistoryListWrapper>
            <Heading kind="h3" size={2} m={0}>
                Version History
            </Heading>
            {isLoading ? null : (
                <VersionListItem
                    restorePoint={null}
                    setSelectedRestorePoint={setSelectedRestorePoint}
                    selected={selectedRestorePoint === null}
                    current
                />
            )}
            {versions.map((restorePoint) => (
                <VersionListItem
                    key={restorePoint.id}
                    restorePoint={restorePoint}
                    setSelectedRestorePoint={setSelectedRestorePoint}
                    selected={restorePoint.id === selectedRestorePoint?.id}
                    onRestore={onRestore}
                />
            ))}
            {isLoading ? null : (
                <Space align="center" justify="center">
                    {!hasMorePages ? (
                        <p>All versions loaded.</p>
                    ) : (
                        <Button
                            onClick={getNextPage}
                            kind="text"
                            intent="info"
                            isLoading={isLoadingNextPage}
                        >
                            Load more...
                        </Button>
                    )}
                </Space>
            )}
        </HistoryListWrapper>
    )
}
