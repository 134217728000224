import { LoaderAvatar, UserAvatar } from '@shapeci/components'
import { DocumentCollaborator } from '@shapeci/types'
import { getAvatarBucketUri } from '@shapeci/utils'
import { FC, ReactNode, useMemo } from 'react'
import styled from 'styled-components'

import useDocumentStore from '../../hooks/useDocumentStore'

const ActionListWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    .action {
        min-height: 35px;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: ${({ theme }) => theme.getSpacing(1)};

        :not(:first-child) {
            margin-left: ${({ theme }) => theme.getSpacing(1)};
        }
    }
`

const EndContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
`

export const UserStack = styled.div`
    display: flex;
    align-items: center;

    .shape__avatar {
        border: 2px solid ${({ theme }) => theme.colors.grey100};

        &:not(:last-child) {
            margin-right: -0.675rem;
        }
    }

    :not(:last-child) {
        margin-right: ${({ theme }) => theme.getSpacing(2)};
    }
`

interface ActionListProps {
    users?: DocumentCollaborator[]
    preStackActions?: ReactNode[]
    postStackActions?: ReactNode[]
}

interface ActionListLoaderProps {
    size: number
}

const isUsersEmpty = (users: DocumentCollaborator[]) => {
    if (users.length === 0 || !users[0]?.id) {
        return true
    }
    return false
}

export const ActionList: FC<ActionListProps> = ({
    users = [],
    preStackActions,
    postStackActions,
}) => {
    const activeEditorSession = useDocumentStore((store) => store.value.editorSession)
    const editingUser = useMemo(
        () =>
            users.find(
                (u) =>
                    u.id === activeEditorSession?.userId &&
                    u.sessionId === activeEditorSession?.sessionId
            ),
        [activeEditorSession, users]
    )
    const reviewingUsers = useMemo(
        () =>
            users.filter(
                (u) =>
                    u.id !== activeEditorSession?.userId ||
                    u.sessionId !== activeEditorSession?.sessionId
            ),
        [activeEditorSession, users]
    )

    const renderUser = (user: DocumentCollaborator, withBorder?: boolean) => (
        <UserAvatar
            key={`${user.id}-${user.sessionId}`}
            user={user}
            withBorder={withBorder}
            imageServerURL={getAvatarBucketUri(localStorage)}
        />
    )

    return (
        <ActionListWrapper>
            <div>
                {preStackActions?.map((action, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={idx} className="action">
                        {action}
                    </div>
                ))}
            </div>
            <EndContainer>
                <UserStack>
                    {!isUsersEmpty(reviewingUsers) && reviewingUsers.map((u) => renderUser(u))}
                    {editingUser && renderUser(editingUser, true)}
                </UserStack>
                {postStackActions?.map((action, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={idx} className="action">
                        {action}
                    </div>
                ))}
            </EndContainer>
        </ActionListWrapper>
    )
}

export const ActionListLoader: FC<ActionListLoaderProps> = ({ size }) => {
    const dummyUserArray = new Array(size).fill(null)
    return (
        <ActionListWrapper>
            <UserStack>
                {dummyUserArray.map((_, idx) => (
                    <LoaderAvatar key={idx} />
                ))}
            </UserStack>
        </ActionListWrapper>
    )
}

ActionList.defaultProps = {
    preStackActions: [],
    postStackActions: [],
    users: [],
}

export type { ActionListProps }
