import { FC, ReactNode, useEffect } from 'react'

import { useApi } from '../hooks/useApi'
import { modelStore } from '../store/model.store'

interface ProviderProps {
    children: ReactNode
}

const ModelStoreProvider: FC<ProviderProps> = ({ children }) => {
    const api = useApi()

    useEffect(() => {
        modelStore.setAPI(api)
    }, [api])

    return <>{children}</>
}

export default ModelStoreProvider
