import { forwardRef, useMemo } from 'react'
import { Link, LinkProps, NavLink, NavLinkProps, useParams } from 'react-router-dom'

import { processTo } from './utils'

export interface AppLinkProps extends LinkProps {
    to: string
}

export function AppLink({ to, ...props }: AppLinkProps) {
    const { teamDomain } = useParams()
    const processedTo = useMemo(() => processTo(to, teamDomain), [teamDomain, to])

    return <Link {...props} to={processedTo} />
}

export interface AppNavLinkProps extends NavLinkProps {
    to: string
}

export const AppNavLink = forwardRef<HTMLAnchorElement, AppNavLinkProps>((props, ref) => {
    const { teamDomain } = useParams()

    const processedTo = useMemo(() => processTo(props.to, teamDomain), [teamDomain, props.to])

    return <NavLink ref={ref} {...props} to={processedTo} />
})
