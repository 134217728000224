import { BlockType, Operation as ShapeOperation, TeamId } from '@shapeci/types'
import {
    AutoformatPlugin,
    createAlignPlugin,
    createAutoformatPlugin,
    createBasicMarksPlugin,
    createDndPlugin,
    createExitBreakPlugin,
    createFontBackgroundColorPlugin,
    createFontColorPlugin,
    createHeadingPlugin,
    createImagePlugin,
    createLinkPlugin,
    createListPlugin,
    createParagraphPlugin,
    createSelectOnBackspacePlugin,
    createSoftBreakPlugin,
    createTrailingBlockPlugin,
    ELEMENT_IMAGE,
} from '@udecode/plate'
import { Descendant, Path } from 'slate'
import { v4 as uuidv4 } from 'uuid'

import { ModelStore } from '../blocks/ModelViewer/types'
import { autoformatPlugin } from '../components/AutoFormat/plugin'
import { createCodePlugin } from '../plugins/codePlugin/createCodePlugin'
import { exitBreakPlugin } from '../plugins/exitBreakPlugin/exitBreakPlugin'
import { createFullSelectionPlugin } from '../plugins/fullSelection/createFullSelectinonPlugin'
import { createLatexPlugin } from '../plugins/latexPlugin/createLatexPlugin'
import { linkPlugin } from '../plugins/linkPlugin/linkPlugin'
import { createMergableAttributesPlugin } from '../plugins/mergableAttributes/createMergableAttributesPlugin'
import { createModelViewerPlugin } from '../plugins/modelViewer/createModelViewerPlugin'
import { ELEMENT_MODEL_VIEWER } from '../plugins/modelViewer/types'
import { createNodeIdWithNormalizationPlugin } from '../plugins/nodeIdWithNormalization/createNodeIdWithNormalizationPlugin'
import { createNormalizeFragmentPlugin } from '../plugins/normalizeFragmentPlugin/createNormalizeFragmentPlugin'
import { createRemoveBOMPlugin } from '../plugins/removeBOM/createRemoveBOMPlugin'
import { createTransactionPlugin } from '../plugins/transactions/createTransactionPlugin'
import { createUnsplittableAttributesPlugin } from '../plugins/unsplittableAttributes/createUnsplittableAttributesPlugin'
import { createBlockTypePlugin } from '../plugins/withBlockTypes/createBlockTypePlugin'
import { createMyPlugins, ShapeValue } from '../types'
import { ShapeEditor } from '../types/editor'
import { createShapeUI } from './createShapeUI'
import { TransactionObserver } from './TransactionObserver'
import { Listeners } from './withOnHover'

const UNSPLITTABLES_ATTRIBUTES = ['meta', 'value']
const MERGABLE_ATTRIBUTES = ['meta', 'id', 'value']

export type OnTransactionFinished = (op: ShapeOperation[], editorValue: Descendant[]) => void
export type OpenWizardCallback = (blockType: BlockType, options?: OpenWizardOptions) => void
export interface OpenWizardOptions {
    isNew?: boolean
    at?: Path
}

export interface ShapePluginOptions {
    listeners: Listeners
    onTransactionFinished: OnTransactionFinished
    onOpenWizard: OpenWizardCallback
    teamId: TeamId
    transactionObserver?: TransactionObserver
    modelStore: ModelStore
}

export const createShapePlugins = ({
    listeners,
    onOpenWizard,
    onTransactionFinished,
    teamId,
    transactionObserver,
    modelStore,
}: ShapePluginOptions) =>
    // Plugin order matters -- they get applied from bottom to top
    createMyPlugins(
        [
            // Styling
            createFontColorPlugin(),
            createFontBackgroundColorPlugin(),
            createAlignPlugin({
                inject: {
                    props: {
                        validTypes: [BlockType.PARAGRAPH, BlockType.H1, BlockType.H2, BlockType.H3],
                    },
                },
            }),
            // Elements
            createHeadingPlugin({
                options: {
                    levels: 3,
                },
            }),
            createParagraphPlugin(),
            createCodePlugin({
                options: {
                    listeners,
                },
            }),
            createLatexPlugin({
                options: {
                    listeners,
                },
            }),
            createModelViewerPlugin({
                options: {
                    listeners,
                    store: modelStore,
                    onClickBanner: (at: Path) =>
                        onOpenWizard(BlockType.MODEL_VIEWER, { isNew: false, at }),
                },
            }),

            // Data
            createTransactionPlugin({
                options: {
                    onTransaction: onTransactionFinished as any,
                    pendingOperations: [],
                    transactionObserver,
                },
            }),

            createFullSelectionPlugin(),

            createUnsplittableAttributesPlugin({
                options: {
                    attributes: UNSPLITTABLES_ATTRIBUTES,
                },
            }),
            createMergableAttributesPlugin({
                options: {
                    attributes: MERGABLE_ATTRIBUTES,
                },
            }),
            createNodeIdWithNormalizationPlugin({
                options: {
                    idCreator: uuidv4,
                    idKey: 'id',
                    filterText: false,
                    reuseId: false,
                },
            }),
            createBlockTypePlugin(),

            // Behavior
            createImagePlugin({
                options: {
                    disableUploadInsert: true,
                    disableEmbedInsert: true,
                    disableCaption: true,
                },
            }),
            createListPlugin(),
            createLinkPlugin(linkPlugin),
            createBasicMarksPlugin(),
            createTrailingBlockPlugin(),
            createSoftBreakPlugin(),
            createExitBreakPlugin(exitBreakPlugin),
            createDndPlugin(),
            createAutoformatPlugin<
                AutoformatPlugin<ShapeValue, ShapeEditor>,
                ShapeValue,
                ShapeEditor
            >(autoformatPlugin),
            createRemoveBOMPlugin(),
            createSelectOnBackspacePlugin({
                options: {
                    query: {
                        allow: [ELEMENT_IMAGE, ELEMENT_MODEL_VIEWER],
                    },
                },
            }),
            createNormalizeFragmentPlugin(),
        ],
        {
            components: createShapeUI(teamId, listeners, modelStore),
        }
    )
