import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import useMenuBarStore from '../../hooks/useMenuBarStore'
import MenuBar from '../MenuBar'
import { LayoutGridAreas, MENU_BAR_HEIGHT } from './constants'
import LoaderPage from './LoaderPage'
import { LayoutWrapper, MenuBarDummy } from './Site'

const PublicWrapper = styled(LayoutWrapper)`
    grid-template-areas:
        '${LayoutGridAreas.MENU_BAR}'
        '${LayoutGridAreas.MAIN}';
    grid-template-columns: 1fr;
    grid-template-rows: ${MENU_BAR_HEIGHT} 1fr;
`

export function PublicDocument() {
    const menuBarState = useMenuBarStore((state) => state.value)

    return (
        <PublicWrapper>
            {menuBarState ? <MenuBar {...menuBarState} /> : <MenuBarDummy />}
            <Suspense fallback={<LoaderPage />}>
                <Outlet />
            </Suspense>
        </PublicWrapper>
    )
}
