import {
    BlockType,
    EditorNode,
    ModelCommit,
    ModelId,
    ModelPathData,
    RepositoryId,
} from '@shapeci/types'
import { Descendant, Editor, Node, Path, Transforms } from 'slate'
import { ReactEditor } from 'slate-react'
import { v4 as uuidv4 } from 'uuid'

const createBlockType = (type: BlockType, text: string) => ({
    id: uuidv4(),
    type,
    value: '',
    children: [
        {
            id: uuidv4(),
            type: BlockType.INLINE_TEXT,
            value: text,
            text,
        },
    ],
})

export const insertBlock = (editor: Editor, text: string, type: BlockType, at?: Path) => {
    // This is a quick fix to get the code to look decent
    const block = createBlockType(type, text)

    // defaults
    if (type === BlockType.LATEX)
        block.value =
            '% Start writing here and preview your changes\n\n\\text{Write an expression here...}'

    Transforms.insertNodes(editor, block as Descendant, { at })
}

export const insertImage = (editor: Editor, uploadId: string) => {
    const block = {
        ...createBlockType(BlockType.IMAGE, ''),
        uploadId,
    }

    Transforms.insertNodes(editor, block as Descendant)
}

export const insertModelViewer = (
    editor: Editor,
    repoId: RepositoryId,
    modelId: ModelId,
    modelPathData: ModelPathData,
    modelCommit: ModelCommit | undefined
) => {
    const block = {
        ...createBlockType(BlockType.MODEL_VIEWER, ''),
        value: {
            repoId,
            modelId,
            modelPathData,
            modelCommit,
        },
    }

    Transforms.insertNodes(editor, block as Descendant)
}

export const replaceSelection = (editor: Editor, newProperties: Partial<EditorNode>) => {
    Editor.withoutNormalizing(editor, () => {
        setNodes(editor, newProperties)
        Transforms.delete(editor)
    })
}

export const insertNodes = (editor: Editor, nodes: EditorNode) => {
    Transforms.insertNodes(editor, nodes as Descendant)
}

export const setNodes = (editor: Editor, nodes: Partial<EditorNode>, at?: Path) => {
    Transforms.setNodes(editor, nodes as any, { at })
}

export const selectNodeStart = (editor: Editor, nodePath: Path) => {
    Transforms.setSelection(editor, {
        anchor: {
            path: nodePath,
            offset: 0,
        },
        focus: {
            path: nodePath,
            offset: 0,
        },
    })
}

export const removeModel = (editor: ReactEditor, path: number[]) => {
    Transforms.setNodes(editor, { value: null } as Partial<Node>, { at: path })
}
