import { Region } from '@shapeci/types'
import {
    getAvatarBucketUri as getAvatarBucketUriUtil,
    getRegion as getRegionUtil,
    getServerURL as getServerUrlUtil,
    setRegion as setRegionUtil,
    StorageProvider,
} from '@shapeci/utils'

export const getRegion = () => getRegionUtil(localStorage as StorageProvider)
export const setRegion = (region: Region) => setRegionUtil(localStorage as StorageProvider, region)
export const getAvatarBucketUri = () => getAvatarBucketUriUtil(localStorage as StorageProvider)
export const getServerURL = () => getServerUrlUtil(localStorage as StorageProvider)
