import { useEffect } from 'react'
import { NavigateProps } from 'react-router-dom'

import { useAppNavigate } from '../hooks/useAppNavigate'

interface AppNavigateProps extends NavigateProps {
    to: string
}

// TODO -- implement the rest of the navigate component API
export function AppNavigate({ to }: AppNavigateProps) {
    const navigate = useAppNavigate()

    useEffect(() => {
        navigate(to)
    }, [to])

    return null
}
