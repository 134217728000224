import { LIGHT_THEME } from '@shapeci/ui'
import { ReactComponent as Logo } from '@shapeci/ui/dist/assets/wordmark.svg'
import { LoaderAlt as Loader } from '@styled-icons/boxicons-regular'
import styled from 'styled-components'

const LoadingScreenWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.colors.grey300};
`

const LoaderIcon = styled(Loader)`
    margin-top: ${({ theme }) => theme.getSpacing(4)};
    animation: 2s infinite spin ease;
    width: 3em;
    height: 3em;

    color: ${({ theme }) => theme.colors.primary500};

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

const LoadingScreen = () => (
    <LoadingScreenWrapper>
        <Logo fill={LIGHT_THEME.colors.grey900} title="ShapeCI" height={60} width={220} />
        <LoaderIcon />
    </LoadingScreenWrapper>
)

export default LoadingScreen
