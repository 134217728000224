import { BlockType, EditorNode, ListItem, ListItemChild } from '@shapeci/types'
import { v4 as uuidv4 } from 'uuid'

import { fragmentToNode } from './fragmentToNode'
import { Fragment } from './types'

export const fragmentToListItem = (fragment: Fragment) => {
    const maybeListItem = fragment as any as ListItem

    const children = maybeListItem.children as any as Fragment[]
    if (!children?.length) return null

    let listChildren = children.map(fragmentToNode).filter((t): t is EditorNode => t !== null)
    if (!listChildren.length) return null

    // Nodes need to be wrapped in LICs
    listChildren = listChildren.map(wrapInLIC).filter((n): n is EditorNode => n !== null)

    return {
        type: BlockType.LIST_ITEM,
        id: uuidv4(),
        children: listChildren,
    } as ListItem
}

const wrapInLIC = (node: EditorNode): EditorNode | null => {
    const lic = {
        id: uuidv4(),
        type: BlockType.LIST_ITEM_CHILD,
        children: [node],
    } as ListItemChild

    switch (node.type) {
        // Valid bullet point items
        case BlockType.PARAGRAPH:
        case BlockType.INLINE_TEXT:
        case BlockType.LINK:
        case BlockType.H1:
        case BlockType.H2:
        case BlockType.H3:
        case BlockType.CODE:
            return lic

        // Don't need to do anything
        case BlockType.LIST_ITEM_CHILD:
        case BlockType.BULLET_LIST:
        case BlockType.NUMBER_LIST:
            return node

        // We don't allow model viewers or images to be children
        case BlockType.IMAGE:
        case BlockType.MODEL_VIEWER:
            return null

        default:
            console.error(`Unsure if ${node.type} can be wrapped in LIC. Skipping.`)
            return null
    }
}
