import { BASE_THEME, getShapeClassName, LIGHT_THEME } from '@shapeci/ui'
import React, { useEffect, useState } from 'react'
import { Node, Transforms } from 'slate'
import { ReactEditor, useReadOnly } from 'slate-react'
import styled from 'styled-components'

import {
    CODE_EDITOR_LANGUAGES,
    CodeEditor,
    CodeEditorLanguage,
} from '../components/Code/CodeEditor'
import { useSlateReactStatic } from '../hooks/useSlateReactStatic'
import { BlockProps } from './types'

const CODE_WRAPPER_CLASSNAME = getShapeClassName('CodeBlock')

const CodeBlockOuter = styled.div`
    position: relative;
`

CodeBlockOuter.defaultProps = {
    className: CODE_WRAPPER_CLASSNAME,
}

const CodeBlockWrapper = styled.div`
    display: flex;
    align: center;
    justify-content: center;
    position: relative;

    select {
        position: absolute;
        z-index: 1;

        top: 0.6rem;
        right: 0.6rem;

        font-size: 0.9125rem;
        user-select: none;

        padding: 0.2rem 0.4rem;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.grey500};
        background-color: ${({ theme }) => theme.colors.grey100};

        font-family: ${({ theme }) => theme.fonts};
        color: ${({ theme }) => theme.colors.grey600};

        appearance: none;

        outline: 0px solid ${({ theme }) => theme.colors.primary100};
        transition: outline 0.125s ease-in-out;

        :focus {
            border-color: ${({ theme }) => theme.colors.primary500};
            outline-width: 2px;
        }
    }
`

interface CodeBlockProps extends BlockProps {
    children?: React.ReactNode
}

export default function Code({ element, attributes, children }: CodeBlockProps) {
    const isReadOnly = useReadOnly()

    const editor = useSlateReactStatic()
    const path = ReactEditor.findPath(editor, element as any)

    const { value, language } = element as any

    const [updatedLanguage, setUpdatedLanguage] = useState<CodeEditorLanguage>(language)

    const saveChanges = (updatedValue: string) => {
        Transforms.setNodes(
            editor,
            {
                value: updatedValue,
                language: updatedLanguage,
            } as Partial<Node>,
            { at: path }
        )
    }

    const deleteBlock = () => Transforms.delete(editor, { at: path })

    useEffect(() => {
        saveChanges(value)
    }, [updatedLanguage])

    return (
        <CodeBlockOuter
            contentEditable={false}
            {...attributes}
            id={(element as any).id}
            onKeyDown={(e) => {
                if (e.key === 'Backspace' && !value) {
                    // delete the block if its empty and backspace is pressed
                    deleteBlock()
                }
            }}
        >
            <CodeBlockWrapper>
                <select
                    value={language}
                    disabled={isReadOnly}
                    style={{ opacity: 1 }}
                    onChange={(e) => setUpdatedLanguage(e.target.value as CodeEditorLanguage)}
                >
                    {CODE_EDITOR_LANGUAGES.map((lang) => (
                        <option>{lang}</option>
                    ))}
                </select>
                <CodeEditor
                    readonly={isReadOnly}
                    language={updatedLanguage}
                    value={value}
                    setValue={saveChanges}
                    showLineNumbers
                    style={{
                        borderRadius: BASE_THEME.borderRadius,
                        background: LIGHT_THEME.colors.grey100,
                    }}
                />
            </CodeBlockWrapper>
            {children}
        </CodeBlockOuter>
    )
}
