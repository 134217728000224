import * as t from 'io-ts'

import { documentId, documentSessionId, userId } from './id.model'
import { MongoModelType } from './types.model'

export interface EditorSession extends t.TypeOf<typeof editorSession> {}
export const editorSession = t.strict({
    userId,
    sessionId: t.string,
})

export interface DocumentSession extends t.TypeOf<typeof documentSession> {}
export const documentSession = t.strict({
    id: documentSessionId,
    type: t.literal(MongoModelType.DOCUMENT_SESSION),
    documentId,
    editingSessions: t.array(editorSession),
    activeEditingSession: t.union([editorSession, t.null]),
})
