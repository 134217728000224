import { LoaderAlt } from '@styled-icons/boxicons-regular'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled(LoaderAlt)<{ size?: number }>`
    ${({ size }) =>
        size &&
        `
    width: ${size}rem;
  `}
    height: 100%;
    animation: 2s linear ${spin} infinite;
    color: ${({ theme }) => theme.colors.grey600};
`

export default Spinner
