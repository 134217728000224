import * as t from 'io-ts'

import { baseOperation, OperationType } from './base.operations'

export interface InsertTextOperation extends t.TypeOf<typeof insertTextOperation> {}
export const insertTextOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.INSERT_TEXT),
        offset: t.number,
        text: t.string,
        path: t.array(t.number),
    }),
])
