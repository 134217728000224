import { FC, ReactNode } from 'react'

import Auth0ProviderWithNavigate from './Auth0ProviderWithNavigate'
import ModelStoreProvider from './ModelStoreProvider'
import NonAuthProviders from './NonAuthProviders'

interface ProviderProps {
    children?: ReactNode
}

const ClientProviders: FC<ProviderProps> = ({ children }) => (
    <Auth0ProviderWithNavigate>
        <NonAuthProviders>
            <ModelStoreProvider>{children}</ModelStoreProvider>
        </NonAuthProviders>
    </Auth0ProviderWithNavigate>
)

export default ClientProviders
