import { ClientTeam, ReviewWithReviewer } from '@shapeci/types'
import { Button, Heading, Input, RPopover, Space, zIndex } from '@shapeci/ui'
import { Check, Plus, Search } from '@styled-icons/boxicons-regular'
import { ReactNode, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useErrorNotification } from '../../../../utils/dispatchNotifications'

type TeamMembers = ClientTeam['users']
type TeamMember = TeamMembers[0]

const RequestMenuContent = styled(RPopover.Content)`
    background: ${({ theme }) => theme.colors.grey100};
    border-radius: ${({ theme }) => theme.borderRadius};
    border: ${({ theme }) => theme.border};

    margin-top: ${({ theme }) => theme.getSpacing(1.5)};
    margin-right: ${({ theme }) => theme.getSpacing(3)};
    width: calc(20rem - ${({ theme }) => theme.getSpacing(6)});
    box-sizing: border-box;
    padding: 0;

    ${({ theme }) => theme.getDepth(2)};

    h3 {
        padding: ${({ theme }) => theme.getSpacing(1)} ${({ theme }) => theme.getSpacing(2)};
        border-bottom: ${({ theme }) => theme.border};
    }

    h4 {
        margin: ${({ theme }) => theme.getSpacing(1.5)} 0 ${({ theme }) => theme.getSpacing(1)};
        padding: 0 ${({ theme }) => theme.getSpacing(2)};
    }

    h5 {
        margin: ${({ theme }) => theme.getSpacing(1.5)} 0 ${({ theme }) => theme.getSpacing(2.5)};
        padding: 0 ${({ theme }) => theme.getSpacing(2)};
        color: ${({ theme }) => theme.colors.grey600};

        word-break: break-word;
    }

    > .shape__space {
        margin: 0;
        padding: ${({ theme }) => theme.getSpacing(1.5)} 0 0;

        > .shape__space:not(.result) {
            padding: 0 ${({ theme }) => theme.getSpacing(2)};
        }

        span {
            color: ${({ theme }) => theme.colors.grey600};
        }

        .result {
            .shape__space {
                border-top: ${({ theme }) => theme.border};
                padding: ${({ theme }) => theme.getSpacing(0.5)}
                    ${({ theme }) => theme.getSpacing(2)};

                font-size: 0.875rem;
            }

            .icon-wrapper {
                height: 1.75rem;
                width: 1.75rem;

                display: flex;
                align-items: center;
                justify-content: center;

                color: ${({ theme }) => theme.colors.success500};

                svg {
                    fill: currentColor;
                    width: 75%;
                    height: 75%;
                }
            }
        }
    }

    z-index: ${zIndex.BLOCK_DRAWER + 1};
`

interface RequestMenuProps {
    children: ReactNode
    teamMembers: TeamMembers
    reviews: ReviewWithReviewer[]
    requestReview: (reviewerId: string) => Promise<void>
}

const doesUserMatchQuery = (user: TeamMember, queryText: string) => {
    const query = queryText.toLowerCase()

    return `${user.firstName}\t${user.lastName}`.toLowerCase().includes(query)
}

export default function RequestMenu({
    children,
    teamMembers,
    reviews,
    requestReview,
}: RequestMenuProps) {
    // id of the user whose request is being created
    const [loadingRequestReviewerId, setLoadingRequestReviewerId] = useState<string | null>(null)

    const [query, setQuery] = useState('')

    const dispatchError = useErrorNotification()

    const processedTeamMembers = useMemo(
        () => teamMembers.filter((user) => doesUserMatchQuery(user as any, query)),
        [teamMembers, query]
    )

    const handleRequestReview = async (reviewerId: string) => {
        try {
            setLoadingRequestReviewerId(reviewerId)
            await requestReview(reviewerId)
        } catch (error) {
            dispatchError((error as any).message)
        } finally {
            setLoadingRequestReviewerId(null)
        }
    }

    return (
        <RPopover.Root modal>
            <RPopover.Trigger asChild>{children}</RPopover.Trigger>
            <RPopover.Portal>
                <RequestMenuContent>
                    <Heading kind="h3" size={1.5} m={0}>
                        Request a review
                    </Heading>
                    <Space vertical align="stretch" size={0}>
                        <Input
                            icon={<Search />}
                            label="Search for a team member"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            fluid
                        />
                        <Heading kind="h4" size={1.25} m={0}>
                            Reviewers
                        </Heading>
                        <Space className="result" vertical size={0} align="stretch">
                            {processedTeamMembers.length ? (
                                processedTeamMembers.map((user: any) => {
                                    const hasReview = Boolean(
                                        reviews.find((review) => review.reviewer.id === user.id)
                                    )

                                    return (
                                        <Space key={user.id} align="center">
                                            {!hasReview ? (
                                                <Button
                                                    kind="icon"
                                                    intent={'muted'}
                                                    size="small"
                                                    isLoading={user.id === loadingRequestReviewerId}
                                                    onClick={() => handleRequestReview(user.id)}
                                                >
                                                    <Plus />
                                                </Button>
                                            ) : (
                                                <div className="icon-wrapper">
                                                    <Check />
                                                </div>
                                            )}
                                            <span>
                                                {user.firstName} {user.lastName}
                                            </span>
                                        </Space>
                                    )
                                })
                            ) : (
                                <Heading kind="h5" size={1.25}>
                                    No users found for '{query}'
                                </Heading>
                            )}
                        </Space>
                    </Space>
                </RequestMenuContent>
            </RPopover.Portal>
        </RPopover.Root>
    )
}
