import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

interface Auth0ProviderProps {
    children?: ReactNode
}

const Auth0ProviderWithNavigate: FC<Auth0ProviderProps> = ({ children }) => {
    const navigate = useNavigate()
    const domain = process.env.REACT_APP_AUTH0_DOMAIN
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE
    const redirectUri = new URL('/callback', window.origin).toString()

    const onRedirectCallback = (appState: AppState | undefined) => {
        navigate(appState?.returnTo || window.location.pathname, { replace: true })
    }

    if (!domain || !clientId || !redirectUri) throw new Error(`Invalid environment configuration`)

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience,
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithNavigate
