import { BlockType, InlineText } from '@shapeci/types'
import pick from 'lodash.pick'
import { v4 as uuidv4 } from 'uuid'

import { Fragment } from './types'

export const fragmentToText = (fragment: Fragment) => {
    const maybeText = fragment as InlineText
    const blockMarks = pick(maybeText, [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'code-editor',
        'kbd',
        'subscript',
    ])

    return {
        type: BlockType.INLINE_TEXT,
        id: uuidv4(),
        text: maybeText.text,
        ...blockMarks,
    } as InlineText
}
