import { BlockId } from '@shapeci/types'
import { Button, getShapeClassName, zIndex } from '@shapeci/ui'
import { MessageAdd as CommentIcon } from '@styled-icons/boxicons-regular'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { HoverPayload } from '../Editor/core/components/Editor'
import { getContainerElementFromBlockElement } from './utils'

const COMMENT_BUTTON_CLASSNAME = getShapeClassName('CommentButton')

const CommentButtonOuter = styled.div`
    position: absolute;
    transition: all 0.12s ease-in-out;
    right: -8rem;
    width: 8.5rem;
    height: 9rem;
    background: transparent;
    margin-top: -4.5rem;
    padding-top: 3rem;
    box-sizing: border-box;
    z-index: ${zIndex.BLOCK_DRAWER - 1};
`

const CommentButtonInner = styled(Button)`
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    position: absolute;
    right: 3rem;
    border: none;

    transition: all 0.06s ease-in-out;
    background-color: ${({ theme }) => theme.colors.grey100};
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scaleX(-1);
        width: 1.5rem;
        height: 1.5rem;
    }

    :hover {
        display: flex !important;
        background-color: ${({ theme }) => theme.colors.primary500};
        color: ${({ theme }) => theme.colors.grey100};
        opacity: 1 !important;
    }
`

CommentButtonInner.defaultProps = {
    className: COMMENT_BUTTON_CLASSNAME,
}

interface CommentButtonProps {
    isPreviewMode: boolean
    current: HoverPayload | null
    add: (blockId: BlockId) => void
    isOpenThread: boolean
}

export default function CommentButton({
    current,
    add,
    isPreviewMode,
    isOpenThread,
}: CommentButtonProps) {
    const [offset, setOffset] = useState<number>(-1)
    const [targetId, setTargetId] = useState<string | null>(null)
    const [editorContainer, setEditorContainer] = useState<HTMLElement | null>(null)
    const [isButtonHovered, setIsButtonHovered] = useState<boolean>(false)
    const hoveredElement = useMemo(
        () => getContainerElementFromBlockElement(current?.target, isPreviewMode),
        [current, isPreviewMode]
    )

    useEffect(() => {
        if (!editorContainer)
            setEditorContainer(document.getElementById(getShapeClassName('editor')))

        if (!hoveredElement || !current || !editorContainer) return

        const { top, bottom } = hoveredElement.getBoundingClientRect()
        const blockHeight = bottom - top
        const editorTop = editorContainer.getBoundingClientRect().top
        const newOffset = top - editorTop + blockHeight / 2

        setOffset(newOffset)
        setTargetId(current.blockId)
    }, [hoveredElement, editorContainer])

    const handleClick = () => {
        if (!targetId) return

        add(targetId)
    }

    const isVisible = isOpenThread || (!isButtonHovered && (offset === -1 || current === null))

    return (
        <CommentButtonOuter
            onMouseEnter={() => {
                setIsButtonHovered(true)
            }}
            onMouseLeave={() => {
                setIsButtonHovered(false)
            }}
            style={{
                opacity: isVisible ? 0 : 1,
                pointerEvents: isVisible ? 'none' : 'all',
                transform: `translateY(${offset}px)`,
            }}
        >
            <CommentButtonInner kind="secondary" onClick={handleClick}>
                <CommentIcon />
            </CommentButtonInner>
        </CommentButtonOuter>
    )
}
