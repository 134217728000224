import {
    ClientKnowledgeDocument,
    ClientReviewDocument,
    ReviewStatus,
    ReviewWithReviewer,
} from '@shapeci/types'
import { zIndex } from '@shapeci/ui'
import { FC, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { cache } from '../../../caches'
import { useErrorNotification } from '../../../utils/dispatchNotifications'
import { isReviewDocument } from '../../../utils/types'
import Reviews from './Reviews'

const DrawerContainer = styled.div<{ $isOpen: boolean }>`
    ${({ $isOpen }) =>
        !$isOpen &&
        css`
            transform: translateX(100%));
        `}

    width: 100%;
    position: sticky;
    top: 70px;
    height: 100%;

    right: 0;
    z-index: ${zIndex.BLOCK_DRAWER};
    transition: width 0.3s ease-in-out;
`

const BlocksDrawerWrapper = styled.div<{ $isOpen: boolean }>`
    --width: 20rem;
    flex-direction: column;
    filter: drop-shadow(-3px 2px 2px #00000008);
    position: sticky;
    top: 0;
    right: 0;
    transition: transform 0.3s ease-in-out;

    ${({ $isOpen }) => $isOpen && 'transform: translateX(0);'}

    box-sizing: border-box;
    height: calc(100vh - 71px);
    overflow-y: hidden;
    width: var(--width);
    background: ${({ theme }) => theme.colors.grey100};
    border-left: ${({ theme }) => theme.border};

    padding-top: 24px;

    .shape__space {
        margin-top: ${({ theme }) => theme.getSpacing(3)};
        overflow: auto;
        flex: 1;
    }

    h1 {
        &:not(:first-of-type) {
            margin: ${({ theme }) => theme.getSpacing(2)} 0;
        }
        font-size: 1.125rem;
    }
`

interface BlocksDrawerProps {
    documentMetadata?: ClientKnowledgeDocument | ClientReviewDocument
    documentId?: string
    reviews?: ReviewWithReviewer[]
    updateReviewData?: any
    restoreDocument: (value: string) => void
    reloadDocument: () => void
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    isPreviewMode: boolean
}

// TODO: Write interface for review data stub
const BlocksDrawer: FC<BlocksDrawerProps> = (props) => {
    const { isOpen, documentMetadata, reviews } = props

    const [isReviewApproved, setIsReviewApproved] = useState<boolean>(false)
    const { data: team } = cache.useTeam()
    const { data: user } = cache.useUser()
    const dispatchError = useErrorNotification()

    const checkApprovedReviews = async () => {
        if (team?.minimumMergeReviews === 0) {
            setIsReviewApproved(true)
            return
        }
        if (!reviews?.length) {
            setIsReviewApproved(false)
            return
        }
        const approvedReviews = reviews.filter((r) => r.status === ReviewStatus.APPROVED)
        const isApproved = approvedReviews?.length >= (team?.minimumMergeReviews ?? 0)

        setIsReviewApproved(isApproved)
    }

    useEffect(() => {
        if (reviews) {
            checkApprovedReviews()
        }
    }, [reviews])

    const currentUserRequestIdx =
        reviews?.findIndex((review) => review.reviewer.id === user?.id) ?? -1

    let currentUserRequest: null | ReviewWithReviewer = null

    if (reviews && currentUserRequestIdx > -1) {
        currentUserRequest = reviews[currentUserRequestIdx]
    }

    return (
        <DrawerContainer $isOpen={isOpen}>
            {/* 
            // TODO re-implement toggle for smaller screens
            <ToggleButton $isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <ChevronsRight />
                {!isOpen && <span>Blocks</span>}
            </ToggleButton> */}

            <BlocksDrawerWrapper $isOpen={isOpen}>
                {isReviewDocument(documentMetadata) && (
                    <Reviews {...props} documentMetadata={documentMetadata} />
                )}
            </BlocksDrawerWrapper>
        </DrawerContainer>
    )
}

BlocksDrawer.defaultProps = {
    documentId: undefined,
    reviews: undefined,
    updateReviewData: undefined,
    documentMetadata: undefined,
}

export default BlocksDrawer
