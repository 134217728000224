import { css } from 'styled-components'

export const toolbarItemStyles = css`
    border: none;
    background: none;
    padding: ${({ theme }) => theme.getSpacing(1)};
    border-radius: calc(
        ${({ theme }) => theme.borderRadius} - ${({ theme }) => theme.getSpacing(0.5)}
    );

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.grey700};

    cursor: pointer;

    font-size: 0.875rem;

    svg {
        width: 1.25rem;
        height: 1.25rem;
    }

    :hover,
    :focus-visible {
        background: ${({ theme }) => theme.colors.primary100};
        color: ${({ theme }) => theme.colors.primary500};
    }

    :focus {
        outline: none;
    }

    :focus-visible {
        outline: none;
        position: relative;
        box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primary300};
    }

    &.slate-ToolbarButton-active {
        background: ${({ theme }) => theme.colors.primary200};
        color: ${({ theme }) => theme.colors.primary600};
    }

    :not(:last-child) {
        margin-right: 0.175rem;
    }
`
