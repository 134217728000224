import { NotificationTray } from '@shapeci/ui'

import { useNotificationState } from '../context/Notifications'
import { useRemoveNotification } from '../utils/dispatchNotifications'

const GlobalNotificationTray = () => {
    const notifications = useNotificationState()
    const dispatch = useRemoveNotification()

    const onClose = (id: string) => {
        dispatch(id)
    }

    return (
        <NotificationTray position="bottom-right" onClose={onClose} notifications={notifications} />
    )
}

export default GlobalNotificationTray
