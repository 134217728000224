import { forwardRef, useEffect } from 'react'

import { useShapeEditorRef } from '../../types'
import { ShapeEditor } from '../../types/editor'

interface SetRefProps {
    onEditorLoaded?: (editor: ShapeEditor) => void
}

/**
 * This is pretty jank, but we need to set the ref so that components above the editor
 * can have access to the editor object. However, we can only call the useEditorRef hook
 * inside the editor, so we just inject this element to the editor for the sole purpose
 * of calling the hook
 */
const SetRef = forwardRef<ShapeEditor, SetRefProps>(({ onEditorLoaded }, ref) => {
    const editor = useShapeEditorRef()

    useEffect(() => {
        if (!editor) return

        if (ref && typeof ref !== 'function') {
            // eslint-disable-next-line no-param-reassign
            ref.current = editor
        } else {
            throw new Error('Ref cannot be a null nor a function')
        }

        onEditorLoaded?.(editor)
    }, [editor])

    return <></>
})

SetRef.defaultProps = {
    onEditorLoaded: () => {},
}

export default SetRef
