import { Button, DropdownMenu, Heading, LIGHT_THEME, Portal } from '@shapeci/ui'
import { CaretDown } from '@styled-icons/boxicons-regular'
import { CommentDetail as ReviewerIcon, Pencil as EditorIcon } from '@styled-icons/boxicons-solid'
import { CSSProperties, useMemo } from 'react'
import styled from 'styled-components'

const ModeSelectButton = styled(Button)`
    background: var(--mode-select-background) !important;
    border-color: var(--mode-select-background) !important;
    color: var(--mode-select-text) !important;
    width: 135px;

    > span {
        flex: 1;
        text-align: left;
    }

    svg {
        margin-right: ${({ theme }) => theme.getSpacing(0.5)};
    }
`

const ModeMenuContent = styled(DropdownMenu.Content)`
    margin-top: 0.25rem;
    margin-right: 150px;
    width: 300px;
`

const ModeMenuItem = styled(DropdownMenu.Item)<{ $active: boolean }>`
    padding: ${({ theme }) => theme.getSpacing(2)} ${({ theme }) => theme.getSpacing(0.5)}
        ${({ theme }) => theme.getSpacing(2)} ${({ theme }) => theme.getSpacing(1.5)};
    display: flex;
    align-items: start;
    height: auto;
    gap: ${({ theme }) => theme.getSpacing(2)};

    color: ${({ theme }) => theme.colors.grey600};

    h4 {
        color: ${({ theme }) => theme.colors.grey700};
    }
    border-radius: 0.125rem;

    :hover {
        background: ${({ theme }) => theme.colors.grey300};

        color: ${({ theme }) => theme.colors.grey700};

        h4 {
            color: ${({ theme }) => theme.colors.grey800};
        }

        svg {
            background: ${({ theme }) => theme.colors.grey400};
            color: ${({ theme }) => theme.colors.grey800};
        }
    }

    &&& svg {
        width: 2.25em;
        height: 2.25em;
        margin-top: -0.125em;
        padding: ${({ theme }) => theme.getSpacing(1)};
        box-sizing: border-box;
        border-radius: 0.175rem;

        ${({ $active }) =>
            $active &&
            `
            background: var(--mode-select-background);
            color: var(--mode-select-text);
        `}
    }

    div {
        h4 {
            font-size: 0.875rem;
        }

        p {
            margin: 0.25rem 0 0;
            line-height: 1.3;
        }
    }
`

export enum DocumentMode {
    EDITING = 'Editing',
    REVIEWING = 'Reviewing',
}

interface DocumentModeSelectProps {
    mode: DocumentMode
    onModeChanged: (mode: DocumentMode) => void
    isLoading?: boolean
}

const OPTION_EDITING = {
    icon: <EditorIcon size={18} />,
    label: 'Editing',
    value: DocumentMode.EDITING,
    description: 'Edit the document',
    selectedColor: LIGHT_THEME.colors.info100,
    indicatorColor: LIGHT_THEME.colors.info600,
}

const OPTION_REVIEWING = {
    icon: <ReviewerIcon size={18} />,
    label: 'Reviewing',
    value: DocumentMode.REVIEWING,
    description: 'Review and leave comments',
    selectedColor: LIGHT_THEME.colors.success200,
    indicatorColor: LIGHT_THEME.colors.success800,
}

const DOCUMENT_MODE_OPTIONS = [OPTION_EDITING, OPTION_REVIEWING]

type ModeOption = (typeof DOCUMENT_MODE_OPTIONS)[number]

export function ModeSelect({ mode, onModeChanged, isLoading }: DocumentModeSelectProps) {
    const value = useMemo(() => DOCUMENT_MODE_OPTIONS.find((o) => o.value === mode)!, [mode])

    const handleClick = (selected: ModeOption) => {
        // ignore if value is the same as current mode
        if (selected.value === mode) return

        // otherwise update mode
        onModeChanged(selected.value as DocumentMode)
    }

    const cssVars = useMemo(
        () =>
            ({
                '--mode-select-background': value.selectedColor,
                '--mode-select-text': value.indicatorColor,
            } as CSSProperties),
        [value]
    )

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <ModeSelectButton
                    isLoading={isLoading}
                    prepend={value.icon}
                    append={<CaretDown />}
                    style={cssVars}
                    size="small"
                >
                    <span>{value.label}</span>
                </ModeSelectButton>
            </DropdownMenu.Trigger>
            <Portal>
                <ModeMenuContent
                    style={cssVars}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {DOCUMENT_MODE_OPTIONS.map((option) => (
                        <ModeMenuItem
                            $active={option.value === mode}
                            onClick={() => handleClick(option)}
                            key={option.value}
                        >
                            {option.icon}
                            <div>
                                <Heading kind="h4" m={0}>
                                    {option.label}
                                </Heading>
                                <p>{option.description}</p>
                            </div>
                        </ModeMenuItem>
                    ))}
                </ModeMenuContent>
            </Portal>
        </DropdownMenu.Root>
    )
}
