import { BlockType } from '@shapeci/types'

export const isTextualBlock = (type: BlockType | null) => {
    switch (type) {
        case BlockType.BULLET_LIST:
        case BlockType.NUMBER_LIST:
        case BlockType.LIST_ITEM:
        case BlockType.LIST_ITEM_CHILD:
        case BlockType.H1:
        case BlockType.H2:
        case BlockType.H3:
        case BlockType.INLINE_TEXT:
        case BlockType.PARAGRAPH:
        case BlockType.LINK:
            return true

        case BlockType.CODE:
        case BlockType.LATEX:
        case BlockType.IMAGE:
        case BlockType.MODEL_VIEWER:
        case null:
            return false

        default:
            console.warn(`Unhandeled block type in isTextualBlock: ${type}`)
            return false
    }
}

export const isAlignableBlock = (type: BlockType | null) => {
    switch (type) {
        case BlockType.H1:
        case BlockType.H2:
        case BlockType.H3:
        case BlockType.INLINE_TEXT:
        case BlockType.PARAGRAPH:
        case BlockType.LINK:
            return true

        case BlockType.BULLET_LIST:
        case BlockType.NUMBER_LIST:
        case BlockType.LIST_ITEM:
        case BlockType.LIST_ITEM_CHILD:
        case BlockType.CODE:
        case BlockType.LATEX:
        case BlockType.IMAGE:
        case BlockType.MODEL_VIEWER:
        case null:
            return false

        default:
            console.warn(`Unhandeled block type in isAlignableBlock: ${type}`)
            return false
    }
}
