import { CodeAlt } from '@styled-icons/boxicons-regular/CodeAlt'
import { Check } from '@styled-icons/material/Check'
import { FontDownload } from '@styled-icons/material/FontDownload'
import { FormatBold } from '@styled-icons/material/FormatBold'
import { FormatColorText } from '@styled-icons/material/FormatColorText'
import { FormatItalic } from '@styled-icons/material/FormatItalic'
import { FormatStrikethrough } from '@styled-icons/material/FormatStrikethrough'
import { FormatUnderlined } from '@styled-icons/material/FormatUnderlined'
import {
    ColorPickerToolbarDropdown,
    getPluginType,
    MARK_BG_COLOR,
    MARK_BOLD,
    MARK_CODE,
    MARK_COLOR,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_UNDERLINE,
    MarkToolbarButton,
    useEditorRef,
} from '@udecode/plate'
import React from 'react'

function MarkButtons() {
    const editor = useEditorRef()

    return (
        <>
            <MarkToolbarButton
                type={getPluginType(editor, MARK_BOLD)}
                icon={<FormatBold />}
                tooltip={{
                    content: 'Bold',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_ITALIC)}
                icon={<FormatItalic />}
                tooltip={{
                    content: 'Italics',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_UNDERLINE)}
                icon={<FormatUnderlined />}
                tooltip={{
                    content: 'Underline',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_STRIKETHROUGH)}
                icon={<FormatStrikethrough />}
                tooltip={{
                    content: 'Strikethrough',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <MarkToolbarButton
                type={getPluginType(editor, MARK_CODE)}
                icon={<CodeAlt />}
                tooltip={{
                    content: 'Code',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <ColorPickerToolbarDropdown
                pluginKey={MARK_COLOR}
                icon={<FormatColorText />}
                selectedIcon={<Check />}
                tooltip={{
                    content: 'Text Color',
                    placement: 'bottom',
                    delay: 1250,
                }}
            />
            <ColorPickerToolbarDropdown
                pluginKey={MARK_BG_COLOR}
                icon={<FontDownload />}
                selectedIcon={<Check />}
                tooltip={{
                    content: 'Highlight Color',
                    placement: 'bottom',
                    delay: 1250,
                }}
            />
        </>
    )
}

export default MarkButtons
