import * as t from 'io-ts'

import { fromEnum } from '../../utils/enum.util'
import { PayloadTypes } from './http.payloads'

export enum MergeOption {
    OURS = 'ours',
    THEIRS = 'theirs',
    ADD = 'add',
    DELETE = 'delete',
}
export const mergeOption = fromEnum('mergeOption', MergeOption)

export interface MergeStrategy extends t.TypeOf<typeof mergeStrategy> {}
export const mergeStrategy = t.strict({
    path: t.string,
    strategy: mergeOption,
})

export interface CreateBranchPayload extends t.TypeOf<typeof createBranchPayload> {}
export const createBranchPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_BRANCH),
    branch: t.string,
    baseBranch: t.string,
})

export interface MergeBranchPayload extends t.TypeOf<typeof mergeBranchPayload> {}
export const mergeBranchPayload = t.intersection([
    t.strict({
        type: t.literal(PayloadTypes.MERGE_BRANCH),
        deleteBranchAfterMerge: t.boolean,
        baseBranch: t.string,
        incomingBranch: t.string,
    }),
    t.partial({
        mergeStrategy: t.array(mergeStrategy),
        incomingHeadCommit: t.string,
    }),
])
