/* eslint-disable no-param-reassign */
import { PlateEditor, Value, WithPlatePlugin } from '@udecode/plate'
import cloneDeep from 'lodash.clonedeep'
import omit from 'lodash.omit'
import { SplitNodeOperation } from 'slate'

import { UnsplittableAttributesPlugin } from './types'

export const withUnsplittableAttributes = <
    V extends Value = Value,
    E extends PlateEditor<V> = PlateEditor<V>
>(
    editor: E,
    { options: { attributes } }: WithPlatePlugin<UnsplittableAttributesPlugin, V, E>
) => {
    const { apply } = editor

    editor.apply = (op) => {
        if (op.type === 'split_node') {
            const newOp = removeAttributesFromOp(op, attributes || [])
            return apply(newOp)
        }

        return apply(op)
    }

    return editor
}

const removeAttributesFromOp = (op: SplitNodeOperation, attributes: string[]) => {
    const copy = cloneDeep(op)
    return {
        ...copy,
        properties: omit(copy.properties, attributes),
    }
}
