import * as t from 'io-ts'

import { blockProperties } from '../models/block.model'
import { baseOperation, OperationType } from './base.operations'

export interface MergeNodeOperation extends t.TypeOf<typeof mergeNodeOperation> {}
export const mergeNodeOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.MERGE_NODE),
        path: t.array(t.number),
        properties: t.union([t.null, blockProperties]),
        position: t.number,
    }),
])
