/* eslint-disable no-param-reassign */
/* eslint-disable no-continue */
import { PlateEditor, TNode, TNodeEntry, Value, WithPlatePlugin } from '@udecode/plate'
import omit from 'lodash.omit'
import { BaseText, Descendant, Editor, Element, Node, Text, Transforms } from 'slate'

import { MergableAttributesPlugin } from './types'

export const withMergableAttributes = <
    V extends Value = Value,
    E extends PlateEditor<V> = PlateEditor<V>
>(
    editor: E,
    { options: { attributes } }: WithPlatePlugin<MergableAttributesPlugin, V, E>
) => {
    const { normalizeNode } = editor

    editor.normalizeNode = (entry) => {
        const didMerge = mergeTextNodes(editor as any, entry, attributes || [])
        if (didMerge) return

        normalizeNode(entry)
    }

    return editor
}

const mergeTextNodes = <N extends TNode>(
    editor: Editor,
    entry: TNodeEntry<N>,
    attributesToIgnore: string[]
) => {
    const [node, path] = entry
    if (!Element.isElement(node) || node.children.length === 0) return false

    let didMergeNodes = false
    for (let i = 1, n = 1; i < node.children.length; i++, n++) {
        const currentNode = Node.get(editor, path)
        if (Text.isText(currentNode)) continue

        // If there is only one child remaining, we can't do any merging
        if (currentNode.children.length === 1) continue

        const prev = currentNode.children[n - 1] as Descendant
        const child = node.children[i] as Descendant

        // We can only merge two inline texts
        if (!Text.isText(child) || !Text.isText(prev)) continue

        // Now we check to see if these nodes are mergable when we ignore the attributes
        const childWithoutAttribs = omit(child, attributesToIgnore) as BaseText
        const prevWithoutAttribs = omit(prev, attributesToIgnore) as BaseText
        if (Text.equals(childWithoutAttribs, prevWithoutAttribs, { loose: true })) {
            Transforms.mergeNodes(editor, { at: path.concat(n), voids: true })
            n--
            didMergeNodes = true
        }
    }

    return didMergeNodes
}
