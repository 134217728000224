import * as t from 'io-ts'

import { DateFromISOString } from '../primitives/primitives'
import { userId } from './id.model'

export interface ObjectMetadata extends t.TypeOf<typeof objectMetadata> {}
export const objectMetadata = t.exact(
    t.partial({
        lastUpdated: DateFromISOString,
        lastUpdatedBy: userId,
        dateCreated: DateFromISOString,
        creator: userId,
    })
)
