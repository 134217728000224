import * as Dialog from '@radix-ui/react-dialog'
import { ClientTeam, CollectionType } from '@shapeci/types'
import { LIGHT_THEME, Space } from '@shapeci/ui'
import { getSharableRoute } from '@shapeci/utils'
import {
    Command,
    Data,
    File,
    GitPullRequest,
    Search,
    SubdirectoryLeft,
} from '@styled-icons/boxicons-regular'
import { Folder } from '@styled-icons/boxicons-solid'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { cache } from '../../caches'
import { useApi } from '../../hooks/useApi'
import useTeamId from '../../hooks/useTeamId'
import { isMacOS } from '../../utils/browser'
import { getTimeAgo } from '../../utils/formatTime'
import { useCommandMenu } from './store'
import { SearchDialogContent, SearchDialogOverlay, SearchInput, SearchResultItem } from './ui'

type AtlasSearchHighlight = {
    path: string
    texts: {
        value: string
        type: 'hit' | 'text'
    }[]
    score: number
}

type SearchDocumentStub = {
    id: string
    title: string
    lastUpdated: string
    source: CollectionType.DOCUMENT
    type: 'KnowledgeDocument' | 'ReviewRequest'
    highlights: AtlasSearchHighlight[]
}

type SearchRepositoryStub = {
    id: string
    name: string
    lastUpdated: string
    source: CollectionType.REPOSITORY
    type: 'GiteaRepository'
    highlights: AtlasSearchHighlight[]
}

type SearchFolderStub = {
    id: string
    title: string
    lastUpdated: string
    source: CollectionType.FOLDER
    type: 'Folder'
    highlights: AtlasSearchHighlight[]
}

type SearchResult = SearchDocumentStub | SearchRepositoryStub | SearchFolderStub

function getBestHighlight(highlights: AtlasSearchHighlight[]): AtlasSearchHighlight | undefined {
    const topHighlight = highlights.sort((a, b) => b.score - a.score)[0]

    if (['name', 'title'].includes(topHighlight.path)) {
        return undefined
    }

    // TODO: handle cases for fields (branch, etc)
    return topHighlight
}

function MenuItem(props: { result: SearchResult; team: ClientTeam; idx: number }) {
    const { result, idx, team } = props

    const renderProps = {} as {
        to: string
        icon: ReactNode
        text: string
        highlight?: AtlasSearchHighlight
    }

    if (result.source === CollectionType.DOCUMENT) {
        renderProps.to = `${team?.domain}/documents/${getSharableRoute(result.id, result.title)}`

        if (result.type === 'KnowledgeDocument') {
            renderProps.icon = <File />
        } else {
            renderProps.icon = <GitPullRequest />
        }

        renderProps.text = result.title
    }

    if (result.type === 'GiteaRepository') {
        renderProps.to = `${team?.domain}/projects/${getSharableRoute(
            result.id,
            result.name
        )}/files/master`
        renderProps.icon = <Data />
        renderProps.text = result.name
    }

    if (result.type === 'Folder') {
        renderProps.to = `${team?.domain}/folders/${getSharableRoute(result.id, result.title)}`
        renderProps.icon = <Folder />
        renderProps.text = result.title
    }

    renderProps.highlight = getBestHighlight(result.highlights)

    return (
        <SearchResultItem
            tabIndex={0}
            data-result-idx={idx}
            to={renderProps.to}
            onMouseEnter={() => {
                const element = document.querySelector(`[data-result-idx="${idx}"]`) as HTMLElement
                element?.focus()
            }}
            onMouseLeave={() => {
                const element = document.querySelector(`[data-result-idx="${idx}"]`) as HTMLElement
                element?.blur()
            }}
        >
            <div className="left">
                {renderProps.icon}
                <div className="search-result-meta">
                    <h4>{renderProps.text}</h4>
                    {renderProps.highlight && (
                        <p className="search-highlight">
                            {renderProps.highlight.texts.map((text, textIdx) => (
                                <span key={textIdx} data-search-highlight={text.type}>
                                    {text.value}
                                </span>
                            ))}
                        </p>
                    )}
                </div>
            </div>
            <div className="right">
                <div className="hovered-indicator">
                    <SubdirectoryLeft />
                </div>
                {result.lastUpdated && (
                    <time dateTime={new Date(result.lastUpdated).toISOString()}>
                        {getTimeAgo(result.lastUpdated)}
                    </time>
                )}
            </div>
        </SearchResultItem>
    )
}

export const CommandMenu = () => {
    const api = useApi()
    const teamId = useTeamId()
    const { data: team } = cache.useTeam()

    const { open, setOpen } = useCommandMenu()

    const contentRef = useRef<HTMLDivElement>(null)

    const location = useLocation()

    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 300)

    const enabled = !!teamId && debouncedQuery.length >= 3 && open

    const {
        data: resultsData,
        isLoading,
        isError,
    } = useQuery(
        ['site-search', debouncedQuery],
        () =>
            api.search({
                teamId: teamId ?? '',
                query: debouncedQuery,
                limit: 10,
                fullText: true,
            }),
        {
            enabled,
        }
    )

    useEffect(() => {
        // Close the search dialog when the route changes
        setOpen(false)
    }, [location.pathname])

    useEffect(() => {
        if (!open) {
            setQuery('')
        }
    }, [open])

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
                e.preventDefault()
                setOpen(!open)
            }
        }

        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
    }, [])

    useEffect(() => {
        const maybeRef = contentRef.current
        const keybinds = (e: KeyboardEvent) => {
            e.stopPropagation()

            if (!maybeRef) {
                return
            }

            if (e.key === 'Escape') {
                setOpen(false)
            }

            // Navigate the search results with the arrow keys
            if (document.activeElement?.hasAttribute('data-search-input')) {
                if (e.key === 'ArrowDown') {
                    const firstResult = maybeRef.querySelector(
                        '[data-result-idx="0"]'
                    ) as HTMLAnchorElement
                    firstResult?.focus()
                }
                return
            }

            const currentActiveResult = (document.activeElement as HTMLElement)?.getAttribute(
                'data-result-idx'
            )
            const activeResultIdx = currentActiveResult ? parseInt(currentActiveResult, 10) : -1

            if (activeResultIdx < 0 || Number.isNaN(activeResultIdx)) {
                return
            }

            if (e.key === 'ArrowDown') {
                const nextResult = maybeRef.querySelector(
                    `[data-result-idx="${activeResultIdx + 1}"]`
                ) as HTMLAnchorElement
                nextResult?.focus()
            }

            if (e.key === 'ArrowUp') {
                const prevResult = maybeRef?.querySelector(
                    `[data-result-idx="${activeResultIdx - 1}"]`
                ) as HTMLAnchorElement
                prevResult?.focus()
            }

            // Edit the search input without focus
            const refocus = () => {
                // focus the search input
                const input = maybeRef.querySelector('[data-search-input]') as HTMLInputElement
                input?.focus()
            }

            if (e.key === 'Backspace') {
                setQuery((prevQuery) => prevQuery.slice(0, -1))
                refocus()
            }

            // otherwise add the character to the search input
            if (e.key.length === 1) {
                setQuery((prevQuery) => prevQuery + e.key)
                refocus()
            }
        }

        if (open && maybeRef) {
            maybeRef.addEventListener('keydown', keybinds)
        }

        return () => {
            if (maybeRef) {
                maybeRef.removeEventListener('keydown', keybinds)
            }
        }
    }, [contentRef.current])

    const results = (resultsData as SearchResult[]) ?? []
    const isEmpty = results.length === 0

    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <SearchDialogOverlay />
            <SearchDialogContent ref={contentRef} tabIndex={-1}>
                <SearchInput
                    fluid
                    placeholder="Search..."
                    icon={<Search />}
                    value={query}
                    onChange={(e) => {
                        setQuery(e.target.value)
                    }}
                    data-search-input
                />
                <kbd className="search-hotkey">
                    <span>{isMacOS() ? <Command /> : 'Ctrl'}</span>
                    <span>+</span>
                    <span>K</span>
                </kbd>
                {isLoading && enabled && (
                    <Space
                        style={{
                            height: 100,
                        }}
                        justify="center"
                        align="center"
                        color={LIGHT_THEME.colors.grey700}
                    >
                        Loading...
                    </Space>
                )}
                {!isLoading && isError && (
                    <Space
                        style={{
                            height: 100,
                        }}
                        justify="center"
                        align="center"
                        color={LIGHT_THEME.colors.grey700}
                    >
                        Error loading search results
                    </Space>
                )}
                {(!isLoading || !enabled) && !isError && isEmpty && (
                    <Space
                        style={{
                            height: 100,
                        }}
                        justify="center"
                        align="center"
                        color={LIGHT_THEME.colors.grey700}
                    >
                        {enabled
                            ? 'No results found'
                            : 'Search for documents, folders, and projects'}
                    </Space>
                )}
                {!isEmpty &&
                    team &&
                    results.map((result, idx) => (
                        <MenuItem key={result.id} result={result} idx={idx} team={team} />
                    ))}
            </SearchDialogContent>
        </Dialog.Root>
    )
}
