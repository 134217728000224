import { BlockType } from '@shapeci/types'
import { Heading } from '@shapeci/ui'
import { PropsWithChildren } from 'react'

export const TOOLBAR_TEXT_OPTIONS = [
    {
        label: 'Heading 1',
        value: BlockType.H1,
        component: (props: PropsWithChildren) => <Heading kind="h1" size={5} {...props} />,
    },

    {
        label: 'Heading 2',
        value: BlockType.H2,
        component: (props: PropsWithChildren) => <Heading kind="h2" size={3} {...props} />,
    },
    {
        label: 'Heading 3',
        value: BlockType.H3,
        component: (props: PropsWithChildren) => <Heading kind="h3" size={2} {...props} />,
    },
    {
        label: 'Paragraph',
        value: BlockType.PARAGRAPH,
        component: (props: PropsWithChildren) => <p {...props} />,
    },
]
