import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'
import { omitOnIntersection } from '../utils/io-ts.util'
import { anyDocument } from './document.model'
import { documentId, folderId, modelId, teamId } from './id.model'
import { objectMetadata } from './metadata.model'
import { model } from './model.model'
import { MongoModelType } from './types.model'

export enum FolderContentType {
    DOCUMENT_FOLDER = 'DocumentFolder',
    MODEL_FOLDER = 'ModelFolder',
}

export const folderContentType = fromEnum('FolderContentType', FolderContentType)

export interface Folder extends t.TypeOf<typeof folder> {}
export const folder = t.intersection([
    t.strict({
        type: t.literal(MongoModelType.FOLDER),
        meta: objectMetadata,
        id: folderId,
        title: t.string,
        team: teamId,
        contentType: folderContentType,
        isStarred: t.boolean,
        isArchived: t.boolean,
        directories: t.array(folderId),
        content: t.union([t.array(documentId), t.array(modelId)]),
    }),
    t.exact(
        t.partial({
            parent: folderId,
            lastParent: folderId,
        })
    ),
])

export interface NestedFolder extends t.TypeOf<typeof nestedFolder> {}
export const nestedFolder = t.intersection([
    folder,
    t.strict({
        subfolders: t.array(folder),
    }),
])

export interface ClientFolder extends t.TypeOf<typeof clientFolder> {}
export const clientFolder = t.intersection([
    omitOnIntersection(folder, ['directories', 'content']),
    t.type({
        directories: t.array(folder),
        content: t.union([t.array(anyDocument), t.array(model)]),
        parents: t.array(
            t.strict({
                title: t.string,
                id: folderId,
            })
        ),
    }),
])
