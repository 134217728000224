import 'katex/dist/katex.min.css'

import {
    Button,
    Card,
    getShapeClassName,
    Heading,
    LIGHT_THEME,
    Portal,
    Space,
    zIndex,
} from '@shapeci/ui'
import { Edit } from '@styled-icons/boxicons-solid'
import katex from 'katex'
import React, { useEffect, useState } from 'react'
import { Node, Transforms } from 'slate'
import { ReactEditor, useReadOnly } from 'slate-react'
import styled from 'styled-components'

import { CodeEditor } from '../components/Code/CodeEditor'
import { useSlateReactStatic } from '../hooks/useSlateReactStatic'
import { FULLSCREEN_HEIGHT, FULLSCREEN_MARGIN, FULLSCREEN_WIDTH } from './constants'
import { BlockProps } from './types'

const LATEX_WRAPPER_CLASSNAME = getShapeClassName('LatexBlock')
const LATEX_PREVIEW_CLASSNAME = getShapeClassName('LatexPreview')

const LatexBlockOuter = styled.div`
    position: relative;
`

LatexBlockOuter.defaultProps = {
    className: LATEX_WRAPPER_CLASSNAME,
}

const Mask = styled.div`
    position: fixed;
    background: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: ${zIndex.MODAL_OVERLAY};
    transform: translateY(0);
`

const LatexBlockWrapper = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.getSpacing(2)};
    position: relative;

    min-height: 3rem;

    button {
        opacity: 0;
        position: absolute;
        bottom: ${({ theme }) => theme.getSpacing(1)};
        right: ${({ theme }) => theme.getSpacing(1)};

        transition: opacity 0.125s ease-in-out;
    }

    :hover {
        button {
            opacity: 1;
        }
    }
`

const LatexEditorWrapper = styled(Card)`
    cursor: default !important;
    box-shadow: none !important;
    z-index: ${zIndex.MODAL};

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${FULLSCREEN_WIDTH};
    height: ${FULLSCREEN_HEIGHT};
    max-width: 1200px;
    max-height: 800px;

    &&& {
        h2 {
            font-weight: 500;
            padding-top: 0 !important;
        }

        p {
            padding-bottom: 0.8rem;
            padding-top: 0 !important;
            font-weight: 400;
            font-size: 1rem;

            a {
                color: ${({ theme }) => theme.colors.info500} !important;
            }
        }
    }

    .${LATEX_PREVIEW_CLASSNAME} {
        box-sizing: border-box;
        padding: ${({ theme }) => theme.getSpacing(2)};
        border-radius: 0 4px 4px 0;
        border: 1px solid ${({ theme }) => theme.colors.grey500};
        border-left: none;
        justify-content: flex-start;
        align-items: flex-start;
        display: flex;
    }

    .${LATEX_PREVIEW_CLASSNAME}, .shape__code-editor {
        flex: 1;
        overflow-y: auto;
    }
`

interface LatexEditorProps {
    defaultValue: string
    setValue: (value: string) => void
    onClose: () => void
}

function isValidLatex(value: string) {
    try {
        katex.renderToString(value, {
            throwOnError: true,
        })
        return true
    } catch (e) {
        return false
    }
}

function LatexEditor({ defaultValue, setValue, onClose }: LatexEditorProps) {
    const [updatedValue, setUpdatedValue] = useState<string>(defaultValue)

    const handleCancel = () => {
        if (
            defaultValue === updatedValue ||
            window.confirm('You have unsaved changes. Are you sure you want to cancel?')
        ) {
            onClose()
        }
    }

    const handleSave = () => {
        if (updatedValue) {
            setValue(updatedValue)
        }
    }

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                handleCancel()
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    })

    const preview = katex.renderToString(updatedValue, {
        throwOnError: false,
    })

    const isError = !isValidLatex(updatedValue)

    return (
        <>
            <Mask />
            <LatexEditorWrapper padding={3} contentEditable={false}>
                <div style={{ height: '100%' }}>
                    <Heading kind="h2" mt={0}>
                        Write LaTeX
                    </Heading>
                    <p>
                        We use{' '}
                        <a href="https://katex.org/" target="_blank" rel="noreferrer">
                            KaTeX
                        </a>{' '}
                        to render LaTeX. You can find a list of{' '}
                        <a
                            href="https://katex.org/docs/supported.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            supported functions here
                        </a>
                        .
                        <span style={{ color: LIGHT_THEME.colors.danger500 }}>
                            {isError ? ' (Errors shown in red)' : ''}
                        </span>
                    </p>
                    <Space
                        style={{
                            height: `calc(100% - ${FULLSCREEN_MARGIN} - calc(1.5 * ${FULLSCREEN_MARGIN}))`,
                        }}
                        align="stretch"
                        size={0}
                    >
                        <CodeEditor
                            language="LaTeX"
                            value={updatedValue}
                            setValue={setUpdatedValue}
                            showLineNumbers
                            style={{
                                borderRadius: '4px 0 0 4px',
                            }}
                        />
                        <div className={LATEX_PREVIEW_CLASSNAME}>
                            <div dangerouslySetInnerHTML={{ __html: preview }} />
                        </div>
                    </Space>

                    <Space style={{ marginTop: '0.8rem' }} justify="end">
                        <Button kind="secondary" intent="muted" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button disabled={isError || !updatedValue} onClick={handleSave}>
                            Save
                        </Button>
                    </Space>
                </div>
            </LatexEditorWrapper>
        </>
    )
}

interface LatexBlockProps extends BlockProps {
    children?: React.ReactNode
}

export default function Latex({ element, attributes, children }: LatexBlockProps) {
    const isReadOnly = useReadOnly()
    const editor = useSlateReactStatic()
    const path = ReactEditor.findPath(editor, element as any)

    const [isEditing, setIsEditing] = useState<boolean>(false)

    const { value } = element as any

    const saveChanges = (newValue: string) => {
        Transforms.setNodes(
            editor,
            {
                value: newValue,
            } as Partial<Node>,
            { at: path }
        )

        setIsEditing(false)
    }

    const renderedLatex = katex.renderToString(value, {
        throwOnError: false,
    })

    return (
        <LatexBlockOuter contentEditable={false} {...attributes} id={(element as any).id}>
            <LatexBlockWrapper>
                <div dangerouslySetInnerHTML={{ __html: renderedLatex }} />
                {!isReadOnly && (
                    <Button
                        kind="text"
                        prepend={<Edit />}
                        aria-label="Edit"
                        intent="muted"
                        onClick={() => setIsEditing(true)}
                    >
                        Edit
                    </Button>
                )}
            </LatexBlockWrapper>
            {isEditing && (
                <Portal>
                    <LatexEditor
                        defaultValue={(element as any)?.value}
                        setValue={saveChanges}
                        onClose={() => setIsEditing(false)}
                    />
                </Portal>
            )}
            {children}
        </LatexBlockOuter>
    )
}
