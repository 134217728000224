import { Cog, Group, User } from '@styled-icons/boxicons-regular'
import React, { Suspense } from 'react'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'

import { Auth0Authenticated } from '../components/Auth0Authenticated'
import ClientProviders from '../components/ClientProviders'
import CompatibilityBanner from '../components/CompatibilityBanner'
import { EditorLayout } from '../components/Layout/EditorLayout'
import ErrorPage from '../components/Layout/ErrorPage'
// Layouts
import FormPage from '../components/Layout/FormPage'
// eslint-disable-next-line import/no-named-default
import PageLayout from '../components/Layout/Page'
import PageWithTabs from '../components/Layout/PageWithTabs'
import ProjectLayout from '../components/Layout/Project'
import { PublicDocument } from '../components/Layout/PublicDocument'
import AppLayout from '../components/Layout/Site'
import LoadingScreen from '../components/LoadingScreen'
import NonAuthProviders from '../components/NonAuthProviders'
import NoTeam from '../components/NoTeam'
import { CommandMenu } from '../components/Search'
import Public from '../pages/Public'
import AuthenticatedRedirects from '../renderless/AuthenticatedRedirects'
import TeamFolderRedirect from '../renderless/TeamFolderRedirect'
import UnauthenticatedRedirects from '../renderless/UnauthenticatedRedirects'
import { AppNavigate } from './AppNavigate'

/**
 * Lazy routes are loaded on demand, this is useful for heavy pages that are not
 * needed for the initial render.
 *
 * @see https://reactjs.org/docs/code-splitting.html#reactlazy
 */

// Auth
const Signin = React.lazy(() => import('../pages/Signin'))
const ProxiedSignin = React.lazy(() => import('../pages/ProxiedSignin'))
const ProxiedCallback = React.lazy(() => import('../pages/ProxiedCallback'))

// Onboarding
const Setup = React.lazy(() => import('../pages/Setup'))
const LinkAccount = React.lazy(() => import('../pages/LinkAccount'))

// Projects
const Projects = React.lazy(() => import('../pages/Projects'))
const Project = React.lazy(() => import('../pages/Projects/Project'))
const Reviews = React.lazy(() => import('../pages/Projects/Reviews'))
const Branch = React.lazy(() => import('../pages/Projects/Branch'))
const ProjectSettings = React.lazy(() => import('../pages/Projects/Settings'))
const Conflict = React.lazy(() => import('../pages/Conflict'))

// User
const Notifications = React.lazy(() => import('../pages/Notifications'))

// Documents
const Editor = React.lazy(() => import('../pages/Editor'))
const FolderPage = React.lazy(() => import('../pages/Folder'))
const Archive = React.lazy(() => import('../pages/Archive'))
const StarredPage = React.lazy(() => import('../pages/Starred'))

// Team Settings
const General = React.lazy(() => import('../pages/TeamSettings/General'))
const Members = React.lazy(() => import('../pages/TeamSettings/Members'))

// User Settings
const Account = React.lazy(() => import('../pages/UserSettings/Account'))
const Teams = React.lazy(() => import('../pages/UserSettings/Teams'))

/**
 * The router for the app is stored as a tree so support nested components
 * for both renderless components and page layouts.
 *
 * The router also serves as a data-driven entry point for the app, so
 * data is fetched and cached on initial load and injected into each page
 * as a prop at render time.
 *
 * @see https://reactrouter.com/en/6.5.0/routers/create-browser-router
 */
const createRouter = () =>
    createBrowserRouter([
        {
            path: '/proxy',
            element: (
                <NonAuthProviders>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </NonAuthProviders>
            ),
            children: [
                {
                    // Proxy for desktop logins
                    path: '',
                    errorElement: (
                        <NonAuthProviders>
                            <ErrorPage />
                        </NonAuthProviders>
                    ),
                    children: [
                        {
                            path: 'login',
                            element: <ProxiedSignin />,
                        },
                        {
                            path: 'callback',
                            element: (
                                <FormPage>
                                    <ProxiedCallback />
                                </FormPage>
                            ),
                        },
                    ],
                },
            ],
        },
        {
            path: '/',
            element: (
                <ClientProviders>
                    <Outlet />
                </ClientProviders>
            ),
            // Public routes
            children: [
                {
                    errorElement: <ErrorPage />,
                    path: '/public',
                    element: (
                        <>
                            <CompatibilityBanner />
                            <PublicDocument />
                        </>
                    ),
                    children: [
                        {
                            path: '/public/:documentSlug',
                            element: <Public />,
                        },
                    ],
                },

                // Authenticated Routes
                {
                    path: '/',
                    errorElement: <ErrorPage />,
                    element: (
                        <UnauthenticatedRedirects>
                            <Auth0Authenticated
                                component={() => (
                                    <AuthenticatedRedirects>
                                        <>
                                            <CompatibilityBanner withSidebar />
                                            <CommandMenu />
                                            <Outlet />
                                        </>
                                    </AuthenticatedRedirects>
                                )}
                            />
                        </UnauthenticatedRedirects>
                    ),
                    children: [
                        {
                            path: '/callback',
                            element: <LoadingScreen />,
                        },
                        {
                            path: '/link',
                            element: (
                                <>
                                    <CompatibilityBanner />
                                    <FormPage>
                                        <Outlet />
                                    </FormPage>
                                </>
                            ),
                            children: [
                                {
                                    path: '/link',
                                    element: <LinkAccount />,
                                },
                            ],
                        },
                        {
                            path: '/',
                            element: (
                                <>
                                    <AppLayout title="" />
                                </>
                            ),
                            children: [
                                // Authenticated Redirects
                                {
                                    path: '/',
                                    element: <AppNavigate to="/projects" />,
                                },
                                {
                                    path: '/new',
                                    element: <PageLayout />,
                                    children: [
                                        {
                                            path: '/new',
                                            element: <NoTeam />,
                                        },
                                    ],
                                },
                                // App Pages
                                {
                                    path: '/:teamDomain',
                                    element: <PageLayout />,
                                    children: [
                                        // Standard Layout Pages
                                        {
                                            path: 'folders',
                                            element: <TeamFolderRedirect />,
                                        },
                                        {
                                            path: 'projects',
                                            element: <Projects />,
                                        },
                                        {
                                            path: 'notifications',
                                            element: <Notifications />,
                                        },
                                        {
                                            path: 'folders/:folderSlug',
                                            element: <FolderPage />,
                                        },
                                        {
                                            path: 'starred',
                                            element: <StarredPage />,
                                        },
                                        {
                                            path: 'trash',
                                            element: <Archive />,
                                        },
                                    ],
                                },
                                {
                                    path: '/:teamDomain/projects/:repoSlug',
                                    element: <ProjectLayout />,
                                    children: [
                                        {
                                            path: 'files/:branchName/:commitHash?/*',
                                            element: <Project />,
                                        },
                                        {
                                            path: 'reviews',
                                            element: <Reviews />,
                                        },
                                        {
                                            path: 'reviews/:branchName',
                                            element: <Branch />,
                                        },
                                        {
                                            path: 'settings',
                                            element: <ProjectSettings />,
                                        },
                                        {
                                            path: 'diff/:diffSlug',
                                            element: <Conflict />,
                                        },
                                    ],
                                },
                                {
                                    path: '/:teamDomain/settings',
                                    children: [
                                        // Settings Pages
                                        {
                                            path: 'user',
                                            element: (
                                                <PageWithTabs
                                                    title="User Settings"
                                                    tabs={[
                                                        {
                                                            label: 'Account',
                                                            path: '/settings/user/account',
                                                            icon: <User />,
                                                        },
                                                        {
                                                            label: 'Teams',
                                                            path: '/settings/user/teams',
                                                            icon: <Group />,
                                                        },
                                                    ]}
                                                />
                                            ),
                                            children: [
                                                {
                                                    path: 'account',
                                                    element: <Account />,
                                                },
                                                {
                                                    path: 'teams',
                                                    element: <Teams />,
                                                },
                                                {
                                                    index: true,
                                                    element: <AppNavigate to="account" />,
                                                },
                                            ],
                                        },
                                        {
                                            path: 'team',
                                            element: (
                                                <PageWithTabs
                                                    title="Team Settings"
                                                    tabs={[
                                                        {
                                                            label: 'General',
                                                            path: '/settings/team/general',
                                                            icon: <Cog />,
                                                        },
                                                        {
                                                            label: 'Members',
                                                            path: '/settings/team/members',
                                                            icon: <Group />,
                                                        },
                                                    ]}
                                                />
                                            ),
                                            children: [
                                                {
                                                    path: 'general',
                                                    element: <General />,
                                                },
                                                {
                                                    path: 'members',
                                                    element: <Members />,
                                                },
                                                {
                                                    index: true,
                                                    element: <AppNavigate to="general" />,
                                                },
                                            ],
                                        },
                                        {
                                            // wildcard to redirect to first user settings tab
                                            path: '*',
                                            element: <AppNavigate to="/settings/user" />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: '/:teamDomain/',
                            element: <EditorLayout />,
                            children: [
                                {
                                    path: 'documents/:documentSlug',
                                    element: <Editor />,
                                },
                            ],
                        },
                    ],
                },

                // Unauthenticated Routes
                {
                    errorElement: <ErrorPage />,
                    path: '/',
                    element: (
                        <>
                            <CompatibilityBanner />
                            <FormPage>
                                <Outlet />
                            </FormPage>
                        </>
                    ),
                    children: [
                        {
                            path: '/login',
                            element: <Signin />,
                        },
                        {
                            path: '/setup',
                            element: <Setup />,
                        },
                        // wildcard to redirect to projects (dashboard for authenticated users)
                        {
                            path: '*',
                            element: <AppNavigate to="/projects" />,
                        },
                    ],
                },
            ],
        },
    ])

const Router = () => <RouterProvider router={createRouter()} />

export default Router
