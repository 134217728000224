import { BlockType, Image } from '@shapeci/types'
import { parseNumber } from '@shapeci/utils'
import { v4 as uuidv4 } from 'uuid'

import { Fragment } from './types'

export const fragmentToImage = (fragment: Fragment) => {
    const maybeImage = fragment as Image

    // If it's missing a url, or it's missing publicURL/uploadId (which means it was copied from our doc), then invalid
    if (!maybeImage.publicURL && !maybeImage.uploadId && !fragment.url) return null

    const baseImage = {
        type: BlockType.IMAGE,
        id: uuidv4(),
        children: [
            {
                id: uuidv4(),
                type: BlockType.INLINE_TEXT,
                text: '',
            },
        ],
    } as Image

    // Try to set width
    const width = parseNumber(maybeImage.width)
    if (width) baseImage.width = width

    // Try to set image info
    if (maybeImage.uploadId) baseImage.uploadId = maybeImage.uploadId
    if (maybeImage.publicURL) baseImage.publicURL = maybeImage.publicURL
    else if (fragment.url && typeof fragment.url === 'string') baseImage.publicURL = fragment.url
    else return null

    return baseImage
}
