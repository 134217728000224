import { ReviewStatus } from '@shapeci/types'
import { Theme, Tooltip } from '@shapeci/ui'
import { Stopwatch } from '@styled-icons/boxicons-regular'
import { CheckCircle, XCircle } from '@styled-icons/boxicons-solid'
import styled from 'styled-components'

interface ReviewIndicatorProps {
    status: ReviewStatus
}

function getIconFromStatus(status: ReviewStatus) {
    if (status === ReviewStatus.DENIED) {
        return <XCircle />
    }
    if (status === ReviewStatus.APPROVED) {
        return <CheckCircle />
    }
    return <Stopwatch />
}

function getColorFromStatus(status: ReviewStatus): keyof Theme['colors'] {
    if (status === ReviewStatus.DENIED) {
        return 'danger500'
    }
    if (status === ReviewStatus.APPROVED) {
        return 'success500'
    }
    return 'grey600'
}

const IconWrapper = styled.div<{ $status: ReviewStatus }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    svg {
        width: 1.25rem;
        height: 1.25rem;
    }

    ${({ theme, $status }) => `
        color: ${theme.colors[getColorFromStatus($status)]};
    `}
`

export default function ReviewIndicator({ status }: ReviewIndicatorProps) {
    return (
        <Tooltip
            content={status === ReviewStatus.DENIED ? 'Changes Requested' : status}
            delayDuration={375}
        >
            <IconWrapper $status={status}>{getIconFromStatus(status)}</IconWrapper>
        </Tooltip>
    )
}
