import { Button } from '@shapeci/ui'
import styled from 'styled-components'

const ActionButton = styled(Button)`
    color: ${({ theme }) => theme.colors.grey600};

    border: none;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    svg {
        width: 1.325rem;
        height: 1.325rem;
    }

    :hover {
        opacity: 1;
        background: ${({ theme }) => theme.colors.grey300};
    }
`

ActionButton.defaultProps = {
    kind: 'icon',
}

export default ActionButton
