/* eslint-disable no-param-reassign, @typescript-eslint/ban-types */
import { NodeIdPlugin, PlateEditor, TNodeEntry, Value } from '@udecode/plate'
import { Element, Transforms } from 'slate'

/**
 * The default withNodeId plugin doesn't enfoce IDs through normalization
 */
export const withNodeIdNormalization =
    (options: NodeIdPlugin) =>
    <V extends Value = Value, E extends PlateEditor<V> = PlateEditor<V>>(editor: E) => {
        const { normalizeNode } = editor

        editor.normalizeNode = (entry) => {
            const didAdd = addIdIfMissing<V, E>(editor, entry, options.idCreator)
            if (didAdd) return

            normalizeNode(entry)
        }

        return editor
    }

const addIdIfMissing = <V extends Value, E extends PlateEditor<V>>(
    editor: E,
    entry: TNodeEntry,
    idCreator: Function = Date.now
) => {
    const [node, at] = entry
    if (!Element.isElement(node)) return false

    if (node.id) return false

    Transforms.setNodes(editor as any, { id: idCreator() } as any, { at })
    return true
}
