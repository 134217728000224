import { createPluginFactory } from '@udecode/plate'

import { MergableAttributesPlugin } from './types'
import { withMergableAttributes } from './withMergableAttributes'

const KEY_PLUGIN_MERGABLE_ATTRIBUTES = 'mergable_attributes'

export const createMergableAttributesPlugin = createPluginFactory<MergableAttributesPlugin>({
    key: KEY_PLUGIN_MERGABLE_ATTRIBUTES,
    options: {
        attributes: [],
    },
    withOverrides: withMergableAttributes,
})
