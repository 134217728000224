import * as t from 'io-ts'

import { modelId } from '../models/id.model'

export interface ModelWithPath extends t.TypeOf<typeof modelWithPath> {}
export const modelWithPath = t.strict({
    modelId,
    path: t.string,
})

export interface ModelWithDependencies extends t.TypeOf<typeof modelWithDependencies> {}
export const modelWithDependencies = t.strict({
    rootModel: modelId,
    dependentChanges: t.array(modelWithPath),
})

export interface DiffGroup extends t.TypeOf<typeof diffGroup> {}
export const diffGroup = t.strict({
    path: t.string,
    base: t.union([modelWithDependencies, t.null]),
    incoming: t.union([modelWithDependencies, t.null]),
})

export interface BranchAndCommit extends t.TypeOf<typeof branchAndCommit> {}
export const branchAndCommit = t.strict({
    branchName: t.string,
    commit: t.string,
})

export interface BranchDiff extends t.TypeOf<typeof branchDiff> {}
export const branchDiff = t.strict({
    base: branchAndCommit,
    incoming: branchAndCommit,
    prNumber: t.number,
    groups: t.array(diffGroup),
})
