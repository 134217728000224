import * as t from 'io-ts'

import { user } from '../../models/user.model'
import { omitOnType, partialOnType, pickOnType } from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const USER_UNSETTABLE_ATTRIBUTES = [
    ...UNSETTABLE_ATTRIBUTES,
    'teams',
    'giteaCredentials',
    'lastTeamViewed',
] as const

const USER_MUTABLE_ATTRIBUTES = ['firstName', 'lastName', 'lastTeamViewed'] as const
const USER_INITIALIZE_ATTRIBUTES = ['firstName', 'lastName'] as const

export interface CreateUserPayload extends t.TypeOf<typeof createUserPayload> {}
export const createUserPayload = t.type({
    type: t.literal(PayloadTypes.CREATE_USER),
    user: omitOnType(user, USER_UNSETTABLE_ATTRIBUTES),
})

export interface InitializeUserPayload extends t.TypeOf<typeof initializeUserPayload> {}
export const initializeUserPayload = t.type({
    type: t.literal(PayloadTypes.INITIALIZE_USER),
    user: pickOnType(user, USER_INITIALIZE_ATTRIBUTES),
    isSubscribedToMarketingEmails: t.boolean,
})

export interface UpdateUserPayload extends t.TypeOf<typeof updateUserPayload> {}
export const updateUserPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_USER),
    user: partialOnType(pickOnType(user, USER_MUTABLE_ATTRIBUTES)),
    avatarName: t.union([t.undefined, t.string]),
})
