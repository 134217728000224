export enum MongoModelType {
    TOKEN = 'Token',
    DOCUMENT_SESSION = 'DocumentSession',
    DIFF = 'Diff',
    REFERRAL = 'Referral',
    BLOCK = 'Block',
    COMMENT = 'Comment',
    FOLDER = 'Folder',
    TEAM = 'Team',
    USER = 'User',
    VIEW = 'View',
    ACCOUNT = 'Account',
    THREAD = 'Thread',
    RESOURCE = 'Resource',
}

export enum CollectionType {
    TOKEN = 'tokens',
    DIFF = 'diffs',
    DOCUMENT_SESSION = 'documentSessions',
    DOCUMENT = 'documents',
    REPOSITORY = 'repositories',
    MODEL = 'models',
    ALERT = 'alerts',
    BLOCK = 'blocks',
    FOLDER = 'folders',
    TEAM = 'teams',
    USER = 'users',
    VIEW = 'views',
    ACCOUNT = 'accounts',
    REFERRAL = 'referrals',
    RESOURCE = 'resources',
}
