import { Notification } from '@shapeci/ui'
import { v4 as uuidv4 } from 'uuid'

import { NOTIFICATION_ACTIONS, useNotificationDispatch } from '../context/Notifications'

// eslint-disable-next-line no-shadow
export enum NOTIFICATION_TYPE {
    ERROR = 'error',
    SUCCESS = 'success',
    WARNING = 'warning',
    INFO = 'info',
}

export const useErrorNotification = () => {
    const dispatch = useNotificationDispatch()
    return (message: string, title?: string) => {
        const errorNotification: Notification = {
            id: uuidv4(),
            type: NOTIFICATION_TYPE.ERROR,
            title: title ?? 'Error!',
            message,
        }

        dispatch({
            type: NOTIFICATION_ACTIONS.ADD,
            notification: errorNotification,
        })
    }
}

export const useWarnNotification = () => {
    const dispatch = useNotificationDispatch()
    return (message: string, title?: string) => {
        const warningNotification: Notification = {
            id: uuidv4(),
            type: NOTIFICATION_TYPE.WARNING,
            title: title ?? 'Warning',
            message,
        }

        dispatch({
            type: NOTIFICATION_ACTIONS.ADD,
            notification: warningNotification,
        })
    }
}

export const useSuccessNotification = () => {
    const dispatch = useNotificationDispatch()
    return (message: string, title?: string) => {
        const successNotification: Notification = {
            id: uuidv4(),
            type: NOTIFICATION_TYPE.SUCCESS,
            title: title ?? 'Success!',
            message,
        }

        dispatch({
            type: NOTIFICATION_ACTIONS.ADD,
            notification: successNotification,
        })
    }
}

export const useRemoveNotification = () => {
    const dispatch = useNotificationDispatch()
    return (id: string) => {
        dispatch({
            type: NOTIFICATION_ACTIONS.REMOVE,
            id,
        })
    }
}

export const useClearNotification = () => {
    const dispatch = useNotificationDispatch()
    return () => {
        dispatch({
            type: NOTIFICATION_ACTIONS.CLEAR,
        })
    }
}
