import * as t from 'io-ts'

import { omitOnType } from '../utils/io-ts.util'
import { documentId, folderId, repositoryId, teamId, userId } from './id.model'
import { objectMetadata } from './metadata.model'
import { repositoryType } from './repository.model'
import { MongoModelType } from './types.model'

export interface TeamCredential extends t.TypeOf<typeof teamCredential> {}
export const teamCredential = t.type({
    username: t.string,
    password: t.string,
    token: t.string,
    repositoryType,
})

export interface Team extends t.TypeOf<typeof team> {}
export const team = t.strict({
    type: t.literal(MongoModelType.TEAM),
    id: teamId,
    meta: objectMetadata,
    name: t.string,
    repositories: t.array(repositoryId),
    rootFolder: folderId,
    users: t.array(userId),
    teamCredentials: t.array(teamCredential),
    pendingUsers: t.array(userId),
    starredFolders: t.array(folderId),
    starredDocuments: t.array(documentId),
    archiveFolder: folderId,
    bucketName: t.string,
    avatarName: t.union([t.string, t.null]),
    // Minimum number of reviews required to merge a merge request
    minimumMergeReviews: t.number,
    // Used for the workspace URL e.g. https://app.shape.ci/<domain>/:path*
    domain: t.string,
})

export interface ClientTeam extends t.TypeOf<typeof clientTeam> {}
export const clientTeam = t.intersection([
    omitOnType(team, ['users']),
    t.type({
        repositories: t.array(
            t.type({
                id: repositoryId,
                name: t.string,
            })
        ),
        users: t.array(
            t.type({
                id: userId,
                firstName: t.string,
                lastName: t.string,
                email: t.string,
                avatarName: t.string,
            })
        ),
        pendingUsers: t.array(
            t.type({
                id: userId,
                firstName: t.string,
                lastName: t.string,
                email: t.string,
                avatarName: t.string,
            })
        ),
        pendingAccounts: t.array(
            t.type({
                invitedEmail: t.string,
            })
        ),
    }),
])
