import { ApiInstance, UserCacheData } from '@shapeci/components'
import { isLegacyAccountError } from '@shapeci/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'

import { cache } from '../caches'
import { useApi } from './useApi'
import { useAuth } from './useAuth'

export interface AuthData {
    user: UserCacheData | null
    hasLegacyAccount: boolean
}

export const getAuthQuery = (api: ApiInstance) => ({
    queryKey: cache.getAuthQueryKey(),
    queryFn: async () => {
        try {
            const result = await cache.getUserQuery(api).queryFn()

            return {
                user: result,
                hasLegacyAccount: false,
            }
        } catch (error) {
            if (isLegacyAccountError(error)) {
                return {
                    user: null,
                    hasLegacyAccount: true,
                }
            }

            throw error
        }
    },
})

export const useAuthData = () => {
    const api = useApi()
    const { isLoading, error } = useAuth()
    const queryResult = useQuery<AuthData>(getAuthQuery(api))

    return useMemo(
        () => ({
            ...queryResult,
            isLoading: isLoading || queryResult.isLoading,
            isError: !!error || queryResult.isError,
        }),
        [api, queryResult, queryResult.isLoading, queryResult.isError, isLoading, error]
    )
}

export const useInvalidateAuthData = () => {
    const queryClient = useQueryClient()
    return () =>
        queryClient.invalidateQueries({
            queryKey: cache.getAuthQueryKey(),
        })
}
