import { zIndex } from '@shapeci/ui'
import { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { ActionList, ActionListProps } from './ActionList'
import Breadcrumbs, { BreadcrumbProps } from './Breadcrumbs'

export const MenuBarWrapper = styled.div<{ $bottomless?: boolean; $fullWidth?: boolean }>`
    ${({ $bottomless, theme }) =>
        !$bottomless &&
        `
  border-bottom: ${theme.border};
  `}
    position: sticky;
    width: 100%;
    top: 0;
    background: ${({ theme }) => theme.colors.grey100};
    z-index: ${zIndex.MENU_BAR};

    > div {
        margin: 0 auto;
        width: 95%;
        max-width: 1300px;

        ${({ $fullWidth }) =>
            $fullWidth &&
            css`
                max-width: unset;
            `}

        height: 70px;
        align-items: center;

        display: flex;
        justify-content: space-between;
    }
`

interface MenuBarOverrides {
    prePath?: ReactNode | ReactNode[]
    left?: ReactNode | ReactNode[]
    right?: ReactNode | ReactNode[]
    bottomless?: boolean
    fullWidth?: boolean
}

type MenuBarProps = BreadcrumbProps & ActionListProps & MenuBarOverrides

const MenuBar: FC<MenuBarProps> = ({
    prePath,
    path,
    users,
    preStackActions,
    postStackActions,
    onClick,
    left,
    right,
    bottomless,
    fullWidth,
}) => (
    <MenuBarWrapper $bottomless={bottomless} $fullWidth={fullWidth}>
        <div>
            {left ?? (
                <>
                    {prePath ?? null}
                    <Breadcrumbs path={path} maxDepth={3} onClick={onClick} />
                </>
            )}
            {right ?? (
                <ActionList
                    users={users}
                    preStackActions={preStackActions}
                    postStackActions={postStackActions}
                />
            )}
        </div>
    </MenuBarWrapper>
)

MenuBar.defaultProps = {
    left: undefined,
    right: undefined,
    bottomless: false,
    fullWidth: false,
}

export default MenuBar
export type { MenuBarProps }
