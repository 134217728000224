import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'

export enum OperationType {
    INSERT_NODE = 'insert_node',
    REMOVE_NODE = 'remove_node',
    INSERT_TEXT = 'insert_text',
    REMOVE_TEXT = 'remove_text',
    SET_SELECTION = 'set_selection',
    SET_NODE = 'set_node',
    SPLIT_NODE = 'split_node',
    MERGE_NODE = 'merge_node',
    MOVE_NODE = 'move_node',
}

export const operationType = fromEnum('OperationType', OperationType)

export interface BaseOperation extends t.TypeOf<typeof baseOperation> {}
export const baseOperation = t.type({
    type: operationType,
    cachedVersion: t.number,
    transactionId: t.string,
})
