/* eslint-disable consistent-return */
import { ApiInstance } from '@shapeci/components'
import { ModelId, RepositoryId } from '@shapeci/types'
import { getErrorMessage } from '@shapeci/utils'
import maxBy from 'lodash.maxby'

const DB_VERSION = 1
const MODELS_STORE_NAME = 'models'

enum DBState {
    OPENING = 'Opening',
    OPEN = 'Open',
    ERROR = 'Error',
}

class ModelStore {
    // Keep reference to API instance so that we can make authenticated requests
    api: ApiInstance | null

    // Maps DB keys to object URLs so that we can revoke them later
    objectURLS: Map<string, string> = new Map()

    // DB Stuff
    DBState: DBState

    request: IDBOpenDBRequest

    db: IDBDatabase | null = null

    // This is really just useful for testing when we don't want models cached
    disableCache = false

    constructor(disableCache = false) {
        this.api = null
        this.disableCache = disableCache
        this.DBState = DBState.OPENING
        this.request = indexedDB.open(MODELS_STORE_NAME, DB_VERSION)
        this.request.onsuccess = this.onDBOpened.bind(this)
        this.request.onerror = this.onDBError.bind(this)
        this.request.onupgradeneeded = ModelStore.onUpgradeNeeded.bind(this)

        this.getModelFromDB = this.getModelFromDB.bind(this)
        this.putModelInDB = this.putModelInDB.bind(this)
    }

    setAPI(api: ApiInstance) {
        this.api = api
    }

    async getModelURL(repoId: RepositoryId, modelId: ModelId, commitHash: string | undefined) {
        if (!this.api) return ''

        let hash = commitHash
        if (!hash) hash = await this.getLatestCommitHash(repoId, modelId)

        const cachedResult = await this.getModelFromDB(modelId, hash)
        if (cachedResult && !this.disableCache) {
            return this.urlify(cachedResult, modelId, hash)
        }

        const buffer = await this.api.downloadModel(repoId, modelId, hash)
        this.putModelInDB(modelId, hash, buffer)
        return this.urlify(buffer, modelId, hash)
    }

    async getLatestCommitHash(repoId: RepositoryId, modelId: ModelId) {
        if (!this.api) return ''

        const latestModel = await this.api.getModel(repoId, modelId)
        const lastVersion = maxBy(latestModel.versionMappings, (v) => v.version)
        if (!lastVersion)
            throw new Error(`Invariant Violation: No versions found for model ${modelId}`)

        return lastVersion.commitHash
    }

    async unloadModel(repoId: RepositoryId, modelId: ModelId, commitHash: string | undefined) {
        if (!repoId || !modelId) return

        const commit = commitHash || (await this.getLatestCommitHash(repoId, modelId))
        const key = ModelStore.getModelKey(modelId, commit)
        const url = this.objectURLS.get(key)
        if (!url) return

        URL.revokeObjectURL(url)
    }

    private async getModelFromDB(
        modelId: ModelId,
        commitHash: string
    ): Promise<ArrayBuffer | null> {
        if (!this.db) return null

        const key = ModelStore.getModelKey(modelId, commitHash)
        const transaction = this.db.transaction([MODELS_STORE_NAME], 'readonly')
        const op = transaction.objectStore(MODELS_STORE_NAME).get(key)
        transaction.commit()

        return new Promise((res, rej) => {
            op.onsuccess = (evt) => res((evt?.target as any)?.result as ArrayBuffer)
            op.onerror = (evt) => res(null)
        })
    }

    private async putModelInDB(modelId: ModelId, commitHash: string, data: ArrayBuffer) {
        if (!this.db) return

        const key = ModelStore.getModelKey(modelId, commitHash)
        const transaction = this.db.transaction([MODELS_STORE_NAME], 'readwrite')
        const op = transaction.objectStore(MODELS_STORE_NAME).put(data, key)
        transaction.commit()

        return new Promise<void>((res) => {
            op.onsuccess = (evt) => res()
            op.onerror = (evt) => res()
        })
    }

    private static getModelKey(modelId: ModelId, commitHash: string) {
        return `${modelId}/${commitHash}`
    }

    private onDBOpened() {
        this.DBState = DBState.OPEN
        this.db = this.request.result
    }

    private onDBError(error: any) {
        console.warn(
            `Indexed DB could not be used. Performance may be impacted. Error: ${getErrorMessage(
                error
            )}`,
            error
        )
        this.DBState = DBState.ERROR
        this.db = null
    }

    private static onUpgradeNeeded(event: IDBVersionChangeEvent) {
        const db = (event?.target as any)?.result
        if (!db) return

        db.createObjectStore(MODELS_STORE_NAME)
    }

    private urlify(data: ArrayBuffer, modelId: ModelId, commit: string) {
        const blob = new Blob([data])
        const url = URL.createObjectURL(blob)
        const key = ModelStore.getModelKey(modelId, commit)
        this.objectURLS.set(key, url)
        return url
    }
}

export const modelStore = new ModelStore()
