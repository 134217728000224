import { BlockType, H1, H2, H3, Paragraph } from '@shapeci/types'
import { v4 as uuidv4 } from 'uuid'

import { isInlineNode } from '../../helpers/blocks'
import { fragmentToLink } from './fragmentToLink'
import { fragmentToText } from './fragmentToText'
import { Fragment } from './types'

type TextNodeType = BlockType.PARAGRAPH | BlockType.H1 | BlockType.H2 | BlockType.H3
export const fragmentToTextNode = (fragment: Fragment, type: TextNodeType) => {
    const maybeText = fragment as Paragraph

    let textChildren = maybeText.children?.filter(isInlineNode) as any as Fragment[]
    if (!textChildren?.length) textChildren = [{ text: '' }] as Fragment[]

    const children = textChildren.map(mapTextChildren).filter((t) => t !== null)
    if (!children) return null

    return {
        type,
        id: uuidv4(),
        children,
    } as Paragraph | H1 | H2 | H3
}

const mapTextChildren = (fragment: Fragment) => {
    switch (fragment.type) {
        case BlockType.LINK:
            return fragmentToLink(fragment)
        case BlockType.INLINE_TEXT:
        case undefined:
            return fragmentToText(fragment)
        default:
            console.error(`Cannot map text child type ${fragment.type}, ignoring.`)
            return null
    }
}
