import { useEffect, useState } from 'react'

export const useAnimatedEllipses = (initialText: string, maxDots: number, intervalMs: number) => {
    const [text, setText] = useState(initialText)

    useEffect(() => {
        let dots = ''
        const timer = setInterval(() => {
            dots = dots.length < maxDots ? `${dots}.` : ''
            setText(initialText + dots)
        }, intervalMs)

        return () => clearInterval(timer)
    }, [initialText, maxDots, intervalMs])

    return text
}
