import { Badge } from '@shapeci/ui'
import { Bell } from '@styled-icons/boxicons-regular'
import { FC } from 'react'

import { cache } from '../../../caches'

const NotificationButton: FC<any> = () => {
    const { data } = cache.useNotifications()

    const totalNotifications = data?.alerts?.length || 0

    return (
        <>
            <Bell />
            <span style={{ marginRight: '10px' }}>Notifications</span>
            {totalNotifications > 0 && <Badge value={totalNotifications} standalone />}
        </>
    )
}

export default NotificationButton
