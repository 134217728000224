import { AnyObject, createPluginFactory } from '@udecode/plate-core'

import { MyPlatePlugin } from '../../types'
import { ELEMENT_MODEL_VIEWER, ModelViewerOptions } from './types'

export const createModelViewerPluginFactory = (config: { options: ModelViewerOptions }) =>
    createPluginFactory({
        key: ELEMENT_MODEL_VIEWER,
        isElement: true,
        isVoid: true,
        isInline: false,
    })

export const createModelViewerPlugin = (config: {
    options: ModelViewerOptions
}): MyPlatePlugin<AnyObject> => createModelViewerPluginFactory(config)()
