/* eslint-disable no-redeclare */
/* eslint-disable no-import-assign */
/* eslint-disable react/require-default-props */
import 'tippy.js/dist/tippy.css'

import { BlockId, BlockType, EditorNode, OperationHistoryStack } from '@shapeci/types'
import { getShapeClassName, Portal, Theme } from '@shapeci/ui'
import { Plate } from '@udecode/plate'
import cloneDeep from 'lodash.clonedeep'
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import styled, { ThemeProvider } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import { ModelStore } from '../../blocks/ModelViewer/types'
import {
    createShapePlugins,
    OnTransactionFinished,
    OpenWizardCallback,
} from '../../helpers/createShapePlugins'
import { TransactionObserver } from '../../helpers/TransactionObserver'
import { AuthState, useAuthStore } from '../../stores/auth'
import { ShapeEditorType, ShapeValue } from '../../types'
import { CursorOverlayContainer } from '../Cursor'
import SetRef from '../SetRef'
import { ShapeToolbarProps, Toolbar } from '../Toolbar'

const EditorWrapper = styled.div`
    position: relative;

    strong {
        font-weight: 500;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // clear margin and use padding instead
        margin: 0;
    }

    h1 {
        font-size: 1.875rem;
        font-weight: 500;
        line-height: 1.25;
        padding: 0.75rem 0;
    }

    h2 {
        font-size: 1.625rem;
        font-weight: 500;
        line-height: 1.375;
        padding: 0.625rem 0;
    }

    h3 {
        font-size: 1.375rem;
        font-weight: 500;
        padding: 0.5rem 0;
    }

    p,
    .shape__model-viewer-block,
    .shape__latex-block,
    .shape__code-block {
        overflow: hidden;
    }
`

const defaultEditableProps = {
    spellCheck: false,
    autoFocus: false,
    readOnly: false,
    placeholder: 'Begin typing...',
}

export interface ShapeEditorValue {
    nodes: EditorNode[]
    cachedVersion: number
    undoHistory: OperationHistoryStack
}

export interface HoverPayload {
    blockId: BlockId
    blockType: BlockType
    target: HTMLElement | null
}

export interface ShapeEditorProps {
    authContext: AuthState

    onTransactionFinished: OnTransactionFinished
    onOpenWizard: OpenWizardCallback
    teamId: string
    value: ShapeEditorValue
    theme: Theme
    isPreviewMode: boolean

    // listeners
    onHover: (payload: HoverPayload | null) => void

    // observables
    transactionObserver?: TransactionObserver

    // composition
    toolbarProps?: ShapeToolbarProps

    modelStore: ModelStore
}

const ShapeEditor = forwardRef<ShapeEditorType, ShapeEditorProps>(
    (
        {
            authContext,
            value,
            theme,
            teamId,
            isPreviewMode,

            // shape listeners
            onTransactionFinished,
            onOpenWizard,

            // generic listeners
            onHover,

            // observables
            transactionObserver,

            // composition
            toolbarProps,

            modelStore,
        },
        ref
    ) => {
        const [editorId] = useState(uuidv4())
        const [editableProps, setEditableProps] = useState(defaultEditableProps)
        const [nodesValue, setNodesValue] = useState(value.nodes)
        const [keyId, setKeyId] = useState(uuidv4())
        const setAuth = useAuthStore((s) => s.set)

        const wrapperRef = useRef<HTMLDivElement>(null)

        useEffect(() => {
            setAuth(authContext)
        }, [authContext])

        useEffect(() => {
            setEditableProps((prev) => ({ ...prev, readOnly: isPreviewMode }))
        }, [isPreviewMode])

        useEffect(() => {
            setNodesValue(value.nodes)
            setKeyId(uuidv4())
        }, [value])

        useEffect(() => {
            setKeyId(uuidv4())
        }, [onTransactionFinished])

        /* eslint-disable no-param-reassign */
        const onEditorLoaded = (editor: ShapeEditorType) => {
            editor.history.undos.length = 0
            value.undoHistory.forEach((op) => {
                editor.history.undos.push(cloneDeep(op) as any)
            })
        }
        /* eslint-enable no-param-reassign */

        const onEditorMouseEnter = (
            blockId: BlockId,
            blockType: BlockType,
            target?: HTMLElement
        ) => {
            onHover({ blockId, blockType, target: target ?? null })
        }

        const onEditorMouseLeave = () => {
            onHover(null)
        }

        const listeners = useMemo(
            () => ({
                onEditorMouseEnter,
                onEditorMouseLeave,
            }),
            []
        )

        const plugins = createShapePlugins({
            listeners,
            modelStore,
            onTransactionFinished,
            onOpenWizard,
            teamId,
            transactionObserver,
        })

        return (
            <ThemeProvider theme={theme}>
                <EditorWrapper
                    onMouseLeave={onEditorMouseLeave}
                    ref={wrapperRef}
                    id={`${getShapeClassName('editor')}`}
                >
                    <DndProvider backend={HTML5Backend} debugMode={true}>
                        <Plate<ShapeValue>
                            id={editorId}
                            key={keyId as any}
                            editableProps={editableProps}
                            plugins={plugins}
                            initialValue={nodesValue as any}
                            firstChildren={<SetRef ref={ref} onEditorLoaded={onEditorLoaded} />}
                        >
                            {toolbarProps && (
                                <Portal>
                                    <Toolbar {...toolbarProps} onOpenWizard={onOpenWizard} />
                                </Portal>
                            )}
                            <CursorOverlayContainer containerRef={wrapperRef} />
                        </Plate>
                    </DndProvider>
                </EditorWrapper>
            </ThemeProvider>
        )
    }
)

export { ShapeEditor }
