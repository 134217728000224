import * as t from 'io-ts'

import { folderId } from '../../models/id.model'
import { model } from '../../models/model.model'
import { omitOnType, partialOnType, pickOnType } from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const MODEL_UNSETTABLE_ATTRIBUTES = [
    ...UNSETTABLE_ATTRIBUTES,
    'versionMappings',
    'repository',
    'modelStructure',
    'directlyReferencedBy',
    'isDeleted',
    'branchName',
] as const
const MODEL_MUTABLE_ATTRIBUTES = ['name'] as const

export interface CreateModelPayload extends t.TypeOf<typeof createModelPayload> {}
export const createModelPayload = t.intersection([
    t.type({
        type: t.literal(PayloadTypes.CREATE_MODEL),
        folderId,
        models: t.array(omitOnType(model, MODEL_UNSETTABLE_ATTRIBUTES)),
    }),
    t.type({
        commitMessage: t.string,
    }),
])

export interface UpdateModelPayload extends t.TypeOf<typeof updateModelPayload> {}
export const updateModelPayload = t.intersection([
    t.type({
        type: t.literal(PayloadTypes.UPDATE_MODEL),
        model: partialOnType(pickOnType(model, MODEL_MUTABLE_ATTRIBUTES)),
    }),
    t.type({
        commitMessage: t.string,
    }),
])
