import { Team, UserWithTeams } from '@shapeci/types'
import { Card } from '@shapeci/ui'
import { parseAccessToken } from '@shapeci/utils'
import { Check, Cog, LogOut } from '@styled-icons/boxicons-regular'
import { transparentize } from 'polished'
import React, { FC, Fragment, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import { cache } from '../../../caches'
import { useApi } from '../../../hooks/useApi'
import { useAppNavigate } from '../../../hooks/useAppNavigate'
import { useAuth } from '../../../hooks/useAuth'
import { TeamLockup } from '../../Lockup'
import { SIDE_BAR_WIDTH } from '../constants'
import NotificationButton from './NotificationButton'
import SidebarLink, { SidebarItem } from './SidebarLink'

interface SidebarMenuProps {
    onClose: () => void
}

interface SidebarUserMenuProps extends SidebarMenuProps {
    user: UserWithTeams
}

const entrance = keyframes`
    from {
        opacity: 0;
        transform: translate(-5%, -10%) scale(0.9);
    }
    to {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }
`

const SidebarMenuWrapper = styled(Card)`
    margin-left: -${({ theme }) => theme.getSpacing(1)};
    width: calc(${SIDE_BAR_WIDTH} * 0.75);
    margin-top: ${({ theme }) => theme.getSpacing(1.5)};
    color: ${({ theme }) => theme.colors.grey600};
    overflow: hidden;

    box-shadow: 2.8px 2.8px 2.2px -8px rgba(0, 0, 0, 0.02),
        6.7px 6.7px 5.3px -8px rgba(0, 0, 0, 0.028), 12.5px 12.5px 10px -8px rgba(0, 0, 0, 0.035),
        22.3px 22.3px 17.9px -8px rgba(0, 0, 0, 0.042),
        41.8px 41.8px 33.4px -8px rgba(0, 0, 0, 0.05), 100px 100px 80px -8px rgba(0, 0, 0, 0.07);

    animation: ${entrance} 0.075s ease-in-out;

    > h2 {
        font-weight: 500;
        font-size: 1rem;
        padding: ${({ theme }) => theme.getSpacing(2)} ${({ theme }) => theme.getSpacing(2)}
            ${({ theme }) => theme.getSpacing(1)};
        margin: 0;
    }

    h2:nth-child(2) {
        font-weight: 400;
        padding-top: 0;
        color: ${({ theme }) => theme.colors.grey600};
        font-size: 0.9rem;
    }

    section {
        width: 100%;
        box-sizing: border-box;
        padding: ${({ theme }) => theme.getSpacing(2)};

        :not(:last-of-type) {
            border-bottom: ${({ theme }) => theme.border};
        }
    }

    a,
    button {
        font-size: 0.875rem;

        &:hover:before {
            background: ${({ theme }) => transparentize(0.125, theme.colors.grey300)};
        }

        &.active:before,
        &.active:hover:before {
            background: ${({ theme }) => theme.colors.primary100};
        }
    }
`

const TeamCard = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;

    padding: ${({ theme }) => theme.getSpacing(2)} ${({ theme }) => theme.getSpacing(2)};

    cursor: pointer;
    user-select: none;

    svg {
        width: 2em;
    }

    h2 {
        font-size: 0.875rem !important;
    }

    :hover {
        background: ${({ theme }) => transparentize(0.125, theme.colors.grey300)};
    }
`

export const TeamSwitcher: FC<SidebarMenuProps> = ({ onClose }) => {
    const api = useApi()
    const navigate = useAppNavigate()

    const { data: user } = cache.useUser()
    const { data: currentTeam } = cache.useTeam()

    const switchTeam = async (t: Team) => {
        if (!user) return

        if (t.id !== user.lastTeamViewed) {
            const updatedUser = {
                lastTeamViewed: t.id,
            }

            api.updateUser(user.id, updatedUser, null)
        }

        navigate(`/projects`, {
            updateTeamDomain: t.domain,
        })

        onClose()
    }

    if (!user) {
        return null
    }

    return (
        <SidebarMenuWrapper padding={0}>
            {currentTeam ? (
                <>
                    <h2>Change Teams</h2>
                </>
            ) : null}
            {(user.teams || []).map((team: Team, idx) => (
                <Fragment key={team.id}>
                    <TeamCard onClick={() => switchTeam(team)}>
                        <TeamLockup size={0.25} team={team} />
                        {currentTeam?.id === team?.id ? <Check /> : null}
                    </TeamCard>
                    {idx !== user.teams.length - 1 && <hr />}
                </Fragment>
            ))}
        </SidebarMenuWrapper>
    )
}

export const UserMenu: FC<SidebarUserMenuProps> = ({ onClose, user }) => {
    const [orgName, setOrgName] = useState<string | null>(null)
    const invalidateUser = cache.useInvalidateUser()
    const { logout, getAccessTokenSilently } = useAuth()

    useEffect(() => {
        setOrgFromToken()
    }, [getAccessTokenSilently])

    const setOrgFromToken = async () => {
        const token = await getAccessTokenSilently()
        const { org } = parseAccessToken(token)
        if (!org) return

        setOrgName(org)
    }

    const handleLogout = async () => {
        logout()
        invalidateUser()
        localStorage.clear()
        window.location.reload()
    }

    const getSettingsRoute = () =>
        user.teams.length ? '/settings/user/teams' : '/new/settings/user/teams'

    const getNotificationsRoute = () =>
        user.teams.length ? '/notifications' : '/new/notifications'

    return (
        <SidebarMenuWrapper padding={0}>
            <h2>{user.firstName} (You)</h2>
            {orgName && <h2>Organization: {orgName}</h2>}
            <section>
                <SidebarLink to={getSettingsRoute()} onClick={onClose}>
                    <Cog /> User Settings
                </SidebarLink>
                <SidebarLink to={getNotificationsRoute()} onClick={onClose}>
                    <NotificationButton />
                </SidebarLink>
                <SidebarItem as="button" type="button" onClick={handleLogout}>
                    <span className="inner">
                        <LogOut /> Logout
                    </span>
                </SidebarItem>
            </section>
        </SidebarMenuWrapper>
    )
}
