import 'highlight.js/styles/github.css'

import { BASE_THEME, getShapeClassName } from '@shapeci/ui'
import hljs from 'highlight.js/lib/core'
import latex from 'highlight.js/lib/languages/css'
import javascript from 'highlight.js/lib/languages/javascript'
import json from 'highlight.js/lib/languages/json'
import markdown from 'highlight.js/lib/languages/markdown'
import matlab from 'highlight.js/lib/languages/matlab'
import python from 'highlight.js/lib/languages/python'
import sql from 'highlight.js/lib/languages/sql'
import typescript from 'highlight.js/lib/languages/typescript'
import html from 'highlight.js/lib/languages/xml'
import yaml from 'highlight.js/lib/languages/yaml'
import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import Editor from 'react-simple-code-editor'
import styled, { css } from 'styled-components'

hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('typescript', typescript)
hljs.registerLanguage('json', json)
hljs.registerLanguage('yaml', yaml)
hljs.registerLanguage('markdown', markdown)
hljs.registerLanguage('matlab', matlab)
hljs.registerLanguage('python', python)
hljs.registerLanguage('sql', sql)
hljs.registerLanguage('html', html)
hljs.registerLanguage('latex', latex)

const CODE_EDITOR_CLASSNAME = getShapeClassName('CodeEditor')
const CODE_EDITOR_TEXTAREA_CLASSNAME = getShapeClassName('CodeEditorTextArea')
const CODE_EDITOR_LINE = getShapeClassName('CodeEditorLine')

export const CODE_EDITOR_LANGUAGES = [
    'Auto',
    'JavaScript',
    'TypeScript',
    'JSON',
    'YAML',
    'Markdown',
    'MATLAB',
    'Python',
    'SQL',
    'HTML',
    'LaTeX',
] as const

export type CodeEditorLanguage = (typeof CODE_EDITOR_LANGUAGES)[number]

export interface CodeEditorProps {
    language?: CodeEditorLanguage
    value: string
    setValue: (value: string) => void
    height?: number
    readonly?: boolean
    showLineNumbers?: boolean
    style: CSSProperties
}

const CodeEditorWrapper = styled.div<{
    $height?: number
    $showLineNumbers?: boolean
}>`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    min-height: 100px;
    height: ${({ $height }) => $height ?? '100%'};

    overflow: hidden;

    border: 1px solid ${({ theme }) => theme.colors.grey500};

    :after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3.2rem;
        background: ${({ theme }) => theme.colors.grey300};
        z-index: 0;
    }

    .${CODE_EDITOR_TEXTAREA_CLASSNAME} {
        :focus {
            outline: none;
        }

        &::selection,
        *::selection {
            background: ${({ theme }) => theme.colors.info600}20 !important;
        }

        // align text if line numbers are shown
        ${({ $showLineNumbers }) =>
            $showLineNumbers &&
            `
            width: calc(100% - 3.2rem) !important;
            left: 3.2rem !important;
        `}
    }

    // line numbers
    ${({ $showLineNumbers }) =>
        $showLineNumbers &&
        css`
            .${CODE_EDITOR_LINE} {
                :before {
                    content: attr(data-line);
                    display: inline-block;
                    width: 2.2rem;
                    padding-right: 1rem;
                    margin-right: 0.8rem;
                    text-align: right;
                    background: ${({ theme }) => theme.colors.grey300};
                    color: ${({ theme }) => theme.colors.grey600};
                    position: absolute;
                    left: 0;
                    margin-left: -3.2rem;
                    z-index: 1;
                }

                :last-child:before {
                    border-bottom: 20px solid ${({ theme }) => theme.colors.grey300};
                    margin-bottom: -20px;
                }
            }

            pre {
                left: 3.2rem !important;
            }
        `}
`

interface highlightOptions {
    language: CodeEditorLanguage
    showLineNumbers?: boolean
}

function highlight(code: string, options: highlightOptions) {
    const { language, showLineNumbers = true } = options

    let result

    if (language === 'Auto') {
        result = hljs.highlightAuto(code).value
    } else {
        result = hljs.highlight(code, { language: language.toLowerCase() }).value
    }

    if (showLineNumbers) {
        result = result
            .split('\n')
            .map(
                (line, idx) =>
                    `<span class="${CODE_EDITOR_LINE}" data-line="${idx + 1}">${line}</span>`
            )
            .join('\n')
    }

    return result
}

export const CodeEditor: React.FC<CodeEditorProps> = ({
    language = 'Auto',
    value,
    setValue,
    height,
    readonly,
    showLineNumbers,
    style,
}) => {
    // TODO convert to a Slate editable node for collaborative editing
    const [editorValue, setEditorValue] = useState(value)

    useEffect(() => {
        if (value !== editorValue) {
            // sync with Editor-level undo/redo stack
            setEditorValue(value)
        }
    }, [value])

    const onChange = useCallback(
        (v: string) => {
            if (!readonly) {
                setEditorValue(v)
                setValue(v)
            }
        },
        [readonly, setEditorValue]
    )

    return (
        <CodeEditorWrapper
            style={style}
            $height={height}
            $showLineNumbers={showLineNumbers}
            className={CODE_EDITOR_CLASSNAME}
        >
            <Editor
                highlight={(v: string) =>
                    highlight(v, {
                        language,
                        showLineNumbers,
                    })
                }
                value={editorValue}
                readOnly={readonly}
                onValueChange={onChange}
                textareaClassName={CODE_EDITOR_TEXTAREA_CLASSNAME}
                padding={BASE_THEME.getSpacing(2)}
                style={{
                    fontFamily:
                        'ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro","Fira Mono", "Droid Sans Mono", "Courier New", monospace',
                    fontSize: 14,
                    tabSize: 4,
                }}
                onCopy={(e) => {
                    const selection = document.getSelection()
                    if (selection) {
                        e.clipboardData.setData('text/plain', selection.toString())
                    }

                    // prevent default copy behavior
                    e.stopPropagation()
                    e.preventDefault()
                }}
            />
        </CodeEditorWrapper>
    )
}
