import {
    ClientReviewDocument,
    DocumentReviewStatus,
    Mergability,
    MergabilityReason,
    MergeBranchPayload,
    MergeStatus,
    PayloadTypes,
} from '@shapeci/types'
import { Button, LIGHT_THEME, Space } from '@shapeci/ui'
import { GITEA_DEFAULT_BRANCH_NAME } from '@shapeci/utils'
import { GitMerge, XCircle } from '@styled-icons/boxicons-regular'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

import { useAnimatedEllipses } from '../../../../hooks/useAnimatedEllipses'
import { useApi } from '../../../../hooks/useApi'
import { getMergeMessage } from './utils'

const DocumentReviewStatusBadge = styled(Button)`
    pointer-events: none;
`

const ButtonWrapper = styled.div`
    padding: ${({ theme }) => theme.getSpacing(1.5)} 0;
    display: grid;
    grid-template-columns: 1fr min-content;
    column-gap: ${({ theme }) => theme.getSpacing(1)};
    width: 100%;

    button {
        align-items: center;
        justify-content: center;
    }
`

const BadgeWrapper = styled.div`
    padding: ${({ theme }) => theme.getSpacing(1.5)} 0;
    justify-content: center;
    align-items: middle;
`

const ActionButtonsWrapper: FC<{
    onAction?: () => void | Promise<void>
    isReviewApproved: boolean
    documentMetadata?: ClientReviewDocument
    reloadDocument: () => void
}> = ({ onAction, isReviewApproved, documentMetadata, reloadDocument }) => {
    const api = useApi()
    const documentReviewStatus = documentMetadata?.status
    const checkingText = useAnimatedEllipses('Checking Mergeability', 3, 400)

    // is doc data loading
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isMergableLoading, setMergableLoading] = useState<boolean>(false)

    // is user action for merging loading
    const [mergeLoading, setMergeLoading] = useState<boolean>(false)

    const [mergeabilityStatus, setMergeabilityStatus] = useState<MergeStatus>({
        mergability: Mergability.UNKNOWN,
        reason: MergabilityReason.NO_DIFF,
    })

    const getMergeabilityStatus = async () => {
        if (!documentMetadata)
            return {
                mergability: Mergability.UNKNOWN,
                reason: MergabilityReason.NO_DIFF,
            }
        setMergableLoading(true)
        try {
            return await api.isBranchMergable(documentMetadata.repoId, documentMetadata.branch)
        } catch (e) {
            // TODO: Show global error
            return {
                mergability: Mergability.INDETERMINATE,
                reason: MergabilityReason.NO_DIFF,
            }
        } finally {
            setMergableLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(!documentMetadata)
        ;(async () => {
            if (!documentMetadata) return

            const mergeStatus = await getMergeabilityStatus()
            setMergeabilityStatus(mergeStatus)
        })()
    }, [documentMetadata])

    const isMergeable =
        isReviewApproved &&
        !isLoading &&
        !mergeLoading &&
        documentMetadata?.status === DocumentReviewStatus.OPEN &&
        mergeabilityStatus.mergability === Mergability.MERGABLE

    const mergeBranch = async () => {
        if (!documentMetadata || !isMergeable) return

        if (!isMergeable) return

        setMergeLoading(true)

        const payload: MergeBranchPayload = {
            type: PayloadTypes.MERGE_BRANCH,
            deleteBranchAfterMerge: false,
            baseBranch: GITEA_DEFAULT_BRANCH_NAME,
            incomingBranch: documentMetadata?.branch,
        }

        try {
            await api.mergeBranch(documentMetadata.repoId, payload)
            await onAction?.()
            reloadDocument()
        } catch (e) {
            // TODO: Show global error
        } finally {
            setMergeLoading(false)
        }
    }

    if (
        documentReviewStatus === DocumentReviewStatus.MERGED ||
        documentReviewStatus === DocumentReviewStatus.CLOSED
    ) {
        const badgeIntent =
            documentReviewStatus === DocumentReviewStatus.MERGED ? 'default' : 'danger'
        const badgeContent =
            documentReviewStatus === DocumentReviewStatus.MERGED ? 'Merged' : 'Closed'
        const badgeIcon =
            documentReviewStatus === DocumentReviewStatus.MERGED ? <GitMerge /> : <XCircle />

        return (
            <BadgeWrapper>
                <DocumentReviewStatusBadge prepend={badgeIcon} fluid intent={badgeIntent}>
                    {badgeContent}
                </DocumentReviewStatusBadge>
            </BadgeWrapper>
        )
    }

    return (
        <Space vertical>
            <ButtonWrapper>
                <Button
                    isLoading={mergeLoading}
                    onClick={mergeBranch}
                    disabled={!isMergeable}
                    intent="muted"
                >
                    Merge
                </Button>
            </ButtonWrapper>

            <p
                style={{
                    fontSize: '0.875rem',
                    color: LIGHT_THEME.colors.grey700,
                }}
            >
                {isMergableLoading
                    ? checkingText
                    : getMergeMessage(isReviewApproved, mergeabilityStatus, documentMetadata)}
            </p>
        </Space>
    )
}

ActionButtonsWrapper.defaultProps = {
    documentMetadata: undefined,
    onAction: undefined,
}

export default ActionButtonsWrapper
