import { getFullId } from './uuid'

export function getIdFromSharableRoute(route: string) {
    const segments = route.split('-')

    if (segments.length === 1) {
        return getFullId(route)
    }

    const trimmedId = segments[segments.length - 1]
    return getFullId(trimmedId)
}
