import { getSharableRoute } from '@shapeci/utils'

import { cache } from '../caches'
import NoTeam from '../components/NoTeam'
import { AppNavigate } from '../router/AppNavigate'

const TeamFolderRedirect = () => {
    const { data: team } = cache.useTeam()

    if (!team?.rootFolder) {
        return <NoTeam />
    }

    const rootFolderRoute = getSharableRoute(team.rootFolder, team.name)

    return <AppNavigate to={`/folders/${rootFolderRoute}`} replace />
}

export default TeamFolderRedirect
