import { AnyObject, createPluginFactory } from '@udecode/plate-core'

import Latex from '../../blocks/Latex'
import { withOnHoverProps } from '../../helpers/withOnHover'
import { withStyledDraggable } from '../../helpers/withStyledDraggables'
import { MyPlatePlugin } from '../../types'
import { ELEMENT_LATEX, LatexOptions } from './types'

export const createLatexPluginFactory = (config: { options: LatexOptions }) =>
    createPluginFactory({
        key: ELEMENT_LATEX,
        component: withStyledDraggable(
            (props: any, context: any) =>
                Latex(
                    withOnHoverProps(
                        {
                            ...props,
                        },
                        config.options.listeners
                    )
                ),
            ELEMENT_LATEX
        ),
        isElement: true,
        isVoid: true,
        isInline: false,
    })

export const createLatexPlugin = (config: { options: LatexOptions }): MyPlatePlugin<AnyObject> =>
    createLatexPluginFactory(config)()
