import { createCache } from '@shapeci/components'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { useApi } from '../hooks/useApi'
import useTeamId from '../hooks/useTeamId'

export const cache = createCache({
    useApi,
    useTeamId,
    useQueryClient,
    useMutation,
    useQuery,
    useInfiniteQuery,
})
