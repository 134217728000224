import { ClientComment as CommentType } from '@shapeci/types'
import { DropdownMenu, Portal } from '@shapeci/ui'
import { DotsVerticalRounded } from '@styled-icons/boxicons-regular'
import { Pencil, Trash } from '@styled-icons/boxicons-solid'
import { FC } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import styled from 'styled-components'

import { cache } from '../../caches'
import { useAuth } from '../../hooks/useAuth'
import { getTimeAgo } from '../../utils/formatTime'
import Lockup, { UserLockup } from '../Lockup'

const MARKDOWN_ALLOWED_ELEMENTS = ['p', 'em', 'strong', 'a']

const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: 0.625rem 0.5rem;
    box-sizing: border-box;
    position: relative;

    p {
        margin: ${({ theme }) => theme.getSpacing(1.5)} 0 0;
        font-size: 0.875rem;
        line-height: 1.25rem;
        word-wrap: break-word;
        white-space: pre-line;
    }

    a {
        color: ${({ theme }) => theme.colors.info600};
    }

    cite {
        display: block;
        font-weight: 400;
        font-style: normal;
    }

    &:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
    }

    .shape__lockup {
        h2 {
            font-size: 0.875rem;
            margin-bottom: 0;
            line-height: 1.125;
            margin-left: 0.125rem;
        }

        h3 {
            font-size: 0.75rem;
            margin-left: 0.125rem;
        }
    }
`

const DropdownButton = styled(DropdownMenu.Trigger)`
    background: none;
    border: none;
    display: block;

    position: absolute;

    top: 0.375rem;
    right: 0.25rem;
    color: ${({ theme }) => theme.colors.grey600};

    cursor: pointer;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;

    svg {
        width: 1.125rem;
        height: 1.125rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    :hover {
        background: ${({ theme }) => theme.colors.grey300};
    }
`

const DropdownMenuContent = styled(DropdownMenu.Content)`
    z-index: 999;
    min-width: 140px;
`

CommentContainer.defaultProps = {
    className: 'shape__comment',
}

export interface CommentActionsProps {
    handleDelete: () => void
    handleEdit?: () => void
    handleResolve?: () => void
}
export interface CommentProps extends CommentActionsProps {
    comment: CommentType
}

const CommentActions: FC<CommentActionsProps> = ({ handleDelete, handleEdit, handleResolve }) => (
    <DropdownMenu.Root>
        <DropdownButton>
            <DotsVerticalRounded />
        </DropdownButton>
        <Portal>
            <DropdownMenuContent
                className="shape__comment-dropdown-menu"
                onClick={(e) => {
                    // Prevent the thread from opening when clicking on the dropdown menu
                    e.stopPropagation()
                }}
            >
                {handleEdit && (
                    <DropdownMenu.Item onClick={handleEdit}>
                        <DropdownMenu.LeftSlot>
                            <Pencil />
                        </DropdownMenu.LeftSlot>
                        Edit
                    </DropdownMenu.Item>
                )}
                <DropdownMenu.Item intent="danger" onClick={handleDelete}>
                    <DropdownMenu.LeftSlot>
                        <Trash />
                    </DropdownMenu.LeftSlot>
                    Delete
                </DropdownMenu.Item>
                {handleResolve && (
                    <DropdownMenu.Item intent="danger" onClick={handleResolve}>
                        <DropdownMenu.LeftSlot>
                            <Trash />
                        </DropdownMenu.LeftSlot>
                        Delete Thread
                    </DropdownMenu.Item>
                )}
            </DropdownMenuContent>
        </Portal>
    </DropdownMenu.Root>
)

const Comment: FC<CommentProps> = ({ comment, handleDelete, handleEdit, handleResolve }) => {
    // determine if the user is the author of the comment
    // we should still render the comment if the user is not logged in
    // since the document may be public
    const { data: user } = cache.useUser()
    const { isAuthenticated } = useAuth()

    // keep for editing
    const isUserAuthor = user && user.id === comment.meta.creator.id

    // if date is missing hide the entire field
    const formattedDate = comment.meta.lastUpdated
        ? getTimeAgo(comment.meta.lastUpdated)
        : undefined

    return (
        <CommentContainer>
            <UserLockup size={-0.125} user={comment.meta.creator} subtitle={formattedDate} />
            <ReactMarkdown
                skipHtml={true}
                children={comment.content}
                remarkPlugins={[remarkGfm]}
                allowedElements={MARKDOWN_ALLOWED_ELEMENTS}
                linkTarget="_blank"
            />

            {isAuthenticated && (
                <CommentActions
                    handleDelete={handleDelete}
                    // only allow editing if the user is the author
                    handleEdit={isUserAuthor ? handleEdit : undefined}
                    handleResolve={handleResolve}
                />
            )}
        </CommentContainer>
    )
}

const GhostCommentContainer = styled(CommentContainer)`
    font-size: 16px;

    p {
        margin: 0;
        font-size: 0.875rem;
        line-height: 1.125rem;
        padding: 0.5em 0;
    }

    background: pink;
    border-color: red;
`

const CommentGhost: FC<Pick<CommentProps, 'comment'>> = ({ comment }) => (
    <GhostCommentContainer>
        <Lockup size={-0.125} title="NAME" subtitle="DATE" />
        <ReactMarkdown
            skipHtml={true}
            children={comment.content}
            remarkPlugins={[remarkGfm]}
            allowedElements={MARKDOWN_ALLOWED_ELEMENTS}
            linkTarget="_blank"
        />
    </GhostCommentContainer>
)

export default Comment
export { CommentGhost }
