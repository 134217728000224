import { createPluginFactory, TOperation } from '@udecode/plate'

import { TransactionPlugin } from './types'
import { withTransactions } from './withTransactions'

export const createTransactionPlugin = createPluginFactory<TransactionPlugin>({
    key: 'transaction',
    options: {
        onTransaction: (..._: any) => {},
        pendingOperations: [] as TOperation[],
        transactionObserver: undefined,
    },
    withOverrides: withTransactions,
})
