import { FC, ReactNode, Suspense } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useAppNavigate } from '../../hooks/useAppNavigate'
import { Tab, Tabs } from '../Tabs'
import { MENU_BAR_HEIGHT } from './constants'
import { PageInner, PageLayoutProps, PageOuter } from './Page'

const Wrapper = styled.div`
    margin: 0 auto;
    width: 95%;
    max-width: 1300px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: auto;
    flex-direction: row;
    overflow: visible;
`

interface PageWithTabsProps extends PageLayoutProps {
    tabs: {
        label: string
        path: string
        icon?: ReactNode

        // Allow additional regexes to be passed in to specify when this tab is active
        matchPath?: RegExp[]
    }[]
    children?: ReactNode

    // if this page is public, omit the team domain from the url
    isPublic?: boolean
}

const FullPage = styled(PageOuter)`
    height: calc(100vh - ${MENU_BAR_HEIGHT});
    overflow: auto;
    display: flex;
    flex-direction: column;
`

const FullPageInner = styled(PageInner)`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`

const InnerHeader = styled(PageInner)`
    height: auto;
`

const PageWithTabs: FC<PageWithTabsProps> = ({ title, description, tabs, children, isPublic }) => {
    const { pathname } = useLocation()
    const navigate = useAppNavigate({
        isPublic,
    })

    return (
        <FullPage>
            <InnerHeader as="header">
                {title && <h1>{title}</h1>}
                {description && <p className="description">{description}</p>}
            </InnerHeader>
            <Tabs>
                <Wrapper>
                    {tabs.map(({ label, path, icon, matchPath }) => {
                        const isActive =
                            pathname.endsWith(path) || !!matchPath?.some((r) => r.test(pathname))

                        return (
                            <Tab
                                key={path}
                                onClick={() => navigate(path)}
                                color="border"
                                prepend={icon}
                                $active={isActive}
                            >
                                {label}
                            </Tab>
                        )
                    })}
                </Wrapper>
            </Tabs>
            <FullPageInner>
                <Suspense fallback={null}>{children ?? <Outlet />}</Suspense>
            </FullPageInner>
        </FullPage>
    )
}

export default PageWithTabs
