import { AutoformatPlugin } from '@udecode/plate'

import { MyPlatePlugin, ShapeValue } from '../../types'
import { ShapeEditor } from '../../types/editor'
import { autoformatRules } from './rules'

export const autoformatPlugin: Partial<MyPlatePlugin<AutoformatPlugin<ShapeValue, ShapeEditor>>> = {
    options: {
        rules: autoformatRules,
        enableUndoOnDelete: true,
    },
}
