import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient, QueryKey } from '@tanstack/react-query'

const STALE_TIME_MS = 10_000 // 10 seconds

/**
 * React Query is used to cache data and prevent unnecessary requests. The client
 * is instantiated here and passed to the QueryClientProvider so that it can be
 * used throughout the app.
 */
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: STALE_TIME_MS,
        },
    },
})

export const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage })

export const getQuery = <T>(query: {
    queryKey: QueryKey
    queryFn: () => T
}): Promise<T> | NonNullable<T> =>
    queryClient.getQueryData(query.queryKey) ?? queryClient.fetchQuery(query)
