import { PlatformReader } from 'platform-ts'

const platform = new PlatformReader()

export const isBrowserCompatible = (() => {
    const { engine, browser, device } = platform.getResult()

    if (device.type === 'mobile' || device.type === 'tablet') {
        // Mobile devices are not supported
        return false
    }

    if (
        // Chromium based browsers will work
        browser.name === 'Chrome' ||
        browser.name === 'Chromium' ||
        browser.name === 'Vivaldi' ||
        browser.name === 'Brave' ||
        // Opera will show up as Chrome
        // Edge versions after 18 are based on Chromium
        (browser.name === 'Edge' && Number(browser.version?.split('.')?.[0]) >= 18)
    ) {
        return true
    }

    if (engine.name === 'Blink') {
        // Non-chromium browsers that use Blink (https://www.chromium.org/blink/) will work
        return true
    }

    return false
})()

export const isMacOS = () => platform.getResult().os.name === 'Mac OS'
