import * as t from 'io-ts'

import { InsertNodeOperation, insertNodeOperation } from './insertNode.operations'
import { InsertTextOperation, insertTextOperation } from './insertText.operations'
import { MergeNodeOperation, mergeNodeOperation } from './mergeNode.operations'
import { MoveNodeOperation, moveNodeOperation } from './moveNode.operations'
import { RemoveNodeOperation, removeNodeOperation } from './removeNode.operations'
import { RemoveTextOperation, removeTextOperation } from './removeText.operations'
import { SetNodeOperation, setNodeOperation } from './setNode.operations'
import { SetSelectionOperation, setSelectionOperation } from './setSelection.operations'
import { SplitNodeOperation, splitNodeOperation } from './splitNode.operations'

// Explicitly declare this instead of inferring because the generated type was getting
// super long
export type Operation =
    | InsertNodeOperation
    | RemoveNodeOperation
    | SplitNodeOperation
    | SetNodeOperation
    | MergeNodeOperation
    | InsertTextOperation
    | RemoveTextOperation
    | SetSelectionOperation
    | MoveNodeOperation

export const operation = t.union([
    insertNodeOperation,
    removeNodeOperation,
    splitNodeOperation,
    setNodeOperation,
    mergeNodeOperation,
    insertTextOperation,
    removeTextOperation,
    setSelectionOperation,
    moveNodeOperation,
])

export type Transaction = Operation[]
export const transaction = t.array(operation)
