import { FC, useMemo } from 'react'
import { DropEvent, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const DropZoneWrapper = styled.div`
    width: 100%;
    height: 100%;
`

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
}

const focusedStyle = {
    borderColor: '#2196f3',
}

const acceptStyle = {
    borderColor: '#00e676',
}

const rejectStyle = {
    borderColor: '#ff1744',
}

type OnDropType = <T extends File>(files: T[], event: DropEvent) => void
interface DropZoneProps {
    onDrop?: OnDropType
    isDisabled?: boolean
}

const DropZone: FC<DropZoneProps> = ({ onDrop, isDisabled }) => {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
        accept: { 'image/*': [] },
        onDropAccepted: onDrop,
        multiple: false,
        disabled: isDisabled,
    })

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )

    return (
        <DropZoneWrapper>
            <div {...getRootProps({ style: style as any })}>
                <input {...getInputProps()} />
                {!isDisabled && <p>{`Drag 'n' drop some files here, or click to select files`}</p>}
            </div>
        </DropZoneWrapper>
    )
}

DropZone.defaultProps = {
    onDrop: () => {},
    isDisabled: false,
}

export default DropZone
