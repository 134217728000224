import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'

export enum SceneConflictType {
    INTERFERENCE = 'interference',
    FEATURE = 'feature',
}

export const sceneConflictType = fromEnum('SceneConflictType', SceneConflictType)

export enum SceneNodeSource {
    OURS = 'ours',
    THEIRS = 'theirs',
}

export const sceneNodeSource = fromEnum('SceneNodeSource', SceneNodeSource)

export enum ConflictCause {
    MODIFIED_BY_US__DELETED_BY_THEM = 'MD',
    MODIFIED_BY_US__MODIFIED_BY_THEM = 'MM',
    DELETED_BY_US__MODIFIED_BY_THEM = 'DM',
}

export const conflictCause = fromEnum('ConflictCause', ConflictCause)

export interface SceneNode extends t.TypeOf<typeof sceneNode> {}
export const sceneNode = t.strict({
    value: t.strict({
        id: t.number,
        originalID: t.number,
        source: sceneNodeSource,
    }),
    edges: t.array(t.number),
})

export interface FeatureConflict extends t.TypeOf<typeof featureConflict> {}
export const featureConflict = t.strict({
    type: t.literal(SceneConflictType.FEATURE),
    nodeA: t.number,
    nodeB: t.number,
    cause: conflictCause,
})

export interface InterferenceConflict extends t.TypeOf<typeof interferenceConflict> {}
export const interferenceConflict = t.strict({
    type: t.literal(SceneConflictType.INTERFERENCE),
    nodeA: t.number,
    nodeB: t.number,
})

export type SceneConflict = FeatureConflict | InterferenceConflict
export const sceneConflict = t.union([featureConflict, interferenceConflict])

export interface SceneGraph extends t.TypeOf<typeof sceneGraph> {}
export const sceneGraph = t.strict({
    nodes: t.array(sceneNode),
    conflicts: t.array(sceneConflict),
})

export interface DiffValue {
    sceneGraph: SceneGraph
    diffURL: string
}
