import { createPluginFactory } from '@udecode/plate'

import { withBlockType } from './withBlockType'

const KEY_WITH_BLOCK_TYPE = 'withBlockType'

export const createBlockTypePlugin = createPluginFactory({
    key: KEY_WITH_BLOCK_TYPE,
    withOverrides: withBlockType,
})
