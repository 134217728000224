import * as t from 'io-ts'

import {
    comment,
    COMMENT_MUTABLE_ATTRIBUTES,
    COMMENT_UNSETTABLE_ATTRIBUTES,
} from '../../models/comment.model'
import { editorSession } from '../../models/documentSession.model'
import { editorNode } from '../../models/editorBlocks.model'
import { blockId, commentId, documentId, restorePointId, threadId } from '../../models/id.model'
import { clientThread } from '../../models/thread.model'
import { operation } from '../../operations/index.operations'
import { documentCollaborator } from '../../types/documentCollaborator.types'
import { omitOnType, partialOnType, pickOnType } from '../../utils/io-ts.util'
import { webSocketErrors, WebSocketEvents } from '../../websocket/index.websocket'

export interface OperationHistoryStack extends t.TypeOf<typeof operationHistoryStack> {}
export const operationHistoryStack = t.array(t.array(operation))

export interface WebSocketRestorePayload extends t.TypeOf<typeof webSocketRestorePayload> {}
export const webSocketRestorePayload = t.type({
    type: t.literal(WebSocketEvents.RESTORE),
    restorePointId,
})

export interface WebSocketLoadPayload extends t.TypeOf<typeof webSocketLoadPayload> {}
export const webSocketLoadPayload = t.type({
    type: t.literal(WebSocketEvents.LOAD),
    sessionId: t.string,
    loadAsViewer: t.boolean,
    documentId,
})

export interface WebSocketLoadedPayload extends t.TypeOf<typeof webSocketLoadedPayload> {}
export const webSocketLoadedPayload = t.type({
    type: t.literal(WebSocketEvents.DOCUMENT_LOADED),
    editorContent: t.array(editorNode),
    undoHistory: operationHistoryStack,
    activeEditorSession: t.union([editorSession, t.null]),
    activeUsers: t.array(documentCollaborator),
    title: t.string,
    isPublicAccess: t.boolean,
})

export interface WebSocketTransactionPayload extends t.TypeOf<typeof webSocketTransactionPayload> {}
export const webSocketTransactionPayload = t.type({
    type: t.literal(WebSocketEvents.COMMIT_TRANSACTION),
    operations: t.array(operation),
})

export type WebSocketTransactionCommittedPayload = t.TypeOf<
    typeof webSocketTransactionCommittedPayload
>
export const webSocketTransactionCommittedPayload = t.type({
    type: t.literal(WebSocketEvents.TRANSACTION_COMMITTED),
    operations: t.array(operation),
})

export interface WebSocketNewEditorPayload extends t.TypeOf<typeof webSocketNewEditorPayload> {}
export const webSocketNewEditorPayload = t.type({
    type: t.literal(WebSocketEvents.NEW_EDITOR_ASSIGNED),
    editorSession: t.union([editorSession, t.null]),
})

export interface WebSocketErrorPayload extends t.TypeOf<typeof webSocketErrorPayload> {}
export const webSocketErrorPayload = t.intersection([
    t.type({
        type: webSocketErrors,
    }),
    t.partial({
        message: t.string,
    }),
])

export interface UserJoinedDocumentPayload extends t.TypeOf<typeof userJoinedDocumentPayload> {}
export const userJoinedDocumentPayload = t.type({
    type: t.literal(WebSocketEvents.USER_JOINED_DOCUMENT),
    user: documentCollaborator,
})

export interface UserDisconnectedPayload extends t.TypeOf<typeof userDisconnectedPayload> {}
export const userDisconnectedPayload = t.type({
    type: t.literal(WebSocketEvents.USER_DISCONNECTED),
    editorSession,
})

export type AddCommentPayload = t.TypeOf<typeof addCommentPayload>
export const addCommentPayload = t.intersection([
    t.type({
        type: t.literal(WebSocketEvents.ADD_COMMENT),
        comment: omitOnType(comment, COMMENT_UNSETTABLE_ATTRIBUTES),
    }),
    t.union([
        t.type({ threadId, isNewThread: t.literal(false) }),
        t.type({ referencedBlock: blockId, isNewThread: t.literal(true) }),
    ]),
])

export interface CommentAddedPayload extends t.TypeOf<typeof commentAddedPayload> {}
export const commentAddedPayload = t.type({
    type: t.literal(WebSocketEvents.COMMENT_ADDED),
    updatedThread: clientThread,
})

export interface UpdateCommentPayload extends t.TypeOf<typeof updateCommentPayload> {}
export const updateCommentPayload = t.type({
    type: t.literal(WebSocketEvents.UPDATE_COMMENT),
    threadId,
    commentId,
    comment: partialOnType(pickOnType(comment, COMMENT_MUTABLE_ATTRIBUTES)),
})

export interface CommentUpdatedPayload extends t.TypeOf<typeof commentUpdatedPayload> {}
export const commentUpdatedPayload = t.type({
    type: t.literal(WebSocketEvents.COMMENT_UPDATED),
    updatedThread: clientThread,
})

export interface DeleteCommentPayload extends t.TypeOf<typeof deleteCommentPayload> {}
export const deleteCommentPayload = t.type({
    type: t.literal(WebSocketEvents.DELETE_COMMENT),
    threadId,
    commentId,
})

export type CommentDeletedPayload = t.TypeOf<typeof commentDeletedPayload>
export const commentDeletedPayload = t.intersection([
    t.type({
        type: t.literal(WebSocketEvents.COMMENT_DELETED),
    }),
    t.union([
        t.type({
            updatedThread: clientThread,
            isThreadDeleted: t.literal(false),
        }),
        t.type({
            deletedThreadId: threadId,
            isThreadDeleted: t.literal(true),
        }),
    ]),
])

export interface DeleteThreadPayload extends t.TypeOf<typeof deleteThreadPayload> {}
export const deleteThreadPayload = t.type({
    type: t.literal(WebSocketEvents.DELETE_THREAD),
    threadId,
})

export interface ThreadDeletedPayload extends t.TypeOf<typeof threadDeletedPayload> {}
export const threadDeletedPayload = t.type({
    type: t.literal(WebSocketEvents.THREAD_DELETED),
    deletedThreadId: threadId,
})

export interface SetTitlePayload extends t.TypeOf<typeof setTitlePayload> {}
export const setTitlePayload = t.type({
    type: t.literal(WebSocketEvents.SET_TITLE),
    title: t.string,
})

export interface TitleChangedPayload extends t.TypeOf<typeof titleChangedPayload> {}
export const titleChangedPayload = t.type({
    type: t.literal(WebSocketEvents.TITLE_CHANGED),
    title: t.string,
})
