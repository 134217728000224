import { DocumentCollaborator, RestorePoint } from '@shapeci/types'
import { Heading } from '@shapeci/ui'
import { formatDateWithTime } from '@shapeci/utils'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { UserLockup } from '../../Lockup'
import { RestorePointWithCreator } from './types'
import VersionMenuDropdownMenu from './VersionDropDownMenu'

const VersionListItemWrapper = styled.article`
     {
        padding: ${({ theme }) => theme.getSpacing(3)} ${({ theme }) => theme.getSpacing(2)};
        min-height: 90px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        justify-content: center;
        gap: 0.5rem;
        position: relative;
        cursor: default;

        > * {
            margin: 0;
        }

        &:not(:last-child) {
            border-bottom: ${({ theme }) => theme.border};
        }

        &:hover {
            background: ${({ theme }) => theme.colors.grey300};
        }

        &.active {
            background: ${({ theme }) => theme.colors.primary100};
            color: ${({ theme }) => theme.colors.primary600};

            h4 {
                font-weight: 700;
            }

            button {
                color: ${({ theme }) => theme.colors.primary600};
            }
        }

        h4 {
            font-size: 0.9125rem;

            + p {
                font-size: 0.9125rem;
                margin: -0.5rem 0 0;
            }
        }

        .shape__lockup {
            .shape__avatar {
                font-size: 0.625rem;
            }

            h2 {
                font-size: 0.875rem;
                margin-bottom: 0;
                line-height: 1.125;
                margin-left: 0.125rem;
            }

            h3 {
                font-size: 0.75rem;
                margin-left: 0.125rem;
            }
        }
    }
`

interface VersionListItemProps {
    restorePoint: RestorePointWithCreator | null
    setSelectedRestorePoint: (v: RestorePoint | null) => void
    selected?: boolean
    current?: boolean
    onRestore?: (restorePointId: string) => void
}

export default function VersionListItem({
    restorePoint,
    setSelectedRestorePoint,
    current,
    selected,
    onRestore,
}: VersionListItemProps) {
    let content: ReactNode = null

    if (current) {
        content = (
            <>
                <Heading kind="h4">Current</Heading>
                <p>The up to date version of this document.</p>
            </>
        )
    } else if (restorePoint !== null) {
        content = (
            <>
                <Heading kind="h4">
                    {restorePoint.meta.dateCreated
                        ? formatDateWithTime(restorePoint.meta.dateCreated)
                        : '--'}
                </Heading>
                <UserLockup
                    size={0}
                    user={{ id: '_', ...restorePoint.creator } as unknown as DocumentCollaborator}
                />
            </>
        )
    }

    return (
        <VersionListItemWrapper
            tabIndex={0}
            className={selected ? 'active' : undefined}
            onClick={() => {
                setSelectedRestorePoint(restorePoint)
            }}
            role="button"
        >
            {content}
            <VersionMenuDropdownMenu
                current={current}
                onRestore={onRestore ? () => restorePoint && onRestore(restorePoint.id) : undefined}
            />
        </VersionListItemWrapper>
    )
}
