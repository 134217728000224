import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'
import { modelViewerState } from './geometry.model'
import { blockId } from './id.model'
import { objectMetadata } from './metadata.model'
import { MongoModelType } from './types.model'

export enum BlockType {
    PARAGRAPH = 'p',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    INLINE_TEXT = 'inlineText',
    LINK = 'a',
    MODEL_VIEWER = 'modelViewer',
    CODE = 'code-editor',
    LATEX = 'latex',
    BULLET_LIST = 'ul',
    NUMBER_LIST = 'ol',
    LIST_ITEM = 'li',
    LIST_ITEM_CHILD = 'lic',
    IMAGE = 'img',
}

export enum BlockTypeTitles {
    PARAGRAPH = 'Text',
    H1 = 'Heading 1',
    H2 = 'Heading 2',
    H3 = 'Heading 3',
    INLINE_TEXT = 'Inline Text',
    MODEL_VIEWER = 'Model Viewer',
    CODE = 'Code',
    LATEX = 'LaTeX',
    IMAGE = 'Image',
}

export const blockType = fromEnum('BlockType', BlockType)

export const blockTypeTitles = fromEnum('BlockTypeTitles', BlockTypeTitles)

export const blockValue = t.union([t.string, modelViewerState, t.undefined])

export interface BlockMarks extends t.TypeOf<typeof blockMarks> {}
export const blockMarks = t.partial({
    // Universal
    id: blockId,
    value: blockValue,

    // Code block
    language: t.string,

    // Rich text
    bold: t.boolean,
    italic: t.boolean,
    underline: t.boolean,
    strikethrough: t.boolean,
    code: t.boolean,
    color: t.string,
    backgroundColor: t.string,
    kbd: t.boolean,
    subscript: t.boolean,

    // Link
    url: t.string,
    target: t.string,

    // Image
    uploadId: t.string,
    width: t.number,

    text: t.string,
})

export interface BlockProperties extends t.TypeOf<typeof blockProperties> {}
export const blockProperties = t.intersection([
    t.partial({
        type: blockType,
    }),
    blockMarks,
])

export interface BaseBlock extends t.TypeOf<typeof baseBlock> {}
export const baseBlock = t.intersection([
    t.strict({
        type: t.union([blockType, t.literal(MongoModelType.BLOCK)]),
        id: blockId,
    }),
    t.exact(
        t.partial({
            value: blockValue,
        })
    ),
])

export interface ChildlessBlock extends t.TypeOf<typeof childlessBlock> {}
export const childlessBlock = t.intersection([
    baseBlock,
    blockMarks,
    t.type({
        type: t.literal(MongoModelType.BLOCK),
        blockType,
    }),
    t.partial({
        meta: objectMetadata,
    }),
])

export interface Block extends t.TypeOf<typeof block> {}
export const block = t.intersection([
    childlessBlock,
    t.partial({
        children: t.array(blockId),
    }),
])

export interface ExpandedBlock extends ChildlessBlock {
    children?: ExpandedBlock[]
}

export const expandedBlock: t.Type<ExpandedBlock> = t.recursion('expandedBlock', () =>
    t.intersection([
        childlessBlock,
        t.partial({
            children: t.array(expandedBlock),
        }),
    ])
)
