import * as t from 'io-ts'

import { accountId, referralId, userId } from './id.model'
import { objectMetadata } from './metadata.model'
import { MongoModelType } from './types.model'

export interface Account extends t.TypeOf<typeof account> {}
export const account = t.strict({
    type: t.literal(MongoModelType.ACCOUNT),
    id: accountId,
    meta: objectMetadata,
    email: t.string,
    sub: t.string,
    org: t.string,
    userId,
    isSubscribedToMarketingEmails: t.boolean,
})

export interface LegacyAccount extends t.TypeOf<typeof legacyAccount> {}
export const legacyAccount = t.intersection([
    t.strict({
        type: t.literal(MongoModelType.ACCOUNT),
        id: accountId,
        meta: objectMetadata,
        email: t.string,
        hashedPassword: t.string,
        salt: t.string,
        userId,
        isSubscribedToMarketingEmails: t.boolean,
        isVerified: t.boolean,
    }),
    t.exact(
        t.partial({
            referralId,
        })
    ),
])

export interface UserCredential extends t.TypeOf<typeof userCredential> {}
export const userCredential = t.type({
    email: t.string,
    password: t.string,
})
