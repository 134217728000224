import { Result } from '@shapeci/ui'
import { formatAuth0Error } from '@shapeci/utils'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import LoadingScreen from '../components/LoadingScreen'
import { useAuth } from '../hooks/useAuth'

const AuthError: FC = () => {
    const navigate = useNavigate()
    const { error } = useAuth()

    if (error) if (!error) return <LoadingScreen />

    const err = formatAuth0Error(error as any)

    return (
        <Result
            intent="danger"
            message={err.title}
            description={err.message}
            actions={[
                {
                    label: 'Sign in',
                    onClick: () => navigate('/login'),
                },
            ]}
        />
    )
}

export default AuthError
