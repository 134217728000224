import { fromEnum } from '../utils/enum.util'

export enum WebSocketEvents {
    LOAD = 'Load',
    DOCUMENT_LOADED = 'DocumentLoaded',
    COMMIT_TRANSACTION = 'CommitTransaction',
    TRANSACTION_COMMITTED = 'TransactionCommitted',

    ADD_COMMENT = 'AddComment',
    COMMENT_ADDED = 'CommentAdded',
    UPDATE_COMMENT = 'UpdateComment',
    COMMENT_UPDATED = 'CommentUpdated',
    DELETE_COMMENT = 'DeleteComment',
    COMMENT_DELETED = 'CommentDeleted',

    DELETE_THREAD = 'DeleteThread',
    THREAD_DELETED = 'ThreadDeleted',

    RESTORE = 'Restore',

    SET_TITLE = 'SetTitle',
    TITLE_CHANGED = 'TitleChanged',

    USER_JOINED_DOCUMENT = 'UserJoinedDocument',
    USER_DISCONNECTED = 'UserDisconnected',

    NEW_EDITOR_ASSIGNED = 'NewEditorAssigned',
    REQUEST_EDIT_ACCESS = 'RequestEditAccess',
    ENTER_VIEW_MODE = 'EnterViewMode',

    ERROR = 'ERROR',
}

export const webSocketEvents = fromEnum('WebSocketEvents', WebSocketEvents)

export enum WebSocketErrors {
    DOCUMENT_NOT_FOUND = 'DocumentNotFound',
    DOCUMENT_NOT_LOADED = 'NotLoaded',
    INVALID_STATUS = 'StatusNotValid',
    PAYLOAD_MALFORMED = 'PayloadMalformed',
    SOCKET_NOT_AUTHORIZED = 'SocketNotAuthorized',
    NOT_EDITOR = 'NotEditor',
}

export const webSocketErrors = fromEnum('WebSocketErrors', WebSocketErrors)
