import * as t from 'io-ts'

import { view } from '../../models/view.model'
import { omitOnType, partialOnType, pickOnType } from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const VIEW_UNSETTABLE_ATTRIBUTES = UNSETTABLE_ATTRIBUTES
const VIEW_MUTABLE_ATTRIBUTES = [
    'name',
    'cameraDirection',
    'cameraPosition',
    'crossSectionDirections',
    'crossSectionPositions',
] as const

export interface CreateViewPayload extends t.TypeOf<typeof createViewPayload> {}
export const createViewPayload = t.type({
    type: t.literal(PayloadTypes.CREATE_VIEW),
    view: omitOnType(view, VIEW_UNSETTABLE_ATTRIBUTES),
})

export interface UpdateViewPayload extends t.TypeOf<typeof updateViewPayload> {}
export const updateViewPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_VIEW),
    view: partialOnType(pickOnType(view, VIEW_MUTABLE_ATTRIBUTES)),
})
