import { BlockType, EditorNode, editorNode } from '@shapeci/types'
import { isRight } from 'fp-ts/lib/Either'

import { fragmentToCode } from './fragmentToCode'
import { fragmentToImage } from './fragmentToImage'
import { fragmentToLink } from './fragmentToLink'
import { fragmentToList } from './fragmentToList'
import { fragmentToText } from './fragmentToText'
import { fragmentToTextNode } from './fragmentToTextNode'
import { identityFragment } from './identityFragment'
import { Fragment } from './types'

export const fragmentsToNodes = (fragments: Fragment[]): EditorNode[] =>
    fragments.map(fragmentToNodeWithValidation).filter((f): f is EditorNode => f !== null)

export const fragmentToNodeWithValidation = (maybeNode: Fragment): EditorNode | null => {
    const node = fragmentToNode(maybeNode)
    if (!node) return null

    const validationResult = editorNode.decode(node)
    if (isRight(validationResult)) return node

    console.error(`Unexpected invalid node on paste ${JSON.stringify(maybeNode, null, 2)}`)
    return null
}

export const fragmentToNode = (maybeNode: Fragment): EditorNode | null => {
    switch (maybeNode.type) {
        case BlockType.LINK:
            return fragmentToLink(maybeNode)

        case BlockType.PARAGRAPH:
        case BlockType.H1:
        case BlockType.H2:
        case BlockType.H3:
            return fragmentToTextNode(maybeNode, maybeNode.type)

        case BlockType.BULLET_LIST:
        case BlockType.NUMBER_LIST:
            return fragmentToList(maybeNode, maybeNode.type)

        case BlockType.IMAGE:
            return fragmentToImage(maybeNode)

        case BlockType.CODE:
            return fragmentToCode(maybeNode)

        // The nodes below this won't ever really be copied from an HTML document, but they can be copied.
        // We don't need to run validation here, because all nodes get validated at the end.
        case BlockType.LIST_ITEM_CHILD:
        case BlockType.INLINE_TEXT:
        case BlockType.MODEL_VIEWER:
            return identityFragment(maybeNode)
        case undefined:
            return fragmentToText(maybeNode)

        default:
            console.warn(`Could not validate fragment type ${maybeNode.type}. Not inserting.`)
    }

    return null
}
