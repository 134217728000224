/* eslint-disable no-param-reassign */
import { OperationType, SetSelectionOperation, Transaction } from '@shapeci/types'
import {
    PlateEditor,
    TDescendant,
    TOperation,
    Value,
    withoutNormalizing,
    WithPlatePlugin,
} from '@udecode/plate'
import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'
import { v4 as uuidv4 } from 'uuid'

import { cursorStore } from '../../stores/cursors'
import { TransactionPlugin } from './types'

const TYPING_DEBOUNCE_TIME_MS = 500
let currentTransactionId = uuidv4()

type HasTransaction = {
    transactionId: string
    cachedVersion: number
}

/**
 * Groups operations into transactions and sends them to a callback function after a debounce time.
 */
export const withTransactions = <
    V extends Value = Value,
    E extends PlateEditor<V> = PlateEditor<V>
>(
    editor: E,
    {
        options: { onTransaction, pendingOperations, transactionObserver },
    }: WithPlatePlugin<TransactionPlugin, V, E>
) => {
    if (!pendingOperations) return editor

    const { apply: originalApply, onChange } = editor

    const debouncedOnTransaction = debounce(
        (editorValue: TDescendant[]) => {
            onTransaction?.(cloneDeep(pendingOperations), editorValue)
            pendingOperations.length = 0
        },
        TYPING_DEBOUNCE_TIME_MS,
        { leading: false, trailing: true }
    )

    // Intercept apply calls and add transaction info to operations
    editor.apply = (op) => {
        const transactionOp = addTransactionDetailsToOperation(op, 0)
        pendingOperations.push(transactionOp)
        originalApply(transactionOp)
        debouncedOnTransaction?.(editor.children)
    }

    // When the editor is changed, we are on a new transaction. This ensure that operations that arise
    // from normalizing are grouped into the same transaction as the operation that caused the normalization
    editor.onChange = () => {
        currentTransactionId = uuidv4()
        onChange()
    }

    // if we have an observable for transactions we should subscribe to it
    // and update the editor with the new operations for read-only editor instances
    transactionObserver?.onTransaction((transaction: Transaction) => {
        let lastSetSelection: SetSelectionOperation | undefined

        withoutNormalizing(editor, () => {
            transaction.forEach((op) => {
                if (op.type === OperationType.SET_SELECTION) {
                    // keep track of the last set selection operation to update the cursor store
                    lastSetSelection = op
                }
                originalApply(op as TOperation)
            })
        })

        if (lastSetSelection) {
            const { newProperties } = lastSetSelection

            if (!newProperties) {
                // clear cursor on nullish newProperties
                cursorStore.set.cursors({})
                return
            }

            const { anchor, focus } = newProperties
            if (!focus) {
                return
            }

            cursorStore.set.cursors({
                editor: {
                    key: 'editor',
                    selection: {
                        anchor: anchor ?? focus,
                        focus,
                    },
                },
            })
        }
    })

    return editor
}

const addTransactionDetailsToOperation = <T extends TOperation>(
    op: T,
    cachedVersion: number
): T & HasTransaction => {
    const transactionOp = op
    transactionOp.cachedVersion = cachedVersion
    transactionOp.transactionId = currentTransactionId
    return transactionOp as T & HasTransaction
}
