export const generateSequence = (str: string, delim: string) => {
    const sequence = []

    const components = str.split(delim)
    const numComponents = components.length
    for (let i = 0; i < numComponents; i++) {
        sequence.push(components.join(delim))
        components.pop()
    }

    return sequence
}
