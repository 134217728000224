import { Redo, Undo } from '@styled-icons/material'
import { ToolbarButton, useEditorRef } from '@udecode/plate'
import React from 'react'

export default function UndoRedoButtons() {
    const editor = useEditorRef()

    return (
        <>
            <ToolbarButton
                onMouseDown={(e) => {
                    e.preventDefault()
                    editor.undo()
                }}
                icon={<Undo />}
                tooltip={{
                    content: 'Undo',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <ToolbarButton
                onMouseDown={(e) => {
                    e.preventDefault()
                    editor.redo()
                }}
                icon={<Redo />}
                tooltip={{
                    content: 'Redo',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
        </>
    )
}
