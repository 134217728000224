/* eslint-disable import/prefer-default-export */
import { BlockType } from '@shapeci/types'

export const isWizardBlock = (type: BlockType) => {
    switch (type) {
        case BlockType.MODEL_VIEWER:
            return true
        case BlockType.IMAGE:
            return true
        default:
            return false
    }
}
