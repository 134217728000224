import {
    autoformatArrow,
    autoformatLegal,
    autoformatLegalHtml,
    autoformatMath,
    autoformatPunctuation,
    autoformatSmartQuotes,
} from '@udecode/plate'

import { MyAutoformatRule } from '../../types'
import { autoformatBlocks } from './blocks'
import { autoformatLists } from './lists'
import { autoformatMarks } from './marks'

const allRules = [
    ...autoformatBlocks,
    ...autoformatLists,
    ...autoformatMarks,
    ...autoformatSmartQuotes,
    ...autoformatPunctuation,
    ...autoformatLegal,
    ...autoformatLegalHtml,
    ...autoformatArrow,
    ...autoformatMath,
] as MyAutoformatRule[]

const excludeRule = (rules: MyAutoformatRule[], match: string) =>
    rules.filter((r) => r.match !== match)

// There's a rule that makes '//' into a division sign. I don't like this because
// it makes typing URLs annoying (I.e. http://)
export const autoformatRules = excludeRule(allRules, '//')
