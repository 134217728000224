import { BlockType, InlineLink } from '@shapeci/types'
import { v4 as uuidv4 } from 'uuid'

import { findFirstText } from '../../helpers/node'
import { Fragment } from './types'

export const fragmentToLink = (fragment: Fragment) => {
    const maybeLinkFrag = fragment as InlineLink

    // If we can't find text for the link, it's invalid
    const textChild = findFirstText(maybeLinkFrag)
    if (!textChild) return null

    // If there's no URL, it's invalid
    if (!maybeLinkFrag.url) return null

    return {
        type: BlockType.LINK,
        url: maybeLinkFrag.url,
        id: uuidv4(),
        children: [
            {
                id: uuidv4(),
                type: BlockType.INLINE_TEXT,
                text: textChild.text,
            },
        ],
    } as InlineLink
}
