import {
    Branch,
    CreateDocumentPayload,
    DocumentReviewStatus,
    DocumentType,
    Nullish,
    PayloadTypes,
    RepositoryId,
    TeamId,
    Thread,
} from '@shapeci/types'
import { Dialog, Input, Select } from '@shapeci/ui'
import { formatCommitMessage, getSharableRoute, GITEA_DEFAULT_BRANCH_NAME } from '@shapeci/utils'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { v4 } from 'uuid'

import { cache } from '../../caches'
import { useApi } from '../../hooks/useApi'
import { useAppNavigate } from '../../hooks/useAppNavigate'
import { useErrorNotification } from '../../utils/dispatchNotifications'

type BranchNameAndHead = Pick<Branch, 'name' | 'headCommit'>

interface RequestDialogProps extends PropsWithChildren {
    teamId?: Nullish<TeamId>
    repositoryId?: Nullish<RepositoryId>
    branches: BranchNameAndHead[]
    branchParamName?: string
    isOpen: boolean
    onClose: () => void
    branchHasOpenReviews: () => boolean
}

const RequestDialogWrapper = styled(Dialog)`
    overflow: visible;

    > div {
        overflow: visible !important;
    }
`

export function RequestDialog({
    teamId,
    repositoryId,
    branches,
    branchHasOpenReviews,
    branchParamName,
    children,
    isOpen,
    onClose,
}: RequestDialogProps) {
    const isSingleBranch = branches.length === 1
    const api = useApi()
    const [isLoading, setIsLoading] = useState(false)
    const invalidateBranch = cache.useInvalidateRepositoryBranch()
    const [branch, setBranch] = useState<Nullish<BranchNameAndHead>>(
        isSingleBranch ? branches[0] : branches.find((b) => b.name === branchParamName)
    )

    const [requestName, setRequestName] = useState<string>('')

    useEffect(() => {
        setBranch(isSingleBranch ? branches[0] : branches.find((b) => b.name === branchParamName))
    }, [branchParamName, branches, isOpen])

    const branchOptions = useMemo(
        () =>
            branches
                .filter((b) => b.name !== GITEA_DEFAULT_BRANCH_NAME)
                .map((b) => ({
                    label: b.name,
                    value: b.name,
                })),
        [branches]
    )

    const selectedBranchOption = useMemo(
        () => branchOptions.find((b) => b.value === branch?.name),
        [branch, branchOptions]
    )

    const navigate = useAppNavigate()

    const dispatchError = useErrorNotification()

    if (!teamId || !repositoryId) return null

    const handleCreateRequest = async () => {
        if (branchHasOpenReviews()) {
            dispatchError('Open merge request exists.')
            return
        }

        if (!branch?.name) {
            dispatchError('Choose a branch to merge.')
            return
        }

        try {
            setIsLoading(true)

            const branchCommits = await api.getCommits(repositoryId, branch.name)
            const branchHeadCommitSha = branch.headCommit
            const matchingCommit = branchCommits.find(
                (commit) => commit.commit === branchHeadCommitSha
            )

            if (!matchingCommit) {
                dispatchError('Cannot create review document.')
                return
            }

            const payload: CreateDocumentPayload = {
                type: PayloadTypes.CREATE_DOCUMENT,
                document: {
                    type: DocumentType.REVIEW_DOCUMENT,
                    title: requestName || formatCommitMessage(matchingCommit.message),
                    id: v4(),
                    team: teamId,
                    sharedUsers: [],
                    sharedTeams: [],
                    threads: [] as Thread[],
                    branch: branch.name,
                    repoId: repositoryId,
                    status: DocumentReviewStatus.OPEN,
                    isStarred: false,
                    isArchived: false,
                },
            }

            const { result } = await api.createDocument(payload)
            invalidateBranch(repositoryId, branch.name)
            navigate(`/documents/${getSharableRoute(result.id, result.title)}`)
        } catch (_) {
            dispatchError('Try again.')
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <RequestDialogWrapper
            open={isOpen}
            onOpenChange={(o) => o || onClose()}
            title="Create a merge request"
            description={
                isSingleBranch ? undefined : 'Choose a branch to merge and name your merge request.'
            }
            content={
                <>
                    {isSingleBranch ? (
                        <p>{branch?.name}</p>
                    ) : (
                        <Select
                            isSearchable
                            value={selectedBranchOption}
                            options={branchOptions}
                            onChange={(option) =>
                                setBranch(branches.find((b) => b.name === option?.label))
                            }
                        />
                    )}
                    <Input
                        label="Optionally name the request. If left blank the commit message will be used."
                        value={requestName}
                        fluid
                        onChange={(e) => setRequestName(e.target.value)}
                    />
                </>
            }
            actions={[
                {
                    label: 'Create',
                    onClick: handleCreateRequest,
                    isLoading,
                    disabled: !branch,
                },
                {
                    label: 'Cancel',
                    onClick: onClose,
                    disabled: isLoading,
                },
            ]}
        >
            {children}
        </RequestDialogWrapper>
    )
}
