import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType, FC } from 'react'

import LoaderPage from './Layout/LoaderPage'

interface AuthenticatedProps {
    component: ComponentType
}

export const Auth0Authenticated: FC<AuthenticatedProps> = ({ component }) => {
    const Component = withAuthenticationRequired(component, {
        onRedirecting: LoaderPage,
    })

    return <Component />
}
