export enum LogLevel {
    ALERT = 'alert',
    ERROR = 'error',
    WARNING = 'warn',
    INFO = 'info',
    HTTP = 'http',
    VERBOSE = 'verbose',
    DEBUG = 'debug',
}

export interface Log {
    level: LogLevel
    message: string
    host?: string
    port?: number
}
