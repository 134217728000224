/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { FolderUpdateActions } from '@shapeci/components'
import { LIGHT_THEME } from '@shapeci/ui'
import { Star } from '@styled-icons/boxicons-regular'
import { Star as StarFilled } from '@styled-icons/boxicons-solid'
import { FC, useState } from 'react'
import { useParams } from 'react-router-dom'

import { cache } from '../../caches'
import { useApi } from '../../hooks/useApi'
import { useErrorNotification } from '../../utils/dispatchNotifications'
import { getIdFromSharableRoute } from '../../utils/documents'
import ActionButton from './ActionButton'

interface StarButtonProps {
    isStarred?: boolean
    dataType: number
}

const StarButton: FC<StarButtonProps> = ({ isStarred, dataType }) => {
    const api = useApi()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatchError = useErrorNotification()

    const createStarErrorNotification = () => {
        dispatchError('Starring failed. Try again.')
    }

    const { folderSlug, documentSlug } = useParams()

    const folderId = getIdFromSharableRoute(folderSlug ?? '')
    const docId = getIdFromSharableRoute(documentSlug ?? '')

    const folderMutation = cache.useMutateFolder()

    const toggleStarring = async () => {
        setIsLoading(true)

        if (dataType === 0) {
            try {
                if (!isStarred) {
                    folderMutation.mutate({
                        action: FolderUpdateActions.STAR,
                        folderId,
                        queryKey: cache.getFolderQueryKey(folderId),
                    })
                } else {
                    folderMutation.mutate({
                        action: FolderUpdateActions.UNSTAR,
                        folderId,
                        queryKey: cache.getFolderQueryKey(folderId),
                    })
                }
            } catch (e) {
                createStarErrorNotification()
            }
        }
        if (dataType === 1) {
            try {
                let documentData = null
                if (!isStarred) {
                    documentData = await api.starDocument(docId)
                } else {
                    documentData = await api.unstarDocument(docId)
                }
            } catch (e) {
                createStarErrorNotification()
            }
        }
        setIsLoading(false)
    }

    return (
        <ActionButton disabled={isLoading} intent="muted" onClick={() => toggleStarring()}>
            {isStarred ? <StarFilled style={{ color: LIGHT_THEME.colors.warning400 }} /> : <Star />}
        </ActionButton>
    )
}

export default StarButton
