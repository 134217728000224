import { createPluginFactory } from '@udecode/plate'

import { withNormalizeFragment } from './withNormalizeFragment'

const KEY_NORMALIZE_FRAGMENTS = 'normalizeFragments'

/**
 * Normalizes fragments so that pasted nodes are valid. This is where all
 * logic for pasting content should go
 */
export const createNormalizeFragmentPlugin = createPluginFactory({
    key: KEY_NORMALIZE_FRAGMENTS,
    withOverrides: withNormalizeFragment,
})
