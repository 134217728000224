import * as t from 'io-ts'

import { referralId, teamId, userId } from './id.model'
import { MongoModelType } from './types.model'

export interface Referral extends t.TypeOf<typeof referral> {}
export const referral = t.strict({
    type: t.literal(MongoModelType.REFERRAL),
    id: referralId,
    teamId,
    invitedEmail: t.string,
    invitedOrg: t.string,
    creatorId: userId,
})
