import * as t from 'io-ts'

import { baseOperation, OperationType } from './base.operations'

export interface MoveNodeOperation extends t.TypeOf<typeof moveNodeOperation> {}
export const moveNodeOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.MOVE_NODE),
        path: t.array(t.number),
        newPath: t.array(t.number),
    }),
])
