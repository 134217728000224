import { ClientFolder, ClientKnowledgeDocument, ClientReviewDocument } from '@shapeci/types'
import { getSharableRoute } from '@shapeci/utils'

import { generateSequence } from './string'
import { isReviewDocument } from './types'

type GenerateValueFn = (title?: string, path?: string) => string
export const breadcrumbsFromPath = (
    filepath: string | undefined,
    generateValue: GenerateValueFn
) => {
    // Always add the root crumb
    const crumbs = [
        {
            label: '~',
            value: generateValue(),
        },
    ]

    // Add everything that remains
    if (filepath) {
        const paths = generateSequence(filepath, '/').reverse()
        const remainingCrumbs = paths.map((p) => breadcrumbFromPath(p, generateValue))
        crumbs.push(...remainingCrumbs)
    }

    return crumbs
}

const breadcrumbFromPath = (path: string, generateValue: GenerateValueFn) => {
    const components = path.split('/')
    if (!components.length) throw new Error(`Given invalid path: ${path}`)

    const title = components[components.length - 1]
    const remainingPath = components.slice(0, -1).join('/')

    return {
        label: title,
        value: generateValue(title, remainingPath),
    }
}

export const getCrumbs = (
    meta: ClientKnowledgeDocument | ClientReviewDocument,
    documentTitle: string,
    documentFolder: ClientFolder | null
) => {
    if (isReviewDocument(meta))
        return [
            {
                label: 'Projects',
                value: '/projects',
            },
            {
                label: `${meta.repoId.slice(0, 8)} (${meta.branch})`,
                value: `/projects/${getSharableRoute(meta.repoId)}/files/${meta.branch}`,
            },
            {
                label: documentTitle,
                value: '.',
            },
        ]

    if (documentFolder && !meta.isArchived)
        return [
            ...documentFolder.parents.map(({ title: documentFolderTitle, id }) => ({
                label: documentFolderTitle,
                value: `/folders/${getSharableRoute(id, documentFolderTitle)}`,
            })),
            {
                label: documentFolder.title,
                value: `/folders/${getSharableRoute(documentFolder.id, documentFolder.title)}`,
            },
            {
                label: documentTitle,
                value: '.',
            },
        ]

    if (meta.isArchived)
        return [
            {
                label: 'Trash',
                value: '/trash',
            },
            {
                label: documentTitle,
                value: '.',
            },
        ]

    return [
        {
            label: '',
            value: '',
        },
    ]
}
