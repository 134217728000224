import {
    AutoformatBlockRule,
    ELEMENT_CODE_BLOCK,
    ELEMENT_CODE_LINE,
    getParentNode,
    isElement,
    isType,
    toggleList,
    unwrapList,
} from '@udecode/plate'

import { ShapeValue } from '../../types'
import { ShapeEditor } from '../../types/editor'

export const preFormat: AutoformatBlockRule<ShapeValue, ShapeEditor>['preFormat'] = (editor) =>
    unwrapList(editor)

export const format = (editor: ShapeEditor, customFormatting: any) => {
    if (editor.selection) {
        const parentEntry = getParentNode(editor, editor.selection)
        if (!parentEntry) return
        const [node] = parentEntry
        if (
            isElement(node) &&
            !isType(editor, node, ELEMENT_CODE_BLOCK) &&
            !isType(editor, node, ELEMENT_CODE_LINE)
        ) {
            customFormatting()
        }
    }
}

export const formatList = (editor: ShapeEditor, elementType: string) => {
    format(editor, () =>
        toggleList(editor, {
            type: elementType,
        })
    )
}

export const formatText = (editor: ShapeEditor, text: string) => {
    format(editor, () => editor.insertText(text))
}
