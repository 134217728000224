import * as t from 'io-ts'

import { giteaRepository } from '../../models/repository.model'
import { partialOnIntersection, pickOnIntersection } from '../../utils/io-ts.util'
import { PayloadTypes } from './http.payloads'

const GITEA_REPO_MUTABLE_ATTRIBUTES = ['name', 'description'] as const

export interface CreateGiteaRepositoryPayload
    extends t.TypeOf<typeof createGiteaRepositoryPayload> {}
export const createGiteaRepositoryPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_GITEA_REPO),
    name: t.string,
    description: t.string,
    private: t.boolean,
    autoInit: t.boolean,
})

export interface UpdateGiteaRepositoryPayload
    extends t.TypeOf<typeof updateGiteaRepositoryPayload> {}
export const updateGiteaRepositoryPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_GITEA_REPO),
    repo: partialOnIntersection(pickOnIntersection(giteaRepository, GITEA_REPO_MUTABLE_ATTRIBUTES)),
})
