import { createContext, MouseEvent, useContext } from 'react'

import { AnyThread } from './types'

export interface ThreadsContextType {
    threadHandlers: {
        open: (threadId: string | null) => void
        delete: (threadId: string) => void
    }
    commentHandlers: {
        submit: (content: string, thread: AnyThread) => void
        edit: (threadId: string, commentId: string) => void
        saveEdit: (threadId: string, commentId: string, content: string) => void
        delete: (threadId: string, commentId: string) => void
        cancel: (e: MouseEvent, isExplicit?: boolean) => void
    }
}

const ThreadsContext = createContext<ThreadsContextType | null>(null)

export const ThreadsProvider = ThreadsContext.Provider

export const useThreads = () => {
    const context = useContext(ThreadsContext)

    if (context === null) {
        throw new Error('useThreads must be used within a ThreadsProvider')
    }

    return context
}
