import { FC, ReactElement } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import LoadingScreen from '../components/LoadingScreen'
import { NavigateOnce } from '../components/NavigateOnce'
import { useAuth } from '../hooks/useAuth'
import { useAuthData } from '../hooks/useAuthData'

const AuthenticatedRedirects: FC<{ children: ReactElement }> = ({ children }) => {
    const { isAuthenticated } = useAuth()
    const { data, isLoading, error } = useAuthData()

    const { teamDomain } = useParams()
    const location = useLocation()

    // Throw errors we can't handle
    if (error) throw error

    // User should never be unauthenticated here
    if (!isAuthenticated) return <Navigate to="/login" replace />

    // Currently fetching user from backend
    if (isLoading) return <LoadingScreen />

    // User has a legacy account that needs to be linked
    const { user, hasLegacyAccount } = data!
    if (hasLegacyAccount) return <NavigateOnce children={children} to="/link" replace />

    // User has been authenticated but they don't have a linked account
    if (!user) return <Navigate to="/setup" replace />

    // The user has no teams, send them to team setup
    if (!user.teams.length)
        return <NavigateOnce children={children} to="/new" replace matchBeginning />

    // If the user is viewing an invalid team, take them to their last team
    if (
        !!user.teams.length &&
        (!teamDomain || !user.teams.find((team) => team.domain === teamDomain))
    ) {
        const lastViewedTeam = user.teams.find((team) => team.id === user.lastTeamViewed)
        const teamToNavigateTo = lastViewedTeam || user.teams[user.teams.length - 1]
        return <Navigate to={`/${teamToNavigateTo.domain}${location.pathname}`} replace />
    }

    // If the user is on the root of the team, redirect them to the projects page
    if (
        !location.pathname.startsWith('/public') &&
        location.pathname.split('/').filter(Boolean).length < 2
    ) {
        return <Navigate to={`/${teamDomain}/projects`} replace />
    }

    return <>{children}</>
}

export default AuthenticatedRedirects
