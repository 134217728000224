import * as t from 'io-ts'

import { omitOnType } from '../utils/io-ts.util'
import { clientComment, comment } from './comment.model'
import { blockId, threadId } from './id.model'
import { objectMetadata } from './metadata.model'
import { MongoModelType } from './types.model'

export interface Thread extends t.TypeOf<typeof thread> {}
export const thread = t.strict({
    type: t.literal(MongoModelType.THREAD),
    meta: objectMetadata,
    id: threadId,
    referencedBlock: blockId,
    comments: t.array(comment),
})

export interface ClientThread extends t.TypeOf<typeof clientThread> {}
export const clientThread = t.intersection([
    omitOnType(thread, ['comments']),
    t.type({
        comments: t.array(clientComment),
    }),
])
