import { Button } from '@shapeci/ui'
import styled from 'styled-components'

const Tabs = styled.div<{ sticky?: boolean }>`
    display: flex;
    background: ${({ theme }) => theme.colors.grey100};
    ${({ sticky }) =>
        sticky &&
        `
  position: sticky;
  top: 110px;
  z-index: 1;
  `}
    width: 100%;
    border-bottom: ${({ theme }) => theme.border};
    ${({ theme }) => theme.getDepth(0.25)};
`

const Tab = styled(Button)<{ $active: boolean }>`
    color: ${({ theme }) => theme.colors.grey600};
    display: inline-flex;
    margin-right: ${({ theme }) => theme.getSpacing(1)};
    padding: ${({ theme }) => theme.getSpacing(1.5)} ${({ theme }) => theme.getSpacing(3)};
    z-index: 1;
    margin-bottom: ${({ theme }) => theme.getSpacing(1)};
    position: relative;
    border: none;

    &:hover {
        opacity: 1;
        background-color: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.grey900};
    }

    ::after {
        position: absolute;
        left: 0;
        height: 2px;
        content: '';
        width: 100%;
        bottom: calc(${({ theme }) => theme.getSpacing(-1)} - 1px);
    }

    ${({ $active, theme }) =>
        $active &&
        `
        &, &:hover {
        color: ${theme.colors.primary700};
        background: ${theme.colors.primary100};

        ::after {
        background: ${theme.colors.primary500};
        }
    }
    `}
`

Tab.defaultProps = {
    kind: 'text',
}

export { Tab, Tabs }
