import { Notification } from '@shapeci/ui'
import React, {
    createContext,
    Dispatch,
    FC,
    Reducer,
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react'

// eslint-disable-next-line no-shadow
export enum NOTIFICATION_ACTIONS {
    ADD,
    REMOVE,
    CLEAR,
}

interface AddNotificationAction {
    type: NOTIFICATION_ACTIONS.ADD
    notification: Notification
}

interface RemoveNotificationAction {
    type: NOTIFICATION_ACTIONS.REMOVE
    id: string
}

interface ClearNotificationsAction {
    type: NOTIFICATION_ACTIONS.CLEAR
}

type NotificationAction =
    | ClearNotificationsAction
    | RemoveNotificationAction
    | AddNotificationAction
type MaybeNotificationAction = NotificationAction | null

const NotificationStateContext = createContext<Notification[]>([])
const NotificationDispatchContext = createContext<Dispatch<NotificationAction>>(() => {})

const reducer = (_state: MaybeNotificationAction | null, action: NotificationAction) => {
    switch (action.type) {
        case NOTIFICATION_ACTIONS.ADD: {
            const { notification } = action

            if (!notification) {
                throw new Error('No notification provided.')
            }

            return action
        }
        case NOTIFICATION_ACTIONS.REMOVE: {
            const { id } = action

            if (!id) {
                throw new Error('No notification id provided.')
            }

            return action
        }
        case NOTIFICATION_ACTIONS.CLEAR: {
            return action
        }
        default: {
            throw new Error('Unhandled action type.')
        }
    }
}

interface NotificationProviderProps {
    children: React.ReactNode
}

const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
    const [notificationAction, dispatch] = useReducer<
        Reducer<MaybeNotificationAction, NotificationAction>
    >(reducer, null)
    const [notifications, setNotifications] = useState<Notification[]>([])

    useEffect(() => {
        function dispatchNotificationAction() {
            if (!notificationAction) return
            switch (notificationAction.type) {
                case NOTIFICATION_ACTIONS.ADD: {
                    const timeout = (notifications.length + 1) * 4000
                    const { notification } = notificationAction
                    notification.timeout = timeout
                    setNotifications((n) => n.concat(notification))
                    return
                }
                case NOTIFICATION_ACTIONS.REMOVE: {
                    const { id } = notificationAction
                    setNotifications((n) => n.filter((x) => x.id !== id))
                    return
                }
                case NOTIFICATION_ACTIONS.CLEAR: {
                    setNotifications([])
                    return
                }
                default: {
                    throw new Error('Unhandled action type.')
                }
            }
        }
        dispatchNotificationAction()
    }, [notificationAction])

    return (
        <NotificationStateContext.Provider value={notifications}>
            <NotificationDispatchContext.Provider value={dispatch}>
                {children}
            </NotificationDispatchContext.Provider>
        </NotificationStateContext.Provider>
    )
}

export const useNotificationState = () => useContext(NotificationStateContext)
export const useNotificationDispatch = () => useContext(NotificationDispatchContext)

export default NotificationProvider
