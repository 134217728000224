import * as t from 'io-ts'

import { alert, invitationAlert, reviewRequestAlert } from '../../models/alert.model'
import {
    omitOnIntersection,
    partialOnIntersection,
    pickOnIntersection,
} from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const ALERT_UNSETTABLE_ATTRIBUTES = [...UNSETTABLE_ATTRIBUTES, 'isViewed', 'status'] as const
const ALERT_MUTABLE_ATTRIBUTES = ['status', 'isViewed'] as const

export interface CreateAlertPayload extends t.TypeOf<typeof createAlertPayload> {}
export const createAlertPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_ALERT),
    alert: omitOnIntersection(alert, ALERT_UNSETTABLE_ATTRIBUTES),
})

export interface CreateInvitationAlertPayload
    extends t.TypeOf<typeof createInvitationAlertPayload> {}
export const createInvitationAlertPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_ALERT),
    alert: omitOnIntersection(invitationAlert, ALERT_UNSETTABLE_ATTRIBUTES),
})

export interface CreateReviewRequestAlertPayload
    extends t.TypeOf<typeof createReviewRequestAlertPayload> {}
export const createReviewRequestAlertPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_ALERT),
    alert: omitOnIntersection(reviewRequestAlert, ALERT_UNSETTABLE_ATTRIBUTES),
})

export interface UpdateAlertPayload extends t.TypeOf<typeof updateAlertPayload> {}
export const updateAlertPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_ALERT),
    alert: partialOnIntersection(pickOnIntersection(alert, ALERT_MUTABLE_ATTRIBUTES)),
})
