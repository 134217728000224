import { Button } from '@shapeci/ui'
import { useState } from 'react'
import styled from 'styled-components'

import { isBrowserCompatible } from '../utils/browser'

const CompatibilityBannerWrapper = styled.div<{ $withSidebar?: boolean }>`
    background: ${({ theme }) => theme.colors.warning100};
    color: ${({ theme }) => theme.colors.warning900};
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    margin: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.warning300};
    border-radius: ${({ theme }) => theme.borderRadius};

    width: calc(100vw - 1rem);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;

    ${({ $withSidebar }) =>
        $withSidebar &&
        `
        width: calc(100vw - 21rem);
        right: 0;
        left: unset;
    `}

    filter: drop-shadow(0px 0px 0.5rem ${({ theme }) => theme.colors.grey600});
    display: flex;

    p {
        flex: 1;
        margin: 0;
    }

    align-items: center;

    @media (max-width: 970px) {
        flex-direction: column;
        align-items: flex-end;
        gap: 0.8rem;
    }
`

export default function CompatibilityBanner({ withSidebar }: { withSidebar?: boolean }) {
    const [isDismissed, setIsDismissed] = useState(false)

    if (isBrowserCompatible) {
        return null
    }

    if (isDismissed) {
        return null
    }

    return (
        <CompatibilityBannerWrapper $withSidebar={withSidebar}>
            <p>
                This browser is not officially supported by ShapeCI. For best results, please use
                Microsoft Edge or Google Chrome.
            </p>
            <Button
                size="small"
                onClick={() =>
                    // eslint-disable-next-line no-alert
                    window.confirm('Are you sure you want to continue on this browser?') &&
                    setIsDismissed(true)
                }
            >
                Dismiss
            </Button>
        </CompatibilityBannerWrapper>
    )
}
