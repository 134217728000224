import * as t from 'io-ts'

import { baseOperation, OperationType } from './base.operations'

export interface RemoveNodeOperation extends t.TypeOf<typeof removeNodeOperation> {}
export const removeNodeOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.REMOVE_NODE),
        path: t.array(t.number),

        // It doesn't matter what the node is
        node: t.unknown,
    }),
])
