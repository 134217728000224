import { BlockType } from '@shapeci/types'
import { CodeBlock, Math } from '@styled-icons/boxicons-regular'
import { Cube, Image } from '@styled-icons/boxicons-solid'
import { Link } from '@styled-icons/material'
import { LinkToolbarButton, ToolbarButton, useEditorRef } from '@udecode/plate'
import React from 'react'

import { OpenWizardCallback } from '../../helpers/createShapePlugins'
import { isTextualBlock } from '../../helpers/toolbar'
import { insertBlock } from '../../helpers/transforms'

interface InsertButtonProps {
    onOpenWizard?: OpenWizardCallback
    selectedType: BlockType | null
}

function InsertButtons({ onOpenWizard, selectedType }: InsertButtonProps) {
    const editor = useEditorRef()

    return (
        <>
            {isTextualBlock(selectedType) && <LinkToolbarButton icon={<Link />} />}
            {onOpenWizard && (
                <>
                    <ToolbarButton
                        onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onOpenWizard(BlockType.MODEL_VIEWER)
                        }}
                        icon={<Cube />}
                        tooltip={{
                            content: 'Insert a model viewer',
                            placement: 'bottom',
                            delay: 750,
                        }}
                    />
                    <ToolbarButton
                        onMouseDown={(e) => {
                            e.preventDefault()
                            onOpenWizard(BlockType.IMAGE)
                        }}
                        icon={<Image />}
                        tooltip={{
                            content: 'Insert an image',
                            placement: 'bottom',
                            delay: 750,
                        }}
                    />
                    <ToolbarButton
                        onMouseDown={(e) => {
                            e.preventDefault()
                            insertBlock(editor as any, '', BlockType.CODE)
                        }}
                        icon={<CodeBlock />}
                        tooltip={{
                            content: 'Insert a code block',
                            placement: 'bottom',
                            delay: 750,
                        }}
                    />
                    <ToolbarButton
                        onMouseDown={(e) => {
                            e.preventDefault()
                            insertBlock(editor as any, '', BlockType.LATEX)
                        }}
                        icon={<Math />}
                        tooltip={{
                            content: 'Insert a LaTeX equation',
                            placement: 'bottom',
                            delay: 750,
                        }}
                    />
                </>
            )}
        </>
    )
}

export default InsertButtons
