import * as t from 'io-ts'

import { vec3 } from './geometry.model'
import { viewId } from './id.model'
import { objectMetadata } from './metadata.model'
import { MongoModelType } from './types.model'

export interface View extends t.TypeOf<typeof view> {}
export const view = t.strict({
    type: t.literal(MongoModelType.VIEW),
    id: viewId,
    meta: objectMetadata,
    name: t.string,
    cameraDirection: vec3,
    cameraPosition: vec3,
    crossSectionDirections: t.array(vec3),
    crossSectionPositions: t.array(vec3),
})
