import { parseNumber } from '@shapeci/utils'
import { ErrorCircle } from '@styled-icons/boxicons-regular'
import { FC, useMemo } from 'react'
import styled from 'styled-components'

const ERROR_CIRCLE_MAX_SIZE_PX = 100
const MIN_IMG_PADDING_PX = 20
const ASPECT_RATIO = 2 / 3

const ErrorWrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.grey300};
    width: ${(props) => props!.style!.width}px;
    width: ${(props) => props!.style!.height}px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.25rem;
    display: flex;
    overflow: auto;
`

const ErrorContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: center;
    align-items: center;
`

interface ImageErrorPrpos {
    message: string
    imageWidth?: number
}

const ImageError: FC<ImageErrorPrpos> = ({ message, imageWidth }) => {
    const width = useMemo(() => parseNumber(imageWidth)!, [imageWidth])
    const height = useMemo(() => width * ASPECT_RATIO, [width])

    return (
        <ErrorWrapper style={{ width, height }}>
            <ErrorContentContainer>
                <ErrorCircle
                    size={Math.min(height - MIN_IMG_PADDING_PX, ERROR_CIRCLE_MAX_SIZE_PX)}
                />
                <h4>{message}</h4>
            </ErrorContentContainer>
        </ErrorWrapper>
    )
}

ImageError.defaultProps = {
    imageWidth: 600,
}

export default ImageError
