import '@datadog/browser-logs/bundle/datadog-logs'

import { datadogRum } from '@datadog/browser-rum'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import reportWebVitals from './reportWebVitals'

const version = `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_TAG}`

datadogRum.init({
    applicationId: 'a5c238e7-a1df-43a0-b465-ae3355597e66',
    clientToken: 'pube13d3f294db8d254b007aae046795d55',
    site: 'us5.datadoghq.com',
    service: 'shapeci-web-client',
    version,
    env: process.env.REACT_APP_ENV,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
})

datadogRum.startSessionReplayRecording()
;(window as any).DD_LOGS.init({
    clientToken: 'pubd1e5424b90c2b61ef20a08574001184e',
    site: 'us5.datadoghq.com',
    forwardErrorsToLogs: true,
    env: process.env.REACT_APP_ENV,
    forwardConsoleLogs: 'all',
    service: 'shapeci-web-client',
    version,
    sessionSampleRate: 100,
})

const root = document.getElementById('root') as HTMLElement

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    root
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
