import { AnyObject, createNodeIdPlugin, createPluginFactory, NodeIdPlugin } from '@udecode/plate'

import { MyPlatePlugin } from '../../types'
import { withNodeIdNormalization } from './withNodeIdNormalization'

const KEY_NODE_ID_WITH_NORMALIZATION = 'nodeIdWithNormal'

const createNodeIdWithNormalizationFactory = (plugin: { options: NodeIdPlugin }) =>
    createPluginFactory<NodeIdPlugin>({
        key: KEY_NODE_ID_WITH_NORMALIZATION,
        plugins: [createNodeIdPlugin(plugin)],
        withOverrides: withNodeIdNormalization(plugin.options),
    })

export const createNodeIdWithNormalizationPlugin = (plugin: {
    options: NodeIdPlugin
}): MyPlatePlugin<AnyObject> => createNodeIdWithNormalizationFactory(plugin)()
