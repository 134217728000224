import { useAuth0 } from '@auth0/auth0-react'
import { useApi as baseHook } from '@shapeci/components'

export const useApi = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuth0()
    return baseHook({
        tokenProvider: getAccessTokenSilently,
        isAuthenticated,
    })
}
