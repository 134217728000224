import * as t from 'io-ts'

import { knowledgeDocument, review, reviewDocument } from '../../models/document.model'
import {
    omitOnIntersection,
    omitOnType,
    partialOnIntersection,
    partialOnType,
    pickOnIntersection,
    pickOnType,
} from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const DOCUMENT_UNSETTABLE_ATTRIBUTES = [
    ...UNSETTABLE_ATTRIBUTES,
    'cachedVersion',
    'cachedContent',
    'transactionHistory',
    'uncachedOperations',
    'referencedModels',
    'referencedUploads',
    'reviews',
    'commitHash',
    'isOutdated',
    'restorePoints',
    'isPublic',
    'searchableTextBlob',
] as const
const DOCUMENT_MUTABLE_ATTRIBUTES = ['title', 'sharedUsers', 'sharedTeams'] as const
const REVIEW_DOCUMENT_MUTABLE_ATTRIBUTES = [...DOCUMENT_MUTABLE_ATTRIBUTES, 'status'] as const

const REVIEW_UNSETTABLE_ATTRIBUTES = [...UNSETTABLE_ATTRIBUTES] as const
const REVIEW_MUTABLE_ATTRIBUTES = ['status'] as const

export type CreateDocumentPayload = t.TypeOf<typeof createDocumentPayload>
export const createDocumentPayload = t.union([
    t.strict({
        type: t.literal(PayloadTypes.CREATE_DOCUMENT),
        document: omitOnIntersection(knowledgeDocument, DOCUMENT_UNSETTABLE_ATTRIBUTES),
    }),
    t.strict({
        type: t.literal(PayloadTypes.CREATE_DOCUMENT),
        document: omitOnIntersection(reviewDocument, DOCUMENT_UNSETTABLE_ATTRIBUTES),
    }),
])

export interface UpdateDocumentPayload extends t.TypeOf<typeof updateDocumentPayload> {}
export const updateDocumentPayload = t.strict({
    type: t.literal(PayloadTypes.UPDATE_DOCUMENT),
    document: partialOnIntersection(
        pickOnIntersection(reviewDocument, REVIEW_DOCUMENT_MUTABLE_ATTRIBUTES)
    ),
})

export interface CreateReviewPayload extends t.TypeOf<typeof createReviewPayload> {}
export const createReviewPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_REVIEW),
    review: omitOnType(review, REVIEW_UNSETTABLE_ATTRIBUTES),
})

export interface UpdateReviewPayload extends t.TypeOf<typeof updateReviewPayload> {}
export const updateReviewPayload = t.strict({
    type: t.literal(PayloadTypes.UPDATE_REVIEW),
    review: partialOnType(pickOnType(review, REVIEW_MUTABLE_ATTRIBUTES)),
})
