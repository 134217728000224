import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

import useMenuBarStore from '../../hooks/useMenuBarStore'
import MenuBar from '../MenuBar'
import { LayoutGridAreas, MENU_BAR_HEIGHT } from './constants'
import LoaderPage from './LoaderPage'
import { LayoutWrapper, MenuBarDummy } from './Site'

const EditorLayoutWrapper = styled(LayoutWrapper)`
    grid-template-areas:
        '${LayoutGridAreas.MENU_BAR}'
        '${LayoutGridAreas.MAIN}';
    grid-template-columns: 1fr;
    grid-template-rows: ${MENU_BAR_HEIGHT} 1fr;

    .document-inner {
        grid-area: ${LayoutGridAreas.MAIN};

        display: flex;

        > * {
            flex: 1;
        }
    }
`

export function EditorLayout() {
    const menuBarState = useMenuBarStore((state) => state.value)

    return (
        <EditorLayoutWrapper>
            {menuBarState ? <MenuBar {...menuBarState} /> : <MenuBarDummy />}
            <Suspense fallback={<LoaderPage />}>
                <Outlet />
            </Suspense>
        </EditorLayoutWrapper>
    )
}
