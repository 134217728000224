import { chain } from 'fp-ts/lib/Either'
import { pipe } from 'fp-ts/lib/pipeable'
import * as t from 'io-ts'

export interface NonNegativeBrand {
    readonly NonNegative: unique symbol
}

export const nonNegative = t.brand(
    t.Int,
    (n): n is t.Branded<t.Int, NonNegativeBrand> => n >= 0,
    'NonNegative'
)

export type NonNegative = t.TypeOf<typeof nonNegative>
export type DateFromISOStringC = t.Type<Date, Date, unknown>
export const DateFromISOString: DateFromISOStringC = new t.Type<Date, Date, unknown>(
    'DateFromISOString',
    (u): u is Date => u instanceof Date,
    (u, c) => {
        if (u instanceof Date) return t.success(u)

        return pipe(
            t.string.validate(u, c),
            chain((s) => {
                const d = new Date(s)
                return Number.isNaN(d.getTime()) ? t.failure(u, c) : t.success(d)
            })
        )
    },
    (a) => a
)
