import { useAuth0 } from '@auth0/auth0-react'
import { create } from 'zustand'

export type AuthState = ReturnType<typeof useAuth0>
const DEFAULT_STATE: AuthState = {
    getAccessTokenSilently: (async () => '') as any,
    getAccessTokenWithPopup: async () => undefined,
    getIdTokenClaims: async () => undefined,
    loginWithPopup: async () => undefined,
    loginWithRedirect: async () => undefined,
    isAuthenticated: false,
    logout: async () => undefined,
    handleRedirectCallback: async () => ({ appState: undefined }),
    isLoading: false,
}

interface AuthStore {
    auth: AuthState
    set: (value: AuthState) => void
}

export const useAuthStore = create<AuthStore>((set) => ({
    auth: DEFAULT_STATE,
    set: (value: AuthState) => set({ auth: value }),
}))
