import {
    ELEMENT_BLOCKQUOTE,
    ELEMENT_DEFAULT,
    ELEMENT_H1,
    ELEMENT_H2,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H5,
    ELEMENT_H6,
    ELEMENT_HR,
    insertNodes,
    setNodes,
} from '@udecode/plate'

import { MyAutoformatRule } from '../../types'
import { preFormat } from './utils'

export const autoformatBlocks: MyAutoformatRule[] = [
    {
        mode: 'block',
        type: ELEMENT_H1,
        match: '# ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_H2,
        match: '## ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_H3,
        match: '### ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_H4,
        match: '#### ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_H5,
        match: '##### ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_H6,
        match: '###### ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_BLOCKQUOTE,
        match: '> ',
        preFormat,
    },
    {
        mode: 'block',
        type: ELEMENT_HR,
        match: ['---', '—-', '___ '],
        format: (editor) => {
            setNodes(editor, { type: ELEMENT_HR })
            insertNodes(editor, {
                type: ELEMENT_DEFAULT,
                children: [{ text: '' }],
            })
        },
    },
]
