import { COMMENT_GUTTER } from '@shapeci/utils'

export const THREAD_GAP = 15
export const THREAD_GUTTER_GAP = THREAD_GAP * 2
export const THREAD_WIDTH = COMMENT_GUTTER - THREAD_GUTTER_GAP

export const THREAD_ITEM_HEIGHT = 80
export const THREAD_VIEW_MORE_HEIGHT = 40
export const THREAD_EDITOR_HEIGHT = 100

export const THREAD_TRANSITION_DELAY_MS = 10

export const NOOP = () => {}

export const GHOST_CONTAINER_ID = 'shape__threads-ghost-container'
