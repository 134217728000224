import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

import LoadingScreen from '../components/LoadingScreen'
import { useAuth } from '../hooks/useAuth'
import AuthError from '../pages/AuthError'

const UnauthenticatedRedirects: FC<{ children: ReactNode }> = ({ children }) => {
    const { isAuthenticated, isLoading, error } = useAuth()

    if (isLoading) return <LoadingScreen />

    if (error) return <AuthError />

    if (!isAuthenticated) return <Navigate to="/login" replace />

    return <>{children}</>
}

export default UnauthenticatedRedirects
