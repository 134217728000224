import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'
import { alertId, documentId, teamId, userId } from './id.model'
import { objectMetadata } from './metadata.model'

export enum AlertStatus {
    ACCEPTED,
    REJECTED,
    UNANSWERED,
}
export const alertStatus = fromEnum('AlertStatus', AlertStatus)

export enum BaseAlertType {
    INVITATION_ALERT = 'InvitationAlert',
}
export const baseAlertType = fromEnum('BaseAlertType', BaseAlertType)

export enum DocumentAlertType {
    REVIEW_SUBMITTED_ALERT = 'ReviewSubmittedAlert',
    REVIEW_REQUEST_ALERT = 'ReviewAlert',
    SHARE_ALERT = 'ShareAlert',
}
export const documentAlertType = fromEnum('DocumentAlertType', DocumentAlertType)

export const alertType = t.union([baseAlertType, documentAlertType])
export type AlertType = t.TypeOf<typeof alertType>

export interface Alert extends t.TypeOf<typeof alert> {}
export const alert = t.intersection([
    t.strict({
        type: alertType,
        meta: objectMetadata,
        alertee: userId,
        status: alertStatus,
        isViewed: t.boolean,
        id: alertId,
    }),
    t.exact(
        t.partial({
            message: t.string,
        })
    ),
])

export interface InvitationAlert extends t.TypeOf<typeof invitationAlert> {}
export const invitationAlert = t.intersection([
    t.strict({
        type: t.literal(BaseAlertType.INVITATION_ALERT),
        meta: objectMetadata,
        alertee: userId,
        status: alertStatus,
        isViewed: t.boolean,
        id: alertId,
        team: teamId,
    }),
    t.strict({
        message: t.string,
    }),
])

export interface DocumentAlert extends t.TypeOf<typeof documentAlert> {}
export const documentAlert = t.intersection([
    ...alert.types,
    t.strict({
        type: documentAlertType,
        document: documentId,
    }),
])

export interface ReviewRequestAlert extends t.TypeOf<typeof reviewRequestAlert> {}
export const reviewRequestAlert = t.intersection([
    ...documentAlert.types,
    t.strict({
        type: t.literal(DocumentAlertType.REVIEW_REQUEST_ALERT),
        team: teamId,
    }),
])

export interface ReviewSubmittedAlert extends t.TypeOf<typeof reviewSubmittedAlert> {}
export const reviewSubmittedAlert = t.intersection([
    ...documentAlert.types,
    t.strict({
        type: t.literal(DocumentAlertType.REVIEW_SUBMITTED_ALERT),
        team: teamId,
    }),
])

export interface ShareAlert extends t.TypeOf<typeof shareAlert> {}
export const shareAlert = t.intersection([
    documentAlert,
    t.type({
        type: t.literal(DocumentAlertType.SHARE_ALERT),
    }),
])

export type AnyAlert = t.TypeOf<typeof anyAlert>
export const anyAlert = t.union([
    invitationAlert,
    documentAlert,
    reviewRequestAlert,
    reviewSubmittedAlert,
    shareAlert,
])
