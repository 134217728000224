import { createPluginFactory } from '@udecode/plate'

import { withRemoveBOM } from './withRemoveBOM'

const KEY_WITHOUT_BOM = 'withoutBOM'

export const createRemoveBOMPlugin = createPluginFactory({
    key: KEY_WITHOUT_BOM,
    withOverrides: withRemoveBOM,
})
