/* eslint-disable no-param-reassign */
import { PlateEditor, Value, WithPlatePlugin } from '@udecode/plate'

import { FullSelectionPlugin } from './types'
/**
 * Adds anchor to all set selection events
 */
export const withFullSelections = <
    V extends Value = Value,
    E extends PlateEditor<V> = PlateEditor<V>
>(
    editor: E,
    // eslint-disable-next-line no-empty-pattern
    { options: {} }: WithPlatePlugin<FullSelectionPlugin, V, E>
) => {
    const { apply } = editor

    editor.apply = (op) => {
        if (op.type === 'set_selection') {
            const anchor = op.newProperties?.anchor || editor.selection?.anchor
            if (anchor) {
                op.newProperties = {
                    ...op.newProperties,
                    anchor: op.newProperties?.anchor || editor.selection?.anchor,
                }
            }
        }

        apply(op)
    }

    return editor
}
