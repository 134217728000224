import { BlockType } from '@shapeci/types'
import { Space, zIndex } from '@shapeci/ui'
import { HeadingToolbar, useEditorRef } from '@udecode/plate'
import React from 'react'
import styled, { css } from 'styled-components'

import { OpenWizardCallback } from '../../helpers/createShapePlugins'
import { isAlignableBlock } from '../../helpers/toolbar'
import AlignToolbarButtons from './AlignmentButtons'
import InsertButtons from './InsertButtons'
import ToolbarSeparator from './Separator'
import { toolbarItemStyles } from './styles'
import TextButtons from './TextButtons'
import UndoRedoButtons from './UndoRedoButtons'

const ToolbarWrapper = styled<any>(HeadingToolbar)`
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;

    height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0;
    padding: ${({ theme }) => theme.getSpacing(0.5)} 2.5vw;
    box-sizing: border-box;

    background: ${({ theme }) => theme.colors.grey100};
    border-bottom: ${({ theme }) => theme.border};
    filter: drop-shadow(0 2px 2px #00000008);

    transition: width 0.375s ease-in-out;

    z-index: ${zIndex.BLOCK_DRAWER};

    .slate-ToolbarButton {
        height: unset;
        width: unset;

        ${toolbarItemStyles}
    }

    .react-select__indicator-separator {
        display: none;
    }

    ${({ $isDrawerOpen }) =>
        $isDrawerOpen &&
        css`
            width: calc(100% - 20rem);
            padding-right: ${({ theme }) => theme.getSpacing(1)};
        `}
`

export interface ShapeToolbarProps {
    isEditor?: boolean
    isHidden?: boolean
    slotRight?: React.ReactNode
    isDrawerOpen?: boolean
}

interface ToolbarProps extends ShapeToolbarProps {
    onOpenWizard?: OpenWizardCallback
}

export const Toolbar = ({
    slotRight,
    isDrawerOpen,
    isEditor,
    isHidden,
    onOpenWizard,
}: ToolbarProps) => {
    const editor = useEditorRef()

    if (isHidden) {
        return null
    }

    const { selection } = editor

    // get selected node type
    const selectedType =
        selection !== null && selection.anchor !== null
            ? (editor.children[selection.anchor.path[0]].type as BlockType)
            : null

    return (
        <ToolbarWrapper $isDrawerOpen={isDrawerOpen}>
            <Space size={0} align="center">
                {isEditor && (
                    <>
                        <UndoRedoButtons />
                        <ToolbarSeparator />
                        <TextButtons selectedType={selectedType} />
                        <AlignToolbarButtons selectedType={selectedType} />
                        {isAlignableBlock(selectedType) ? <ToolbarSeparator /> : null}
                        {/* // TODO - implement list/indent buttons */}
                        {/* <ListIndentButtons />
                        <ToolbarSeparator /> */}
                        <InsertButtons onOpenWizard={onOpenWizard} selectedType={selectedType} />
                    </>
                )}
            </Space>
            {slotRight}
        </ToolbarWrapper>
    )
}
