import * as t from 'io-ts'
import { date } from 'io-ts-types'

import { omitOnType } from '../utils/io-ts.util'
import { teamId, userId } from './id.model'
import { team } from './team.model'
import { MongoModelType } from './types.model'

export interface UserMetadata extends t.TypeOf<typeof userMetadata> {}
export const userMetadata = t.strict({
    dateCreated: date,
    avatarName: t.union([t.string, t.null]),
})

export interface UserGiteaCredentials extends t.TypeOf<typeof giteaCredentials> {}
export const giteaCredentials = t.type({
    username: t.string,
    password: t.string,
    token: t.string,
})

export interface User extends t.TypeOf<typeof user> {}
export const user = t.strict({
    type: t.literal(MongoModelType.USER),
    id: userId,
    teams: t.array(teamId),
    meta: userMetadata,
    firstName: t.string,
    lastName: t.string,
    giteaCredentials,
    lastTeamViewed: t.union([t.undefined, teamId]),
})

export interface UserWithTeams extends t.TypeOf<typeof userWithTeams> {}
export const userWithTeams = t.intersection([
    omitOnType(user, ['teams']),
    t.type({
        teams: t.array(team),
    }),
])
