import { DropdownMenu, Portal, zIndex } from '@shapeci/ui'
import { DotsVerticalRounded, History as HistoryIcon } from '@styled-icons/boxicons-regular'
import styled from 'styled-components'

const DropdownButton = styled(DropdownMenu.Trigger)`
    background: none;
    border: none;
    display: block;

    position: absolute;

    top: 0.825rem;
    right: 0.75rem;
    color: ${({ theme }) => theme.colors.grey600};

    cursor: pointer;
    border-radius: 50%;
    width: 1.625rem;
    height: 1.625rem;

    svg {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    :hover {
        background: #00000010;
    }
`

const DropdownMenuContent = styled(DropdownMenu.Content)`
    z-index: ${zIndex.MAX};
    min-width: 100px;

    // account for scrollbar width (this menu will always
    // be against the right edge of the window)
    margin-right: 20px;
`

interface VersionDropdownMenuProps {
    current?: boolean
    onRestore?: () => void
}

export default function VersionDropdownMenu({ current, onRestore }: VersionDropdownMenuProps) {
    const canRestore = Boolean(!current && onRestore)

    if (!canRestore) {
        // TODO: Enable dropdown menu for copying when available
        return null
    }

    return (
        <DropdownMenu.Root>
            <DropdownButton>
                <DotsVerticalRounded />
            </DropdownButton>
            <Portal>
                <DropdownMenuContent
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    {canRestore && (
                        <DropdownMenu.Item onClick={onRestore}>
                            <DropdownMenu.LeftSlot>
                                <HistoryIcon />
                            </DropdownMenu.LeftSlot>
                            Restore this version
                        </DropdownMenu.Item>
                    )}
                    {/* <DropdownMenu.Item onClick={() => {}}>
                        <DropdownMenu.LeftSlot>
                            <Copy />
                        </DropdownMenu.LeftSlot>
                        Make a copy
                    </DropdownMenu.Item> */}
                </DropdownMenuContent>
            </Portal>
        </DropdownMenu.Root>
    )
}
