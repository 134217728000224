import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { processTo, ProcessToOptions } from '../router/utils'

export function useAppNavigate(options?: ProcessToOptions) {
    const navigate = useNavigate()
    const { teamDomain } = useParams()

    return useCallback(
        (to: string, overrideOptions?: ProcessToOptions) => {
            navigate(processTo(to, teamDomain, overrideOptions ?? options))
        },
        [teamDomain, navigate]
    )
}
