import * as t from 'io-ts'

import { baseOperation, OperationType } from './base.operations'

export interface RemoveTextOperation extends t.TypeOf<typeof removeTextOperation> {}
export const removeTextOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.REMOVE_TEXT),
        offset: t.number,
        text: t.string,
        path: t.array(t.number),
    }),
])
