import { Textarea } from '@shapeci/ui'
import styled from 'styled-components'

export const TitleInput = styled(Textarea)`
    border: none;
    font-size: 2.125rem;
    font-weight: 600;
    width: 100%;
    color: ${({ theme }) => theme.colors.grey900};
    margin: 0 0 1rem;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.background};

    ::placeholder {
        color: ${({ theme }) => theme.colors.grey600};
        opacity: 0.4;
    }

    :focus {
        outline: none;
        border: none;
    }

    :disabled {
        background-color: unset;
    }
`

export const TitleContainer = styled.div`
    margin: 2rem 0 1rem;
    padding: 0;
`
