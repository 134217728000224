import { Space, Tooltip } from '@shapeci/ui'
import { ChevronRight } from '@styled-icons/boxicons-regular'
import { Home } from '@styled-icons/boxicons-solid'
import { FC, Fragment } from 'react'
import styled from 'styled-components'

interface BreadcrumbLink {
    label: string
    value: string
}

interface BreadcrumbProps {
    path?: BreadcrumbLink[]
    onClick?: (value: string) => void
    maxDepth?: number
}

const CrumbWrapper = styled.div`
    display: flex;
    color: ${({ theme }) => theme.colors.grey600};
    user-select: none;

    svg {
        width: 1.2em;
        margin: 0 ${({ theme }) => theme.getSpacing(1)};
    }
`

const Crumb = styled.div<{ $isActive?: boolean }>`
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    padding: ${({ theme }) => theme.getSpacing(0.75)} ${({ theme }) => theme.getSpacing(1)};
    border-radius: ${({ theme }) => theme.borderRadius};

    ${({ onClick }) =>
        onClick &&
        `
    cursor: pointer;
  `}

    ${({ $isActive, theme }) =>
        $isActive &&
        `
  color: ${theme.colors.grey900};
  pointer-events: none;
  `}

  :hover {
        background: ${({ theme }) => theme.colors.grey300};
    }
`

interface CrumbsProps {
    path: BreadcrumbLink[]
    onClick?: (value: string) => void
}

const Crumbs: FC<CrumbsProps> = ({ path, onClick = () => {} }) => (
    <>
        {path.map(({ label, value }, idx) => (
            <Fragment key={value}>
                <Crumb onClick={() => onClick(value)} $isActive={idx === path.length - 1}>
                    {label}
                </Crumb>

                {idx < path.length - 1 && <ChevronRight />}
            </Fragment>
        ))}
    </>
)

Crumbs.defaultProps = {
    onClick: () => {},
}

const Breadcrumbs: FC<BreadcrumbProps> = ({ path, maxDepth, onClick = () => {} }) => {
    if (!path) {
        return null
    }

    let content = <Crumbs path={path} onClick={onClick} />
    if (maxDepth && path.length > maxDepth + 1) {
        const closestHiddenPath = path[path.length - maxDepth]
        content = (
            <>
                <Tooltip
                    content={
                        <Space align="center" style={{ fontSize: '0.875rem' }}>
                            {path.slice(0, path.length - maxDepth).map(({ label }, idx) => (
                                <>
                                    {idx === 0 ? (
                                        <Home style={{ width: '1.2em', paddingLeft: '0.15rem' }} />
                                    ) : (
                                        label
                                    )}{' '}
                                    {idx !== path.length - maxDepth - 1 && (
                                        <ChevronRight style={{ width: '1.2em' }} />
                                    )}
                                </>
                            ))}
                        </Space>
                    }
                >
                    <Crumb
                        onClickCapture={() => {
                            onClick(closestHiddenPath.value)
                        }}
                    >
                        . . . /
                    </Crumb>
                </Tooltip>

                <ChevronRight />
                <Crumbs onClick={onClick} path={path.slice(path.length - maxDepth)} />
            </>
        )
    }

    return <CrumbWrapper>{content}</CrumbWrapper>
}

Breadcrumbs.defaultProps = {
    maxDepth: undefined,
    path: undefined,
    onClick: () => {},
}

export default Breadcrumbs
export type { BreadcrumbLink, BreadcrumbProps }
