/* eslint-disable no-param-reassign */
import { PlateEditor, Value, WithPlatePlugin } from '@udecode/plate'

import { fragmentsToNodes } from './fragmentToNode'
import { NormalizeFragmentPlugin } from './types'

export const withNormalizeFragment = <
    V extends Value = Value,
    E extends PlateEditor<V> = PlateEditor<V>
>(
    editor: E,
    { options }: WithPlatePlugin<NormalizeFragmentPlugin, V, E>
) => {
    const { insertFragment } = editor

    editor.insertFragment = (frags) => {
        const nodes = fragmentsToNodes(frags)
        return insertFragment(nodes as any)
    }

    return editor
}
