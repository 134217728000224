import * as t from 'io-ts'

import { giteaRepository } from '../../models/repository.model'
import { team } from '../../models/team.model'
import {
    omitOnType,
    partialOnIntersection,
    partialOnType,
    pickOnIntersection,
    pickOnType,
} from '../../utils/io-ts.util'
import { PayloadTypes, UNSETTABLE_ATTRIBUTES } from './http.payloads'

const TEAM_UNSETTABLE_ATTRIBUTES = [
    ...UNSETTABLE_ATTRIBUTES,
    'repositories',
    'users',
    'pendingUsers',
    'rootFolder',
    'teamCredentials',
    'bucketName',
    'starredFolders',
    'starredDocuments',
    'archiveFolder',
] as const
const GITEA_REPO_MUTABLE_ATTRIBUTES = ['name', 'users'] as const
const TEAM_MUTABLE_ATTRIBUTES = ['avatarName', 'minimumMergeReviews', 'domain'] as const

export interface CreateTeamPayload extends t.TypeOf<typeof createTeamPayload> {}
export const createTeamPayload = t.strict({
    type: t.literal(PayloadTypes.CREATE_TEAM),
    team: omitOnType(team, TEAM_UNSETTABLE_ATTRIBUTES),
})

export interface UpdateTeamPayload extends t.TypeOf<typeof updateTeamPayload> {}
export const updateTeamPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_TEAM),
    team: t.intersection([
        partialOnIntersection(pickOnIntersection(giteaRepository, GITEA_REPO_MUTABLE_ATTRIBUTES)),
        partialOnType(pickOnType(team, TEAM_MUTABLE_ATTRIBUTES)),
    ]),
})
