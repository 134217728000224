const SLATE_ATTRIBUTES = ['data-slate-node']
const SLATE_CLASSES = ['slate-Draggable']

/* eslint-disable no-restricted-syntax */
const isSlateElement = (element: Element | null) => {
    for (const attribute of SLATE_ATTRIBUTES) {
        if (element?.getAttribute(attribute)) return true
    }

    for (const className of SLATE_CLASSES) {
        if (element?.classList?.contains(className)) return true
    }

    return false
}
/* eslint-enable no-restricted-syntax */

const isSlateEditor = (element: Element | null) => {
    if (element?.getAttribute('data-slate-editor') === 'true') return true
    return false
}

// eslint-disable-next-line import/prefer-default-export
export const getPathFromCursor = ({ x, y }: { x: number; y: number }) => {
    const element = document.elementFromPoint(x, y)
    const path = []

    let current = element

    while (current && !isSlateEditor(current)) {
        const parent = current?.parentElement

        if (parent && isSlateElement(current)) {
            const idx = Array.prototype.indexOf.call(parent.children, current)
            path.unshift(idx)
        }

        current = parent ?? null
    }

    return path
}
