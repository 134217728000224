import { io } from 'socket.io-client'

import { getServerURL } from '../region'

export const createWebSocket = (accessToken: string | null) =>
    io(
        getServerURL() ?? 'ws://localhost:8080',
        accessToken
            ? {
                  extraHeaders: {
                      authorization: `bearer ${accessToken}`,
                  },
              }
            : undefined
    )
