import { Path } from 'slate'

import { ModelViewerBlockProps } from '../../blocks/ModelViewer/types'
import { Listeners } from '../../helpers/withOnHover'

export const ELEMENT_MODEL_VIEWER = 'modelViewer'

export interface ModelViewerOptions
    extends Omit<ModelViewerBlockProps, 'onClick' | 'children' | 'element' | 'attributes'> {
    listeners: Listeners
    onClickBanner: (thisLocation: Path) => void
}
