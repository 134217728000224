import { ErrorCircle, InfoCircle } from '@styled-icons/boxicons-solid'
import { ReactNode } from 'react'
import create from 'zustand'

import { Banner, BannerIntent } from './types'
import {
    BannerSegment,
    BannerStackWrapper,
    InfoBannerWrapper,
    MutedBannerWrapper,
    WarningBannerWrapper,
} from './ui'

interface BannerStore {
    banners: Banner[]
    addBanner: (banner: Banner) => void
    removeBanner: (bannerId: string) => void
}

export const useBannerStore = create<BannerStore>((set) => ({
    banners: [],
    addBanner: (banner: Banner) => {
        set((state) => ({ banners: [...state.banners.filter((b) => b.id !== banner.id), banner] }))
    },
    removeBanner: (bannerId: string) => {
        set((state) => ({
            banners: state.banners.filter((b) => b.id !== bannerId),
        }))
    },
}))

const BANNER_INTENT_TO_ICON: Record<BannerIntent, ReactNode> = {
    info: <InfoCircle />,
    warning: <ErrorCircle />,
    muted: <InfoCircle />,
}

function BannerRenderer({ banner }: { banner: Banner }) {
    const left = (
        <BannerSegment>
            {BANNER_INTENT_TO_ICON[banner.intent]}
            {banner.message}
        </BannerSegment>
    )

    const right = <BannerSegment>{banner.right}</BannerSegment>

    if (banner.intent === 'info') {
        return (
            <InfoBannerWrapper>
                {left}
                {right}
            </InfoBannerWrapper>
        )
    }

    if (banner.intent === 'warning') {
        return (
            <WarningBannerWrapper>
                {left}
                {right}
            </WarningBannerWrapper>
        )
    }

    return (
        <MutedBannerWrapper>
            {left}
            {right}
        </MutedBannerWrapper>
    )
}

export function BannerStack() {
    const banners = useBannerStore((store) => store.banners)

    return (
        <BannerStackWrapper>
            {banners.map((banner, idx) => (
                <BannerRenderer key={banner.message + idx} banner={banner} />
            ))}
        </BannerStackWrapper>
    )
}
