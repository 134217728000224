import * as t from 'io-ts'

import { operation, transaction } from '../operations/index.operations'
import { nonNegative } from '../primitives/primitives'
import { fromEnum } from '../utils/enum.util'
import { omitOnIntersection } from '../utils/io-ts.util'
import {
    blockId,
    documentId,
    modelId,
    repositoryId,
    restorePointId,
    reviewId,
    teamId,
    userId,
} from './id.model'
import { objectMetadata } from './metadata.model'
import { clientThread, thread } from './thread.model'
import { userMetadata } from './user.model'

export enum DocumentType {
    KNOWLEDGE_DOCUMENT = 'KnowledgeDocument',
    REVIEW_DOCUMENT = 'ReviewDocument',
}
export const documentType = fromEnum('DocumentType', DocumentType)

export enum RestorePointCreationReason {
    SESSION_END = 'SessionEnd',
    PERIODIC_SAVE = 'PeriodicSave',
}
export const restorePointCreationReason = fromEnum(
    'RestorePointCreationReason',
    RestorePointCreationReason
)

export interface RestorePoint extends t.TypeOf<typeof restorePoint> {}
export const restorePoint = t.strict({
    id: restorePointId,
    content: t.array(blockId),
    reason: restorePointCreationReason,
    meta: objectMetadata,
})

export interface PaginatedRestorePoint extends t.TypeOf<typeof paginatedRestorePoint> {}
export const paginatedRestorePoint = t.intersection([
    restorePoint,
    t.type({
        count: t.number,
        hasMore: t.boolean,
    }),
])

export interface BaseDocument extends t.TypeOf<typeof baseDocument> {}
export const baseDocument = t.strict({
    type: documentType,
    id: documentId,
    meta: objectMetadata,
    title: t.string,
    team: t.string,
    sharedUsers: t.array(userId),
    sharedTeams: t.array(teamId),
    cachedContent: t.array(blockId),
    cachedVersion: nonNegative,
    transactionHistory: t.array(transaction),
    uncachedOperations: t.array(operation),
    threads: t.array(thread),
    referencedModels: t.array(modelId),
    referencedUploads: t.array(t.string),
    restorePoints: t.array(restorePoint),
    isStarred: t.boolean,
    isArchived: t.boolean,
    isPublic: t.boolean,

    /**
     * Blob of text embedded in the document model used
     * for full text search. Built from cachedContent.
     */
    searchableTextBlob: t.union([t.string, t.undefined]),
})

export interface KnowledgeDocument extends t.TypeOf<typeof knowledgeDocument> {}
export const knowledgeDocument = t.intersection([
    baseDocument,
    t.strict({
        type: t.literal(DocumentType.KNOWLEDGE_DOCUMENT),
        parent: t.string,
    }),
])

export interface ClientKnowledgeDocument extends t.TypeOf<typeof clientKnowledgeDocument> {}
export const clientKnowledgeDocument = t.intersection([
    omitOnIntersection(knowledgeDocument, ['threads']),
    t.strict({
        threads: t.array(clientThread),
    }),
])

export enum ReviewStatus {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    DENIED = 'Denied',
}

export const reviewStatus = fromEnum('ReviewStatus', ReviewStatus)

export enum DocumentReviewStatus {
    OPEN = 'Open',
    CLOSED = 'Closed',
    MERGED = 'Merged',
}

export const documentReviewStatus = fromEnum('DocumentReviewStatus', DocumentReviewStatus)

export interface Reviewer extends t.TypeOf<typeof reviewer> {}
export const reviewer = t.strict({
    id: userId,
    firstName: t.string,
    lastName: t.string,
    meta: userMetadata,
})

export interface Review extends t.TypeOf<typeof review> {}
export const review = t.strict({
    id: reviewId,
    meta: objectMetadata,
    status: reviewStatus,
    reviewer: userId,
})

export interface ReviewWithReviewer extends t.TypeOf<typeof reviewWithReviewer> {}
export const reviewWithReviewer = t.strict({
    id: reviewId,
    meta: objectMetadata,
    status: reviewStatus,
    reviewer,
})

export interface ReviewDocument extends t.TypeOf<typeof reviewDocument> {}
export const reviewDocument = t.intersection([
    baseDocument,
    t.strict({
        repoId: repositoryId,
        type: t.literal(DocumentType.REVIEW_DOCUMENT),
        reviews: t.array(review),
        branch: t.string,
        status: documentReviewStatus,
        isOutdated: t.boolean,
        commitHash: t.string,
    }),
])

export interface ClientReviewDocument extends t.TypeOf<typeof clientReviewDocument> {}
export const clientReviewDocument = t.intersection([
    omitOnIntersection(reviewDocument, ['threads', 'reviews']),
    t.strict({
        threads: t.array(clientThread),
        reviews: t.array(reviewWithReviewer),
    }),
    t.partial({
        latestDocument: t.strict({
            latestDocumentId: documentId,
            headCommit: t.string,
        }),
    }),
])

export type AnyDocument = t.TypeOf<typeof anyDocument>
export const anyDocument = t.union([knowledgeDocument, reviewDocument])
