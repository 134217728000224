/* eslint-disable consistent-return */
import { Card, Portal } from '@shapeci/ui'
import { getErrorMessage } from '@shapeci/utils'
import React, { useState } from 'react'
import styled from 'styled-components'

import { cache } from '../../caches'
import { useApi } from '../../hooks/useApi'
import { useErrorNotification, useWarnNotification } from '../../utils/dispatchNotifications'
import DropZone from '../DropZone'
import Spinner from '../Loader/Spinner'
import Modal from '../Modal'

const PADDING_SPACING_AMOUNT = 4
const SPINNER_SIZE = 10

const SelectImageModalWrapper = styled(Modal)`
    ${Card} {
        display: flex;
        height: 50%;
        width: 600px;
        max-height: 600px;
        max-width: 800px;
        box-sizing: border-box;
        padding: ${({ theme }) => theme.getSpacing(PADDING_SPACING_AMOUNT)};
    }
`

const DropZoneWrapper = styled.div`
    width: calc(100% - ${({ theme }) => theme.getSpacing(PADDING_SPACING_AMOUNT * 2)});
    height: calc(100% - ${({ theme }) => theme.getSpacing(PADDING_SPACING_AMOUNT * 2)});
    position: absolute;
`

const SpinnerWrapper = styled.div`
    z-index: 1;
    position: absolute;
    left: calc(50% - ${SPINNER_SIZE / 2}rem);
    top: calc(50% - ${SPINNER_SIZE / 2}rem);
`

interface SelectImageModalProps {
    isModalOpen: boolean
    onCloseModal: () => void
    onSelectImage: (imageURL: string) => void
}

const SelectImageModal: React.FC<SelectImageModalProps> = ({
    isModalOpen,
    onCloseModal,
    onSelectImage,
}) => {
    const api = useApi()
    const { data: team } = cache.useTeam()
    const [isUploading, setIsUploading] = useState(false)
    const dispatchError = useErrorNotification()
    const dispatchWarning = useWarnNotification()

    const uploadImage = async (file: File) => {
        if (!team) return dispatchError(`No team selected, try refreshing page`)

        setIsUploading(true)

        try {
            const result = await api.uploadImage(team.id, file)
            return result
        } catch (error) {
            return dispatchError(`Could not upload file: ${getErrorMessage(error)}`)
        } finally {
            setIsUploading(false)
        }
    }

    const onDropImage = async <T extends File>(files: T[]) => {
        if (!team) return dispatchError(`No team selected, try refreshing page`)

        if (!files.length) return

        if (files.length > 1)
            return dispatchWarning(
                'Multiple files were selected! Only the first one will be uploaded'
            )

        const uploadResult = await uploadImage(files[0])
        if (!uploadResult) return

        onSelectImage(uploadResult.result.uploadId)
    }

    const renderLoader = () => (
        <SpinnerWrapper>
            <Spinner size={SPINNER_SIZE} />
        </SpinnerWrapper>
    )

    return (
        <Portal>
            <SelectImageModalWrapper isOpen={isModalOpen} onClose={onCloseModal}>
                <DropZoneWrapper>
                    <DropZone onDrop={onDropImage} isDisabled={isUploading} />
                </DropZoneWrapper>

                {isUploading && renderLoader()}
            </SelectImageModalWrapper>
        </Portal>
    )
}

export default SelectImageModal
