import { Button, Card } from '@shapeci/ui'
import { CSSProperties, FC, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import Comment, { CommentGhost } from './Comment'
import CommentEditor from './CommentEditor'
import {
    NOOP,
    THREAD_EDITOR_HEIGHT,
    THREAD_TRANSITION_DELAY_MS,
    THREAD_VIEW_MORE_HEIGHT,
} from './constants'
import { useThreads } from './Context'
import { AnyThread } from './types'
import { isNewThread } from './utils'

const ThreadContainer = styled(Card)`
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin: 0;
    cursor: default;

    transform: translateY(var(--y));

    transition: all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition-delay: ${THREAD_TRANSITION_DELAY_MS}ms;

    > .shape__comment-editor {
        min-height: ${THREAD_EDITOR_HEIGHT}px;
    }

    > [data-view-more-spacer] {
        height: ${THREAD_VIEW_MORE_HEIGHT}px;
    }

    filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.0375));

    :hover {
        box-shadow: none;
        filter: drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.125));
    }

    &[data-new-thread] {
        animation: thread-in 0.125s ease-in-out;
    }

    // move and scale up the open thread
    &[data-thread-open='true'] {
        transform: translate(-0.75rem, var(--y)) scale(1.001);
        z-index: 1;
    }

    @keyframes thread-in {
        from {
            transform: translateY(calc(var(--y) + 2rem)) scale(0.9);
            opacity: 0;
        }
        to {
            transform: translateY(var(--y)) scale(1);
            opacity: 1;
        }
    }
`

const ViewMoreButton = styled(Button)`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
    border-radius: 0;

    font-size: 0.875rem;

    color: ${({ theme }) => theme.colors.grey600};

    &:hover {
        opacity: 1;
        background: ${({ theme }) => theme.colors.grey300};
        color: ${({ theme }) => theme.colors.grey600};
    }
`

ThreadContainer.defaultProps = {
    className: 'shape__thread',
}

export interface ThreadProps {
    thread: AnyThread
    isOpen: boolean
    editingCommentId: string | null
    isNew?: boolean
    position?: number
}

const Thread: FC<ThreadProps> = ({ thread, isOpen, position, editingCommentId }) => {
    const { commentHandlers, threadHandlers } = useThreads()

    const styles = useMemo(
        () =>
            ({
                '--y': `${position}px`,
            } as CSSProperties),
        [position]
    )

    if (isNewThread(thread)) {
        return (
            <ThreadContainer style={styles} data-new-thread data-thread-open={true}>
                <CommentEditor
                    submitComment={(s: string) => commentHandlers.submit(s, thread)}
                    // we know this editor can only be used for new comments
                    editComment={NOOP}
                    cancelComment={commentHandlers.cancel}
                />
            </ThreadContainer>
        )
    }

    const { comments, id: threadId } = thread

    const handleOpenThread = useCallback(() => {
        if (!isOpen) {
            threadHandlers.open(threadId)
        }
    }, [isOpen, threadHandlers.open])

    if (!comments) {
        return null
    }

    if (comments.length > 3 && !isOpen) {
        return (
            <ThreadContainer onClick={handleOpenThread} style={styles}>
                <Comment
                    comment={comments[0]}
                    handleResolve={() => threadHandlers.delete(threadId)}
                    handleDelete={() => commentHandlers.delete(threadId, comments[0].id)}
                    handleEdit={() => commentHandlers.edit(threadId, comments[0].id)}
                />
                <ViewMoreButton
                    data-view-more-spacer
                    kind="text"
                    onClick={(e) => {
                        e.stopPropagation()
                        handleOpenThread()
                    }}
                >
                    View all {comments.length - 2} more
                </ViewMoreButton>
                <Comment
                    comment={comments[comments.length - 1]}
                    handleDelete={() =>
                        commentHandlers.delete(threadId, comments[comments.length - 1].id)
                    }
                    handleEdit={() =>
                        commentHandlers.edit(threadId, comments[comments.length - 1].id)
                    }
                />
            </ThreadContainer>
        )
    }

    return (
        <ThreadContainer onClick={handleOpenThread} style={styles} data-thread-open={isOpen}>
            {thread.comments.map((comment, idx) => {
                if (comment.id === editingCommentId) {
                    return (
                        <CommentEditor
                            key={comment.id}
                            // we know this editor can only be used for old comments
                            submitComment={NOOP}
                            editComment={(content: string) =>
                                commentHandlers.saveEdit(threadId, comment.id, content)
                            }
                            cancelComment={commentHandlers.cancel}
                            defaultValue={comment.content}
                        />
                    )
                }

                return (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        handleDelete={() => commentHandlers.delete(threadId, comment.id)}
                        handleResolve={
                            idx === 0 ? () => threadHandlers.delete(threadId) : undefined
                        }
                        handleEdit={() => commentHandlers.edit(threadId, comment.id)}
                    />
                )
            })}
            {isOpen && !editingCommentId && (
                <CommentEditor
                    submitComment={(s: string) => commentHandlers.submit(s, thread)}
                    // we know this editor can only be used for new comments
                    editComment={NOOP}
                    cancelComment={commentHandlers.cancel}
                />
            )}
        </ThreadContainer>
    )
}

const ThreadGhostContainer = styled(ThreadContainer)`
    pointer-events: none;
    top: unset;
    left: unset;
    position: relative;

    font-weight: 400;
    line-height: 1.5;
`

const ThreadGhost: FC<Omit<ThreadProps, 'position'>> = ({ thread, isOpen, editingCommentId }) => {
    if (isNewThread(thread)) {
        return (
            <ThreadGhostContainer
                data-new-thread
                data-thread-open={true}
                data-ghost-thread-id={thread.id}
            >
                <CommentEditor submitComment={NOOP} cancelComment={NOOP} editComment={NOOP} />
            </ThreadGhostContainer>
        )
    }
    const { comments } = thread

    if (!comments) {
        return <div style={{ height: 0 }} />
    }

    if (comments.length > 3 && !isOpen) {
        return (
            <ThreadGhostContainer data-ghost-thread-id={thread.id}>
                <CommentGhost comment={comments[0]} />
                <ViewMoreButton data-view-more-spacer kind="text" />
                <CommentGhost comment={comments[comments.length - 1]} />
            </ThreadGhostContainer>
        )
    }

    return (
        <ThreadGhostContainer data-thread-open={isOpen} data-ghost-thread-id={thread.id}>
            {thread.comments.map((comment) => {
                if (editingCommentId === comment.id) {
                    return (
                        <CommentEditor
                            key={comment.id}
                            submitComment={NOOP}
                            cancelComment={NOOP}
                            editComment={NOOP}
                        />
                    )
                }

                return <CommentGhost key={comment.id} comment={comment} />
            })}
            {isOpen && !editingCommentId && (
                <CommentEditor submitComment={NOOP} cancelComment={NOOP} editComment={NOOP} />
            )}
        </ThreadGhostContainer>
    )
}

export default Thread
export { ThreadGhost }
