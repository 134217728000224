import { GlobalStyle, LIGHT_THEME } from '@shapeci/ui'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { FC, ReactNode } from 'react'
import { ThemeProvider } from 'styled-components'

import NotificationProvider from '../context/Notifications'
import { localStoragePersister, queryClient } from '../utils/queryClient'
import GlobalNotificationTray from './GlobalNotificationTray'
import LocalGlobalStyle from './LocalGlobalStyle'

interface ProviderProps {
    children?: ReactNode
}

const NonAuthProviders: FC<ProviderProps> = ({ children }) => (
    <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister: localStoragePersister }}
    >
        <ThemeProvider theme={LIGHT_THEME}>
            <ReactQueryDevtools />
            <NotificationProvider>
                <GlobalStyle />
                <LocalGlobalStyle />
                <GlobalNotificationTray />
                {children}
            </NotificationProvider>
        </ThemeProvider>
    </PersistQueryClientProvider>
)

export default NonAuthProviders
