import * as t from 'io-ts'

import { editorNode } from '../models/editorBlocks.model'
import { baseOperation, OperationType } from './base.operations'

export interface InsertNodeOperation extends t.TypeOf<typeof insertNodeOperation> {}
export const insertNodeOperation = t.intersection([
    baseOperation,
    t.type({
        path: t.array(t.number),
        type: t.literal(OperationType.INSERT_NODE),
        node: editorNode,
    }),
])
