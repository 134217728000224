import { BlockType, BulletedList, NumberedList } from '@shapeci/types'
import { v4 as uuidv4 } from 'uuid'

import { fragmentToListItem } from './fragmentToListItem'
import { Fragment } from './types'

type ListType = BlockType.BULLET_LIST | BlockType.NUMBER_LIST

export const fragmentToList = (fragment: Fragment, type: ListType) => {
    const maybeBulletList = fragment as any as BulletedList | NumberedList
    const listItems = maybeBulletList?.children?.filter(
        (c) => c.type === BlockType.LIST_ITEM
    ) as any as Fragment[]
    const parsedListItems = listItems.map(fragmentToListItem).filter((t) => t !== null)
    if (!parsedListItems.length) return null

    return {
        type,
        id: uuidv4(),
        children: parsedListItems,
    } as BulletedList | NumberedList
}
