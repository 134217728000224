import { getShapeClassName, joinClassNames } from '@shapeci/ui'
import { CursorOverlay, CursorOverlayProps, CursorProps } from '@udecode/plate'
import React from 'react'
import styled from 'styled-components'

import { cursorStore } from '../../stores/cursors'

export const CURSOR_CLASSNAME = getShapeClassName('EditorCursor')
export const CURSOR_LABEL_CLASSNAME = getShapeClassName('EditorCursorLabel')

interface MyCursorProps extends CursorProps {
    className?: string
    style?: React.CSSProperties
}

const CursorSelection = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colors.primary500};
    opacity: 0.175;
    z-index: 0;
    pointer-events: none;
`

const CursorWrapper = styled.div`
    width: 2px;
    background-color: ${({ theme }) => theme.colors.primary500};
    position: absolute;
    z-index: 0;

    :before {
        content: '';
        position: absolute;
        width: 1em;
        height: 1em;
        left: -0.5em;
    }

    :after {
        content: '';
        position: absolute;
        width: 0.5em;
        height: 0.5em;
        left: -0.18em;
        top: -0.5em;
        background-color: ${({ theme }) => theme.colors.primary500};
    }

    :hover {
        :after {
            border-bottom-left-radius: 0.25em;
        }

        .${CURSOR_LABEL_CLASSNAME} {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
            transition-delay: 0 !important;
        }
    }
`

const CursorLabel = styled.div`
    position: absolute;
    padding: 0 0.325em;
    box-sizing: border-box;
    border-radius: 0.25em;
    left: calc(-0.18em - 2px);
    top: -1.5em;
    height: 1.5em;
    font-size: 0.875rem;
    width: auto;
    color: ${({ theme }) => theme.colors.grey100};
    background-color: ${({ theme }) => theme.colors.primary500};
    z-index: 2;

    :before {
        color: ${({ theme }) => theme.colors.white};
    }

    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0.75s;
`

const Cursor = ({
    style: styleOverrides,
    className,
    caretPosition,
    selectionRects,
}: MyCursorProps) => (
    <>
        <CursorWrapper
            className={joinClassNames(className, CURSOR_CLASSNAME)}
            style={{
                ...styleOverrides,
                left: caretPosition?.left,
                top: caretPosition ? caretPosition.top - 1 : undefined,
                height: caretPosition ? caretPosition.height - 2 : undefined,
            }}
        >
            <CursorLabel className={CURSOR_LABEL_CLASSNAME} />
        </CursorWrapper>
        {selectionRects.map((rect, i) => (
            <CursorSelection
                key={i}
                style={{
                    ...rect,
                }}
            />
        ))}
    </>
)

const renderCursor = (props: any) => <Cursor {...props} />

export const CursorOverlayContainer = ({ ...props }: CursorOverlayProps) => {
    const cursors = cursorStore.use.cursors()

    return <CursorOverlay onRenderCursor={renderCursor} {...props} cursors={cursors} />
}
