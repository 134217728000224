import { useApi as baseHook } from '@shapeci/components'

import { useAuthStore } from '../stores/auth'

export const useApi = () => {
    const { getAccessTokenSilently, isAuthenticated } = useAuthStore((s) => s.auth)

    return baseHook({
        tokenProvider: getAccessTokenSilently,
        isAuthenticated,
    })
}
