import { GiteaRepo } from './repository.gitea'

export interface GiteaBranch {
    label: string
    ref: string
    repo: GiteaRepo
    repo_id: number
    sha: string
}

/**
 * "Mergability" is a typo, but not worth refactoring. Make sure
 * user interfaces use "Merg[e]ability".
 */
export enum Mergability {
    MERGABLE = 'Mergable',
    UNMERGABLE = 'Unmergable',
    UNKNOWN = 'Unknown',
    INDETERMINATE = 'Indeterminate',
    MERGED = 'Merged',
}

export enum MergabilityReason {
    NO_CONFLICTS = 'NoConflicts',
    CONFLICTS = 'Conflicts',
    NO_DIFF = 'NoDiff',
    MERGED = 'Merged',
}

export interface MergeStatus {
    mergability: Mergability
    reason: MergabilityReason
}
