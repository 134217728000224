import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'
import { folderId, modelId, repositoryId, resourceId } from './id.model'
import { objectMetadata } from './metadata.model'

export enum ModelContentType {
    WEB_ACCESSIBLE_MODEL = 'WebAccessibleModel',
}
export const modelContentType = fromEnum('ModelContentType', ModelContentType)

export interface ModelReference extends t.TypeOf<typeof modelReference> {}
export const modelReference = t.strict({
    modelId,
    relativePath: t.string,
})

export interface ModelReferences extends t.TypeOf<typeof modelReferences> {}
export const modelReferences = t.strict({
    directReferences: t.array(modelReference),
    unresolvedReferences: t.array(t.string),
})

export interface ModelStructure extends t.TypeOf<typeof modelStructure> {}
export const modelStructure = t.intersection([
    t.strict({
        areReferencesStale: t.boolean,
        isCachedModelStale: t.boolean,
        updateHistory: t.array(
            t.strict({
                updateNumber: t.number,
                resourceId,
            })
        ),
    }),
    t.exact(
        t.partial({
            references: modelReferences,
        })
    ),
])

export interface VersionMapping extends t.TypeOf<typeof versionMapping> {}
export const versionMapping = t.strict({
    version: t.number,
    commitHash: t.string,
    parentFolderId: folderId,
    modelStructure,
})

export interface Model extends t.TypeOf<typeof model> {}
export const model = t.strict({
    type: modelContentType,
    modelStructure,
    id: modelId,
    meta: objectMetadata,
    branchName: t.string,
    name: t.string,
    versionMappings: t.array(versionMapping),
    repository: repositoryId,
    isDeleted: t.boolean,
    directlyReferencedBy: t.array(modelId),
})

export const breadcrumbLink = t.strict({
    label: t.string,
    value: t.string,
})

export interface ModelPathData extends t.TypeOf<typeof modelPathData> {}
export const modelPathData = t.strict({
    repoName: t.string,
    branchName: t.string,
    modelName: t.string,
    foldersPath: t.array(breadcrumbLink),
})

export interface ModelCommit extends t.TypeOf<typeof modelCommit> {}
export const modelCommit = t.strict({
    sha: t.string,
    message: t.string,
})

export interface WebAccessibleModel extends t.TypeOf<typeof webAccessibleModel> {}
export const webAccessibleModel = t.intersection([
    model,
    t.strict({
        type: t.literal(ModelContentType.WEB_ACCESSIBLE_MODEL),
    }),
])
