import { EditorNode } from '@shapeci/types'
import { AnyObject } from '@udecode/plate'

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface BlockProps {
    attributes: any
    element: EditorNode
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export const hasBlockProps = (maybeBlockProps: AnyObject): maybeBlockProps is BlockProps =>
    maybeBlockProps.element && maybeBlockProps.attributes
