import { BlockType } from '@shapeci/types'
import { FormatAlignCenter } from '@styled-icons/material/FormatAlignCenter'
import { FormatAlignJustify } from '@styled-icons/material/FormatAlignJustify'
import { FormatAlignLeft } from '@styled-icons/material/FormatAlignLeft'
import { FormatAlignRight } from '@styled-icons/material/FormatAlignRight'
import { AlignToolbarButton } from '@udecode/plate'
import React from 'react'

import { isAlignableBlock } from '../../helpers/toolbar'

interface AlignToolbarButtonsProps {
    selectedType: BlockType | null
}

export default function AlignToolbarButtons({ selectedType }: AlignToolbarButtonsProps) {
    if (!isAlignableBlock(selectedType)) return null

    return (
        <>
            <AlignToolbarButton
                value="left"
                icon={<FormatAlignLeft />}
                tooltip={{
                    content: 'Left Align',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <AlignToolbarButton
                value="center"
                icon={<FormatAlignCenter />}
                tooltip={{
                    content: 'Center Align',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <AlignToolbarButton
                value="right"
                icon={<FormatAlignRight />}
                tooltip={{
                    content: 'Right Align',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
            <AlignToolbarButton
                value="justify"
                icon={<FormatAlignJustify />}
                tooltip={{
                    content: 'Justify',
                    placement: 'bottom',
                    delay: 750,
                }}
            />
        </>
    )
}
