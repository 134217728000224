import * as t from 'io-ts'

import { fromEnum } from '../utils/enum.util'
import { folderId, repositoryId, userId } from './id.model'
import { objectMetadata } from './metadata.model'

export enum RepositoryType {
    GITHUB_REPOSITORY = 'GithubRepository',
    GITEA_REPOSITORY = 'GiteaRepository',
}
export const repositoryType = fromEnum('RepositoryType', RepositoryType)

export interface BranchCommit extends t.TypeOf<typeof branchCommit> {}
export const branchCommit = t.strict({
    number: t.number,
    commit: t.string,
    message: t.string,
    root: folderId,
    hasBeenSynced: t.boolean,
    meta: objectMetadata,
})

export interface Branch extends t.TypeOf<typeof branch> {}
export const branch = t.strict({
    name: t.string,
    headCommit: t.string,
    commits: t.array(branchCommit),
    meta: objectMetadata,
})

export interface Repository extends t.TypeOf<typeof repository> {}
export const repository = t.strict({
    type: repositoryType,
    id: repositoryId,
    name: t.string,
    meta: objectMetadata,
    branches: t.array(branch),
    description: t.string,
    contributors: t.array(userId),
})

export interface ClientRepository extends t.TypeOf<typeof clientRepository> {}
export const clientRepository = t.intersection([
    repository,
    t.type({
        contributors: t.array(
            t.type({
                id: userId,
                firstName: t.string,
                lastName: t.string,
                email: t.string,
                avatarURL: t.string,
            })
        ),
    }),
])

export interface GithubRepository extends t.TypeOf<typeof githubRepository> {}
export const githubRepository = t.intersection([
    repository,
    t.type({
        type: t.literal(RepositoryType.GITHUB_REPOSITORY),
    }),
])

export interface GiteaRepository extends t.TypeOf<typeof giteaRepository> {}
export const giteaRepository = t.intersection([
    repository,
    t.strict({
        type: t.literal(RepositoryType.GITEA_REPOSITORY),
        owner: t.string,
        hookSecret: t.string,
    }),
    t.exact(
        t.partial({
            organization: t.string,
        })
    ),
])
