import { Result } from '@shapeci/ui'
import { Group } from '@styled-icons/boxicons-regular'
import { FC } from 'react'
import styled from 'styled-components'

import { useAppNavigate } from '../hooks/useAppNavigate'
import { MENU_BAR_HEIGHT } from './Layout/constants'

const ResultWrapper = styled.div`
    margin-top: calc(-1 * ${MENU_BAR_HEIGHT});
    height: 100%;
    width: 100%;
`

const NoTeam: FC = () => {
    const navigate = useAppNavigate()

    return (
        <ResultWrapper>
            <Result
                message="You have no teams"
                icon={<Group />}
                actions={[
                    {
                        label: 'Create Team',
                        onClick: () => navigate('/settings/user/teams', { isNew: true }),
                    },
                    {
                        label: 'See Invitations',
                        onClick: () => navigate('/notifications', { isNew: true }),
                        kind: 'secondary',
                    },
                ]}
            />
        </ResultWrapper>
    )
}

export default NoTeam
