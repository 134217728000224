import styled from 'styled-components'

const ToolbarSeparator = styled.div`
    align-self: stretch;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.grey500};
    margin: 0 0.5rem;
`

export default ToolbarSeparator
