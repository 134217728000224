import { Alert, BaseAlertType, DocumentAlert, DocumentAlertType } from '../models/alert.model'
import { BaseDocument, DocumentType } from '../models/document.model'

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isDocumentArray = (array: any[]): array is BaseDocument[] => {
    if (array.length === 0) return false

    return isDocument(array[0])
}

export const isDocument = (obj: any): obj is BaseDocument =>
    obj.type === DocumentType.REVIEW_DOCUMENT || obj.type === DocumentType.KNOWLEDGE_DOCUMENT

export const isAlert = (obj: any): obj is Alert => isBaseAlert(obj) || isDocumentAlert(obj)

export const isBaseAlert = (obj: any): obj is Alert =>
    (<any>Object).values(BaseAlertType).includes((obj as Alert).type)

export const isDocumentAlert = (obj: any): obj is DocumentAlert =>
    (<any>Object).values(DocumentAlertType).includes((obj as DocumentAlert).type)
/* eslint-enable @typescript-eslint/no-explicit-any */
