/* eslint-disable no-restricted-syntax */
import { EditorNode, InlineText } from '@shapeci/types'
import { Text } from 'slate'

import { hasChildren } from './blocks'

type FilterNode = (node: EditorNode) => boolean

export const findFirstText = (node: EditorNode, maxDepth?: number): InlineText =>
    findNode(node, Text.isText) as InlineText

export const findNode = (
    node: EditorNode,
    filterFn: FilterNode,
    maxDepth = 1
): EditorNode | null => {
    if (maxDepth < 0) return null

    if (filterFn(node)) return node

    if (!hasChildren(node)) return null

    for (const child of node.children) {
        const found = findNode(child, filterFn, maxDepth - 1)
        if (found) return found
    }

    return null
}
