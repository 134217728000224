import {
    BlockType,
    EditorNode,
    InlineNode,
    ModelId,
    ModelViewerState,
    modelViewerState,
    RepositoryId,
} from '@shapeci/types'
import { isRight } from 'fp-ts/lib/Either'
import cloneDeep from 'lodash.clonedeep'
import { Text } from 'slate'

export type WithModelViewerState = { value: ModelViewerState }
export type WithChildren = { children: EditorNode[] }
export type WithModelAndRepo = {
    value: { modelId: ModelId; repoId: RepositoryId }
}

export const getModelViewerStateFromBlock = (element: EditorNode | undefined) => {
    if (!element || element.type !== BlockType.MODEL_VIEWER) return {}

    if (typeof element.value === 'string') return {}

    return cloneDeep(element.value)
}

export const isInlineNode = (node: EditorNode): node is InlineNode =>
    [BlockType.INLINE_TEXT, BlockType.LINK].includes(node.type) || Text.isText(node)

/* eslint-disable @typescript-eslint/no-explicit-any */
export const isEditorNode = (d: any): d is EditorNode => d.type

export const hasChildren = <T extends WithChildren>(d: any): d is T => d?.children?.length

export const hasModelAndRepoState = <T extends WithModelAndRepo>(b: any): b is T =>
    b?.value?.modelId && b?.value?.repoId

export const hasModelViewerState = <T extends WithModelViewerState>(b: any): b is T => {
    const result = modelViewerState.validate(b?.value, [])
    return isRight(result)
}
/* eslint-enable @typescript-eslint/no-explicit-any */
