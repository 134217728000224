import {
    BaseAvatar,
    LoaderAvatar,
    NoImageAvatar,
    TeamAvatar,
    UserAvatar,
} from '@shapeci/components'
import { ClientTeam, DocumentCollaborator, Team } from '@shapeci/types'
import { joinClassNames } from '@shapeci/ui'
import { getAvatarBucketUri } from '@shapeci/utils'
import { FC, ReactNode, SyntheticEvent } from 'react'
import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

interface LockupProps {
    size?: number
    imageSrc?: string
    title: string
    subtitle?: string
    onClick?: (e: SyntheticEvent) => any
    isInTable?: boolean
    className?: string

    avatarElement?: ReactNode
}

interface LockupLoaderProps {
    size?: number
    withSubtitle?: boolean
}

interface UserLockupProps extends Pick<LockupProps, 'size' | 'onClick' | 'subtitle'> {
    user: DocumentCollaborator
    isInTable?: boolean
}

interface TeamLockupProps extends Pick<LockupProps, 'size' | 'onClick' | 'subtitle'> {
    team: ClientTeam | Team
}

interface PendingAccountLockupProps extends Pick<LockupProps, 'size' | 'onClick' | 'subtitle'> {
    email: string
}

const LockupWrapper = styled.div<{ $size: number; $isInTable?: boolean }>`
    display: flex;
    align-items: center;
    width: 100%;

    ${({ onClick }) =>
        onClick &&
        `
      cursor: pointer;
    `}

    .shape__avatar {
        margin-right: ${({ theme, $size }) => theme.getSpacing(1 + $size)} !important;
        font-size: ${({ $isInTable }) => ($isInTable ? '0.8em' : '1em')};
    }

    > div:not(.shape__avatar) {
        display: flex;
        flex-direction: column;
        color: ${({ theme, $isInTable }) => ($isInTable ? 'inherit' : theme.colors.grey600)};
        overflow: hidden;
        flex: 1;

        h2 {
            font-size: ${({ $isInTable }) => ($isInTable ? 'inherit' : '1rem')};
            font-weight: ${({ $isInTable }) => ($isInTable ? '400' : '500')};
            margin: 0 0 ${({ theme }) => theme.getSpacing(0.25)};

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 100%;
        }

        h3 {
            font-size: 0.875rem;
            font-weight: 400;
            margin: 0;
        }
    }
`

LockupWrapper.defaultProps = {
    className: 'shape__lockup',
}

const Lockup: FC<LockupProps> = ({
    size = 1,
    imageSrc,
    title,
    subtitle,
    onClick,
    avatarElement,
    isInTable,
    className,
}) => (
    <LockupWrapper
        $size={size}
        $isInTable={isInTable}
        onClick={onClick}
        className={joinClassNames('shape__lockup', className)}
    >
        {avatarElement ??
            (imageSrc ? <img src={imageSrc} alt={title} /> : <NoImageAvatar $color={'grey'} />)}
        <div>
            <h2>{title}</h2>
            {subtitle && <h3>{subtitle}</h3>}
        </div>
    </LockupWrapper>
)

export const LockupLoader: FC<LockupLoaderProps> = ({ size = 1, withSubtitle = false }) => (
    <LockupWrapper $size={size}>
        <LoaderAvatar />
        <div>
            <h2>
                <Skeleton width={100} />
            </h2>
            {withSubtitle && (
                <h3>
                    <Skeleton width={60} />
                </h3>
            )}
        </div>
    </LockupWrapper>
)

function UserLockup({ user, isInTable, ...props }: UserLockupProps) {
    return (
        <Lockup
            isInTable={isInTable}
            avatarElement={
                <UserAvatar
                    user={user}
                    showTooltip={false}
                    imageServerURL={getAvatarBucketUri(localStorage)}
                />
            }
            title={`${user.firstName} ${user.lastName}`}
            {...props}
        />
    )
}

function PendingAccountLockup({ email, ...props }: PendingAccountLockupProps) {
    return (
        <Lockup
            avatarElement={<BaseAvatar id={email} noImageText={email?.[0]} />}
            title={email}
            {...props}
        />
    )
}

function TeamLockup({ team, ...props }: TeamLockupProps) {
    return (
        <Lockup
            avatarElement={
                <TeamAvatar team={team} imageServerURL={getAvatarBucketUri(localStorage)} />
            }
            title={`${team.name}`}
            {...props}
        />
    )
}

Lockup.defaultProps = {
    size: 1,
}

export default Lockup
export { PendingAccountLockup, TeamLockup, UserLockup }
