/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable  consistent-return */

import {
    CrossSectionTab,
    MODEL_VIEWER_ERROR_WRAPPER_CLASS,
    MODEL_VIEWER_WRAPPER_CLASS,
    ModelViewer,
    ModelViewerButton,
    ModelViewerState,
    PartTreeTab,
    SHADOW_WRAPPER_CLASS,
} from '@shapeci/components'
import { BlockType, ModelViewerState as ModelViewerNodeState } from '@shapeci/types'
import { ContextMenu, Portal, zIndex } from '@shapeci/ui'
import { EDITOR_WIDTH, parseNumber } from '@shapeci/utils'
import { Trash } from '@styled-icons/bootstrap/Trash'
import { Save } from '@styled-icons/boxicons-solid'
import { ResizeFullScreen } from '@styled-icons/entypo'
import { Scissors } from '@styled-icons/entypo/Scissors'
import { CloseFullscreen, RestartAlt } from '@styled-icons/material'
import cloneDeep from 'lodash.clonedeep'
import throttle from 'lodash.throttle'
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react'
import { Editor, Node, Path, Text, Transforms } from 'slate'
import { ReactEditor, useReadOnly } from 'slate-react'
import styled, { css } from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

import Spinner from '../../components/Spinner'
import {
    getModelViewerStateFromBlock,
    hasModelAndRepoState,
    hasModelViewerState,
} from '../../helpers/blocks'
import { insertBlock, removeModel, selectNodeStart } from '../../helpers/transforms'
import { useSlateReactStatic } from '../../hooks/useSlateReactStatic'
import { useAuthStore } from '../../stores/auth'
import {
    FULLSCREEN_HEIGHT,
    FULLSCREEN_MARGIN,
    FULLSCREEN_WIDTH,
    MINIMIZED_HEIGHT,
} from '../constants'
import { ModelViewerBlockProps } from './types'

interface ModelViewerContextMenuProps {
    onRemove: () => void
    children: React.ReactNode
    isReadOnly: boolean
}

const ModelViewerContextMenu: React.FC<ModelViewerContextMenuProps> = ({
    onRemove,
    children,
    isReadOnly,
}) => {
    if (isReadOnly) {
        return <>{children}</>
    }

    return (
        <ContextMenu.Root>
            <ContextMenu.Trigger asChild>{children}</ContextMenu.Trigger>
            <ContextMenu.Primitives.Portal>
                <ContextMenu.Content>
                    <ContextMenu.Item onClick={onRemove} style={{ color: 'red' }}>
                        <ContextMenu.LeftSlot>
                            <Trash style={{ color: 'red' }} />
                        </ContextMenu.LeftSlot>
                        Remove
                    </ContextMenu.Item>
                </ContextMenu.Content>
            </ContextMenu.Primitives.Portal>
        </ContextMenu.Root>
    )
}

const ViewerWrapper = styled.div<{
    width: string
    height: string
    isReadOnly: boolean
    isSelected: boolean
    isFullscreen: boolean
}>`
    .${MODEL_VIEWER_ERROR_WRAPPER_CLASS} {
        width: ${({ width }) => width};
        height: ${({ height }) => height};
    }

    .${MODEL_VIEWER_WRAPPER_CLASS} {
        width: ${({ width }) => width};
        height: ${({ height }) => height};
        border-radius: ${({ theme }) => theme.borderRadius} ${({ theme }) => theme.borderRadius} 0 0;
        border: ${({ theme }) => theme.border};
        border-bottom: none;
    }

    .${SHADOW_WRAPPER_CLASS} {
        margin: 4px;
        transition: box-shadow 0.125s ease;
        box-shadow: ${({ theme, isSelected, isReadOnly }) =>
            isSelected && !isReadOnly ? `0 0px 4px 4px ${theme.colors.primary200}` : 'none'};
        border-radius: 7px;
    }
`

interface FullscreenModalProps {
    style: React.CSSProperties
}

const FullscreenModal = styled.div<FullscreenModalProps>`
    ${({ style }) => css({ ...style })}
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Change the color and opacity as needed
    z-index: ${zIndex.MODAL - 1}; // Ensure the overlay is below the viewer
`

const RESIZE_THROTTLE_MS = 50

const ModelViewerBlock: FC<ModelViewerBlockProps> = ({ attributes, children, element, store }) => {
    const { getAccessTokenSilently } = useAuthStore((s) => s.auth)
    const editor = useSlateReactStatic()
    const path: number[] = ReactEditor.findPath(editor, element as Node)

    const [hiddenObjectIds, setHiddenObjectIds] = useState(new Set<string>())
    const [isCreatingXSection, setIsCreatingXSection] = useState(false)
    const [modelViewerState, setModelViewerState] = useState<ModelViewerState | null>(null)
    const [saveIcon, setSaveIcon] = useState<JSX.Element>(() => <Save />)
    const [isFullscreen, setFullscreen] = useState(false)

    const [viewerStyle, setViewerStyle] = useState<CSSProperties>({})
    const [viewerWidth, setViewerWidth] = useState(EDITOR_WIDTH)
    const [viewerHeight, setViewerHeight] = useState(EDITOR_WIDTH)
    const [cutoutOffset, setCutoutOffset] = useState('0')
    const isReadOnly = useReadOnly()
    const [isSelected, setIsSelected] = useState(false)
    const prevIsSelectedRef = useRef(isSelected)
    const viewerWrapperRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        const restyle = throttle(() => setImmediate(updateViewerStyle), RESIZE_THROTTLE_MS, {
            leading: true,
            trailing: false,
        })
        window.addEventListener('resize', restyle, { passive: true })
        window.dispatchEvent(new Event('resize'))
        return () => {
            window.removeEventListener('resize', restyle)
        }
    }, [isFullscreen])

    // Pull in state from the editor node
    useDeepCompareEffect(() => {
        setStateFromNodeState()
    }, [element])

    useEffect(() => {
        const node = document.getElementById(element.id)
        if (!node) return

        const viewerWrapper = node.querySelector(`:scope > .${MODEL_VIEWER_WRAPPER_CLASS}`)
        if (!viewerWrapper) return

        const offset = viewerWrapper.getBoundingClientRect().top - viewerWrapper.scrollTop
        setCutoutOffset(`${offset}px`)
    }, [viewerStyle, viewerHeight])

    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [cutoutOffset])

    let blurTimeout: number | null = null

    const handleBlur = () => {
        blurTimeout = window.setTimeout(() => {
            setIsSelected(false)
        }, 100)
    }

    const isNewModelViewerSelected = () => {
        const currentSelection = editor.selection
        const selectedPath = currentSelection
            ? Editor.path(editor, currentSelection).slice(0, -1)
            : null
        return selectedPath && !Path.equals(path, selectedPath)
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                viewerWrapperRef.current &&
                !viewerWrapperRef.current.contains(event.target as globalThis.Node) &&
                (event.target as HTMLElement).id !== 'insert-model-viewer-button' &&
                !isReadOnly
            ) {
                handleBlur()
            }
        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        if (isNewModelViewerSelected() && isSelected && !isReadOnly) {
            handleBlur()
        }
    }, [editor.selection])

    useEffect(() => {
        const prevIsSelected = prevIsSelectedRef.current
        prevIsSelectedRef.current = isSelected
        if (prevIsSelected && !isSelected && !isReadOnly) {
            // Deselect the current node
            Transforms.deselect(editor)
            // return if user selected another model viewer.
            if (isNewModelViewerSelected()) return

            // Get the last node
            const lastNodePath = Editor.path(editor, Editor.end(editor, []))
            const lastNode = Node.get(editor, lastNodePath)
            // new block to be selected
            let newBlockPath = [lastNodePath[0]]
            let insertNewNode = false

            if (Text.isText(lastNode)) {
                // If a text node, check to see if the text content is empty. If it is not. Insert a new node.
                if (lastNode.text !== '') {
                    insertNewNode = true
                }
            } else {
                insertNewNode = true
            }

            if (insertNewNode) {
                // If it's a text or paragraph node without content, replace it with the new block
                newBlockPath = [lastNodePath[0] + 1]
                insertBlock(editor, '', BlockType.PARAGRAPH, newBlockPath)
            }
            selectNodeStart(editor, newBlockPath.concat(0))
        }
    }, [isSelected])

    const setStateFromNodeState = () => {
        if (!hasModelViewerState(element) || !hasModelAndRepoState(element)) {
            console.error(`Invalid viewer state: No model and repo`)
            return
        }

        setModelViewerState({
            ...(element.value || {}),
            modelId: element.value.modelId,
            repoId: element.value.repoId,
            modelPath: element.value.modelPathData,
            hiddenObjectIds,
        })
    }

    const onObjectVisibilityChanged = (id: string, isVisible: boolean) => {
        if (isVisible) return onObjectDisplayed(id)
        return onObjectHidden(id)
    }

    const onObjectHidden = (id: string) => {
        setHiddenObjectIds((objs) => {
            objs.add(id)
            return objs
        })
    }

    const onObjectDisplayed = (id: string) => {
        setHiddenObjectIds((objs) => {
            objs.delete(id)
            return objs
        })
    }

    const saveViewerState = (viewerState: ModelViewerNodeState) => {
        const existingState = getModelViewerStateFromBlock(element)

        Transforms.setNodes(
            editor,
            {
                value: { ...existingState, ...cloneDeep(viewerState) },
            } as Partial<Node>,
            { at: path }
        )
    }

    const buttons: ModelViewerButton[] = [
        {
            id: 'cross-section',
            icon: <Scissors />,
            $isSelected: isCreatingXSection,
            tooltipText: 'Create cross section',
            onClick: (engine) => {
                if (!engine) return
                engine.createCrossSection()
                setIsCreatingXSection((s) => !s)
            },
        },
        {
            id: 'save',
            icon: saveIcon,
            $isSelected: false,
            tooltipText: 'Save view',
            onClick: (engine) => {
                saveViewerState(engine.getViewerState(Array.from(hiddenObjectIds)))
                setSaveIcon(() => <Spinner />)
                setTimeout(() => setSaveIcon(() => <Save />), 750)
            },
        },
        {
            id: 'reset',
            icon: <RestartAlt />,
            $isSelected: false,
            tooltipText: 'Reset view',
            onClick: (engine) => {
                if (hasModelViewerState(element)) engine.setViewerState(cloneDeep(element.value))
            },
        },
        {
            id: 'fullscreen',
            icon: isFullscreen ? <CloseFullscreen /> : <ResizeFullScreen />,
            $isSelected: false,
            tooltipText: 'Fullscreen',
            onClick: () => setFullscreen((f) => !f),
        },
    ]

    const updateViewerStyle = () => {
        if (!isFullscreen) {
            setViewerStyle(attributes.style)
            setViewerWidth(`${(parseNumber(EDITOR_WIDTH) ?? 0) - 0}`)
            setViewerHeight(MINIMIZED_HEIGHT)
        } else {
            const editorInner = document.getElementById('editor-inner')
            const editorBounds = editorInner?.getBoundingClientRect()
            if (!editorBounds) return

            const top = parseNumber(FULLSCREEN_MARGIN, 10)
            const left = parseNumber(FULLSCREEN_MARGIN, 10)
            const height = FULLSCREEN_HEIGHT
            const width = FULLSCREEN_WIDTH
            setViewerWidth(width)
            setViewerHeight(height)

            setViewerStyle({
                ...attributes.style,
                position: 'fixed',
                top,
                left,
                width,
                height,
                zIndex: `${zIndex.MODAL}`,
            })
        }
    }

    const handleRemove = () => {
        setModelViewerState(null)
        removeModel(editor, path)
    }

    if (!modelViewerState) return null

    const handleClick = () => {
        if (isReadOnly) return
        if (blurTimeout !== null) {
            window.clearTimeout(blurTimeout)
        }
        Transforms.deselect(editor)
        Transforms.select(editor, path)
        setIsSelected(true)
    }

    const renderModelViewer = () => (
        <ModelViewerContextMenu onRemove={handleRemove} isReadOnly={isReadOnly}>
            <ViewerWrapper
                className="model-viewer-block"
                ref={viewerWrapperRef}
                width={viewerWidth}
                height={viewerHeight}
                isReadOnly={isReadOnly}
                isFullscreen={isFullscreen}
                isSelected={isSelected}
                onClick={() => handleClick()}
            >
                <ModelViewer
                    buttons={buttons}
                    state={modelViewerState}
                    style={viewerStyle}
                    domAttributes={attributes}
                    onObjectVisibilityChanged={onObjectVisibilityChanged}
                    onCrossSectionChanged={() => setIsCreatingXSection(false)}
                    store={store}
                    containerId={element.id}
                    getAccessToken={getAccessTokenSilently}
                    tabs={[PartTreeTab, CrossSectionTab]}
                >
                    {children}
                </ModelViewer>
            </ViewerWrapper>
        </ModelViewerContextMenu>
    )

    if (isFullscreen) {
        return (
            <Portal>
                <Overlay />
                <FullscreenModal style={viewerStyle}>{renderModelViewer()}</FullscreenModal>
            </Portal>
        )
    }
    return renderModelViewer()
}

export default ModelViewerBlock
