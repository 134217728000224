import { TDescendant, TNode, TOperation } from '@udecode/plate'

interface HasNode<N extends TDescendant = TDescendant> {
    node: N
}

export const hasNode = <T extends TOperation>(op: T): op is T & HasNode => !!op.node

interface HasChildren<N extends TDescendant = TDescendant> {
    children: N[]
}

export const hasChildren = <T extends TNode>(node: T): node is T & HasChildren =>
    !!(node.children as any)?.length
