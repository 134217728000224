import { Theme } from '@shapeci/ui'
import { createGlobalStyle } from 'styled-components'

const LocalGlobalStyle = createGlobalStyle<{ theme: Theme }>`
    .slate-BalloonToolbar {
        &&& {
            border-radius: ${({ theme }) => theme.borderRadius};
            border: ${({ theme }) => theme.border};
            border-width: 0.5px;
            background-color: ${({ theme }) => theme.colors.grey100};
            color: ${({ theme }) => theme.colors.grey700};

            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.125);
            padding: 0;
            display: flex;
            align-items: stretch;
            justify-content: center;
            min-height: unset;
            height: 2.25rem;

            .slate-ToolbarButton {
                height: 100%;
                width: 2.125rem;
                border-radius: calc(${({ theme }) => theme.borderRadius} - 0.25rem);

                :hover {
                    background-color: ${({ theme }) => theme.colors.grey300};
                }

                &.slate-ToolbarButton-active {
                    background-color: ${({ theme }) => theme.colors.primary50};
                    color: ${({ theme }) => theme.colors.primary500};
                }

                svg {
                    width: 1.25em;
                    height: 1.25em;
                }
            }
        }
    }

    ::selection {
        background-color: ${({ theme }) => theme.colors.primary200};
    }
`

export default LocalGlobalStyle
