import * as t from 'io-ts'

import { account } from '../../models/auth.model'
import { partialOnType, pickOnType } from '../../utils/io-ts.util'
import { PayloadTypes } from './http.payloads'

const ACCOUNT_MUTABLE_ATTRIBUTES = ['isSubscribedToMarketingEmails'] as const

export interface UpdateAccountPayload extends t.TypeOf<typeof updateAccountPayload> {}
export const updateAccountPayload = t.type({
    type: t.literal(PayloadTypes.UPDATE_ACCOUNT),
    account: partialOnType(pickOnType(account, ACCOUNT_MUTABLE_ATTRIBUTES)),
})

export interface LinkAccountPayload extends t.TypeOf<typeof linkAccountPayload> {}
export const linkAccountPayload = t.type({
    type: t.literal(PayloadTypes.LINK_ACCOUNTS),
    password: t.string,
})
