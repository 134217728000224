/* eslint-disable no-param-reassign */
import { deleteText, PlateEditor, TNodeEntry, Value } from '@udecode/plate'
import { Element, Text } from 'slate'

const BOM_BYTE = '\uFEFF'

/**
 * For some reason, slate keeps inserting BOM bytes which are rendereded as invisible characters. If one of these
 * marks is at the beginning of a line, when the user presses "delete", they would expect the newline to be deleted
 * but instead the BOM byte is deleted, so it looks like nothing happened.
 */
export const withRemoveBOM = <V extends Value = Value, E extends PlateEditor<V> = PlateEditor<V>>(
    editor: E
) => {
    const { normalizeNode } = editor

    editor.normalizeNode = (entry) => {
        const didRemove = removeBOM<V, E>(editor, entry)
        if (didRemove) return

        normalizeNode(entry)
    }

    return editor
}

const removeBOM = <V extends Value, E extends PlateEditor<V>>(editor: E, entry: TNodeEntry) => {
    const [node, at] = entry
    if (!Element.isElement(node)) return false

    if (!node.children?.length) return false

    let didDeleteText = false

    for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i]

        if (Text.isText(child) && child.text.includes(BOM_BYTE)) {
            const indices = allIndicesOf(child.text, BOM_BYTE)
            for (let idx = 0; idx < indices.length; ++idx) {
                const nodeAt = {
                    anchor: {
                        path: at.concat(i),
                        offset: indices[idx] - idx,
                    },
                    focus: {
                        path: at.concat(i),
                        offset: indices[idx] - idx + 1,
                    },
                }

                deleteText(editor, { at: nodeAt })
                didDeleteText = true
            }
        }
    }
    return didDeleteText
}

const allIndicesOf = (str: string, search: string) => {
    const indices = []

    for (let index = 0; index < str.length; index++) {
        if (str[index] === search) {
            indices.push(index)
        }
    }

    return indices
}
