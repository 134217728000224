import * as t from 'io-ts'

import { documentCollaborator } from '../types/documentCollaborator.types'
import { omitOnType } from '../utils/io-ts.util'
import { commentId } from './id.model'
import { objectMetadata } from './metadata.model'
import { MongoModelType } from './types.model'

export const COMMENT_UNSETTABLE_ATTRIBUTES = ['meta'] as const
export const COMMENT_MUTABLE_ATTRIBUTES = ['content'] as const

export interface Comment extends t.TypeOf<typeof comment> {}
export const comment = t.strict({
    type: t.literal(MongoModelType.COMMENT),
    meta: objectMetadata,
    id: commentId,
    content: t.string,
})

export interface ClientComment extends t.TypeOf<typeof clientComment> {}
export const clientComment = t.intersection([
    omitOnType(comment, ['meta']),
    t.type({
        meta: t.intersection([
            omitOnType(objectMetadata, ['creator']),
            t.type({
                creator: documentCollaborator,
            }),
        ]),
    }),
])
