import { Button, Textarea } from '@shapeci/ui'
import { Send as SendDisabled, Send as SendEnabled } from '@styled-icons/boxicons-regular'
import { MouseEvent, useCallback, useState } from 'react'
import styled from 'styled-components'

const CommentEditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.5rem;
    gap: 0.5rem;
    position: relative;
    background: ${({ theme }) => theme.colors.grey300};

    align-items: stretch;
    justify-content: flex-start;

    :first-child {
        border-top-left-radius: ${({ theme }) => theme.borderRadius};
        border-top-right-radius: ${({ theme }) => theme.borderRadius};
    }

    :last-child {
        border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
        border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
    }

    :not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.colors.grey500};
    }

    .button-wrapper {
        display: flex;
        gap: 0.5rem;

        button {
            padding: 0.25rem 0.5rem;
        }
    }

    button:first-child {
        border-radius: ${({ theme }) => theme.borderRadius};
        background-color: ${({ theme }) => theme.colors.primary500};
        position: relative;
        color: ${({ theme }) => theme.colors.grey100};

        svg {
            margin-left: 0.5rem;
            width: 1rem;
            height: 1rem;

            color: ${({ theme }) => theme.colors.grey100};
        }

        :disabled {
            background-color: ${({ theme }) => theme.colors.grey600};
            cursor: not-allowed;

            svg {
                color: ${({ theme }) => theme.colors.grey500};
                cursor: not-allowed;
            }
        }
    }

    button:last-child {
        color: ${({ theme }) => theme.colors.grey600};
    }
`

const CommentTextarea = styled(Textarea)`
    font-family: ${({ theme }) => theme.fonts};
    min-height: 3.5rem;
    padding: 0.5rem;

    border: 1px solid ${({ theme }) => theme.colors.grey500};
    font-size: 0.9125rem;
    line-height: 1.5;

    box-sizing: border-box;

    resize: none;

    border-radius: 0.25rem;
    background: ${({ theme }) => theme.colors.grey100};

    transition: outline 0.075s ease-in-out;
`

CommentEditorContainer.defaultProps = {
    className: 'shape__comment-editor',
}

export interface CommentEditorProps {
    defaultValue?: string
    submitComment: (content: string) => void
    editComment: (content: string) => void
    cancelComment: (e: MouseEvent, isExplicit?: boolean) => void
}

const getSubmitButtonIcon = (content: string, isSubmitting: boolean) => {
    if (isSubmitting) {
        return <SendDisabled />
    }
    if (content.length > 0) {
        return <SendEnabled />
    }
    return <SendDisabled />
}

export default function CommentEditor({
    submitComment,
    editComment,
    cancelComment,
    defaultValue,
}: CommentEditorProps) {
    const [content, setContent] = useState(defaultValue ?? '')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const isEdit = !!defaultValue

    const handleSubmit = useCallback(async () => {
        if (!content) {
            return
        }

        setIsSubmitting(true)

        if (isEdit) {
            if (editComment) {
                await editComment(content)
            }
        } else {
            await submitComment(content)
        }

        setIsSubmitting(false)
        setContent('')
    }, [isEdit, content])

    return (
        <CommentEditorContainer>
            <CommentTextarea
                placeholder="Leave a comment..."
                value={content}
                onChange={(v) => setContent(v)}
            />
            <div className="button-wrapper">
                <Button
                    isLoading={isSubmitting}
                    kind="text"
                    onClick={handleSubmit}
                    disabled={!content}
                    size="small"
                >
                    {!isEdit ? 'Submit' : 'Update'} {getSubmitButtonIcon(content, isSubmitting)}
                </Button>
                <Button
                    size="small"
                    kind="text"
                    disabled={isSubmitting}
                    onClick={(e: MouseEvent) => cancelComment(e, true)}
                >
                    Cancel
                </Button>
            </div>
        </CommentEditorContainer>
    )
}
