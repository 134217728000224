import { AnyObject, createPluginFactory } from '@udecode/plate-core'

import Code from '../../blocks/Code'
import { withOnHoverProps } from '../../helpers/withOnHover'
import { withStyledDraggable } from '../../helpers/withStyledDraggables'
import { MyPlatePlugin } from '../../types'
import { CodeOptions, ELEMENT_CODE } from './types'

export const createCodePluginFactory = (config: { options: CodeOptions }) =>
    createPluginFactory({
        key: ELEMENT_CODE,
        component: withStyledDraggable(
            (props: any, context: any) =>
                Code(
                    withOnHoverProps(
                        {
                            ...props,
                        },
                        config.options.listeners
                    )
                ),
            ELEMENT_CODE
        ),
        isElement: true,
        isVoid: true,
        isInline: false,
    })

export const createCodePlugin = (config: { options: CodeOptions }): MyPlatePlugin<AnyObject> =>
    createCodePluginFactory(config)()
