export enum FileType {
    PDF = 'pdf',
    XML_3D = '3dxml',
    DS_3 = '3ds',
    MF_3 = '3mf',
    BREP = 'brep',
    CATIA_PART = 'CATPart',
    CATIA_PRODUCT = 'CATProduct',
    COLLADA = 'dae',
    CREO_PRT = 'prt',
    CREO_ASM = 'asm',
    DXF = 'dxf',
    DWG = 'dwg',
    FBX = 'fbx',
    ACIS_SAT = 'sat',
    ACIS_SAB = 'sab',
    CDXFB = 'cdxfb',
    GLTF = 'gltf',
    GLB = 'glb',
    IFC = 'ifc',
    IGES = 'iges',
    IGS = 'igs',
    INVENTOR_PRT = 'ipt',
    INVENTOR_ASM = 'iam',
    JT = 'jt',
    NX = 'prt',
    OBJ = 'obj',
    PLY = 'ply',
    PRC = 'prc',
    RHINO = '3dm',
    SOLID_EDGE_PAR = 'par',
    SOLID_EDGE_PSM = 'psm',
    SOLID_EDGE_ASM = 'asm',
    SOLID_WORKS_PRT = 'sldprt',
    SOLID_WORKS_ASM = 'sldasm',
    STEP = 'step',
    STP = 'stp',
    STL = 'stl',
    U3D = 'u3d',
    USD = 'usd',
    USDA = 'usda',
    USDC = 'usdc',
    USDZ = 'usdz',
    VRML = 'wrl',
    X3D = 'x3d',
    XKT = 'xkt',
    JPEG = 'jpeg',
    JPG = 'jpg',
    PNG = 'png',
    GIF = 'gif',
    SVG = 'svg',
    BMP = 'bmp',
    ICO = 'ico',
    MP4 = 'mp4',
    WEBM = 'webm',
    OGG = 'ogg',
    AVI = 'avi',
    MPEG = 'mpeg',
    MOV = 'mov',
    DOC = 'doc',
    DOCX = 'docx',
    XLS = 'xls',
    XLSX = 'xlsx',
    PPT = 'ppt',
    PPTX = 'pptx',
    HTML = 'html',
    XHTML = 'xhtml',
    XML = 'xml',
    CSS = 'css',
    JS = 'js',
    SWF = 'swf',
    DAE = 'dae',
    ZIP = 'zip',
    TAR = 'tar',
    GZIP = 'gzip',
    MD = 'md',
    TXT = 'txt',
    WMV = 'wmv',
    FLV = 'flv',
    PYTHON = 'py',
    JAVA = 'java',
    C = 'c',
    CPP = 'cpp',
    JAVASCRIPT = 'js',
    TYPESCRIPT = 'ts',
    TYPESCRIPT_JSX = 'tsx',
    CSHARP = 'cs',
    GOLANG = 'go',
    RUBY = 'rb',
    SWIFT = 'swift',
    SHELL = 'sh',
}
