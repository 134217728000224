import * as t from 'io-ts'

import { branchDiff } from '../types/diff.types'
import { fromEnum } from '../utils/enum.util'
import { modelId, repositoryId, resourceId } from './id.model'
import { objectMetadata } from './metadata.model'

export enum ResourceType {
    MODEL = 'Model',
    COMMIT = 'Commit',
    DIFF = 'Diff',
}

export const resourceType = fromEnum('ResourceType', ResourceType)

export enum ResourceState {
    SUCCEEDED = 'Succeeded',
    IN_PROGRESS = 'InProgress',
    FAILED = 'Failed',
}

export const resourceState = fromEnum('ResourceState', ResourceState)

export interface BaseResource extends t.TypeOf<typeof baseResource> {}
export const baseResource = t.intersection([
    t.type({
        id: resourceId,
        type: resourceType,
        state: resourceState,
        meta: objectMetadata,
    }),
    t.partial({
        alias: resourceId,
    }),
])

export interface ModelResource extends t.TypeOf<typeof modelResource> {}
export const modelResource = t.intersection([
    baseResource,
    t.type({
        format: t.string,
        ref: t.string,
        modelId,
    }),
])

export interface CommitResource extends t.TypeOf<typeof commitResource> {}
export const commitResource = t.intersection([
    baseResource,
    t.type({
        type: t.literal(ResourceType.COMMIT),
        repoId: repositoryId,
        branchName: t.string,
        commitHash: t.string,
    }),
])

export interface DiffResource extends t.TypeOf<typeof diffResource> {}
export const diffResource = t.intersection([
    baseResource,
    t.type({
        type: t.literal(ResourceType.DIFF),
        repoId: repositoryId,
        baseCommit: t.string,
        incomingCommit: t.string,
    }),
    t.partial({
        diff: branchDiff,
    }),
])
