import { LinkPlugin, PlateFloatingLink } from '@udecode/plate'

import { MyPlatePlugin } from '../../types'

const DEFAULT_LINK_PROPS = {
    target: '_blank',
    rel: 'noopener noreferrer',
}

export const linkPlugin: Partial<MyPlatePlugin<LinkPlugin>> = {
    renderAfterEditable: PlateFloatingLink,
    props: ({ element }) => ({
        nodeProps: { href: element?.url, ...DEFAULT_LINK_PROPS },
        element: { ...element, ...DEFAULT_LINK_PROPS },
    }),
}
