import {
    Button,
    Card,
    Heading,
    Input,
    LIGHT_THEME,
    Portal,
    Select,
    Space,
    Tooltip,
} from '@shapeci/ui'
import { CheckDouble, Globe, X } from '@styled-icons/boxicons-regular'
import { Copy, LockAlt } from '@styled-icons/boxicons-solid'
import { CSSProperties, FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useAuth } from '../../hooks/useAuth'
import Modal from '../Modal'

// time for copy button to be active again in ms
const COPY_RESET_TIMEOUT = 1000

const ShareModal = styled(Modal)`
    ${Card} {
        max-width: 600px;
        width: 95%;
        box-sizing: border-box;
        padding: ${({ theme }) => theme.getSpacing(4)};
        overflow: visible;

        p {
            color: ${({ theme }) => theme.colors.grey600};
            margin-bottom: ${({ theme }) => theme.getSpacing(4)};
            margin: 0 0 ${({ theme }) => theme.getSpacing(1)};
            font-weight: 400;
            line-height: 1.5;
        }

        .copy-wrapper {
            display: flex;
            align-items: stretch;
            gap: ${({ theme }) => theme.getSpacing(1)};

            > div {
                flex: 1;

                input {
                    width: 100%;
                    box-sizing: border-box;
                }
            }

            button {
                min-width: 90px;
                margin-left: ${({ theme }) => theme.getSpacing(1)};
                display: flex;
                padding: 0;
                align-items: center;
                justify-content: center;
            }
        }

        label {
            margin-top: ${({ theme }) => theme.getSpacing(2)};
            font-weight: 400;

            p {
                margin: 0;
            }
        }

        .react-select__control {
            min-width: 150px;
            background: var(--share-menu-background);

            .react-select__single-value,
            .react-select__indicator {
                color: var(--share-menu-text) !important;
            }

            .react-select__indicator {
                opacity: 0.625;
            }

            :hover .react-select__indicator {
                opacity: 0.875;
            }
        }

        .react-select--is-disabled .react-select__indicators,
        .react-select__indicator-separator {
            display: none;
        }

        .react-select__value-container svg {
            margin-right: 0.25rem;
        }
    }
`

enum ShareMode {
    TEAM = 'team',
    PUBLIC = 'public',
}

interface ShareModeOption {
    label: ReactNode
    description: string
    value: ShareMode
    backgroundColor: string
    textColor: string
}

const SHARE_MODES_OPTIONS: ShareModeOption[] = [
    {
        label: (
            <Space size={1}>
                <LockAlt size={18} /> Team only
            </Space>
        ),
        description: 'Only user team members can view',
        value: ShareMode.TEAM,
        textColor: LIGHT_THEME.colors.primary800,
        backgroundColor: LIGHT_THEME.colors.primary200,
    },
    {
        label: (
            <Space size={1}>
                <Globe size={18} /> Public
            </Space>
        ),
        description: 'Anyone with the link can view',
        value: ShareMode.PUBLIC,
        textColor: LIGHT_THEME.colors.success800,
        backgroundColor: LIGHT_THEME.colors.success200,
    },
]
const getShareMode = (isDocumentPublic?: boolean): ShareMode | undefined => {
    if (isDocumentPublic === true) {
        return ShareMode.PUBLIC
    }
    if (isDocumentPublic === false) {
        return ShareMode.TEAM
    }
    return undefined
}

const getShareModalDescription = (isDocumentPublic?: boolean) => {
    if (isDocumentPublic === true) {
        return 'Share this document with anyone by copying the link below. Change your access level below to make this document private.'
    }
    if (isDocumentPublic === false) {
        return 'Share this document with your team by copying the link below. Change your access level below to make this document public.'
    }
    return 'Share this folder with your team by copying the link below.'
}

const getTooltipText = (isDocumentPublic?: boolean) => {
    if (isDocumentPublic === true) {
        return 'Anyone with the link can view'
    }
    return 'Only members on your team can view'
}

const ShareButton: FC<{
    isDocumentPublic?: boolean
    setIsDocumentPublic?: (isPublic: boolean) => void
}> = ({ isDocumentPublic, setIsDocumentPublic }) => {
    const { isAuthenticated } = useAuth()
    const { teamDomain } = useParams()

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isCopied, setIsCopied] = useState<boolean>(false)

    useEffect(() => {
        if (isCopied) {
            window.setTimeout(() => setIsCopied(false), COPY_RESET_TIMEOUT)
        }
    }, [isCopied])

    const copyToClipboard = (str: string) => {
        if (navigator?.clipboard?.writeText) {
            return navigator.clipboard.writeText(str)
        }
        throw new Error()
    }

    let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`

    if (isDocumentPublic) {
        url = url.replace(`/${teamDomain}/documents/`, '/public/')
    }

    const shareMode = getShareMode(isDocumentPublic)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const shareModeValue = SHARE_MODES_OPTIONS.find((mode) => mode.value === shareMode)!

    const shareIcon = shareMode === ShareMode.PUBLIC ? <Globe /> : <LockAlt />

    const cssVars = useMemo(
        () =>
            ({
                '--share-menu-background': shareModeValue?.backgroundColor,
                '--share-menu-text': shareModeValue?.textColor,
            } as CSSProperties),
        [shareModeValue]
    )

    return (
        <>
            <Tooltip content={getTooltipText(isDocumentPublic)}>
                <Button onClick={() => setIsModalOpen(true)} prepend={shareIcon}>
                    Share
                </Button>
            </Tooltip>
            <Portal>
                <ShareModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    style={cssVars}
                >
                    <Space size={2} vertical align="stretch">
                        <Space justify="space-between" align="center">
                            <Heading kind="h2" m={0} emphasis>
                                Share
                            </Heading>
                            <Button
                                kind="icon"
                                intent="muted"
                                size="large"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <X />
                            </Button>
                        </Space>
                        <p>{getShareModalDescription(isDocumentPublic)}</p>
                        <div className="copy-wrapper">
                            <Input
                                fluid
                                value={url}
                                onChange={() => {}}
                                onFocus={(e: any) => e.target.select()}
                            />
                            <Button
                                onClick={() => {
                                    try {
                                        copyToClipboard(url)
                                        setIsCopied(true)
                                    } catch (e) {
                                        // eslint-disable-next-line no-alert
                                        alert('Unable to copy.')
                                    }
                                }}
                                prepend={isCopied ? <CheckDouble /> : <Copy />}
                                disabled={isCopied}
                            >
                                {isCopied ? 'Copied' : 'Copy'}
                            </Button>
                        </div>
                        {isDocumentPublic !== undefined && (
                            <Space vertical>
                                <Heading kind="h3" mb={0}>
                                    Access
                                </Heading>
                                <Space as="label" size={2} align="center">
                                    <Select
                                        isClearable={false}
                                        disabled={!isAuthenticated}
                                        options={SHARE_MODES_OPTIONS}
                                        value={shareModeValue}
                                        onChange={(option) => {
                                            setIsDocumentPublic?.(option?.value === 'public')
                                        }}
                                        borderless
                                    />
                                    <p>{shareModeValue?.description}</p>
                                </Space>
                            </Space>
                        )}
                    </Space>
                </ShareModal>
            </Portal>
        </>
    )
}

export default ShareButton
