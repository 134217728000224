import { Card, Select, Space } from '@shapeci/ui'
import styled from 'styled-components'

const PaddedContainer = styled.div`
    box-sizing: border-box;
    padding: 0 ${({ theme }) => theme.getSpacing(3)};
    h1 {
        margin: ${({ theme }) => theme.getSpacing(3)} 0 ${({ theme }) => theme.getSpacing(1.5)} !important;
        font-size: 1.125rem;
        font-weight: 500;
    }

    h2 {
        font-size: 1rem;
        font-weight: 400;
    }

    p {
        font-weight: 400;
        line-height: 1.375;

        &:first-of-type {
            margin-top: 0;
        }
    }
`

const RequestCard = styled(Card)`
    padding: ${({ theme }) => theme.getSpacing(2)} 0;

    button {
        font-size: 0.875rem;
        padding: ${({ theme }) => `${theme.getSpacing(1)} ${theme.getSpacing(2)}`};
    }

    border: none;

    .shape__space {
        width: 100%;
        align-items: stretch;
        margin-top: 0;
        overflow: visible;
    }

    .error {
        color: ${({ theme }) => theme.colors.danger500};
    }

    .button-wrapper {
        button {
            flex: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 1.25rem;
                height: 1.25rem;
                margin: 0 0.25rem 0 -0.375rem;
            }
        }
    }
`

const ReviewCard = styled(Space)`
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .shape__lockup {
        h2 {
            font-size: 0.875rem;
            margin: 0;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.grey700};
        }

        .shape__avatar {
            width: 1.5rem;
            height: 1.5rem;

            span {
                font-size: 0.75rem;
            }
        }
    }

    .shape__space {
        margin-top: 0 !important;
    }
`

const UserCombobox = styled(Select)`
    flex: 1;
    font-size: 0.875rem;

    div[class~='react-select__value-container'] {
        font-size: 0.875rem;

        div {
            white-space: nowrap;
            overflow: hidden;
        }
    }
`

const Separator = styled.hr`
    background: ${({ theme }) => theme.colors.grey500};
    height: 1px;
    border: none;
    margin-top: ${({ theme }) => theme.getSpacing(1)};
`

export { PaddedContainer, RequestCard, ReviewCard, Separator, UserCombobox }
