import * as t from 'io-ts'

import { blockProperties } from '../models/block.model'
import { baseOperation, OperationType } from './base.operations'

export interface SetNodeOperation extends t.TypeOf<typeof setNodeOperation> {}
export const setNodeOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.SET_NODE),
        path: t.array(t.number),
        newProperties: blockProperties,
        properties: t.union([t.null, blockProperties]),
    }),
])
