import * as t from 'io-ts'

import { fromEnum } from '../../utils/enum.util'

export enum FileStatusType {
    DELETED = 1,
    MODIFIED = 2,
    UNTRACKED = 3,
}
export const fileStatusType = fromEnum('StatusType', FileStatusType)

export interface GitBranch extends t.TypeOf<typeof gitBranch> {}
export const gitBranch = t.type({
    oid: t.string,
    head: t.string,
})

export interface ModifiedFile extends t.TypeOf<typeof modifiedFile> {}
export const modifiedFile = t.type({
    path: t.string,
    status: fileStatusType,
})

export interface GitStatus extends t.TypeOf<typeof status> {}
export const status = t.type({
    branch: gitBranch,
    modifiedFiles: t.array(modifiedFile),
})
