import { BlockId, BlockType, EditorNode } from '@shapeci/types'
import { AnyObject, RenderFunction } from '@udecode/plate'
import { MouseEvent } from 'react'

import { BlockProps, hasBlockProps } from '../blocks/types'

type WithListeners<T> = T & {
    attributes: {
        listenerProps: Listeners
    }
}

export interface Listeners {
    onEditorMouseEnter: (id: BlockId, type: BlockType, target?: HTMLElement) => void
    onEditorMouseLeave: (id: BlockId, type: BlockType, target?: HTMLElement) => void
}

const isTopLevelNode = (props: AnyObject) => {
    const topLevelIds = props?.editor?.children
        ?.map((c: EditorNode) => c?.id)
        ?.filter((id: string) => !!id)

    if (!topLevelIds.length) return false

    return topLevelIds.includes(props?.element?.id)
}

const shouldAddHoverListeners = (props: AnyObject) => isTopLevelNode(props)

/**
 * Adds mouse enter/leave listeners to the block's DOM node for slate nodes (excludes leafs)
 */
export const withOnHover =
    (renderer: RenderFunction<AnyObject>, listeners: Listeners) =>
    (props: AnyObject, defaultRender?: (p?: AnyObject) => JSX.Element | null) => {
        // Disable the listeners for leafs or inline elements (e.g. links)
        const shouldAddHover = shouldAddHoverListeners(props)
        const newProps =
            shouldAddHover && hasBlockProps(props) ? withOnHoverProps(props, listeners) : props

        return renderer(newProps, defaultRender)
    }

export const withOnHoverProps = <T extends BlockProps>(
    props: T,
    listeners: Listeners,
    debugMode = false
): WithListeners<T> => {
    const listenerProps: AnyObject = {
        onMouseEnter: (e: MouseEvent) => {
            const currentTarget = e.currentTarget as HTMLElement
            listeners.onEditorMouseEnter(props.element?.id, props.element?.type, currentTarget)
        },

        onMouseLeave: (e: MouseEvent) => {
            const currentTarget = e.currentTarget as HTMLElement
            listeners.onEditorMouseLeave(props.element?.id, props.element?.type, currentTarget)
        },
    }

    const defaultStyle = props.attributes.style || {}
    const debugStyles = {
        ...defaultStyle,
        backgroundColor: 'rgba(255, 0, 0, 0.2)',
        border: '1px solid black',
    }

    return {
        ...props,
        attributes: {
            ...props.attributes,
            ...listenerProps,
            style: debugMode ? debugStyles : defaultStyle,
        },
    }
}
