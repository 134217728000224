import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { LayoutGridAreas } from './constants'

export const PAGE_TITLE_STYLES = css`
    margin: 0;
`

export const PageOuter = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    grid-area: ${LayoutGridAreas.MAIN};
`

export const PageInner = styled.main`
    max-width: 1300px;
    width: 95%;
    margin: 0 auto;

    padding-top: ${({ theme }) => theme.getSpacing(4)};
    box-sizing: border-box;

    > h1 {
        ${PAGE_TITLE_STYLES}
        margin-bottom: 1.6rem;
    }

    .description {
        color: ${({ theme }) => theme.colors.grey600};
    }
`

const FullPageInner = styled(PageInner)`
    height: 100%;
`

export interface PageLayoutProps {
    title?: string
    description?: string
}

const PageLayout: FC<PageLayoutProps> = ({ title, description }) => (
    <PageOuter>
        <FullPageInner>
            {title && <h1>{title}</h1>}
            {description && <p className="description">{description}</p>}
            <Outlet />
        </FullPageInner>
    </PageOuter>
)

export default PageLayout
