import * as t from 'io-ts'

export const restorePointId = t.string
export type RestorePointId = t.TypeOf<typeof restorePointId>

export const tokenId = t.string
export type TokenId = t.TypeOf<typeof tokenId>

export const reviewId = t.string
export type ReviewId = t.TypeOf<typeof reviewId>

export const userId = t.string
export type UserId = t.TypeOf<typeof userId>

export const accountId = t.string
export type AccountId = t.TypeOf<typeof accountId>

export const alertId = t.string
export type AlertId = t.TypeOf<typeof alertId>

export const blockId = t.string
export type BlockId = t.TypeOf<typeof blockId>

export const commentId = t.string
export type CommentId = t.TypeOf<typeof commentId>

export const diffId = t.string
export type DiffId = t.TypeOf<typeof diffId>

export const modelId = t.string
export type ModelId = t.TypeOf<typeof modelId>

export const repositoryId = t.string
export type RepositoryId = t.TypeOf<typeof repositoryId>

export const resourceId = t.string
export type ResourceId = t.TypeOf<typeof resourceId>

export const viewId = t.string
export type ViewId = t.TypeOf<typeof viewId>

export const folderId = t.string
export type FolderId = t.TypeOf<typeof folderId>

export const teamId = t.string
export type TeamId = t.TypeOf<typeof teamId>

export const documentId = t.string
export type DocumentId = t.TypeOf<typeof documentId>

export const threadId = t.string
export type ThreadId = t.TypeOf<typeof threadId>

export const referralId = t.string
export type ReferralId = t.TypeOf<typeof referralId>

export const documentSessionId = t.string
export type DocumentSessionId = t.TypeOf<typeof documentSessionId>
