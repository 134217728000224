import { LoaderAvatar, TeamAvatar } from '@shapeci/components'
import { ClientTeam } from '@shapeci/types'
import { DropdownMenu, Ellipsis, Pill, Portal, Tooltip } from '@shapeci/ui'
import { ReactComponent as Logo } from '@shapeci/ui/dist/assets/wordmark.svg'
import { getAvatarBucketUri } from '@shapeci/utils'
import { Folder as FolderIcon, InfoCircle } from '@styled-icons/boxicons-solid'
import styled, { keyframes } from 'styled-components'

import { cache } from '../../../caches/index'
import { useAppNavigate } from '../../../hooks/useAppNavigate'
import { useAuth } from '../../../hooks/useAuth'
import { AvatarButtonWrapper } from '../../Layout/Sidebar'

const TeamAvatarButton = styled(AvatarButtonWrapper)`
    margin-right: ${({ theme }) => theme.getSpacing(5)};

    :hover:before {
        background: ${({ theme }) => theme.colors.grey300};
    }

    :after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background: ${({ theme }) => theme.colors.grey500};
        top: 0;
        right: -${({ theme }) => theme.getSpacing(3)};
    }
`

const entrance = keyframes`
    from {
        opacity: 0;
        transform: translate(-5%, -10%) scale(0.9);
    }
    to {
        opacity: 1;
        transform: translate(0, 0) scale(1);
    }
`

const TeamMenuContent = styled(DropdownMenu.Content)`
    animation: ${entrance} 0.075s ease-in-out;

    min-width: unset;
    width: 300px;

    h2 {
        font-weight: 500;
        font-size: 0.875rem;
        padding: 0 0.4rem;
        margin: ${({ theme }) => theme.getSpacing(1)} 0;
    }

    .shape__pill {
        height: unset;
        padding: 0.4rem 0.8rem;
        border-radius: ${({ theme }) => theme.borderRadius};
        align-items: start;
    }

    ${DropdownMenu.LeftSlot}[data-team-avatar-slot] {
        left: ${({ theme }) => theme.getSpacing(1)};

        + span {
            margin-left: ${({ theme }) => theme.getSpacing(1)};
        }
    }

    .shape__avatar {
        aspect-ratio: 1/1;
        height: 1.5rem;
        margin-right: ${({ theme }) => theme.getSpacing(1)};
    }
`

const LogoLink = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${({ theme }) => theme.space[1]};
    text-decoration: none;
    border-radius: ${({ theme }) => theme.borderRadius};
    position: relative;

    svg {
        fill: ${({ theme }) => theme.colors.grey700};
    }

    :hover {
        background: ${({ theme }) => theme.colors.grey300};

        svg {
            fill: ${({ theme }) => theme.colors.grey800};
        }
    }

    margin-right: ${({ theme }) => theme.getSpacing(5)};

    :after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background: ${({ theme }) => theme.colors.grey500};
        top: 0;
        right: -${({ theme }) => theme.getSpacing(2.625)};
    }
`

function TeamMenuLoader() {
    return <LoaderAvatar />
}

interface TeamMenuProps {
    location: string
    isPublic: boolean
}

export default function TeamMenu({ location, isPublic }: TeamMenuProps) {
    const { data: routerTeam, isLoading: isLoadingTeam } = cache.useTeam()
    const { data: user, isLoading: isLoadingUser } = cache.useUser()
    const { isAuthenticated } = useAuth()
    const navigate = useAppNavigate()

    // if the team or auth data is loading, show the loader
    if (isLoadingTeam || isLoadingUser) return <TeamMenuLoader />

    // if the user is not authenticated or has no teams, show the wordmark
    if (!isAuthenticated || user?.teams?.length === 0 || !user) {
        return (
            <Tooltip content={'This document was made on shape.ci. Click to learn more.'}>
                <LogoLink href="https://shape.ci?ref=public-doc" target="_blank">
                    <Logo height={30} width={100} />
                </LogoLink>
            </Tooltip>
        )
    }

    // by default try to pull the team from the router (may be null if the user is on a public document)
    let team: ClientTeam

    if (!routerTeam || isPublic) {
        // otherwise just choose the first team on the user
        // eslint-disable-next-line prefer-destructuring
        team = user.teams[0] as any as ClientTeam
    } else {
        team = routerTeam
    }

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
                <TeamAvatarButton key="button">
                    <TeamAvatar team={team} imageServerURL={getAvatarBucketUri(localStorage)} />
                </TeamAvatarButton>
            </DropdownMenu.Trigger>
            <Portal>
                <TeamMenuContent sideOffset={8} align="start">
                    {routerTeam && (
                        <DropdownMenu.Item onClick={() => navigate(location)}>
                            <DropdownMenu.LeftSlot>
                                <FolderIcon />
                            </DropdownMenu.LeftSlot>
                            Show Location
                        </DropdownMenu.Item>
                    )}
                    {team !== routerTeam && (
                        <>
                            <Pill kind="glass" prepend={<InfoCircle />} style={{ margin: 4 }}>
                                This document is not owned by one of your teams. You cannot edit it.
                            </Pill>
                            <h2>Your Teams</h2>
                            {user.teams.map((t) => (
                                <DropdownMenu.Item
                                    key={t.id}
                                    onClick={() =>
                                        navigate('/documents', { updateTeamDomain: t.domain })
                                    }
                                >
                                    <DropdownMenu.LeftSlot data-team-avatar-slot>
                                        <TeamAvatar
                                            team={t}
                                            imageServerURL={getAvatarBucketUri(localStorage)}
                                        />
                                    </DropdownMenu.LeftSlot>
                                    <Ellipsis>{t.name}</Ellipsis>
                                </DropdownMenu.Item>
                            ))}
                        </>
                    )}
                </TeamMenuContent>
            </Portal>
        </DropdownMenu.Root>
    )
}
