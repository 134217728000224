import * as t from 'io-ts'

import { blockProperties } from '../models/block.model'
import { baseOperation, OperationType } from './base.operations'

export interface SplitNodeOperation extends t.TypeOf<typeof splitNodeOperation> {}
export const splitNodeOperation = t.intersection([
    baseOperation,
    t.type({
        type: t.literal(OperationType.SPLIT_NODE),
        path: t.array(t.number),
        position: t.number,
        properties: blockProperties,
    }),
])
