import { LoaderAlt } from '@styled-icons/boxicons-regular'
import styled from 'styled-components'

import { PageOuter } from './Page'

const LoaderIcon = styled(LoaderAlt)`
    animation: 2s infinite spin ease;
    width: 3em;
    height: 3em;

    color: ${({ theme }) => theme.colors.grey400};

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    margin-top: -10em;
`

export default function LoaderPage() {
    return (
        <PageOuter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoaderIcon />
        </PageOuter>
    )
}
