import * as t from 'io-ts'

import { tokenId } from './id.model'
import { objectMetadata } from './metadata.model'
import { MongoModelType } from './types.model'

export interface SingleUseToken extends t.TypeOf<typeof singleUseToken> {}
export const singleUseToken = t.strict({
    type: t.literal(MongoModelType.TOKEN),
    id: tokenId,
    meta: objectMetadata,
    authorization: t.string,
})
