import { createPluginFactory } from '@udecode/plate'

import { UnsplittableAttributesPlugin } from './types'
import { withUnsplittableAttributes } from './withUnsplittableAttributes'

const KEY_PLUGIN_UNSPLITTABLE_ATTRIBUTES = 'unsplittable_attributes'

export const createUnsplittableAttributesPlugin = createPluginFactory<UnsplittableAttributesPlugin>(
    {
        key: KEY_PLUGIN_UNSPLITTABLE_ATTRIBUTES,
        options: {
            attributes: [],
        },
        withOverrides: withUnsplittableAttributes,
    }
)
